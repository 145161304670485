import PropTypes from "prop-types";
import React, { Component } from "react";

import { Button } from "reactstrap";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter, Link } from "react-router-dom";

// Import icons
import Icon from "../Common/Icons";

//i18n
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import { Auth } from "aws-amplify";

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.refDiv = React.createRef();
  }

  componentDidMount() {
    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
  }

  initMenu() {
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    for (const element of items) {
      if (
        this.props.location.pathname === element.pathname &&
        !element.dataset.nopath
      ) {
        matchingMenuItem = element;
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
    return new MetisMenu("#side-menu");
  }

  scrollElement = item => {
    setTimeout(() => {
      if (this.refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop;
          if (currentPosition > window.innerHeight) {
            if (this.refDiv.current)
              this.refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300;
          }
        }
      }
    }, 300);
  };

  activateParentDropdown = item => {
    item.classList.add("active");
    const parent = item.parentElement;

    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag
        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
    }
    this.scrollElement(item);
    return false;
  };

  showJiraPopup = async () => {
    const user = await Auth.currentUserInfo();
    window.ATL_JQ_PAGE_PROPS.fieldValues.fullname =
      user.attributes["custom:first_name"] +
      " " +
      user.attributes["custom:last_name"];
    window.ATL_JQ_PAGE_PROPS.fieldValues.email = user.attributes["email"];
    window.ATL_JQ_PAGE_PROPS.fieldValues.description = "";

    window.showCollectorDialog();
  };

  render() {
    return (
      <React.Fragment>
        <div
          id="sidebar-menu"
          className="d-flex justify-content-between flex-column h-100 pb-0"
        >
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">
              {this.props.t("main_menu", "Main Menu")}
            </li>
            <li>
              <Link to="#" data-nopath={true} className="has-arrow">
                <Icon name="work" />
                <span>{this.props.t("main_menu_work", "Work")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/my/jobs">
                    {this.props.t("main_menu_work_my_jobs", "My jobs")}
                  </Link>
                </li>
                <li>
                  <Link to="#" data-nopath={true}>
                    {this.props.t(
                      "main_menu_work_requests",
                      "Requests and bookings"
                    )}
                  </Link>
                </li>
                <li>
                  <Link to="/work/orders">
                    {this.props.t(
                      "main_menu_work_work_orders",
                      "Work orders and reports"
                    )}
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="#" data-nopath={true} className="has-arrow">
                <Icon name="schedule" />
                <span>{this.props.t("main_menu_booking", "Booking")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="#" data-nopath={true}>
                    {this.props.t(
                      "main_menu_booking_book",
                      "Book an inspection"
                    )}
                  </Link>
                </li>
                <li>
                  <Link to="#" data-nopath={true}>
                    {this.props.t(
                      "main_menu_booking_work_order_details",
                      "Work order details"
                    )}
                  </Link>
                </li>
                <li>
                  <Link to="#" data-nopath={true}>
                    {this.props.t("main_menu_booking_dispatch", "Dispatch")}
                  </Link>
                </li>
                <li>
                  <Link to="#" data-nopath={true}>
                    {this.props.t("main_menu_booking_online", "Online booking")}
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="#" data-nopath={true} className="has-arrow">
                <Icon name="people" />
                <span>{this.props.t("main_menu_clients", "Clients")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="#" data-nopath={true} className="has-arrow">
                    {this.props.t("main_menu_clients_people", "People")}
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="#" data-nopath={true}>
                        {this.props.t(
                          "main_menu_clients_people_clients",
                          "Clients"
                        )}
                      </Link>
                    </li>
                    <li>
                      <Link to="#" data-nopath={true}>
                        {this.props.t(
                          "main_menu_clients_people_realtors",
                          "Real estate professionals"
                        )}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="#" data-nopath={true} className="has-arrow">
                    {this.props.t("main_menu_clients_properties", "Properties")}
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="#" data-nopath={true}>
                        {this.props.t(
                          "main_menu_clients_properties_residential",
                          "Residential"
                        )}
                      </Link>
                    </li>
                    <li>
                      <Link to="#" data-nopath={true}>
                        {this.props.t(
                          "main_menu_clients_properties_commercial",
                          "Commercial"
                        )}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="#" data-nopath={true}>
                    {this.props.t("main_menu_clients_franchises", "Franchises")}
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="#" data-nopath={true} className="has-arrow">
                <Icon name="envelope" />
                <span>
                  {this.props.t("main_menu_horizon_marketer", "Marketer")}
                </span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="#" data-nopath={true}>
                    {this.props.t(
                      "main_menu_horizon_marketer_campaigns",
                      "Campaigns"
                    )}
                  </Link>
                </li>
                <li>
                  <Link to="#" data-nopath={true}>
                    {this.props.t(
                      "main_menu_horizon_marketer_subscriptions",
                      "Subscriptions"
                    )}
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="#" data-nopath={true} className="has-arrow">
                <Icon name="insights" />
                <span>
                  {this.props.t("main_menu_business_insights", "insights")}
                </span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="#" data-nopath={true}>
                    {this.props.t(
                      "main_menu_business_insights_dashboard",
                      "Dashboard"
                    )}
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
          <ul className="mb-0 list-unstyled">
            <li className="border-top">
              <Button
                color="white"
                onClick={this.showJiraPopup}
                className={classNames(
                  "w-100",
                  "text-muted",
                  "d-flex",
                  "align-items-center",
                  "justify-content-start"
                )}
                style={{
                  height: "33px",
                  paddingTop: "0.6rem",
                  paddingLeft: "1.5rem",
                }}
                onMouseEnter={e => e.target.classList.add("btn-light")}
                onMouseLeave={e => e.target.classList.remove("btn-light")}
              >
                <Icon
                  name="bug"
                  className="font-size-16"
                  style={{
                    display: "inline-block",
                    position: "relative",
                    top: "-1px",
                    marginRight: "0.8rem",
                  }}
                />{" "}
                Report a Problem
              </Button>
            </li>
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
};

export default withRouter(withTranslation()(SidebarContent));
