import PropTypes from "prop-types";
import React from "react";
import SideMenuNavItem from "components/Menus/SideMenuNavItem";
import SideMenu from "components/Menus/SideMenu";

import { withTranslation } from "react-i18next";

const Menu = props => {
  return (
    <React.Fragment>
      <SideMenu prefix="report_settings_menu">
        <SideMenuNavItem
          key="templates"
          id="templates"
          label={props.t("templates", "Templates")}
          activeTab={props.activeTab}
          toggleTab={props.toggleTab}
        />
        <SideMenuNavItem
          key="categories"
          id="categories"
          label={props.t("categories", "Categories")}
          activeTab={props.activeTab}
          toggleTab={props.toggleTab}
        />
        <SideMenuNavItem
          key="snippets"
          id="snippets"
          label={props.t("snippets", "Snippets")}
          activeTab={props.activeTab}
          toggleTab={props.toggleTab}
        />
        <SideMenuNavItem
          key="general"
          id="general"
          label={props.t("general", "General")}
          activeTab={props.activeTab}
          toggleTab={props.toggleTab}
        />
      </SideMenu>
    </React.Fragment>
  );
};

Menu.propTypes = {
  t: PropTypes.any,
  toggleTab: PropTypes.func,
  activeTab: PropTypes.string,
};

export default withTranslation()(Menu);
