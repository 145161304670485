/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      ownerId
      id
      name
      subcategoryOrder
      owner {
        level
        id
      }
      templates {
        items {
          templateOwnerId
          templateId
          categoryOwnerId
          categoryId
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      subcategories {
        items {
          id
          categoryId
          categoryOwnerId
          name
          notes
          itemOrder
          parentId
          subcategoryOrder
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      ownerId
      id
      name
      subcategoryOrder
      owner {
        level
        id
      }
      templates {
        items {
          templateOwnerId
          templateId
          categoryOwnerId
          categoryId
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      subcategories {
        items {
          id
          categoryId
          categoryOwnerId
          name
          notes
          itemOrder
          parentId
          subcategoryOrder
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      ownerId
      id
      name
      subcategoryOrder
      owner {
        level
        id
      }
      templates {
        items {
          templateOwnerId
          templateId
          categoryOwnerId
          categoryId
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      subcategories {
        items {
          id
          categoryId
          categoryOwnerId
          name
          notes
          itemOrder
          parentId
          subcategoryOrder
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createDiscount = /* GraphQL */ `
  mutation CreateDiscount(
    $input: CreateDiscountInput!
    $condition: ModelDiscountConditionInput
  ) {
    createDiscount(input: $input, condition: $condition) {
      id
      ownerId
      name
      description
      type
      appliesTo
      option
      value
      minPurchase
      minPurchaseAmount
      limit
      onePerCustomer
      fixedLimit
      fixedLimitAmount
      startDate
      endDate
      owner {
        level
        id
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateDiscount = /* GraphQL */ `
  mutation UpdateDiscount(
    $input: UpdateDiscountInput!
    $condition: ModelDiscountConditionInput
  ) {
    updateDiscount(input: $input, condition: $condition) {
      id
      ownerId
      name
      description
      type
      appliesTo
      option
      value
      minPurchase
      minPurchaseAmount
      limit
      onePerCustomer
      fixedLimit
      fixedLimitAmount
      startDate
      endDate
      owner {
        level
        id
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteDiscount = /* GraphQL */ `
  mutation DeleteDiscount(
    $input: DeleteDiscountInput!
    $condition: ModelDiscountConditionInput
  ) {
    deleteDiscount(input: $input, condition: $condition) {
      id
      ownerId
      name
      description
      type
      appliesTo
      option
      value
      minPurchase
      minPurchaseAmount
      limit
      onePerCustomer
      fixedLimit
      fixedLimitAmount
      startDate
      endDate
      owner {
        level
        id
      }
      createdAt
      updatedAt
    }
  }
`;
export const createInspection = /* GraphQL */ `
  mutation CreateInspection(
    $input: CreateInspectionInput!
    $condition: ModelInspectionConditionInput
  ) {
    createInspection(input: $input, condition: $condition) {
      ownerId
      id
      workOrder
      owner {
        level
        id
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateInspection = /* GraphQL */ `
  mutation UpdateInspection(
    $input: UpdateInspectionInput!
    $condition: ModelInspectionConditionInput
  ) {
    updateInspection(input: $input, condition: $condition) {
      ownerId
      id
      workOrder
      owner {
        level
        id
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteInspection = /* GraphQL */ `
  mutation DeleteInspection(
    $input: DeleteInspectionInput!
    $condition: ModelInspectionConditionInput
  ) {
    deleteInspection(input: $input, condition: $condition) {
      ownerId
      id
      workOrder
      owner {
        level
        id
      }
      createdAt
      updatedAt
    }
  }
`;
export const createItem = /* GraphQL */ `
  mutation CreateItem(
    $input: CreateItemInput!
    $condition: ModelItemConditionInput
  ) {
    createItem(input: $input, condition: $condition) {
      id
      subcategoryId
      name
      subcategory {
        id
        categoryId
        categoryOwnerId
        name
        notes
        itemOrder
        parentId
        subcategoryOrder
        parent {
          id
          categoryId
          categoryOwnerId
          name
          notes
          itemOrder
          parentId
          subcategoryOrder
          createdAt
          updatedAt
        }
        children {
          nextToken
        }
        category {
          ownerId
          id
          name
          subcategoryOrder
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateItem = /* GraphQL */ `
  mutation UpdateItem(
    $input: UpdateItemInput!
    $condition: ModelItemConditionInput
  ) {
    updateItem(input: $input, condition: $condition) {
      id
      subcategoryId
      name
      subcategory {
        id
        categoryId
        categoryOwnerId
        name
        notes
        itemOrder
        parentId
        subcategoryOrder
        parent {
          id
          categoryId
          categoryOwnerId
          name
          notes
          itemOrder
          parentId
          subcategoryOrder
          createdAt
          updatedAt
        }
        children {
          nextToken
        }
        category {
          ownerId
          id
          name
          subcategoryOrder
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteItem = /* GraphQL */ `
  mutation DeleteItem(
    $input: DeleteItemInput!
    $condition: ModelItemConditionInput
  ) {
    deleteItem(input: $input, condition: $condition) {
      id
      subcategoryId
      name
      subcategory {
        id
        categoryId
        categoryOwnerId
        name
        notes
        itemOrder
        parentId
        subcategoryOrder
        parent {
          id
          categoryId
          categoryOwnerId
          name
          notes
          itemOrder
          parentId
          subcategoryOrder
          createdAt
          updatedAt
        }
        children {
          nextToken
        }
        category {
          ownerId
          id
          name
          subcategoryOrder
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      ownerId
      name
      description
      category
      size
      duration
      price
      owner {
        level
        id
      }
      taxExempt
      visibility
      createdAt
      updatedAt
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      ownerId
      name
      description
      category
      size
      duration
      price
      owner {
        level
        id
      }
      taxExempt
      visibility
      createdAt
      updatedAt
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      ownerId
      name
      description
      category
      size
      duration
      price
      owner {
        level
        id
      }
      taxExempt
      visibility
      createdAt
      updatedAt
    }
  }
`;
export const createReport = /* GraphQL */ `
  mutation CreateReport(
    $input: CreateReportInput!
    $condition: ModelReportConditionInput
  ) {
    createReport(input: $input, condition: $condition) {
      id
      ownerId
      workOrderId
      owner {
        level
        id
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateReport = /* GraphQL */ `
  mutation UpdateReport(
    $input: UpdateReportInput!
    $condition: ModelReportConditionInput
  ) {
    updateReport(input: $input, condition: $condition) {
      id
      ownerId
      workOrderId
      owner {
        level
        id
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteReport = /* GraphQL */ `
  mutation DeleteReport(
    $input: DeleteReportInput!
    $condition: ModelReportConditionInput
  ) {
    deleteReport(input: $input, condition: $condition) {
      id
      ownerId
      workOrderId
      owner {
        level
        id
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSubcategory = /* GraphQL */ `
  mutation CreateSubcategory(
    $input: CreateSubcategoryInput!
    $condition: ModelSubcategoryConditionInput
  ) {
    createSubcategory(input: $input, condition: $condition) {
      id
      categoryId
      categoryOwnerId
      name
      notes
      itemOrder
      parentId
      subcategoryOrder
      parent {
        id
        categoryId
        categoryOwnerId
        name
        notes
        itemOrder
        parentId
        subcategoryOrder
        parent {
          id
          categoryId
          categoryOwnerId
          name
          notes
          itemOrder
          parentId
          subcategoryOrder
          createdAt
          updatedAt
        }
        children {
          nextToken
        }
        category {
          ownerId
          id
          name
          subcategoryOrder
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        createdAt
        updatedAt
      }
      children {
        items {
          id
          categoryId
          categoryOwnerId
          name
          notes
          itemOrder
          parentId
          subcategoryOrder
          createdAt
          updatedAt
        }
        nextToken
      }
      category {
        ownerId
        id
        name
        subcategoryOrder
        owner {
          level
          id
        }
        templates {
          nextToken
        }
        subcategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      items {
        items {
          id
          subcategoryId
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSubcategory = /* GraphQL */ `
  mutation UpdateSubcategory(
    $input: UpdateSubcategoryInput!
    $condition: ModelSubcategoryConditionInput
  ) {
    updateSubcategory(input: $input, condition: $condition) {
      id
      categoryId
      categoryOwnerId
      name
      notes
      itemOrder
      parentId
      subcategoryOrder
      parent {
        id
        categoryId
        categoryOwnerId
        name
        notes
        itemOrder
        parentId
        subcategoryOrder
        parent {
          id
          categoryId
          categoryOwnerId
          name
          notes
          itemOrder
          parentId
          subcategoryOrder
          createdAt
          updatedAt
        }
        children {
          nextToken
        }
        category {
          ownerId
          id
          name
          subcategoryOrder
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        createdAt
        updatedAt
      }
      children {
        items {
          id
          categoryId
          categoryOwnerId
          name
          notes
          itemOrder
          parentId
          subcategoryOrder
          createdAt
          updatedAt
        }
        nextToken
      }
      category {
        ownerId
        id
        name
        subcategoryOrder
        owner {
          level
          id
        }
        templates {
          nextToken
        }
        subcategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      items {
        items {
          id
          subcategoryId
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSubcategory = /* GraphQL */ `
  mutation DeleteSubcategory(
    $input: DeleteSubcategoryInput!
    $condition: ModelSubcategoryConditionInput
  ) {
    deleteSubcategory(input: $input, condition: $condition) {
      id
      categoryId
      categoryOwnerId
      name
      notes
      itemOrder
      parentId
      subcategoryOrder
      parent {
        id
        categoryId
        categoryOwnerId
        name
        notes
        itemOrder
        parentId
        subcategoryOrder
        parent {
          id
          categoryId
          categoryOwnerId
          name
          notes
          itemOrder
          parentId
          subcategoryOrder
          createdAt
          updatedAt
        }
        children {
          nextToken
        }
        category {
          ownerId
          id
          name
          subcategoryOrder
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        createdAt
        updatedAt
      }
      children {
        items {
          id
          categoryId
          categoryOwnerId
          name
          notes
          itemOrder
          parentId
          subcategoryOrder
          createdAt
          updatedAt
        }
        nextToken
      }
      category {
        ownerId
        id
        name
        subcategoryOrder
        owner {
          level
          id
        }
        templates {
          nextToken
        }
        subcategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      items {
        items {
          id
          subcategoryId
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTemplate = /* GraphQL */ `
  mutation CreateTemplate(
    $input: CreateTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    createTemplate(input: $input, condition: $condition) {
      ownerId
      id
      name
      categoryOrder
      owner {
        level
        id
      }
      categories {
        items {
          templateOwnerId
          templateId
          categoryOwnerId
          categoryId
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTemplate = /* GraphQL */ `
  mutation UpdateTemplate(
    $input: UpdateTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    updateTemplate(input: $input, condition: $condition) {
      ownerId
      id
      name
      categoryOrder
      owner {
        level
        id
      }
      categories {
        items {
          templateOwnerId
          templateId
          categoryOwnerId
          categoryId
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTemplate = /* GraphQL */ `
  mutation DeleteTemplate(
    $input: DeleteTemplateInput!
    $condition: ModelTemplateConditionInput
  ) {
    deleteTemplate(input: $input, condition: $condition) {
      ownerId
      id
      name
      categoryOrder
      owner {
        level
        id
      }
      categories {
        items {
          templateOwnerId
          templateId
          categoryOwnerId
          categoryId
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTemplateCategoryConnection = /* GraphQL */ `
  mutation CreateTemplateCategoryConnection(
    $input: CreateTemplateCategoryConnectionInput!
    $condition: ModelTemplateCategoryConnectionConditionInput
  ) {
    createTemplateCategoryConnection(input: $input, condition: $condition) {
      templateOwnerId
      templateId
      categoryOwnerId
      categoryId
      template {
        ownerId
        id
        name
        categoryOrder
        owner {
          level
          id
        }
        categories {
          nextToken
        }
        createdAt
        updatedAt
      }
      category {
        ownerId
        id
        name
        subcategoryOrder
        owner {
          level
          id
        }
        templates {
          nextToken
        }
        subcategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateTemplateCategoryConnection = /* GraphQL */ `
  mutation UpdateTemplateCategoryConnection(
    $input: UpdateTemplateCategoryConnectionInput!
    $condition: ModelTemplateCategoryConnectionConditionInput
  ) {
    updateTemplateCategoryConnection(input: $input, condition: $condition) {
      templateOwnerId
      templateId
      categoryOwnerId
      categoryId
      template {
        ownerId
        id
        name
        categoryOrder
        owner {
          level
          id
        }
        categories {
          nextToken
        }
        createdAt
        updatedAt
      }
      category {
        ownerId
        id
        name
        subcategoryOrder
        owner {
          level
          id
        }
        templates {
          nextToken
        }
        subcategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteTemplateCategoryConnection = /* GraphQL */ `
  mutation DeleteTemplateCategoryConnection(
    $input: DeleteTemplateCategoryConnectionInput!
    $condition: ModelTemplateCategoryConnectionConditionInput
  ) {
    deleteTemplateCategoryConnection(input: $input, condition: $condition) {
      templateOwnerId
      templateId
      categoryOwnerId
      categoryId
      template {
        ownerId
        id
        name
        categoryOrder
        owner {
          level
          id
        }
        categories {
          nextToken
        }
        createdAt
        updatedAt
      }
      category {
        ownerId
        id
        name
        subcategoryOrder
        owner {
          level
          id
        }
        templates {
          nextToken
        }
        subcategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const createWorkflow = /* GraphQL */ `
  mutation CreateWorkflow(
    $input: CreateWorkflowInput!
    $condition: ModelWorkflowConditionInput
  ) {
    createWorkflow(input: $input, condition: $condition) {
      id
      ownerId
      name
      owner {
        level
        id
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateWorkflow = /* GraphQL */ `
  mutation UpdateWorkflow(
    $input: UpdateWorkflowInput!
    $condition: ModelWorkflowConditionInput
  ) {
    updateWorkflow(input: $input, condition: $condition) {
      id
      ownerId
      name
      owner {
        level
        id
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteWorkflow = /* GraphQL */ `
  mutation DeleteWorkflow(
    $input: DeleteWorkflowInput!
    $condition: ModelWorkflowConditionInput
  ) {
    deleteWorkflow(input: $input, condition: $condition) {
      id
      ownerId
      name
      owner {
        level
        id
      }
      createdAt
      updatedAt
    }
  }
`;
