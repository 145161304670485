import { Formik, Form, Field } from "formik";
import { Button, Spinner } from "reactstrap";
import { useTranslation } from "react-i18next";

import { useSubcategories, useSubcategory } from "hooks/subcategories";
import { useHistory } from "react-router-dom";

interface ManageTabProps {
  subcategoryId: string;
}

export function ManageTab(props: ManageTabProps) {
  const { t } = useTranslation("subcategories");
  const { replace } = useHistory();
  const { deleteSubcategory } = useSubcategories();
  const { subcategory, updateSubcategory } = useSubcategory(
    props.subcategoryId
  );

  return (
    <>
      <h2 className="mb-2">{t("Manage Subcategory")}</h2>
      <p className="mb-4">
        {t(
          "Update information about this subcategory, or modify its availability."
        )}
      </p>
      <section className="mb-4">
        <h3>{t("Update Name")}</h3>
        <Formik
          enableReinitialize
          initialValues={{ name: subcategory.data?.name ?? "" }}
          onSubmit={async ({ name }) => {
            if (subcategory?.data) {
              await updateSubcategory.mutateAsync({
                name,
              });
            }
          }}
        >
          {({ dirty }) => (
            <Form>
              <div className="d-flex">
                <Field
                  name="name"
                  className={`form-control ${dirty ? "is-valid" : ""}`}
                />
                <Button
                  type="submit"
                  color={dirty ? "success" : "secondary"}
                  className="ml-2"
                >
                  {t("Update")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </section>
      <section>
        <h3>{t("Archive/Delete Subcategory")}</h3>
        <Button
          color="danger"
          onClick={async () => {
            if (subcategory.data) {
              await deleteSubcategory.mutateAsync({
                id: subcategory.data.id ?? "",
              });

              replace(
                `/report/settings/categories/${subcategory.data.categoryId}`
              );
            }
          }}
        >
          {subcategory.isLoading || deleteSubcategory.isLoading ? (
            <Spinner size="sm" />
          ) : (
            t("Delete Category")
          )}
        </Button>
      </section>
    </>
  );
}
