import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";

export default function DetailTemplate(props: any) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Row>
        <Col>
          {t("lockbox_code", "Lockbox code")}: {props.data.data.lockboxCode}
        </Col>
      </Row>
    </React.Fragment>
  );
}
