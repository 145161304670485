import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col, Row, Container } from "reactstrap";
import Breadcrumbs from "./Breadcrumb";
import { withTranslation } from "react-i18next";
import { MetaTags } from "react-meta-tags";

class StandardPage extends Component {
  constructor(props) {
    super(props);
    let title = this.props.t("horizon_core", "Horizon Core");
    if (window.location.href.indexOf("npi") !== -1) {
      title = this.props.t("national_property_inspections", "NPI");
    }
    this.state = {
      title,
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content" id={this.props.pageid}>
          <MetaTags>
            <title>{this.props.pagetitle + " | " + this.state.title}</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.props.pagetitle}
              icon={this.props.breadcrumbIcon}
              breadcrumbItems={this.props.breadcrumbItems}
              topRight={this.props.topRight}
            />
            <Row>
              <Col lg={12}>{this.props.children}</Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

StandardPage.propTypes = {
  t: PropTypes.any,
  pageid: PropTypes.string,
  pagetitle: PropTypes.string,
  breadcrumbItems: PropTypes.array,
  breadcrumbIcon: PropTypes.string,
  children: PropTypes.any,
};

export default withTranslation()(StandardPage);
