import { useQuery } from "@tanstack/react-query";
import { API } from "aws-amplify";
import { DisplayAddress } from "components/Fields/Address";
import Scheduler, { View } from "devextreme-react/scheduler";
import { formatDate } from "devextreme/localization";
import React, { useCallback, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

export default function Timeline(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const [workOrders, setWorkOrders] = useState([]);
  let [currentDate, setCurrentDate] = useState(previousSunday());
  let [currentView, setCurrentView] = useState("week");
  let [dayStart, setDayStart] = useState();
  let [dayEnd, setDayEnd] = useState();

  useQuery(["user_profile"], async () => API.get("UserMeta", "/userMeta"), {
    onSettled: data => {
      let sunday = false;
      let saturday = false;
      if (data[0]?.availability?.blocks !== undefined) {
        for (const block of Object.entries(data[0].availability.blocks)) {
          block.forEach(timeblock => {
            if (timeblock.times !== undefined) {
              timeblock.times.forEach(time => {
                sunday = sunday || timeblock.name === "Sunday";
                saturday = saturday || timeblock.name === "Saturday";
                updateCalendarStartEnd(time);
              });
            }
          });
        }
      } else {
        sunday = false;
        saturday = false;
        updateCalendarStartEnd({ start: "08:00", end: "18:00" });
        // TODO: Maybe show a message to the user that they have no availability set?
      }
      if (!sunday && !saturday) setCurrentView("workWeek");
    },
  });

  const updateCalendarStartEnd = time => {
    let colon = time.start.indexOf(":");
    let start = parseInt(time.start.substr(0, colon));
    let end = parseInt(time.end.substr(0, colon));
    if (dayStart === undefined || start < dayStart) setDayStart(start);
    if (dayEnd === undefined || end > dayEnd) setDayEnd(end);
  };

  function previousSunday(date = new Date()) {
    const prevSunday = new Date();
    prevSunday.setDate(date.getDate() - date.getDay());
    return prevSunday;
  }

  function formatURL() {
    return (
      "/work/order/betweenDates/" +
      formatDate(currentDate, "y-MM-dd") +
      "/" +
      formatDate(
        new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000),
        "y-MM-dd"
      )
    );
  }

  useQuery(
    ["work_orders", currentDate],
    async () => {
      return API.get("WorkOrder", formatURL());
    },
    {
      onSettled: formatDataForScheduler,
    }
  );

  function formatDataForScheduler(data) {
    let items = [];
    data.forEach(workOrder => {
      items.push({
        startDate: workOrder.inspection.inspectionDate,
        endDate: new Date(
          new Date(workOrder.inspection.inspectionDate).getTime() +
            workOrder.inspection.duration * 60 * 60 * 1000
        ),
        text:
          getClientName(workOrder) +
          " " +
          DisplayAddress(workOrder.inspection.address),
        id: workOrder.id,
      });
    });
    setWorkOrders(items);
  }

  function getClientName(workOrder) {
    if (workOrder.clientInformation.length === 0) {
      return "";
    } else {
      return (
        (workOrder.clientInformation[0].firstname || "") +
        " " +
        (workOrder.clientInformation[0].lastname || "")
      );
    }
  }

  const handlePropertyChange = useCallback(e => {
    switch (e.name) {
      case "currentDate":
        setCurrentDate(e.value);
        break;
      case "currentView":
        setCurrentView(e.value);
        break;
      default:
        break;
    }
  }, []);

  const handleAppointmentClick = useCallback(e => {
    history.push("/my/jobs/" + e.targetedAppointmentData.id);
  }, []);

  const views = ["week", "month"];

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          {t("timeline_view", "Timeline view")}
          {workOrders.length === 0 ? (
            <div className="alert alert-danger">
              <Trans i18nKey="no_work_orders_assigned_to_you_timeline">
                No work orders have been assigned to you for the time selected.
                To display all work orders, including those that are past or not
                yet available, go to{" "}
                <Link to="/work/orders">work orders and reports</Link> in the
                main menu.
              </Trans>
            </div>
          ) : (
            ""
          )}
          <Scheduler
            dataSource={workOrders}
            views={views}
            defaultCurrentView={currentView}
            defaultCurrentDate={currentDate}
            height={600}
            startDayHour={dayStart || 0}
            endDayHour={dayEnd || 24}
            onOptionChanged={handlePropertyChange}
            onAppointmentClick={handleAppointmentClick}
          >
            <View type="week" />
            <View type="month" />
          </Scheduler>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}
