type LanguageList = {
  [key: string]: {
    label: string;
  };
};

const languages: LanguageList = {
  "en-US": {
    label: "US - English",
  },
  "fr-CA": {
    label: "CA - Français",
  },
  "es-MX": {
    label: "MX - Español",
  },
};

export default languages;
