import { useState } from "react";
import { Collapse, Row, Col } from "reactstrap";
import { useTranslation } from "react-i18next";
import { specialities } from "helpers/StaticLists/Specialities";
import StandardFormikSwitch from "components/Fields/Switches/StandardFormikSwitch";

export default function SectionSpecialities() {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  function toggle() {
    setIsOpen(isOpen => !isOpen);
  }
  const switchOptions = specialities.map((speciality, idx) => ({
    id: idx + 1,
    switches: [
      {
        id: `specialities.${speciality.id}`,
        columnClass: "col col-md col-lg-2",
      },
    ],
    label: {
      name: speciality.label,
      labelWrapperClass: "col border-start d-flex align-items-center",
    },
    rowClass: "mb-1 row",
  }));

  return (
    <div className="accordion card mb-0 pb-0">
      <div className={"accordion-item " + (isOpen ? "active" : "")}>
        <h3
          className="accordion-header"
          id="specialties"
          onClick={() => toggle()}
        >
          {t("invite_member_specialties_accordion_title", "Specialities")}
        </h3>
        <Collapse isOpen={isOpen} className="accordion-collapse">
          <div className="accordion-body" style={{ backgroundColor: "white" }}>
            <Row>
              {switchOptions.map(switchOption => (
                <Col key={switchOption.id} md xs lg="6">
                  <StandardFormikSwitch
                    switches={switchOption.switches}
                    label={switchOption.label}
                    rowClass={switchOption.rowClass}
                  />
                </Col>
              ))}
            </Row>
          </div>
        </Collapse>
      </div>
    </div>
  );
}
