import { Template } from "API";
import { useTranslation } from "react-i18next";

export function DefaultsTab({ template }: { template: Template }) {
  const { t } = useTranslation("templates");

  return (
    <>
      <h2 className="mb-2">{t("Set Default Values")}</h2>
      <p className="mb-4">{t("Set default values for report elements.")}</p>
      <section>
        <h3>{t("Default Cover Photo")}</h3>
        <p>Not Implemented</p>
      </section>
    </>
  );
}
