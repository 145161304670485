import React, { Component } from "react";
import PropTypes from "prop-types";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { withRouter, Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";
import Icon from "components/Common/Icons";

class SettingsMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu,
    }));
  }

  render() {
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block"
        >
          <DropdownToggle
            className="btn header-item d-flex align-items-center"
            id="page-header-user-dropdown"
            tag="button"
          >
            <Icon
              name="settings"
              style={{
                transform: "rotate(30deg)",
              }}
            />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end text-end">
            <Link to="/company/settings" className="dropdown-item">
              {this.props.t("company_settings", "Company settings")}
            </Link>
            <Link to="/company/team" className="dropdown-item">
              {this.props.t("manage_my_team", "Manage my team")}
            </Link>
            <Link to="/report/settings" className="dropdown-item">
              {this.props.t("report_settings", "Report settings")}
            </Link>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

SettingsMenu.propTypes = {
  t: PropTypes.any,
  success: PropTypes.string,
  first_name: PropTypes.string,
  photo: PropTypes.string,
};

const mapStateToProps = state => {
  const { success, first_name, photo } = state.Profile;
  return { success, first_name, photo };
};

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(SettingsMenu))
);
