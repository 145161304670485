import { QueryFunction, useQuery } from "@tanstack/react-query";
import { API } from "aws-amplify";
import { formatDate } from "devextreme/localization";
import { WorkOrder } from "./workorder";

export function useWorkOrders(date?: Date) {
  const workOrders = useQuery(["workOrders", date], getWorkOrders, {});

  return { workOrders };
}

const getWorkOrders: QueryFunction<
  WorkOrder[],
  ["workOrders", Date | undefined]
> = async ({ queryKey }) => {
  if (queryKey[1] === undefined) {
    return API.get("WorkOrder", "/work/order", {});
  } else {
    return API.get(
      "WorkOrder",
      "/work/order/byDate/" + formatDate(queryKey[1], "y-MM-dd"),
      {}
    );
  }
};
