import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo } from "react";
import { OwnershipLevel } from "API";
import { API, Storage } from "aws-amplify";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { hydrateProfile } from "store/actions";

const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

export function useProfile() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = useSelector((state: any) => state.Profile);
  const companyId = useSelector((state: any) => state.Profile.companies?.[0]);
  const franchiseeId = useSelector(
    (state: any) => state.Profile.franchisees?.[0]
  );
  const userId = useSelector((state: any) => state.Profile.id);

  useEffect(() => {
    dispatch(hydrateProfile());
  }, [dispatch]);

  const createOwnerId = useCallback(
    (level: OwnershipLevel) => {
      if (companyId && userId) {
        switch (level) {
          case OwnershipLevel.COMPANY:
            return companyId;
          case OwnershipLevel.FRANCHISEE:
            return `${companyId}/${franchiseeId ?? ""}`;
          case OwnershipLevel.USER:
            return `${companyId}/${franchiseeId ?? ""}/${userId}`;
        }
      }

      return undefined;
    },
    [companyId, franchiseeId, userId]
  );

  const myId = useMemo(
    () => createOwnerId(OwnershipLevel.USER),
    [createOwnerId]
  );

  async function fetch() {
    let dataUser = await API.get("UserMeta", "/userMeta", {});
    if (dataUser.length === 1) {
      dataUser = dataUser[0];
    }

    let photoCheck = false as any;
    if (dataUser.photo !== undefined && dataUser.photo !== "") {
      photoCheck = await Storage.list(dataUser.photo, {
        level: "public",
      });
    }
    let photo = "";
    if (photoCheck) {
      photo = await Storage.get(dataUser.photo, {
        level: "public",
      });
    }
    dataUser.photo = photo;
    return dataUser;
  }

  async function fetchAvailability() {
    const data = await fetch();
    return data.availability || false;
  }

  const validation = Yup.object().shape({
    firstName: Yup.string().required(
      t("error_first_name_blank", "Please enter a name")
    ),
    lastName: Yup.string().required(
      t("error_last_name_blank", "Please enter a name")
    ),
    email: Yup.string()
      .email(t("error_email_invald", "Please enter a valid email address"))
      .required(t("error_email_blank", "Please enter an email address")),
    website: Yup.string(),
    primaryPhone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Please enter a phone number"),
    secondaryPhone: Yup.string().matches(
      phoneRegExp,
      "Phone number is not valid"
    ),
    country: Yup.string()
      .transform(v => (v === null ? "" : v))
      .required(t("error_country_blank", "Please select a country")),
    region: Yup.string()
      .transform(v => (v === null ? "" : v))
      .required(t("error_region_blank", "Please select a region")),
    timezone: Yup.string()
      .transform(v => (v === null ? "" : v))
      .required(t("error_timezone_blank", "Please select a timezone")),
  });

  async function save(user: any) {
    let dataUser = await API.get("UserMeta", "/userMeta", {});
    dataUser = dataUser[0];

    if (user.photo && user.photo.indexOf("https") !== -1) {
      const targetUser = await API.get("UserMeta", "/userMeta/" + user.id, {});
      user.photo = targetUser[0].photo;
    }

    if (dataUser["id"] === user.id || !user.id) {
      for (let key of Object.keys(user)) {
        if (
          typeof user[key] !== "undefined" &&
          (typeof user[key] === "object" || user[key].length > 0)
        ) {
          dataUser[key] = user[key];
        }
      }
    } else {
      let valid = false;
      if (dataUser["id"] !== user.id) {
        dataUser.companies.forEach((company: string) => {
          if (user.companies.includes(company)) {
            valid = true;
          }
        });
      }
      if (!valid) return false;

      dataUser = user;
    }

    if (dataUser?.photo && dataUser.photo.indexOf("no-avatar") !== -1) {
      dataUser.photo = "";
    }

    let result = {
      success: false,
    };

    try {
      result = await API.put("UserMeta", "/userMeta", {
        body: dataUser,
      });
    } catch (err) {
      console.log(err);
    }

    return { ...result, data: dataUser };
  }

  return {
    createOwnerId,
    fetch,
    fetchAvailability,
    save,
    profile,
    companyId,
    franchiseeId,
    myId,
    validation,
  };
}
