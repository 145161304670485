export const WORK_ORDERS_ERROR = "WORK_ORDERS_ERROR";
export const WORK_ORDER_ERROR = "WORK_ORDER_ERROR";
export const EDIT_WORK_ORDER = "EDIT_WORK_ORDER";
export const EDIT_WORK_ORDER_SUCCESS = "EDIT_WORK_ORDER_SUCCESS";
export const ADD_WORK_ORDER = "ADD_WORK_ORDER";
export const ADD_WORK_ORDER_SUCCESS = "ADD_WORK_ORDER_SUCCESS";
export const HYDRATE_WORK_ORDERS = "HYDRATE_WORK_ORDERS";
export const HYDRATE_WORK_ORDERS_SUCCESS = "HYDRATE_WORK_ORDERS_SUCCESS";
export const HYDRATE_WORK_ORDER = "HYDRATE_WORK_ORDER";
export const HYDRATE_WORK_ORDER_SUCCESS = "HYDRATE_WORK_ORDER_SUCCESS";
