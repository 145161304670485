import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  ButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";

class PerPageListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drop_open: false,
    };
  }
  render() {
    return (
      <div className="btn-group me-1 mt-2">
        <ButtonDropdown
          isOpen={this.state.drop_open}
          toggle={() =>
            this.setState({
              drop_open: !this.state.drop_open,
            })
          }
        >
          <DropdownToggle caret color="primary" className="btn btn-sm">
            {this.props.currSizePerPage}
            <i className="mdi mdi-chevron-down"></i>
          </DropdownToggle>
          <DropdownMenu>
            {this.props.options.map(option => {
              return (
                <DropdownItem
                  key={option.page}
                  onClick={e => {
                    this.props.onChange(option.page);
                  }}
                >
                  {option.text}
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        </ButtonDropdown>
      </div>
    );
  }
}

PerPageListing.propTypes = {
  currSizePerPage: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
};

export default PerPageListing;
