export type AppRole = {
  id: string;
  order: number;
  nameKey: string;
  nameDefault: string;
  descriptionKey: string;
  descriptionDefault?: string;
};

export const appRoles: AppRole[] = [
  {
    id: "administrator",
    order: 1,
    nameKey: "role_company_administrator",
    nameDefault: "Company administrator",
    descriptionKey: "role_company_administrator_description",
    descriptionDefault: "Manage all areas.",
  },
  {
    id: "limited_worker",
    order: 2,
    nameKey: "role_limited_worker",
    nameDefault: "Limited worker (home inspector)",
    descriptionKey: "role_limited_worker_description",
    descriptionDefault:
      "View their schedule, mark work complete , and track their time.",
  },
  {
    id: "worker",
    order: 3,
    nameKey: "role_worker",
    nameDefault: "Worker (home inspector)",
    descriptionKey: "role_worker_description",
    descriptionDefault:
      "View all clients, quotes, and jobs, including pricing details.",
  },
  {
    id: "dispatcher",
    order: 4,
    nameKey: "role_dispatcher",
    nameDefault: "Dispatcher",
    descriptionKey: "role_dispatcher_description",
    descriptionDefault:
      "Edit jobs, team and client details. Recommended for team leads.",
  },
  {
    id: "manager",
    order: 5,
    nameKey: "role_manager",
    nameDefault: "Manager",
    descriptionKey: "role_manager_description",
    descriptionDefault:
      "Manage all areas excluding reports. Recommended for management.",
  },
  {
    id: "custom",
    order: 6,
    nameKey: "role_custom",
    nameDefault: "Custom access",
    descriptionKey: "role_custom_description",
    descriptionDefault: "Choose specific permissions for this member",
  },
];
