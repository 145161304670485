import {
  takeEvery,
  fork,
  put,
  call,
  all,
  StrictEffect,
} from "redux-saga/effects";

import { messageHubChange, messageHubReset } from "store/actions";

import { Template, TemplateCategoryConnection } from "API";
import * as types from "./actionTypes";
import * as actions from "./actions";
import * as effects from "./effects";
import { handleSagaGraphqlError } from "helpers/graphql";

function* fetchTemplates(
  event: ReturnType<typeof actions.fetchTemplates>
): Generator<StrictEffect, void, Template[]> {
  try {
    const templates = yield call(effects.fetchTemplates, event.payload);
    yield put(
      actions.fetchTemplatesSuccess({ ownerId: event.payload, templates })
    );
  } catch (error: any) {
    yield call(handleSagaGraphqlError, `Failed to fetch templates`, error);
  }
}

function* getTemplate(
  event: ReturnType<typeof actions.fetchTemplate>
): Generator<StrictEffect, void, Template> {
  try {
    const template = yield call(effects.getTemplate, event.payload);
    yield put(
      actions.fetchTemplateSuccess({
        ...event.payload,
        template,
      })
    );
  } catch (error) {
    yield call(handleSagaGraphqlError, `Failed to fetch template`, error);
  }
}

function* createTemplate(): Generator<StrictEffect, void, Template> {
  try {
    const template = yield call(effects.createTemplate);
    yield put(
      actions.fetchTemplateSuccess({
        id: template.id,
        template,
      })
    );
    yield call(fetchTemplates, actions.fetchTemplates(template.ownerId));
    yield put(messageHubChange("Template created", "success", true));
    yield put(messageHubReset());
  } catch (error) {
    yield call(handleSagaGraphqlError, `Failed to create template`, error);
  }
}

function* deleteTemplate(
  event: ReturnType<typeof actions.deleteTemplate>
): Generator<StrictEffect> {
  try {
    yield call(effects.deleteTemplate, event.payload);
    yield put(actions.deleteTemplateSuccess(event.payload));
    yield call(fetchTemplates, actions.fetchTemplates(event.payload.ownerId));
    yield put(messageHubChange("Template deleted", "success", true));
    yield put(messageHubReset());
  } catch (error: any) {
    yield call(handleSagaGraphqlError, `Failed to delete template`, error);
  }
}

function* renameTemplate(event: ReturnType<typeof actions.renameTemplate>) {
  try {
    yield call(effects.renameTemplate, event.payload);
    yield put(actions.fetchTemplate(event.payload));
    yield put(messageHubChange("Template renamed", "success", true));
    yield put(messageHubReset());
  } catch (error) {
    yield call(handleSagaGraphqlError, `Failed to rename template`, error);
  }
}

function* fetchTemplateCategories(
  event: ReturnType<typeof actions.fetchTemplateCategories>
): Generator<StrictEffect, void, TemplateCategoryConnection[]> {
  try {
    const categories = yield call(
      effects.getTemplateCategories,
      event.payload.id
    );
    yield put(
      actions.fetchTemplateCategoriesSuccess({ ...event.payload, categories })
    );
  } catch (error) {
    yield call(handleSagaGraphqlError, `Failed to fetch categories`, error);
  }
}

function* addCategoryToTemplate(
  event: ReturnType<typeof actions.addCategoryToTemplate>
) {
  try {
    yield call(effects.addCategoryToTemplate, event.payload);
    yield call(
      fetchTemplates,
      actions.fetchTemplates(event.payload.templateOwnerId)
    );
    yield put(messageHubChange(`Category added to template`, "error", true));
    yield put(messageHubReset());
  } catch (error) {
    yield call(
      handleSagaGraphqlError,
      `Failed to add category to template`,
      error
    );
  }
}

function* removeCategoryFromTemplate(
  event: ReturnType<typeof actions.removeCategoryFromTemplate>
) {
  try {
    yield call(effects.removeCategoryFromTemplate, event.payload.input);
    yield call(
      fetchTemplates,
      actions.fetchTemplates(event.payload.templateOwnerId)
    );
    yield put(
      messageHubChange(`Category removed from template`, "success", true)
    );
    yield put(messageHubReset());
  } catch (error) {
    yield call(
      handleSagaGraphqlError,
      `Failed to remove category from template`,
      error
    );
  }
}

export function* watchTemplates() {
  yield takeEvery(types.FETCH_TEMPLATES, fetchTemplates);
  yield takeEvery(types.FETCH_TEMPLATE, getTemplate);
  yield takeEvery(types.CREATE_TEMPLATE, createTemplate);
  yield takeEvery(types.DELETE_TEMPLATE, deleteTemplate);
  yield takeEvery(types.RENAME_TEMPLATE, renameTemplate);
  yield takeEvery(types.FETCH_TEMPLATE_SYSTEMS, fetchTemplateCategories);
  yield takeEvery(types.ADD_SYSTEM_TO_TEMPLATE, addCategoryToTemplate);
  yield takeEvery(
    types.REMOVE_SYSTEM_FROM_TEMPLATE,
    removeCategoryFromTemplate
  );
}

export default function* TemplatesSaga() {
  yield all([fork(watchTemplates)]);
}
