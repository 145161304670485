import { StandardDropdown, TextInput } from "components/Fields";
import { FormikValues, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import { StandardRadio } from "components/Fields/Radios/StandardRadios";
import { ChangeEvent, useState } from "react";
import StandardSwitches from "components/Fields/Switches/StandardSwitches";
import { appRoles } from "helpers/StaticLists/AppRoles";
import customRoles from "helpers/StaticLists/CustomRoles";
import { otherPermissions } from "helpers/StaticLists/OtherPermissions";
import { permissions } from "helpers/StaticLists/Permissions";

export default function UserAccessForm(props: { editing?: any }) {
  const { t } = useTranslation();
  const { handleChange, errors }: FormikValues = useFormikContext();
  const [selectedRole, setSelectedRole] = useState<string>(
    props.editing?.role || "Administrator"
  );
  const [newCustomRole, setNewCustomRole] = useState<boolean>(false);
  const { setFieldValue } = useFormikContext();

  function onSelectedRole(event: ChangeEvent) {
    const target = event.target as HTMLInputElement;
    setSelectedRole(target.value);
    handleChange(event);
  }

  function onCreateCustomRole(event: ChangeEvent) {
    const target = event.target as HTMLInputElement;
    if (target.value === "4") {
      setNewCustomRole(true);
    } else {
      setNewCustomRole(false);
    }
  }

  function onPermissionChange(values: string[]): void {
    setFieldValue("permissions", values);
  }

  return (
    <>
      <Col sm={12} className="mt-0">
        <StandardRadio
          name="role"
          label={t("select_a_role", "Select a Role") + "*"}
          showErrors={true}
          onChange={onSelectedRole}
          value={selectedRole}
          default="Administrator"
          className={"user-roles " + (errors["role"] ? " is-invalid" : "")}
          options={appRoles.map(role => ({
            label: t(role.nameKey, role.nameDefault),
            value: role.id,
            description: t(role.descriptionKey, role.descriptionDefault),
          }))}
        />

        {selectedRole === "custom" && (
          <>
            <Row className="mt-3">
              <Col lg="6" className="pb-3">
                <StandardDropdown
                  name="custom_role"
                  label={t("choose_custom_role", "Choose a custom role")}
                  showErrors={true}
                  options={customRoles.map(customRole => ({
                    label: customRole.label,
                    value: customRole.id,
                  }))}
                  onChange={onCreateCustomRole}
                />
              </Col>
              {newCustomRole && (
                <Col lg="12" className="pb-3 custom-role-name mt-3">
                  <Row>
                    <Col lg={6}>
                      <TextInput
                        name="newRole"
                        label={t("new_custom_role", "New custom role name")}
                        showErrors={true}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
            <Row
              className={
                "role-permissions " + (newCustomRole ? "highlight" : "")
              }
            >
              <Col lg="6" className="">
                <StandardSwitches
                  name="cutomeAccess"
                  label={t("role", "Profile Section Access")}
                  options={permissions.map(permission => ({
                    label: permission.label,
                    value: permission.id,
                    description: permission.description,
                  }))}
                  onChange={onPermissionChange as any}
                />
              </Col>
              <Col lg="6" className="">
                <StandardSwitches
                  name="otherAccess"
                  label={t("role", "Other Access Privileges")}
                  options={otherPermissions.map(permission => ({
                    label: permission.label,
                    value: permission.id,
                    description: permission.description,
                  }))}
                  onChange={onPermissionChange as any}
                />
              </Col>
            </Row>
          </>
        )}
        {errors["role"] ? (
          <div className="invalid-feedback">{errors["role"]}</div>
        ) : (
          ""
        )}
      </Col>
    </>
  );
}
