import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextInput } from "components/Fields";
import { Row, Col, Card, CardBody, Label } from "reactstrap";
import { useQuery } from "@tanstack/react-query";
import { StandardSwitch } from "components/Fields/Switches/StandardSwitch";
import { TreeViewDropDown } from "components/Fields/dropdowns/TreeViewDropDown";
import { useSnippets } from "hooks/snippets";
import DateBox from "devextreme-react/date-box";
import { useFormikContext } from "formik";

export default function PropertyDescription(props) {
  const { t } = useTranslation();
  const snippets = useSnippets([
    "property_type",
    "building_type",
    "floors",
    "rooms",
    "property_title",
    "occupancy",
    "house_facing",
  ]);
  const formik = useFormikContext();

  const [allSnippets, setAllSnippets] = useState(snippets.defaults());

  useQuery(["snippets", "work_order_snippets"], () => snippets.fetch(), {
    onSettled: data => {
      setAllSnippets(data);
    },
  });

  const measurementSystemChanged = async event => {
    await formik.setFieldValue(
      "propertyDescription.metric",
      event.target.checked
    );
  };

  useEffect(() => {
    if (formik.values["propertyDescription.metric"] === undefined) {
      formik.setFieldValue("propertyDescription.metric", false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const lastSoldDate_Changed = event => {
    formik.setFieldValue("propertyDescription.price.lastSoldDate", event.value);
  };

  const handlePropertyTypeOnChange = useCallback(value => {
    formik.setFieldValue("propertyDescription.propertyType", value);
  }, []);

  return (
    <React.Fragment>
      <Card className="mb-0 border-bottom">
        <CardBody>
          <h2 className="mt-2 mb-2">
            {t("property_description", "Property description")}
          </h2>
          <Row>
            <Col>
              {t(
                "residential_property_summary",
                "Residential property summary"
              )}
            </Col>
            <Col>Property search goes here</Col>
          </Row>
          <Row>
            <Col sm={12}>
              <TreeViewDropDown
                valueExpr="text"
                displayExpr="text"
                onChange={handlePropertyTypeOnChange}
                name="propertyDescription.propertyType"
                label={t("property_type", "Property type")}
                dataSource={allSnippets.property_type.values}
                value={
                  formik.values?.propertyDescription?.propertyType !== undefined
                    ? formik.values.propertyDescription.propertyType
                    : ""
                }
              />
            </Col>
            <Col sm={12}>
              <TreeViewDropDown
                name="propertyDescription.buildingType"
                label={t("building_type", "Building type")}
                dataSource={allSnippets.building_type.values}
                value={
                  formik.values?.propertyDescription?.buildingType !== undefined
                    ? formik.values.propertyDescription.buildingType
                    : ""
                }
                valueExpr="text"
                displayExpr="text"
                onChange={value =>
                  formik.setFieldValue(
                    "propertyDescription.buildingType",
                    value
                  )
                }
              />
            </Col>
            <Col>
              <TreeViewDropDown
                name="propertyDescription.floors"
                label={t("floors_stories", "Floors/stories")}
                dataSource={allSnippets.floors.values}
                value={
                  formik.values?.propertyDescription?.floors !== undefined
                    ? formik.values.propertyDescription.floors
                    : ""
                }
                valueExpr="text"
                displayExpr="text"
                onChange={value =>
                  formik.setFieldValue("propertyDescription.floors", value)
                }
              />
            </Col>
            <Col>
              <TreeViewDropDown
                name="propertyDescription.rooms"
                label={t("rooms", "Rooms")}
                dataSource={allSnippets.rooms.values}
                value={
                  formik.values?.propertyDescription?.rooms !== undefined
                    ? formik.values.propertyDescription.rooms
                    : ""
                }
                valueExpr="text"
                displayExpr="text"
                onChange={value =>
                  formik.setFieldValue("propertyDescription.rooms", value)
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextInput
                name="propertyDescription.yearBuilt"
                label={t("year_built", "Year built")}
              />
            </Col>
            <Col>
              <TextInput
                name="propertyDescription.lockboxCode"
                label={t("lock_box_code", "Lock box code")}
                formik={formik}
              />
            </Col>
            <Col sm={12}>
              <TreeViewDropDown
                name="propertyDescription.houseFacing"
                label={t("house_facing", "House facing")}
                dataSource={allSnippets.house_facing.values}
                value={
                  formik.values?.propertyDescription?.houseFacing !== undefined
                    ? formik.values.propertyDescription.houseFacing
                    : ""
                }
                valueExpr="text"
                displayExpr="text"
                onChange={value =>
                  formik.setFieldValue("propertyDescription.houseFacing", value)
                }
              />
            </Col>
          </Row>
          <h2 className="mt-2 mb-2">
            {t("building_information", "Building information")}
          </h2>

          <Row>
            <Col>
              <TextInput
                label={t("bedrooms_above_grade", "Bedrooms (above grade)")}
                name="propertyDescription.bedrooms.aboveGrade"
                formik={formik}
              />
            </Col>
            <Col>
              <TextInput
                label={t("bedrooms_below_grade", "Bedrooms (below grade)")}
                name="propertyDescription.bedrooms.belowGrade"
                formik={formik}
              />
            </Col>
            <Col sm={12}>
              <TextInput
                label={t("bathrooms", "Bathrooms")}
                name="propertyDescription.bathrooms"
                formik={formik}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <StandardSwitch
            label={t("imperial_metric", "Imperial/metric")}
            name="propertyDescription.metric"
            onChange={measurementSystemChanged}
            checked={
              formik.values.propertyDescription !== undefined
                ? formik.values.propertyDescription.metric
                : false
            }
            value="propertyDescription.metric"
            formik={formik}
          />
          {formik.values.propertyDescription &&
          !formik.values.propertyDescription.metric ? (
            <Row>
              <Col>
                <TextInput
                  label={t("sqft", "Square feet")}
                  name="propertyDescription.sqft"
                />
              </Col>
              <Col>
                <TextInput
                  label={t("finished_sqft", "Finished square feet")}
                  name="propertyDescription.finishedSqft"
                />
              </Col>
            </Row>
          ) : (
            <Row>
              <Col>
                <TextInput
                  label={t("sqmetres", "Square metres")}
                  name="propertyDescription.squareMetres"
                />
              </Col>
              <Col>
                <TextInput
                  label={t("finished_sqmetres", "Finished square metres")}
                  name="propertyDescription.finishedSquareMetres"
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <TextInput
                label={t("lot_size", "Lot size")}
                name="propertyDescription.lotSize"
              />
            </Col>
          </Row>
          <h2 className="mt-2 mb-2">{t("price", "Price")}</h2>
          <Row>
            <Col>
              <Row>
                <Col>
                  <TextInput
                    label={t("current_valuation", "Current valuation")}
                    name="propertyDescription.price.current"
                  />
                </Col>
              </Row>
            </Col>
            <Col sm={12}>
              <Row>
                <Col>
                  <TextInput
                    label={t("last_sold_price", "Last sold price")}
                    name="propertyDescription.price.lastSoldPrice"
                  />
                </Col>
                <Col>
                  <Label
                    for="propertyDescription.price.lastSoldDate"
                    className="col-form-label"
                  >
                    {t("last_sold_date", "Last sold date")}
                  </Label>
                  <DateBox
                    className="date-box"
                    name="propertyDescription.price.lastSoldDate"
                    type="date"
                    onValueChanged={lastSoldDate_Changed}
                    openOnFieldClick={true}
                    value={
                      formik.values.propertyDescription !== undefined &&
                      formik.values.propertyDescription.price !== undefined
                        ? formik.values.propertyDescription.price.lastSoldDate
                        : ""
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <h2 className="mt-2 mb-2">{t("occupancy", "Occupancy")}</h2>
          <Row>
            <Col lg={6}>
              <TreeViewDropDown
                name="propertyDescription.occupancy"
                label={t("occupancy", "Occupancy")}
                dataSource={allSnippets.occupancy.values}
                value={
                  formik.values?.propertyDescription?.occupancy !== undefined
                    ? formik.values.propertyDescription.occupancy
                    : ""
                }
                selectionMode="multiple"
                valueExpr="text"
                displayExpr="text"
                onChange={value => {
                  formik.setFieldValue("propertyDescription.occupancy", value);
                }}
              />
            </Col>
            <Col lg={6}>
              <TextInput
                label={t("other_please_specify", "Other (please specify)")}
                name="propertyDescription.occupancyOther"
              />
            </Col>
            <Col lg={6}>
              <TreeViewDropDown
                name="propertyDescription.propertyTitle"
                label={t("property_title", "Property title")}
                dataSource={allSnippets.property_title.values}
                value={
                  formik.values?.propertyDescription?.propertyTitle
                    ? formik.values.propertyDescription.propertyTitle
                    : ""
                }
                valueExpr="text"
                displayExpr="text"
                onChange={value =>
                  formik.setFieldValue(
                    "propertyDescription.propertyTitle",
                    value
                  )
                }
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}
