import { takeEvery, fork, put, call, all } from "redux-saga/effects";

// Login Redux States
import { HYDRATE_TEAM, ADD_TEAM_MEMBER, EDIT_TEAM_MEMBER } from "./actionTypes";
import {
  teamError,
  hydrateTeamSuccess,
  addTeamMemberSuccess,
  editTeamMemberSuccess,
  messageHubChange,
  messageHubReset,
} from "../../actions";

//Include Both Helper File with needed methods
import { getBackendHelper } from "../../../helpers/backend_helper";

const backend = getBackendHelper();

function* hydrateTeam() {
  try {
    const response = yield call(backend.getCompanyTeam);
    yield put(hydrateTeamSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(teamError(error));
  }
}

function* addTeamMember({ payload }) {
  try {
    const response = yield call(backend.addTeamMember, payload);
    yield put(addTeamMemberSuccess(response));
    yield put(messageHubChange("Invitation sent!", "success", true));
    yield put(messageHubReset());
  } catch (error) {
    console.log(error);
    yield put(teamError(error));
  }
}

function* editTeamMember({ payload }) {
  try {
    const response = yield call(backend.editTeamMember, payload);
    yield put(editTeamMemberSuccess(response));
    yield put(messageHubChange("Team member updated!", "success", true));
    yield put(messageHubReset());
  } catch (error) {
    console.log(error);
    yield put(teamError(error));
  }
}

export function* watchTeam() {
  yield takeEvery(HYDRATE_TEAM, hydrateTeam);
  yield takeEvery(ADD_TEAM_MEMBER, addTeamMember);
  yield takeEvery(EDIT_TEAM_MEMBER, editTeamMember);
}

function* TeamSaga() {
  yield all([fork(watchTeam)]);
}

export default TeamSaga;
