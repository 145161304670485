import React from "react";
import classNames from "classnames";
import { Field, useFormikContext } from "formik";
import { Label } from "reactstrap";

export default function StandardFormikSwitch(props) {
  const { values } = useFormikContext();
  return (
    <div className={`${props.rowClass ? props.rowClass : ""}`}>
      {props.switches.map((item, idx) => (
        <div
          key={item.id}
          className={classNames(
            {
              "text-center": true,
              "border-start": idx !== 0,
            },
            `${item.columnClass ? item.columnClass : ""} `
          )}
        >
          <div id={item.id} role="group" className="btn-group">
            <Field
              id={`${item.id}_yes`}
              type="radio"
              className={classNames(
                "btn-check",
                `${item.yesFieldClass ? item.yesFieldClass : ""} `
              )}
              name={item.id}
              value="1"
            />
            <label
              className="btn btn-sm btn-outline-secondary success"
              htmlFor={`${item.id}_yes`}
            >
              Yes
            </label>
            <Field
              id={`${item.id}_no`}
              type="radio"
              className={classNames(
                "btn-check",
                `${item.noFieldClass ? item.noFieldClass : ""} `
              )}
              checked={!values[item.id] ? true : values[item.id] === "0"}
              name={item.id}
              value="0"
            />
            <label
              className={classNames(
                "btn btn-sm btn-outline-secondary",
                !values[item.id] || values[item.id] === "0"
                  ? "text-bg-secondary border-secondary"
                  : ""
              )}
              htmlFor={`${item.id}_no`}
            >
              No
            </label>
          </div>
        </div>
      ))}
      <div
        className={classNames(
          `${
            props.label.labelWrapperClass ? props.label.labelWrapperClass : ""
          }`
        )}
      >
        <Label
          className={`${props.label.labelClass ? props.label.labelClass : ""}`}
        >
          {props.label.name}
        </Label>
        {props.label.description && (
          <div
            className={`${
              props.label.descriptionClass ? props.label.descriptionClass : ""
            }`}
          >
            {props.label.description}
          </div>
        )}
      </div>
    </div>
  );
}
