import {
  WORK_ORDERS_ERROR,
  HYDRATE_WORK_ORDERS,
  HYDRATE_WORK_ORDERS_SUCCESS,
  HYDRATE_WORK_ORDER,
  HYDRATE_WORK_ORDER_SUCCESS,
  EDIT_WORK_ORDER,
  ADD_WORK_ORDER,
} from "./actionTypes";

const initialState = {
  error: "",
  success: "",
};

const settings = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_WORK_ORDER:
      state = { ...state };
      break;
    case ADD_WORK_ORDER:
      state = { ...state };
      break;
    case WORK_ORDERS_ERROR:
      state = { ...state, error: action.payload };
      break;
    case HYDRATE_WORK_ORDERS:
      state = { ...state };
      break;
    case HYDRATE_WORK_ORDERS_SUCCESS:
      state = { ...state, ...action.payload };
      break;
    case HYDRATE_WORK_ORDER:
      state = { ...state };
      break;
    case HYDRATE_WORK_ORDER_SUCCESS:
      state = { ...state, ...action.payload };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default settings;
