import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";
import {
  Subcategory,
  UpdateSubcategoryMutation,
  UpdateSubcategoryMutationVariables,
  CreateItemMutation,
  CreateItemMutationVariables,
  DeleteItemMutation,
  UpdateSubcategoryInput,
  DeleteItemInput,
  UpdateItemInput,
} from "API";
import toast from "toastr";
import API, { graphqlOperation, GraphQLQuery } from "@aws-amplify/api";
import {
  updateSubcategory as updateSubcategoryMutation,
  createItem as createItemMutation,
  updateItem as updateItemMutation,
  deleteItem as deleteItemMutation,
} from "graphql/mutations";
import { getAccessToken } from "helpers/graphql";
import { getSubcategoryById, getSubcategoryItems } from "./fetchers";

export function useSubcategory(id: string) {
  const queryClient = useQueryClient();
  const subcategory = useQuery(["subcategories", id], getSubcategoryById, {
    enabled: Boolean(id),
  });
  const items = useQuery(["subcategories", id, "items"], getSubcategoryItems, {
    enabled: Boolean(id),
  });

  const updateSubcategory = useMutation({
    mutationKey: ["updateSubcategory", id],
    async mutationFn(input: Omit<UpdateSubcategoryInput, "id" | "ownerId">) {
      if (!subcategory.data) throw new Error("Subcategory not fetched");

      const { data } = await API.graphql<
        GraphQLQuery<UpdateSubcategoryMutation>
      >(
        graphqlOperation(
          updateSubcategoryMutation,
          {
            input: {
              id: subcategory.data.id,
              ...input,
            },
          } as UpdateSubcategoryMutationVariables,
          await getAccessToken()
        )
      );

      return data?.updateSubcategory
        ? {
            ...data.updateSubcategory,
            items: undefined,
            categories: undefined,
          }
        : undefined;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["subcategories"],
      });
      toast.success("Subcategory updated");
    },
    onError(error) {
      console.error(error);
      toast.error("Failed to update subcategory");
    },
  });

  const createItem = useMutation({
    mutationKey: ["createItemOnSubcategory", id],
    async mutationFn(input: {
      name: string;
    }): Promise<Subcategory | undefined> {
      if (!subcategory.data) throw new Error("Subcategory not fetched");

      const { data: item } = await API.graphql<
        GraphQLQuery<CreateItemMutation>
      >(
        graphqlOperation(
          createItemMutation,
          {
            input: {
              name: input.name,
              subcategoryId: subcategory.data.id,
            },
          } as CreateItemMutationVariables,
          await getAccessToken()
        )
      );

      if (item?.createItem?.id) {
        const { data } = await API.graphql<
          GraphQLQuery<UpdateSubcategoryMutation>
        >(
          graphqlOperation(
            updateSubcategoryMutation,
            {
              input: {
                id: subcategory.data.id,
                itemOrder: [
                  ...(subcategory.data.itemOrder || []),
                  item?.createItem.id,
                ],
              },
            } as UpdateSubcategoryMutationVariables,
            await getAccessToken()
          )
        );
        return data?.updateSubcategory
          ? {
              ...data.updateSubcategory,
              items: undefined,
              parent: undefined,
              category: undefined,
            }
          : undefined;
      }
    },
    onSuccess(data) {
      if (data) {
        queryClient.setQueryData(["subcategories", id], data);
      }
      queryClient.invalidateQueries(["subcategories"]);
      toast.success("Item added to subcategory");
    },
    onError(error) {
      console.error(error);
      toast.error("Failed to add item to subcategory");
    },
  });

  const updateItem = useMutation({
    mutationKey: ["updateItemOnSubcategory", id],
    async mutationFn(input: UpdateItemInput) {
      await API.graphql<GraphQLQuery<UpdateSubcategoryMutation>>(
        graphqlOperation(updateItemMutation, { input }, await getAccessToken())
      );
    },
    onSuccess(data) {
      queryClient.invalidateQueries(["subcategories", id, "items"]);
    },
    onError(error) {
      console.error(error);
      toast.error("Failed to update item");
    },
  });

  const reorderItems = useMutation({
    mutationKey: ["reorderItemsOnSubcategory", id],
    async mutationFn(input: { itemOrder: string[] }) {
      if (!subcategory.data) throw new Error("Subcategory not fetched");

      const { data } = await API.graphql<
        GraphQLQuery<UpdateSubcategoryMutation>
      >(
        graphqlOperation(
          updateSubcategoryMutation,
          {
            input: {
              id: subcategory.data.id,
              itemOrder: input.itemOrder,
            },
          } as UpdateSubcategoryMutationVariables,
          await getAccessToken()
        )
      );

      return data?.updateSubcategory
        ? {
            ...data.updateSubcategory,
            items: undefined,
            categories: undefined,
          }
        : undefined;
    },
    onSuccess(data) {
      if (data) {
        queryClient.setQueryData(["subcategories", id], data);
      }
      queryClient.invalidateQueries(["subcategories"]);
    },
    onError(error) {
      console.error(error);
      toast.error("Failed to update item order");
    },
  });

  const removeItem = useMutation({
    mutationKey: ["removeItemFromSubcategory", id],
    async mutationFn(input: { id: string }): Promise<Subcategory | undefined> {
      if (!subcategory.data) throw new Error("Subcategory not fetched");

      const { data: item } = await API.graphql<
        GraphQLQuery<DeleteItemMutation>
      >(
        graphqlOperation(
          deleteItemMutation,
          {
            input: {
              id: input.id,
            } as DeleteItemInput,
          },
          await getAccessToken()
        )
      );

      if (item?.deleteItem?.id) {
        const { data } = await API.graphql<
          GraphQLQuery<UpdateSubcategoryMutation>
        >(
          graphqlOperation(
            updateSubcategoryMutation,
            {
              input: {
                id: subcategory.data.id,
                itemOrder: (subcategory.data.itemOrder || []).filter(
                  id => id !== item?.deleteItem?.id
                ),
              },
            } as UpdateSubcategoryMutationVariables,
            await getAccessToken()
          )
        );
        return data?.updateSubcategory
          ? {
              ...data.updateSubcategory,
              items: undefined,
              category: undefined,
              parent: undefined,
            }
          : undefined;
      }
    },
    onSuccess() {
      queryClient.invalidateQueries(["subcategories"]);
      toast.success("Item removed");
    },
    onError(error) {
      console.error(error);
      toast.error("Failed to remove item from subcategory");
    },
  });

  return {
    subcategory,
    items,
    updateSubcategory,
    createItem,
    updateItem,
    reorderItems,
    removeItem,
  };
}
