import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  REGISTER_MESSAGE_RESET,
} from "./actionTypes";

export const registerUser = (user, history) => {
  return {
    type: REGISTER_USER,
    payload: { user, history },
  };
};

export const registerUserSuccessful = message => {
  return {
    type: REGISTER_USER_SUCCESSFUL,
    payload: message,
  };
};

export const registerUserFailed = user => {
  return {
    type: REGISTER_USER_FAILED,
    payload: user,
  };
};

export const registerMessageReset = () => {
  return {
    type: REGISTER_MESSAGE_RESET,
  };
};
