import React from "react";
import { Card, CardBody, Row } from "reactstrap";

export default function EULA() {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Row className="justify-content-center">
            <h2 className="text-center">
              END-USER LICENSE AGREEMENT FOR HORIZON
            </h2>
            <h2 className="text-center">
              WEBSITE AND SOFTWARE - Revised October 16, 2020
            </h2>
            <div className="mb-3">
              <b>ATTENTION:</b> PLEASE READ THIS CARSON, DUNLOP & ASSOCIATES
              LTD. (<b>"Carson Dunlop"</b>) END-USER LICENSE AGREEMENT (the
              <b>"License Agreement"</b>) CAREFULLY BEFORE INSTALLING OR USING
              CARSON DUNLOP'S "HORIZON" SOFTWARE, IN OBJECT CODE FORM (AND
              RELATED SCRIPTS) (the <b>"Horizon Client Software"</b>) OR USING
              THE HORIZON SOFTWARE AS A SERVICE ACCESSIBLE THROUGH
              DISCOVERHORIZON.COM (the <b>"Horizon SaaS"</b>) (the Horizon
              Client Software and Horizon SaaS are collectively referred to as
              the <b>"Software"</b>). BY ACCESSING AND/OR USING THE HORIZON
              SAAS, OR INSTALLING, DOWNLOADING, COPYING OR OTHERWISE USING THE
              HORIZON CLIENT SOFTWARE, YOU AGREE TO BE BOUND BY THE TERMS AND
              CONDITIONS OF THIS LICENSE AGREEMENT. IF YOU DO NOT ACCEPT THE
              TERMS OF THIS LICENSE AGREEMENT, YOU WILL NOT BE ABLE TO USE THE
              SOFTWARE. IF YOU DO NOT ACCEPT THE TERMS OF THIS LICENSE AGREEMENT
              AND HAVE PROOF OF PAYMENT, YOU MAY, WITHIN 10 DAYS OF PURCHASING
              HORIZON CREDITS or SUBSCRIPTION, RECEIVE A REFUND FOR THE FEES
              ALREADY PAID FOR SUCH CREDITS OR SUBSCRIPTION BY SENDING A REFUND
              REQUEST TO CARSON DUNLOP TOGETHER WITH PROOF THAT YOU HAVE DELETED
              OR DESTROYED ANY COPIES OF THE HORIZON CLIENT SOFTWARE THAT YOU
              HAVE DOWNLOADED OR COPIED (IF APPLICABLE). THE SOFTWARE IS
              PROTECTED BY COPYRIGHT LAWS AND INTERNATIONAL COPYRIGHT TREATIES,
              AS WELL AS OTHER INTELLECTUAL PROPERTY LAWS AND TREATIES. THE
              SOFTWARE IS BEING LICENSED TO YOU IN ACCORDANCE WITH THE TERMS
              CONTAINED HEREIN, NOT SOLD. CARSON DUNLOP RESERVES ANY AND ALL
              RIGHTS IN THE SOFTWARE THAT HAVE NOT BEEN EXPRESSLY GRANTED TO
              YOU.
            </div>
            <div className="mb-3">
              1. <b>Terms and Conditions of Use.</b> As set out above, the use
              of the Website and the Software is subject to the terms and
              conditions set forth below. By creating a Horizon account or by
              accessing and then using the Software, you accept and agree to be
              bound by the terms and conditions of use that govern it. You also
              represent and warrant that the information provided in regard to
              the creation of your Horizon account is accurate, current and
              complete. Carson Dunlop reserves the right to change these terms
              and conditions of use as it sees fit and without prior notice. If
              you do not wish to abide by these terms and conditions, make no
              further use of the Software, and uninstall any copies of the
              Horizon Client Software on any devices that you own or control,
              unless you assume the obligations outlined below.
            </div>
            <div className="mb-3">
              2. <b>License.</b> Carson Dunlop hereby grants you a
              non-exclusive, revocable, limited, non-transferable license to
              install and operate the Horizon Client Software or to access and
              use the Horizon SaaS, subject to your continued compliance with
              this License Agreement. The foregoing license is granted on the
              basis that one work order can only be used for one inspection (one
              client and one address). The license is personal to you and may
              not be shared. The purchase of credits or subscription gives you
              the right to use the Software in accordance with this license to
              create and save inspection reports and work orders. Carson Dunlop
              retains title to the Software and all related documentation.
              Pursuant to the license granted to you herein, you may use the
              Software on one or more personal computers, one or more tablets
              and one or more smart phones, provided use on each of the
              foregoing is restricted to you in conjunction with your business.
              <p>
                Horizon credits will expire after 12 months of non-use.
                Likewise, your Horizon credit balance will expire if you do not
                log into your Horizon account through use of the Software.
              </p>
              <p>
                Carson Dunlop either owns the intellectual property rights in
                the underlying HTML, articles, text, images, audio clips, video
                clips, software and other content (collectively, the{" "}
                <b>"Content"</b>) that is made available to you via the
                Software, or has obtained the permission of the owner of the
                intellectual property in such Content to use the Content. Except
                as provided in this License Agreement, Carson Dunlop prohibits
                the redistribution or copying of any Content without its express
                written permission.
              </p>
              <p>
                Carson Dunlop hereby authorizes you to display on your computer,
                tablet or smart phone, download and print the Content, subject
                to the following provisions: (i) the copyright notice generated
                by the Software must appear on all such printouts without
                modification; (ii) the information will not be altered in any
                manner; and (iii) the Content will not be redistributed to
                anyone other than your home inspection clients and their
                professional advisors; and (iv) the Content will not broadcast
                or copied to any other media.
              </p>
              <p>
                Carson Dunlop retains the right to review the data and
                inspection reports created by the Software, and or stored using
                the Horizon SaaS, for the purpose of support and maintenance of
                client accounts, for the purpose of monitoring for abuse of the
                system, for the purpose of compiling information related to the
                maintenance or updating of the Software, and or for the purpose
                of accumulating information which, may be used from time to time
                for the purpose of research, identifying trends and or products
                or services which may be of interest to you or other customers
                of Carson Dunlop or others. Carson Dunlop will not disclose any
                personal information about any identifiable individual which can
                be directly and specifically connected to any such identifiable
                individual.
              </p>
            </div>
            <div className="mb-3">
              4. <b>No Other License.</b> No other rights or licenses are
              granted to you by Carson Dunlop under this license, expressly or
              by implication, with respect to any proprietary information,
              copyright, trade secret or other intellectual property right owned
              or controlled by Carson Dunlop, except as expressly provided in
              this License Agreement, including, without limitation, with
              respect to the use of the Software.
            </div>
            <div className="mb-3">
              5. <b>Not Transferable.</b> The Software, the related
              documentation and this License Agreement are not transferable (by
              operation of law or otherwise) without the prior express written
              consent of Carson Dunlop. You may not grant any sublicenses in
              regard to the Software.
            </div>
            <div className="mb-3">
              6. <b>Restrictions.</b> The Software contains copyrighted
              material, trade secrets and other proprietary material. In order
              to protect them, and except as permitted by applicable
              legislation, you may not: (a) modify, network, rent, lend, share,
              loan, distribute, use as a service bureau or create derivative
              works based upon the Software in whole or in part; (b)
              electronically transmit the Software from one computer to another
              or over a network or otherwise transfer the Software except as
              permitted by this license; or (c) decompile, reverse-assemble or
              reverse engineer the Software.
            </div>
            <div className="mb-3">
              7. <b>Termination.</b> This license is effective until terminated.
              You may terminate this license at any time by deleting all copies
              of the Horizon Client Software (both installed versions thereof
              and installation software for same) and destroying or returning
              all such copies to Carson Dunlop any related documentation and all
              copies thereof made by you. This license will terminate
              immediately without notice from Carson Dunlop if you fail to
              comply with any provision of this License Agreement. Upon
              termination of this License Agreement, you must delete all copies
              of the Horizon Client Software (both installed version thereof and
              installation software for same) and destroy or return to Carson
              Dunlop, any related documentation and all copies thereof made by
              you.
            </div>
            <div className="mb-3">
              8. <b>Disclaimer of Warranty on Software and Content.</b> YOU
              EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE SOFTWARE IS AT
              YOUR SOLE RISK. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW,
              THE SOFTWARE, THE CONTENT AND RELATED DOCUMENTATION ARE PROVIDED
              ON AN "AS IS" AND "AS AVAILABLE" BASIS AND WITHOUT WARRANTY OF ANY
              KIND AND CARSON DUNLOP EXPRESSLY DISCLAIMS ALL WARRANTIES OR
              CONDITIONS, EXPRESS AND IMPLIED, INCLUDING, BUT NOT LIMITED TO,
              THE IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS
              FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. CARSON DUNLOP DOES
              NOT WARRANT THAT THE FUNCTIONS CONTAINED IN THE SOFTWARE WILL MEET
              YOUR REQUIREMENTS, OR THAT THE OPERATION OF THE SOFTWARE WILL BE
              UNINTERRUPTED OR ERROR-FREE, OR THAT DEFECTS IN THE SOFTWARE WILL
              BE CORRECTED. THE ENTIRE RISK AS TO THE RESULTS AND PERFORMANCE OF
              THE SOFTWARE IS ASSUMED BY YOU. FURTHERMORE, CARSON DUNLOP DOES
              NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR THE
              RESULTS OF THE USE OF THE SOFTWARE OR RELATED DOCUMENTATION IN
              TERMS OF THEIR CORRECTNESS, ACCURACY, RELIABILITY OR OTHERWISE. NO
              ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY CARSON DUNLOP OR A
              DIRECTOR, OFFICER, EMPLOYEE, CONTRACTOR, SERVICE PROVIDER,
              ADVISOR, AGENT OR OTHER REPRESENTATIVE OF CARSON DUNLOP WILL
              CREATE A WARRANTY OR IN ANY WAY INCREASE THE SCOPE OF THIS
              WARRANTY. The Content and other information contained or displayed
              by the Software, is provided for general guidance only. Such
              information should not be relied upon or used as the basis for any
              decision.
              <p>
                Certain information displayed through use of the Software and
                otherwise in the Content has been provided to Carson Dunlop by
                third parties. You must carry out your own due diligence in
                respect of all matters referred to and satisfy yourself as to
                the accuracy thereof. Although Carson Dunlop uses reasonable
                efforts to maintain the accuracy and currency of the Content and
                other information displayed through use of the Software , Carson
                Dunlop makes no warranties or representations as to its accuracy
                or currency. The Software and the Content may contain
                typographical errors and technical inaccuracies. Carson Dunlop
                assumes no liability or responsibility for any errors or
                omissions or the Software or in the Content.
              </p>
              <p>
                Carson Dunlop may update and/or modify the Software and the
                Content at any time and without notice.
              </p>
            </div>
            <div className="mb-3">
              9. <b>General.</b> If you breach any of the terms set out in this
              License Agreement, your license automatically terminates and you
              must immediately delete or destroy any downloaded copy of the
              Horizon Client Software and any copies (printed or digital) of any
              Content.
              <p>
                Carson Dunlop will aggressively enforce its intellectual
                property rights to the fullest extent of the law.
              </p>
              <p>
                By using the Software, you consent to the collection, use and
                storage of your information by Carson Dunlop in the manner
                described herein and in our privacy policy. Carson Dunlop
                reserves the right to make changes to the terms of this License
                Agreement and our privacy policy from time to time. When using
                the Website or the Software, you should review the current terms
                of this License Agreement and privacy policy to determine if
                they have been amended. Changes to the rights and obligations of
                the parties herein may be applied retroactively to information
                or content which you have previously elected to store and
                continue to store, with Carson Dunlop through your use of the
                Software.
              </p>
            </div>
            <div className="mb-3">
              10. <b>Governing Law and Severability.</b> This License Agreement
              shall be governed by and construed under the laws of the Province
              of Ontario (and the federal laws of Canada applicable therein)
              without reference to its conflict of law principles. In the event
              of any conflicts between foreign law and Ontario law, Ontario law
              shall prevail and govern. The United Nations convention on
              contracts for the international sale of goods will not apply to
              this License Agreement and the subject matter thereof (including
              any prior purchases in regard to subscriptions or credits relating
              to the Software). If for any reason a court of competent
              jurisdiction finds any provision of this License Agreement or a
              portion thereof, to be unenforceable, that provision of this
              License Agreement shall be enforced to the maximum extent
              permissible so as to effect the intent of the parties hereto, and
              the remainder of this License Agreement shall continue in full
              force and effect.
            </div>
            <div className="mb-3">
              11. <b>Complete Agreement.</b> This License Agreement constitutes
              the entire agreement between the parties hereto with respect to
              the use of the Software and the related documentation, and
              supersedes all prior or contemporaneous understandings or
              agreements, written or oral, regarding such subject matter. No
              amendment to or modification of this License Agreement will be
              binding unless in writing and signed by a duly authorized
              representative of Carson Dunlop. Notwithstanding the foregoing,
              access to the SaaS and Carson Dunlop website is also subject to
              the terms of use published or provided by Carson Dunlop, as same
              may be modified from time to time.
            </div>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}
