// Hooks
import { useState } from "react";
import { useProfile } from "hooks/profile";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { Col, Container, Row, TabPane, TabContent } from "reactstrap";
import MetaTags from "react-meta-tags";

import Breadcrumb from "components/Common/Breadcrumb";

// Tab Panes
import SideMenu from "./menu";
import TabProfile from "./components/_tabProfile";
import TabDataPrivacy from "./components/_data-privacy";
import TabSecurity from "./components/_tabSecurity";
import TabLicense from "./components/_tabLicenses";
import TabAvailability from "./components/_tabAvailability";
import TabSpeciality from "./components/_tabSpeciality";
import TabNotifications from "./components/_tabNotifications";

const Profile = props => {
  const [activeTab, setActiveTab] = useState("profile");
  const profile = useProfile();
  const [profileData, setProfileData] = useState({});
  const { t } = useTranslation();

  const toggleTab = tab => {
    setActiveTab(tab);
  };

  useQuery(["profile"], profile.fetch, {
    staleTime: -1,
    onSettled: data => setProfileData(data),
  });

  return (
    <div className="page-content">
      <MetaTags>
        <title>
          {t("personal_settings_meta_title", "Personal Settings")} | Horizon
          Core
        </title>
      </MetaTags>
      <Container fluid={true}>
        <Breadcrumb
          title={t("personal_settings_page_title", "My Settings")}
          icon="bx bxs-user-rectangle"
          breadcrumbItems={[
            {
              uri: "/my/settings",
              text: t("settings", "Settings"),
              active: true,
            },
            {
              uri: "/#",
              text: profileData.firstName + " " + profileData.lastName,
              active: true,
            },
          ]}
        />
        <Row>
          <Col md="3" className="pr-0 side-nav-column mobile-shadow">
            <SideMenu activeTab={activeTab} toggleTab={toggleTab} />
          </Col>
          <Col className="bg-white px-0">
            <TabContent activeTab={activeTab}>
              {activeTab === "profile" && <TabProfile />}
              {activeTab === "availability" && (
                <TabAvailability show={activeTab === "availability"} />
              )}
              <TabSpeciality />
              <TabNotifications />
              <TabSecurity />
              <TabLicense />
              <TabPane tabId="dataPrivacy">
                <TabDataPrivacy />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Profile;
