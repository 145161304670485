import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { Fragment, useState, useEffect, useCallback } from "react";

import { ResourceRow } from "./ResourceRow";
import { ResourceListProvider } from "./context";
import { recurseAndRemove, recurseAndAdd } from "./utils";
import type { BaseResource, ResourceListProps } from "./types";

export function ResourceList<Resource extends BaseResource>(
  props: ResourceListProps<Resource>
) {
  const { t } = useTranslation("components");
  const [displayOrder, setDisplayOrder] = useState(props.items);
  const [isDragging, setIsDragging] = useState(false);
  const handleReorderDrop = useMutation(async () =>
    props.onReorder?.(displayOrder)
  );

  useEffect(() => {
    setDisplayOrder(props.items);
  }, [props.items]);

  const handleReorderHover = useCallback(
    (dragIndex: number[], hoverIndex: number[]) => {
      setDisplayOrder(prev => {
        const newOrder = [...prev];
        const item = recurseAndRemove(dragIndex, newOrder);

        recurseAndAdd(hoverIndex, newOrder, item);

        return newOrder;
      });
    },
    []
  );

  const renderRow = useCallback(
    (item: typeof props.items[number], index: number[]) => {
      if (item.type === "group") {
        return (
          <Fragment key={item.value.id}>
            <ResourceRow
              type={props.type}
              resource={item.value}
              badges={props.badges}
              actions={props.actions}
              index={index}
              canBecomeGroup={props.canBecomeGroup?.(item.value)}
            />
            <div className="pl-4" style={{}}>
              <ResourceList
                type={props.type}
                badges={props.badges}
                actions={props.actions}
                items={item.items}
                baseIndex={index}
                canBecomeGroup={props.canBecomeGroup}
              />
            </div>
          </Fragment>
        );
      }

      return (
        <Fragment key={item.value.id}>
          <ResourceRow
            type={props.type}
            resource={item.value}
            badges={props.badges}
            actions={props.actions}
            index={index}
            canBecomeGroup={props.canBecomeGroup?.(item.value)}
          />

          <div className="w-100 my-2 bg-light" style={{ height: 1 }} />
        </Fragment>
      );
    },
    [props]
  );

  if (props.items.length === 0) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center p-4 bg-light rounded text-muted">
        <p className="m-0">{t("Nothing yet")}</p>
      </div>
    );
  }

  if (props.baseIndex) {
    return (
      <>
        <div className="w-100 my-2 bg-light" style={{ height: 1 }} />
        {displayOrder.map((resource, index) =>
          renderRow(resource, [...(props.baseIndex || []), index])
        )}
      </>
    );
  } else {
    return (
      <ResourceListProvider
        value={{
          canReorder: !!props.onReorder,
          handleDragStart: () => setIsDragging(true),
          handleDragEnd: () => setIsDragging(false),
          showDropZones: isDragging,
          handleHover: props.onReorder && handleReorderHover,
          handleDrop: props.onReorder && handleReorderDrop.mutate,
          onRename: props.onRename,
        }}
      >
        <div className="w-100 my-2 bg-light" style={{ height: 1 }} />
        {displayOrder.map((resource, index) =>
          renderRow(resource, [...(props.baseIndex || []), index])
        )}
      </ResourceListProvider>
    );
  }
}
