import React from "react";
import { Redirect } from "react-router-dom";
// User settings pages
import MySettings from "../pages/My/Settings/";

import MyJobs from "../pages/My/Jobs/";

// Company pages
import CompanySettings from "../pages/Company/Settings/";
import CompanyTeam from "../pages/Company/Team/";
import EditMember from "pages/Company/Team/Edit";
import InviteMember from "pages/Company/Team/InviteMember";

// Discounts
import DiscountsActions from "pages/Company/Settings/Discounts/edit";

// Work pages
import WorkOrders from "../pages/Work/Orders";
import WorkOrder from "../pages/Work/Order";

// Report pages
import ReportSettings from "../pages/Report/Settings/";
import ReportPage from "../pages/Report/";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import RecoverPwd from "../pages/Authentication/RecoverPassword";

// Load paths
import { forgotPassword, recoverPassword } from "./paths";

// User Inspections page
import MyInspections from "../pages/Inspections/Listing";
import { EditTemplate } from "pages/Report/Settings/EditTemplate";
import { EditCategory } from "pages/Report/Settings/EditCategory";
import { EditSubcategory } from "pages/Report/Settings/EditSubcategory";
import AuthPrivacyPolicy from "pages/PrivacyPolicy";
import AuthEula from "pages/EULA";

const companyRedirect = () => <Redirect to="/company/team" />;
companyRedirect.displayName = "Company Redirect";

const inspectionRedirect = () => <Redirect to="/inspections/listing" />;
inspectionRedirect.displayName = "Inspection Redirect";

const profileRedirect = () => <Redirect to="/my/settings" />;
profileRedirect.displayName = "Profile Redirect";

const myJobsRedirect = () => <Redirect to="/my/jobs" />;
profileRedirect.displayName = "My Jobs Redirect";

const workRedirect = () => <Redirect to="/work/orders" />;
profileRedirect.displayName = "Work Redirect";

const authProtectedRoutes = [
  // profile
  { path: "/my/settings", component: MySettings },
  { path: "/my/jobs", exact: true, component: MyJobs },
  { path: "/my/jobs/:id", exact: true, component: MyJobs },

  // discounts
  { path: "/company/settings/discounts/:action", component: DiscountsActions },

  // company routes
  { path: "/company", exact: true, component: companyRedirect },
  { path: "/company/settings/:tab?", component: CompanySettings },
  { path: "/company/team/edit", component: EditMember },
  { path: "/company/team/invite", component: InviteMember },
  { path: "/company/team", component: CompanyTeam },

  // inspections
  { path: "/inspections", exact: true, component: inspectionRedirect },
  { path: "/inspections/listing", component: MyInspections },

  // work
  { path: "/work", exact: true, component: workRedirect },
  { path: "/work/orders", exact: true, component: WorkOrders },
  { path: "/work/order/:id", exact: true, component: WorkOrder },
  { path: "/work/order/report/:id", exact: true, component: ReportPage },

  // reports
  { path: "/report/settings", exact: true, component: ReportSettings },

  // inspection resources
  {
    path: "/report/settings/templates/:id",
    exact: true,
    component: EditTemplate,
  },
  {
    path: "/report/settings/categories/:id",
    exact: true,
    component: EditCategory,
  },
  {
    path: "/report/settings/categories/:categoryId/subcategories/:subcategoryId",
    exact: true,
    component: EditSubcategory,
  },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: myJobsRedirect },
  { path: "/my/", exact: true, component: profileRedirect },

  // privacy page when logged in
  { path: "/privacy", exact: true, component: AuthPrivacyPolicy },
  { path: "/eula", exact: true, component: AuthEula },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: forgotPassword, component: ForgetPwd },
  { path: recoverPassword, component: RecoverPwd },
  { path: "/register", component: Register },
];

export { authProtectedRoutes, publicRoutes };
