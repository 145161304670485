export type Speciality = {
  id: string;
  label: string;
  description?: string;
  checked?: boolean;
};

export const specialities: Speciality[] = [
  {
    id: "service_video_documentation",
    label: "Video documentation",
  },
  { id: "service_sewer_scopes", label: "Sewer scopes" },
  {
    id: "service_mold_inspection",
    label: "Mold inspection",
  },
  {
    id: "service_unmanned_aerial_vehicle",
    label: "Unmanned aerial vehicle (UAV) / drone inspection",
  },
  { id: "service_home_security", label: "Home security" },
  { id: "service_radon_test", label: "Radon test" },
  { id: "service_thermography", label: "Thermography" },
  { id: "service_microbiology", label: "Microbiology" },
  {
    id: "service_asbestos_inspection",
    label: "Asbestos inspection",
  },
  {
    id: "service_water_quality_testing",
    label: "Water quality testing",
  },
  {
    id: "service_indoor_air_quality",
    label: "Indoor air quality",
  },
  {
    id: "service_sinkhole_inspection",
    label: "Sinkhole inspections",
  },
  {
    id: "service_optical_gas_imaging",
    label: "Optical gas imaging",
  },
  {
    id: "service_lead_based_paint_testing",
    label: "Lead-based paint testing",
  },
  {
    id: "service_deck_inspection",
    label: "Deck inspections",
  },
  {
    id: "service_phase_construction",
    label: "Phase construction",
  },
  {
    id: "service_environmental_inspection",
    label: "Environmental inspection",
  },
  {
    id: "service_disabilities_safety_inspection",
    label: "Disabilities / safety inspections",
  },
  {
    id: "service_chimeny_inspection",
    label: "Chimney inspection",
  },
  {
    id: "service_vermiculite_sampling",
    label: "Vermiculite sampling",
  },
  {
    id: "service_uffi_inspection",
    label: "UFFI inspection",
  },
  { id: "service_voc_testing", label: "VOC testing" },
  {
    id: "service_wood_destroying_organisms_inspection",
    label: "Wood destroying organisms (WDO) inspection",
  },
  {
    id: "service_elevator_agency_inspector",
    label: "Elevator agency / inspector",
  },
  {
    id: "service_rodent_pest_inspection",
    label: "Rodent / pest inspection",
  },
  {
    id: "service_well_septic_inspection",
    label: "Well and septic inspection",
  },
  {
    id: "service_home_energy_inspection",
    label: "Home energy inspection",
  },
  { id: "service_other", label: "Other (please specify)" },
];
