import { QueryFunction, QueryFunctionContext } from "@tanstack/react-query";
import { graphqlOperation } from "aws-amplify";
import { getAccessToken } from "helpers/graphql";
import {
  Product,
  ListProductsQuery,
  CreateProductInput,
  CreateProductMutation,
  UpdateProductInput,
  UpdateProductMutation,
  DeleteProductMutation,
  ProductByIdQuery,
} from "API";
import { createProduct, deleteProduct, updateProduct } from "graphql/mutations";
import API, { GraphQLQuery } from "@aws-amplify/api";
import { productById, listProducts } from "graphql/queries";
import { useProfile } from "hooks/profile";

export async function getProducts({
  queryKey,
}: QueryFunctionContext<["products", string]>) {
  const res = await API.graphql<GraphQLQuery<ListProductsQuery>>(
    graphqlOperation(
      listProducts,
      { ownerId: queryKey[1] },
      await getAccessToken()
    )
  );

  const products: Product[] = [];
  for (const product of res.data?.listProducts?.items ?? []) {
    if (product) products.push({ ...product });
  }

  return {
    products,
    nextToken: res.data?.listProducts?.nextToken ?? undefined,
  };
}

export async function addProductFn(input: CreateProductInput) {
  const res = await API.graphql<GraphQLQuery<CreateProductMutation>>(
    graphqlOperation(
      createProduct,
      {
        input,
      },
      await getAccessToken()
    )
  );

  return res.data?.createProduct;
}

export async function deleteProductFn(product: {
  id: string;
  ownerId: string;
}) {
  const res = await API.graphql<GraphQLQuery<DeleteProductMutation>>(
    graphqlOperation(
      deleteProduct,
      {
        input: {
          id: product.id,
          ownerId: product.ownerId,
        },
      },
      await getAccessToken()
    )
  );

  return res.data?.deleteProduct;
}

export async function updateProductFn(input: UpdateProductInput) {
  const res = await API.graphql<GraphQLQuery<UpdateProductMutation>>(
    graphqlOperation(
      updateProduct,
      {
        input,
      },
      await getAccessToken()
    )
  );

  return res.data?.updateProduct;
}

export const getProductById: QueryFunction<
  Product,
  ["products", string]
> = async ({ queryKey }) => {
  const res = await API.graphql<GraphQLQuery<ProductByIdQuery>>(
    graphqlOperation(productById, { id: queryKey[1] }, await getAccessToken())
  );

  if (res.data?.productById?.items[0]) {
    return { ...res.data?.productById.items[0] };
  } else {
    throw new Error("Product not found");
  }
};
