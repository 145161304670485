import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";
import MessageHub from "./message/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./profile/reducer";

// Company
import CompanyTeam from "./Company/Team/reducer";
import CompanySettings from "./Company/Settings/reducer";

// Work
import WorkOrders from "./Work/Order/reducer";

// Snippets
import Snippets from "./Snippet/reducer";

import Templates from "./templates/reducer";
import Categories from "./categories/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  MessageHub,
  CompanyTeam,
  CompanySettings,
  WorkOrders,
  Snippets,
  Templates,
  Categories,
});

export default rootReducer;
