import { Row, Col, TabPane, Button, Spinner } from "reactstrap";
import "reactstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css";
import { specialities } from "helpers/StaticLists/Specialities";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import { useProfile } from "hooks/profile";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import toastr from "toastr";
import LeavingForm from "components/Common/LeavingForm";
import StandardFormikSwitch from "components/Fields/Switches/StandardFormikSwitch";

type EditingObject = {
  specialities: Object;
};

const TabSpeciality = (props: { editing: EditingObject }) => {
  const { t } = useTranslation();
  const switchOptions = specialities.map((speciality, idx) => ({
    id: idx + 1,
    switches: [
      {
        id: `${speciality.id}`,
        columnClass:
          "col col-md col-lg-3 d-flex align-items-center justify-content-center",
      },
    ],
    label: {
      name: speciality.label,
      labelWrapperClass: "col border-start d-flex align-items-center",
    },
    rowClass: "mb-2 row",
  }));
  const profile = useProfile();
  const queryClient = useQueryClient();
  const { isLoading, data: profileData } = useQuery(["profile"], profile.fetch);
  let initialData = {};
  if (window.location.pathname === "/company/team/edit") {
    if (typeof props.editing?.specialities === "string") {
      initialData = JSON.parse(props.editing?.specialities).reduce(
        (acc: Object, cur: string) => ({ ...acc, [cur]: "1" }),
        {}
      );
    } else {
      initialData = props.editing?.specialities || {};
    }
  } else {
    if (typeof profileData?.specialities === "string") {
      initialData = JSON.parse(profileData?.specialities).reduce(
        (acc: Object, cur: string) => ({ ...acc, [cur]: "1" }),
        {}
      );
    } else {
      initialData = profileData?.specialities || {};
    }
  }

  const saveProfile = useMutation(
    values => {
      return profile.save(values);
    },
    {
      onSuccess: () => {
        toastr.success(
          t("profile_saved_success", "Profile saved successfully")
        );
        queryClient.invalidateQueries(["profile"]);
      },
      onError: error => {
        console.log(error);
      },
    }
  );

  function handleFormSubmit(values: any) {
    const saveData =
      window.location.pathname === "/company/team/edit"
        ? { ...props.editing, specialities: values }
        : { ...profileData, specialities: values };
    saveProfile.mutate(saveData);
  }

  return (
    <TabPane tabId="specialities">
      <h2>
        {t(
          "personal_settings_speciality_tab_title",
          "Specialities and services "
        )}
      </h2>
      {isLoading ? (
        <Spinner></Spinner>
      ) : (
        <Formik
          enableReinitialize={false}
          initialValues={initialData}
          onSubmit={(values, action) => {
            handleFormSubmit(values);
            action.setTouched({});
          }}
        >
          {formik => (
            <>
              <Form>
                <Row>
                  {switchOptions.map(switchOption => (
                    <Col key={switchOption.id} md xs lg="6">
                      <StandardFormikSwitch
                        switches={switchOption.switches}
                        label={switchOption.label}
                        rowClass={switchOption.rowClass}
                      />
                    </Col>
                  ))}
                  <div className="mt-5">
                    <Button color="primary" type="submit">
                      {t("save_changes", "Save Changes")}
                    </Button>
                    <LeavingForm formikProps={formik} />
                  </div>
                </Row>
              </Form>
            </>
          )}
        </Formik>
      )}
    </TabPane>
  );
};

export default TabSpeciality;
