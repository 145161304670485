import * as types from "./actionTypes";

const initialState = {
  error: "",
  success: null,
  discounts: [],
};

const settings = (state = initialState, action) => {
  switch (action.type) {
    case types.EDIT_COMPANY_SETTINGS:
      return { ...state, success: null };
    case types.EDIT_COMPANY_SETTINGS_SUCCESS:
      return { ...state, success: true };
    case types.COMPANY_SETTINGS_ERROR:
      return { ...state, error: action.payload, success: false };
    case types.HYDRATE_COMPANY_SETTINGS:
      return { ...state };
    case types.HYDRATE_COMPANY_SETTINGS_SUCCESS:
      return { ...state, ...action.payload };
    case types.FETCH_COMPANY_DISCOUNTS:
      return { ...state };
    case types.FETCH_COMPANY_DISCOUNTS_SUCCESS:
      return {
        ...state,
        discounts: action.payload,
      };
    case types.FETCH_COMPANY_DISCOUNTS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case types.FETCH_DISCOUNT:
    case types.DELETE_DISCOUNT:
      return {
        ...state,
        id: action.payload,
      };
    case types.FETCH_DISCOUNT_SUCCESS:
      return { ...state, discounts: { ...action.payload } };
    case types.DELETE_DISCOUNT_SUCCESS:
      return { ...state };
    case types.RESET_DISCOUNT:
      return {
        ...state,
        discounts: [],
      };
    default:
      return { ...state };
  }
};

export default settings;
