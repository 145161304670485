import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useProfile } from "hooks/profile";

import Icon from "components/Common/Icons";
import { useQuery } from "@tanstack/react-query";
import noAvatar from "assets/images/no-avatar.png";

const ProfileMenu = () => {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const profile = useProfile();

  const { data: profileData } = useQuery(["profile"], profile.fetch);

  const toggle = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <React.Fragment>
      <Dropdown isOpen={menuOpen} toggle={toggle} className="d-inline-block">
        <DropdownToggle
          className="btn header-item d-flex align-items-center"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={
              profileData?.photo && profileData?.photo !== ""
                ? profileData.photo
                : noAvatar
            }
            alt=""
          />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end text-end">
          <Link to="/my/settings" className="dropdown-item">
            {t("profile", "Profile")}
            <Icon name="user" className="font-size-16 align-middle ms-1" />
          </Link>
          <Link to="/logout" className="dropdown-item">
            <span>{t("logout", "Logout")}</span>
            <Icon
              name="power-off"
              className="font-size-16 align-middle ms-1 text-danger"
            />
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileMenu;
