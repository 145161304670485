import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import Icon, { getCSSClass } from "components/Common/Icons";

const SideMenuNavItem = props => {
  const [active, setActive] = useState(false);
  const preparedChildren = () => {
    return React.Children.map(props.subMenu, function (child) {
      return React.cloneElement(child, {
        t: props.t,
        toggleDrawer: props.toggleDrawer,
      });
    });
  };

  useEffect(() => {
    setActive(props.activeTab === props.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activeTab]);

  return (
    <React.Fragment>
      <NavItem>
        <NavLink
          data-url={props.url}
          id={props.id}
          style={{ cursor: "pointer" }}
          className={classnames({
            active: active,
            "has-arrow": props.hasSubMenu,
          })}
          onClick={e => {
            const el = e.target;
            const drawer = el.closest("#submenu-drawer");
            if (props.hasSubMenu) {
              props.toggleDrawer(preparedChildren());
            } else {
              if (drawer) {
                const existingActive = drawer.querySelector(".active");
                if (existingActive) {
                  existingActive.classList.remove("active");
                }
                el.classList.add("active");
                if (window.innerWidth < 768) {
                  setTimeout(() => {
                    props.toggleDrawer();
                  }, 500);
                }
              }
            }
            props.toggleTab(props.id);
          }}
        >
          {props.label}
          {props.hasSubMenu ? (
            <Icon
              name={getCSSClass("rightArrow")}
              style={{ fontSize: "16px" }}
              className=""
            />
          ) : (
            ""
          )}
        </NavLink>
      </NavItem>
      {preparedChildren()}
    </React.Fragment>
  );
};

SideMenuNavItem.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  activeTab: PropTypes.string,
  toggleTab: PropTypes.func,
  t: PropTypes.func,
  hasSubMenu: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  subMenu: PropTypes.any,
  url: PropTypes.string,
};

export default SideMenuNavItem;
