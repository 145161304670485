export const SNIPPETS_ERROR = "SNIPPETS_ERROR";
export const SNIPPET_ERROR = "SNIPPET_ERROR";
export const EDIT_SNIPPET = "EDIT_SNIPPET";
export const EDIT_SNIPPET_SUCCESS = "EDIT_SNIPPET_SUCCESS";
export const ADD_SNIPPET = "ADD_SNIPPET";
export const ADD_SNIPPET_SUCCESS = "ADD_SNIPPET_SUCCESS";
export const HYDRATE_SNIPPETS = "HYDRATE_SNIPPETS";
export const HYDRATE_SNIPPETS_SUCCESS = "HYDRATE_SNIPPETS_SUCCESS";
export const HYDRATE_SNIPPET = "HYDRATE_SNIPPET";
export const HYDRATE_SNIPPET_SUCCESS = "HYDRATE_SNIPPET_SUCCESS";
