export const EDIT_TEAM = "EDIT_TEAM";
export const TEAM_SUCCESS = "TEAM_SUCCESS";
export const TEAM_ERROR = "TEAM_ERROR";
export const RESET_TEAM_FLAG = "RESET_TEAM_FLAG";
export const HYDRATE_TEAM = "HYDRATE_TEAM";
export const ADD_TEAM_MEMBER = "ADD_TEAM_MEMBER";
export const EDIT_TEAM_MEMBER = "EDIT_TEAM_MEMBER";
export const EDIT_TEAM_MEMBER_SUCCESS = "EDIT_TEAM_MEMBER_SUCCESS";
export const ADD_TEAM_MEMBER_SUCCESS = "ADD_TEAM_MEMBER_SUCCESS";
export const HYDRATE_TEAM_SUCCESS = "HYDRATE_TEAM_SUCCESS";
