import PropTypes from "prop-types";
import React from "react";
import { Label } from "reactstrap";

const StandardRadio = props => {
  return (
    <div className="radio-list">
      {props.label ? (
        <Label for={props.name} className="col-form-label">
          {props.label}
        </Label>
      ) : (
        ""
      )}
      <div
        className={
          "d-flex flex-wrap" + (props.className ? " " + props.className : "")
        }
      >
        {props.options.map(option => (
          <label
            key={option.value}
            color="primary"
            className={
              "border border-primary py-2 px-3 radio-list-item " +
              (option.value === props.value ? " selected" : "")
            }
            htmlFor={option.value}
          >
            <div className="form-check form-check-primary d-flex align-items-center">
              <input
                className="form-check-input mr-3"
                type="radio"
                name={props.name}
                id={option.value}
                value={option.value}
                checked={option.value == props.value}
                onChange={props.onChange}
              />
              <div className="d-flex flex-column">
                {option.label}
                <span className="text-muted note font-size-12">
                  {option.description}
                </span>
              </div>
            </div>
          </label>
        ))}
      </div>
    </div>
  );
};

StandardRadio.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  showErrors: PropTypes.bool,
  errors: PropTypes.any,
  touched: PropTypes.any,
  disabled: PropTypes.any,
  value: PropTypes.any,
  options: PropTypes.array,
  default: PropTypes.string,
  onChange: PropTypes.any,
  setFieldValue: PropTypes.any,
};

export { StandardRadio };
