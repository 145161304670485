import PropTypes from "prop-types";
import React, { Component } from "react";
import { Col, Row, TabPane, TabContent } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// actions
import { withTranslation } from "react-i18next";

// Tab Panes
import SideMenu from "./menu";
import SnippetsTab from "./components/SnippetsTab";
import { TemplatesTab } from "./components/TemplatesTab";
import { CategoriesTab } from "./components/CategoriesTab";
import StandardPage from "components/Common/StandardPage";
import GeneralTab from "./components/GeneralTab";

class ReportSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "templates",
    };

    this.toggleTab = this.toggleTab.bind(this);
  }

  /* Method to toggle the clicked tab */
  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    return (
      <StandardPage
        pageid="report_settings"
        pagetitle={this.props.t(
          "report_settings_meta_title",
          "Report settings"
        )}
        breadcumbIcon="bx bxs-user-rectangle"
        breadcrumbItems={[
          {
            uri: "/#",
            text: this.props.t("report", "Report"),
            active: true,
          },
          {
            uri: "/report/settings",
            text: this.props.t("settings", "Settings"),
            active: true,
          },
        ]}
      >
        <Row>
          <Col md="3" className="pr-0 side-nav-column mobile-shadow">
            <SideMenu
              activeTab={this.state.activeTab}
              toggleTab={this.toggleTab}
            />
          </Col>
          <Col className="bg-white px-0">
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="snippets">
                <SnippetsTab />
              </TabPane>
              <TabPane tabId="templates">
                <TemplatesTab />
              </TabPane>
              <TabPane tabId="categories">
                <CategoriesTab />
              </TabPane>
              <TabPane tabId="general">
                <GeneralTab />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </StandardPage>
    );
  }
}

ReportSettings.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(withRouter(connect()(ReportSettings)));
