import { BaseHTMLAttributes, PropsWithChildren } from "react";

export function EmptyPane(props: PropsWithChildren<BaseHTMLAttributes<{}>>) {
  return (
    <div
      className="w-100 d-flex justify-content-center align-items-center p-4 bg-light rounded text-muted"
      style={props.style}
    >
      <p className="m-0">{props.children}</p>
    </div>
  );
}
