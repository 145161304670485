import { API, Auth } from "aws-amplify";

export function useUsers() {
  async function signUp(details: any) {
    const cognitoUser = await Auth.signUp({
      username: details.email,
      password:
        process.env.REACT_APP_NEW_USER_TEMP_PASSWORD ||
        "TEMP_PASSWORD_PLEASE_SET_MORE_SECURE",
      attributes: {
        "custom:first_name": details.firstName,
        "custom:last_name": details.lastName,
      },
    });

    // successfully created user, verification email sent
    if (cognitoUser) {
      const date = new Date();
      const fullDate =
        date.getFullYear() +
        "-" +
        date.getMonth() +
        "-" +
        date.getDay() +
        "T" +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds() +
        "Z";
      await API.put("UserMeta", "/userMeta", {
        body: {
          id: cognitoUser.userSub,
          confirmed: false,
          ...details,
          createdAt: fullDate,
          updatedAt: fullDate,
        },
      });
      return true;
    } else {
      return false;
    }
  }

  return {
    signUp,
  };
}
