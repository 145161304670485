import { takeEvery, fork, put, call, all } from "redux-saga/effects";

import * as types from "./actionTypes";
import * as effects from "./effects";
import * as actions from "../../actions";

//Include back-end helper component
import { getBackendHelper } from "../../../helpers/backend_helper";

const backend = getBackendHelper();

function* hydrateCompanySettings() {
  try {
    const response = yield call(backend.hydrateCompanySettings);
    yield put(actions.hydrateCompanySettingsSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(actions.companySettingsError(error));
  }
}

function* editCompanySettings({ payload: { companySettings } }) {
  try {
    yield call(backend.editCompanySettings, companySettings);
    yield put(
      actions.messageHubChange("Company details saved!", "success", true)
    );
    yield put(actions.messageHubReset());
  } catch (error) {
    console.log(error);
    yield put(actions.companySettingsError(error));
  }
}

function* fetchCompanyDiscounts() {
  try {
    const discounts = yield call(effects.fetchDiscounts);
    yield put(actions.fetchCompanyDiscountsSuccess(discounts));
  } catch (error) {
    console.error(error);
    yield put(actions.fetchCompanyDiscountsError(error));
  }
}

function* fetchDiscount({ payload, history }) {
  try {
    const discount = yield call(effects.getDiscount, payload);
    yield put(actions.fetchDiscountSuccess([discount]));
  } catch (error) {
    console.error(error);
    yield put(actions.companySettingsError(error));
  }
}

function* deleteDiscount({ payload }) {
  try {
    const discount = yield call(effects.deleteDiscount, payload);
    yield call(fetchCompanyDiscounts);
    yield put(actions.messageHubChange("Discount deleted!", "success", true));
    yield put(actions.messageHubReset());
  } catch (error) {
    console.error(error);
    yield put(actions.companySettingsError(error));
  }
}

function* createDiscount({ payload, history }) {
  try {
    const discount = yield call(effects.createDiscount, payload);
    yield put(actions.createDiscountSuccess(discount.id, discount));
    yield call(fetchCompanyDiscounts);
    yield put(actions.messageHubChange("Discount created", "success", true));
    yield put(actions.messageHubReset());
    history.push("/company/settings/discounts");
  } catch (error) {
    console.error(error);
    yield put(actions.createDiscountError(error));
  }
}

function* updateDiscount({ payload, history }) {
  try {
    const discount = yield call(effects.updateDiscount, payload);
    yield put(actions.updateDiscountSuccess(discount.id, discount));
    yield put(actions.messageHubChange("Discount updated", "success", true));
    yield put(actions.messageHubReset());
    history.push("/company/settings/discounts");
  } catch (error) {
    console.error(error);
    yield put(actions.createDiscountError(error));
  }
}

export function* watchCompanySettings() {
  yield takeEvery(types.HYDRATE_COMPANY_SETTINGS, hydrateCompanySettings);
  yield takeEvery(types.EDIT_COMPANY_SETTINGS, editCompanySettings);
  yield takeEvery(types.FETCH_COMPANY_DISCOUNTS, fetchCompanyDiscounts);
  yield takeEvery(types.CREATE_DISCOUNT, createDiscount);
  yield takeEvery(types.UPDATE_DISCOUNT, updateDiscount);
  yield takeEvery(types.FETCH_DISCOUNT, fetchDiscount);
  yield takeEvery(types.DELETE_DISCOUNT, deleteDiscount);
}

function* CompanySettingsSaga() {
  yield all([fork(watchCompanySettings)]);
}

export default CompanySettingsSaga;
