import toast from "toastr";
import API, { graphqlOperation, GraphQLQuery } from "@aws-amplify/api";
import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";

import { getCategoryById, getCategorySubcategories } from "./fetchers";
import { publishCategoryFn, updateCategoryFn } from "./effects";
import { UpdateCategoryInput, UpdateCategoryMutation } from "API";
import { updateCategory as updateCategoryMutation } from "graphql/mutations";
import { getAccessToken } from "helpers/graphql";

export function useCategory(id: string) {
  const queryClient = useQueryClient();
  const category = useQuery(["categories", id], getCategoryById, {
    enabled: Boolean(id),
  });
  const subcategories = useQuery(
    ["categories", id, "subcategories"],
    getCategorySubcategories,
    {
      enabled: Boolean(id),
    }
  );

  const updateCategory = useMutation(updateCategoryFn, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["categories"],
      });
      queryClient.invalidateQueries(["templates"]);
      toast.success("Category renamed");
    },
    onError(error) {
      console.error(error);
      toast.error("Failed to rename category");
    },
  });

  const reorderSubcategories = useMutation(
    async (input: { subcategoryOrder: string[] }) => {
      if (category.data) {
        await API.graphql<GraphQLQuery<UpdateCategoryMutation>>(
          graphqlOperation(
            updateCategoryMutation,
            {
              input: {
                id: category.data.id,
                ownerId: category.data.ownerId,
                subcategoryOrder: input.subcategoryOrder,
              } as UpdateCategoryInput,
            },
            await getAccessToken()
          )
        );
      }
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(["categories", id]);
      },
      onError(error) {
        console.error(error);
        toast.error("Failed to reorder subcategories");
      },
    }
  );

  const publishCategory = useMutation(
    async () => {
      if (!category.data) {
        throw new Error("Category not yet loaded");
      }

      return publishCategoryFn({
        id: category.data.id,
        ownerId: category.data.ownerId,
      });
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(["categories", id]);
        toast.success("Category published successfully");
      },
      onError(error) {
        console.error(error);
        toast.error("Failed to publish category");
      },
    }
  );

  return {
    category,
    subcategories,
    reorderSubcategories,
    updateCategory,
    publishCategory,
  };
}
