import { Category } from "API";

import * as types from "./actionTypes";

export function fetchCategories() {
  return {
    type: types.FETCH_SYSTEMS,
  };
}

export function fetchCategoriesSuccess(categories: Category[]) {
  return {
    type: types.FETCH_SYSTEMS_SUCCESS,
    payload: categories,
  };
}

export function fetchCategoriesError(error: unknown) {
  return {
    type: types.FETCH_SYSTEMS_ERROR,
    payload: (error as Error).message,
  };
}

export function createCategory(name: string) {
  return {
    type: types.CREATE_SYSTEM,
    payload: name,
  };
}

export function createCategorySuccess() {
  return {
    type: types.CREATE_SYSTEM_SUCCESS,
  };
}

export function createCategoryError(error: unknown) {
  return {
    type: types.CREATE_SYSTEM_ERROR,
    payload: (error as Error).message,
  };
}
