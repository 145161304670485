import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Label, Row, Col, Card, CardBody, Button } from "reactstrap";
import DateBox from "devextreme-react/date-box";
import { TreeViewDropDown } from "components/Fields/dropdowns/TreeViewDropDown";
import DataGrid from "components/Tables/DataGrid";
import { RowDragging } from "devextreme-react/data-grid";
import { useQuery } from "@tanstack/react-query";
import { API } from "aws-amplify";
import { useFormikContext } from "formik";
import { getBackendHelper } from "helpers/backend_helper";

type Inspector = {
  id: string;
  text: string;
  requested?: boolean;
};

type Team = {
  companies: string[];
  companyLicense: string;
  confirmed: boolean;
  country: string;
  createdAt: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  photo: string;
  primaryPhone: string;
  region: string;
  role: string;
  secondaryPhone: string;
  settings: {
    dataSharing: boolean;
    locationSharing: boolean;
  };
  status: string;
  timezone: string;
  updatedAt: string;
};

export default function InspectorBooking(props: any) {
  let [chooseInspectors, setChooseInspectors] = useState<Inspector[]>([]);
  let [listInspectors, setListInspectors] = useState<Inspector[]>([]);
  let [durations, setDurations] = useState([]);
  let [team, setTeam] = useState<Team[]>([]);
  const formik = useFormikContext<any>();
  const { t } = useTranslation();
  const backendHelper = getBackendHelper();

  const inspectors = useQuery(["team"], async () =>
    backendHelper.getCompanyTeam()
  );

  useEffect(() => {
    if (inspectors.isSuccess) {
      const data: Team[] = inspectors.data.team;
      let _team: Inspector[] = data.map((item: Team) => {
        return {
          id: item.id,
          text: item.firstName + " " + item.lastName,
        };
      });
      let _listInspectors = formik.values.inspection?.inspectors
        ? formik.values.inspection?.inspectors
        : [];
      let _chooseInspectors = _team;

      _listInspectors.forEach((inspector: Inspector) => {
        _chooseInspectors = _chooseInspectors.filter(
          (i: Inspector) => inspector.id !== i.id
        );
      });

      setChooseInspectors(_chooseInspectors);
      setListInspectors(_listInspectors);
      setTeam(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspectors.data]);

  const automaticallySelectInspectors = () => {
    if (formik.values.inspection.inspectionDate === undefined) {
      alert(
        t(
          "please_select_inspection_date_first",
          "Please select inspection date_first"
        )
      );
    } else {
      console.log(team);
    }
  };

  useQuery(
    ["duration"],
    async () => API.get("Snippet", "/snippet/snippetType/duration", {}),
    {
      onSettled: data => {
        setDurations(data[0].values);
      },
    }
  );

  const inspectionDateChanged = (event: any) => {
    //Needed because DateBox returns a different date format depending on the system configuration
    let date = new Date(event.value);
    let _inspectionDate = date.toISOString();
    formik.setFieldValue("inspection.inspectionDate", _inspectionDate);
  };

  const onAdd = (e: any) => {
    let _chooseInspectors = chooseInspectors;
    let _listInspectors = listInspectors;
    if (e.toData === "List") {
      _chooseInspectors = _chooseInspectors.filter(
        (inspector: Inspector) => inspector.id !== e.itemData.id
      );
      _listInspectors = [
        ..._listInspectors,
        { ...e.itemData, requested: false },
      ];
    } else {
      _chooseInspectors = [..._chooseInspectors, e.itemData];
      _listInspectors = _listInspectors.filter(
        inspector => inspector.id !== e.itemData.id
      );
    }

    setChooseInspectors(_chooseInspectors);
    setListInspectors(_listInspectors);

    formik.setFieldValue("inspection.inspectors", _listInspectors);
  };

  const onClick = (e: any) => {
    const dataList = e.element.id;
    let _chooseInspectors = chooseInspectors;
    let _listInspectors = listInspectors;
    if (dataList === "choose") {
      _chooseInspectors = _chooseInspectors.filter(
        inspector => inspector.id !== e.key.id
      );
      _listInspectors = [..._listInspectors, { ...e.key, requested: false }];
    } else {
      _listInspectors = _listInspectors.filter(
        inspector => inspector.id !== e.key.id
      );
      _chooseInspectors = [..._chooseInspectors, e.key];
    }
    setChooseInspectors(_chooseInspectors);
    setListInspectors(_listInspectors);

    formik.setFieldValue("inspection.inspectors", _listInspectors);
  };

  const onCellClick = (e: any) => {
    if (e.column.dataField === "requested") {
      let _listInspectors = listInspectors;
      let index = _listInspectors.findIndex(inspector => {
        return inspector.id === e.key.id;
      });
      _listInspectors[index].requested = !_listInspectors[index].requested;
      setListInspectors(_listInspectors);

      formik.setFieldValue("inspection.inspectors", _listInspectors);
      e.event.stopImmediatePropagation();
    }
  };

  return (
    <Card>
      <CardBody>
        <h2 className="mt-2 mb-2">
          {t("inspector_booking", "Inspector booking")}
        </h2>
        <Row>
          <Col lg={6} className="mt-3">
            <DataGrid
              id="choose"
              dataSource={chooseInspectors}
              columns={[
                {
                  caption: t("your_team", "Your team"),
                  dataField: "text",
                },
              ]}
              showBorders={true}
              onRowClick={onClick}
            >
              <RowDragging data="Choose" group="inspectors" onAdd={onAdd} />
            </DataGrid>
          </Col>
          <Col lg={6} className="mt-3">
            <DataGrid
              id="list"
              dataSource={listInspectors}
              onCellClick={onCellClick}
              columns={[
                {
                  dataField: "text",
                  caption: t("selected_inspectors", "Selected inspector(s)"),
                },
                {
                  dataField: "requested",
                  dataType: "boolean",
                  caption: t("requested", "Requested"),
                },
              ]}
              showBorders={true}
              onRowClick={onClick}
            >
              <RowDragging data="List" group="inspectors" onAdd={onAdd} />
            </DataGrid>
            <Button className="mt-4" onClick={automaticallySelectInspectors}>
              {t(
                "automatically_select_inspectors",
                "Automatically select inspectors"
              )}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label for="inspection.inspectionDate" className="col-form-label">
              {t("inspection_date", "Inspection date")}
            </Label>
            <DateBox
              name="inspection.inspectionDate"
              type="datetime"
              onValueChanged={inspectionDateChanged}
              openOnFieldClick={true}
            />
          </Col>
          <Col>
            <TreeViewDropDown
              name="inspection.duration"
              label={t("inspectionDuration", "Inspection duration")}
              dataSource={durations}
              valueExpr="text"
              displayExpr="text"
              onChange={value =>
                formik.setFieldValue("inspection.duration", value)
              }
              value={
                formik.values.inspection
                  ? formik.values.inspection.duration
                  : ""
              }
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
