import React, { useState } from "react";

import { Button, Row, Col, Tooltip, Alert, TabPane } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { changePassword } from "store/actions";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { PasswordInput } from "components/Fields";

const TabSecurity = props => {
  const { t } = useTranslation();
  const messageHub = useSelector(state => state.MessageHub);
  const [ttMfaAdd, setTtMfaAdd] = useState(false);
  const [ttMfaDelete, setTtMfaDelete] = useState(false);
  const dispatch = useDispatch();

  return (
    <TabPane tabId="security">
      <h2>{t("personal_settings_security_tab_title", "Change password")}</h2>
      {messageHub.message && messageHub.type && !messageHub.toast ? (
        <Alert color={messageHub.type}>{messageHub.message}</Alert>
      ) : null}
      <Formik
        enableReinitialize={true}
        initialValues={{
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        }}
        validationSchema={Yup.object().shape({
          currentPassword: Yup.string().required(
            t("error_password_blank", "Please enter your password")
          ),
          newPassword: Yup.string().required(
            t("error_new_password_blank", "Please enter a new password")
          ),
          confirmPassword: Yup.string()
            .oneOf(
              [Yup.ref("newPassword"), null],
              t("error_passwords_do_not_match", "Passwords do not match")
            )
            .required(
              t(
                "error_confirm_password_blank",
                "Please confirm your new password"
              )
            ),
        })}
        onSubmit={values => {
          dispatch(changePassword(values));
        }}
      >
        {({ errors, touched, values }) => (
          <Form>
            <Row>
              {/* -- Current Password -- */}
              <Col md={6}>
                <PasswordInput
                  name="currentPassword"
                  label={t("current_password", "Current password")}
                  showErrors={true}
                  formik={{ values, errors, touched }}
                  allowShowPassword={true}
                />
              </Col>
            </Row>
            <Row>
              {/* -- New Password -- */}
              <Col md={6}>
                <PasswordInput
                  name="newPassword"
                  label={t("new_password", "New password")}
                  showErrors={true}
                  formik={{ values, errors, touched }}
                  allowShowPassword={true}
                />
              </Col>
            </Row>
            <Row>
              {/* -- Confirm Password -- */}
              <Col md={6}>
                <PasswordInput
                  name="confirmPassword"
                  label={t("confirm_new_password", "Confirm new password")}
                  showErrors={true}
                  formik={{ values, errors, touched }}
                  allowShowPassword={true}
                />
              </Col>
            </Row>
            <div className="mt-4 mb-5">
              <Button color="primary">
                {t("change_password", "Change password")}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <h5 className="mt-4 pt-3 border-top">
        {t("multifactor_authentication", "Multi-factor authentication")}
      </h5>
      <p className="card-title-desc">
        {t(
          "mutlifactor_authentication_subtitle",
          "Protect your account by adding additional layers of login security."
        )}
      </p>
      <div className="mt-4">
        <Tooltip
          placement="right"
          isOpen={ttMfaAdd}
          target="mfa_add"
          toggle={() => setTtMfaAdd(!ttMfaAdd)}
        >
          {t("more_mfa_soon", "More options soon")}
        </Tooltip>
        <Tooltip
          placement="right"
          isOpen={ttMfaDelete}
          target="mfa_delete"
          toggle={() => setTtMfaDelete(!ttMfaDelete)}
        >
          {t("more_mfa_soon", "Cannot remove email MFA")}
        </Tooltip>
        <Button id="mfa_add" size="sm" color="success">
          {t("multifactor_add_new", "Add new method")}
        </Button>
      </div>
      <table className="mt-2">
        <thead>
          <tr>
            <th style={{ height: "20px" }} className="fw-medium py-2">
              {t("multifactor_enabled_methods", "Enabled methods")}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="border-top">
            <td>{t("email", "Email")}</td>
            <td className="px-4 py-2">{t("added", "Added")} 2022-04-10</td>
            <td className="px-4 py-2">
              <Button id="mfa_delete" size="sm" color="danger">
                <i className="bx bx-trash"></i>
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
    </TabPane>
  );
};

export default TabSecurity;
