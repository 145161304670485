import toastr from "toastr";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import API, { graphqlOperation, GraphQLQuery } from "@aws-amplify/api";

import {
  CreateSubcategoryInput,
  CreateSubcategoryMutation,
  CreateSubcategoryMutationVariables,
} from "API";
import { deleteSubcategoryFn } from "./effects";
import { getAccessToken } from "helpers/graphql";
import { createSubcategory as createSubcategoryMutation } from "graphql/mutations";

export function useSubcategories() {
  const queryClient = useQueryClient();

  const createSubcategory = useMutation({
    mutationKey: ["createSubcategory"],
    async mutationFn(input: CreateSubcategoryInput) {
      const { data } = await API.graphql<
        GraphQLQuery<CreateSubcategoryMutation>
      >(
        graphqlOperation(
          createSubcategoryMutation,
          {
            input,
          } as CreateSubcategoryMutationVariables,
          await getAccessToken()
        )
      );

      return data?.createSubcategory;
    },
    onSuccess(data) {
      if (data?.categoryId) {
        queryClient.invalidateQueries([
          "categories",
          data.categoryId,
          "subcategories",
        ]);
      }

      toastr.success("Subcategory created");
    },
    onError(error) {
      console.error(error);
      toastr.error("Failed to create new subcategory");
    },
  });

  const deleteSubcategory = useMutation({
    mutationKey: ["deleteSubcategory"],
    mutationFn: deleteSubcategoryFn,
    onSuccess(data) {
      if (data) {
        queryClient.invalidateQueries(["categories", data]);
      }
    },
    onError(error) {
      console.error(error);
      toastr.error("Failed to delete subcategory");
    },
  });

  return {
    createSubcategory,
    deleteSubcategory,
  };
}
