import PropTypes from "prop-types";
import React from "react";
import { Label } from "reactstrap";
import { Field, useFormikContext } from "formik";
import timezones from "static/timezones";
import { useTranslation } from "react-i18next";

const TimezoneDropdown = props => {
  const { t } = useTranslation();
  const formik = useFormikContext();

  const showError = () => {
    if (formik.errors[props.name] !== "") {
      return (
        <div className="invalid-feedback">{formik.errors[props.name]}</div>
      );
    }
  };
  return (
    <React.Fragment>
      {props.label ? (
        <Label for={props.name} className="col-form-label">
          {props.label}
        </Label>
      ) : (
        ""
      )}
      <Field
        name={props.name}
        id={props.name}
        disabled={props.disabled ? true : false}
        as="select"
        className={
          "form-select" +
          (formik.errors[props.name] &&
          (formik.touched[props.name] || formik.submitCount > 0)
            ? " is-invalid"
            : "")
        }
      >
        <option value="" key="0">
          {t("timezone_dropdown_default", "Please select a timezone...")}
        </option>
        {Object.keys(timezones).map(key => (
          <option value={timezones[key]} key={timezones[key]}>
            {key}
          </option>
        ))}
      </Field>
      {props.showErrors ? showError() : ""}
    </React.Fragment>
  );
};

TimezoneDropdown.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  showErrors: PropTypes.bool,
  disabled: PropTypes.any,
  value: PropTypes.any,
  formik: PropTypes.object,
};

export { TimezoneDropdown };
