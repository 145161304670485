import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useDrag, useDrop } from "react-dnd";
import { Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { InputGroup, Input, Button, Spinner, Badge } from "reactstrap";

import type { BaseResource, ResourceRowProps } from "./types";
import { useResourceListContext } from "./context";
import { calculateRelationship } from "./utils";

type DragItem<Resource extends BaseResource> = {
  index: number[];
  parent?: Resource;
  resource: Resource;
  handleHover?: (targetIndex: number) => void;
};

export function ResourceRow<Resource extends BaseResource>(
  props: ResourceRowProps<Resource>
) {
  const { t } = useTranslation("components");
  const [isEditing, setIsEditing] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const context = useResourceListContext();
  const [{ handlerId }, drop] = useDrop<
    DragItem<Resource>,
    void,
    { handlerId: string | null }
  >(
    {
      accept: props.type,
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId() as string | null,
        };
      },
      hover(item: DragItem<Resource>, monitor) {
        if (!ref.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = props.index;

        // Don't replace items with themselves
        if (dragIndex.every((n, i) => n === hoverIndex[i])) {
          return;
        }

        // Determine rectangle on screen
        const hoverBoundingRect = ref.current?.getBoundingClientRect();

        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the top
        const hoverClientY = (clientOffset as any).y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        if (
          calculateRelationship(dragIndex, hoverIndex) &&
          hoverClientY < hoverMiddleY
        ) {
          return;
        }

        // Dragging upwards
        if (
          !calculateRelationship(dragIndex, hoverIndex) &&
          hoverClientY > hoverMiddleY
        ) {
          return;
        }

        // Recalculate positions in the list
        context.handleHover?.(dragIndex, hoverIndex);

        // Update the index of the dragged item
        item.index = hoverIndex;
      },
    },
    [context]
  );

  const [{ isDragging }, drag, preview] = useDrag({
    type: props.type,
    item: {
      type: props.type,
      index: props.index,
      resource: props.resource,
    },
    collect: monitor => {
      if (monitor.isDragging()) {
        setIsEditing(false);
      }

      return {
        isDragging: monitor.isDragging(),
      };
    },
    end() {
      context.handleDrop?.();
    },
  });

  const opacity = isDragging ? 0 : 1;

  const handleRename = useMutation(
    async (input: { name: string }) => {
      return context.onRename?.(props.resource.id, input.name);
    },
    {
      onSuccess: () => setIsEditing(false),
    }
  );

  preview(drop(ref));

  return (
    <>
      <div
        className="d-flex align-items-center"
        style={{
          gap: "1.5rem",
          opacity,
        }}
        ref={ref}
        data-handler-id={handlerId}
      >
        {context.canReorder && (
          <div
            ref={drag}
            style={{ display: "flex", alignItems: "center", cursor: "grab" }}
          >
            <i className="bx bx-menu" />
          </div>
        )}
        {context.onRename && isEditing ? (
          <Formik
            initialValues={{ name: props.resource.name }}
            onSubmit={async values => {
              await handleRename.mutateAsync({ name: values.name });
            }}
          >
            {({ dirty, submitForm }) => (
              <Form style={{ display: "block", flex: 1 }}>
                <InputGroup>
                  <Field
                    as={Input}
                    name="name"
                    placeholder={t("Name")}
                    autoFocus
                  />
                  <Button
                    outline
                    size="sm"
                    className="d-flex justify-content-center align-items-center"
                    color={dirty ? "success" : "secondary"}
                    type="button"
                    onClick={() => {
                      if (dirty) {
                        submitForm();
                      } else {
                        setIsEditing(false);
                      }
                    }}
                  >
                    {dirty ? (
                      handleRename.isLoading ? (
                        <Spinner size="sm" color="light" />
                      ) : (
                        t("Save")
                      )
                    ) : (
                      t("Cancel")
                    )}
                  </Button>
                </InputGroup>
              </Form>
            )}
          </Formik>
        ) : (
          <>
            <div style={{ flex: 1, textOverflow: "ellipsis" }}>
              {props.resource.name}
            </div>
            <div style={{ flex: 1 }}>
              {props.badges?.map((b, i) => {
                const content = b.content(props.resource);

                return (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ height: "min-content" }}>
                      <Badge
                        key={`${i}-${content}`}
                        color={b.color(props.resource)}
                        className={`text-sm`}
                      >
                        {content}
                      </Badge>
                    </div>
                  </div>
                );
              })}
            </div>
            <div style={{ gap: "0.5rem", display: "flex" }}>
              {context.onRename && (
                <Button
                  outline
                  color="secondary"
                  size="sm"
                  onClick={() => setIsEditing(true)}
                >
                  {t("Rename")}
                </Button>
              )}
              {props.actions?.map((action, i) => {
                switch (action.type) {
                  case "action":
                    if (action.condition?.(props.resource) === false)
                      return null;
                    return (
                      <Button
                        size="sm"
                        key={action.label}
                        outline
                        color={action.color}
                        onClick={() => action.onClick(props.resource)}
                      >
                        {action.label}
                      </Button>
                    );
                  case "link":
                    if (action.condition?.(props.resource) === false)
                      return null;
                    return (
                      <Link key={action.label} to={action.to(props.resource)}>
                        <Button outline color={action.color} size="sm">
                          {action.label}
                        </Button>
                      </Link>
                    );
                  default:
                    return null;
                }
              }) ?? null}
            </div>
          </>
        )}
      </div>
    </>
  );
}
