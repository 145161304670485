import { getCSSClass } from "components/Common/Icons";
import StandardPage from "components/Common/StandardPage";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import AtAGlance from "./components/_atAGlance";
import JobDetails from "./components/_jobDetails";
import Timeline from "./components/_timeline";
import Tabs from "devextreme-react/tabs";
import { toInteger } from "lodash";
import JobList from "./components/_jobList";

export default function MyJobs(props) {
  let [currentDate, setCurrentDate] = useState(new Date());
  let [selectedIndex, setSelectedIndex] = useState(
    toInteger(localStorage.getItem("myjobs_tab"))
  );
  const { t } = useTranslation();
  let id = props?.match?.params?.id;
  let [tabs] = useState([
    { id: 0, text: t("at_a_glance", "At a glance") },
    { id: 1, text: t("timeline", "Timeline") },
    { id: 2, text: t("job_details", "Job details") },
  ]);

  function onTabsSelectionChanged(args) {
    if (args.name === "selectedIndex") {
      setSelectedIndex(args.value);
      localStorage.setItem("myjobs_tab", args.value);
    }
  }

  return (
    <StandardPage
      pageid="my_jobs"
      pagetitle={t("my_jobs", "My jobs")}
      breadcumbIcon={getCSSClass("work")}
      breadcrumbItems={[
        {
          uri: "/my/jobs",
          text: t("my_jobs", "My jobs"),
          active: true,
        },
      ]}
    >
      {id === undefined ? (
        <React.Fragment>
          <Tabs
            dataSource={tabs}
            selectedIndex={selectedIndex}
            onOptionChanged={onTabsSelectionChanged}
          />
          {selectedIndex === 0 && (
            <AtAGlance
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
            />
          )}
          {selectedIndex === 1 && <Timeline />}
          {selectedIndex === 2 && (
            <JobList
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
            />
          )}
        </React.Fragment>
      ) : (
        <JobDetails id={id} />
      )}
    </StandardPage>
  );
}
