import { StandardDropdown, TextInput } from "components/Fields";
import DataGrid from "components/Tables/DataGrid";
import { Column, RowDragging, Selection } from "devextreme-react/data-grid";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import { call } from "redux-saga/effects";

export default function ApplySection(props: any) {
  const { t } = useTranslation();
  const [chooseProducts, setChooseProducts] = useState<any>([]);
  const [listProducts, setListProducts] = useState<any>([]);
  const [chooseOrders, setChooseOrders] = useState<any>([]);
  const [listOrders, setListOrders] = useState<any>([]);
  const { values, errors, handleChange } = useFormikContext<any>();

  // TODO: Update Type with product Type when available
  const product_columns: any = [
    {
      dataField: "product",
    },
  ];
  // TODO: Update Type with order Type when available
  const order_columns: any = [
    {
      dataField: "order",
    },
  ];

  useEffect(() => {
    setChooseProducts([
      {
        id: 1,
        product: "Test product",
      },
      {
        id: 2,
        product: "Test product 2",
      },
    ]);
    setChooseOrders({});
  }, []);

  const onAdd = (e: any) => {
    if (e.toData == "List") {
      setChooseProducts(
        chooseProducts.filter((product: any) => product.id != e.itemData.id)
      );
      setListProducts([...listProducts, e.itemData]);
    } else {
      setListProducts(
        listProducts.filter((product: any) => product.id != e.itemData.id)
      );
      setChooseProducts([...chooseProducts, e.itemData]);
    }
  };

  const onClick = (e: any) => {
    const dataList = e.element.id;
    if (dataList == "choose") {
      setChooseProducts(
        chooseProducts.filter((product: any) => product.id != e.key.id)
      );
      setListProducts([...listProducts, e.key]);
    } else {
      setListProducts(
        listProducts.filter((product: any) => product.id != e.key.id)
      );
      setChooseProducts([...chooseProducts, e.key]);
    }
  };

  if (!values?.type) {
    return <></>;
  }

  return (
    <Card className="mb-0 border-bottom">
      <CardBody>
        <Row>
          <Col lg={6}>
            <Label className="col-form-label">
              {t("discount_applies_to_label", "Applies to")}*
            </Label>
            <StandardDropdown
              name="appliesTo"
              showErrors={true}
              onChange={handleChange}
              value={values?.appliesTo}
              className={
                "standard three-wide " +
                (errors["discount_application"] ? " is-invalid" : "")
              }
              options={[
                {
                  label: t(
                    "discount_application_entire_invoice",
                    "Entire invoice"
                  ),
                  value: "ENTIRE_INVOICE",
                },
                {
                  label: t(
                    "discount_application_specific_product",
                    "Specific product or service"
                  ),
                  value: "SPECIFIC_PRODUCT",
                },
                {
                  label: t(
                    "discount_application_specific_order",
                    "Specific order"
                  ),
                  value: "SPECIFIC_ORDER",
                },
              ]}
            />
          </Col>
        </Row>
        {values?.appliesTo !== "ENTIRE_INVOICE" ? (
          <Row>
            <Col lg={6} className="mt-3">
              <Label className="col-form-label">
                {t(
                  "discount_application_choose_products",
                  "Select items discount will apply to"
                )}
              </Label>
              <DataGrid
                id="choose"
                dataSource={
                  values?.appliesTo == "SPECIFIC_PRODUCT"
                    ? chooseProducts
                    : chooseOrders
                }
                columns={
                  values?.appliesTo == "SPECIFIC_PRODUCT"
                    ? product_columns
                    : order_columns
                }
                showBorders={true}
                onRowClick={onClick}
              >
                <RowDragging data="Choose" group="products" onAdd={onAdd} />
              </DataGrid>
            </Col>
            <Col lg={6} className="mt-3">
              <Label className="col-form-label">
                {t(
                  "discount_application_products_list",
                  "Items discount applies to"
                )}
              </Label>
              <DataGrid
                id="list"
                dataSource={
                  values?.appliesTo == "SPECIFIC_PRODUCT"
                    ? listProducts
                    : listOrders
                }
                columns={
                  values?.appliesTo == "SPECIFIC_PRODUCT"
                    ? product_columns
                    : order_columns
                }
                showBorders={true}
                onRowClick={onClick}
              >
                <RowDragging data="List" group="products" onAdd={onAdd} />
              </DataGrid>
            </Col>
          </Row>
        ) : (
          ""
        )}
      </CardBody>
    </Card>
  );
}
