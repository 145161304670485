import { useTranslation } from "react-i18next";

import { Template } from "API";
import { useTemplate } from "hooks/templates";
import { CategorySearch } from "./CategorySearch";
import { ResourceList } from "components/Settings/ResourceList";
import { ErrorPane } from "components/Settings/ErrorPane";
import { LoadingPane } from "components/Settings/LoadingPane";
import { EmptyPane } from "components/Settings/EmptyPane";

interface CategoriesTabProps {
  template: Template;
}

export function CategoriesTab(props: CategoriesTabProps) {
  const { t } = useTranslation("templates");
  const { template, categories, reorderCategories, removeCategory } =
    useTemplate(props.template.id);

  const orderedCategories =
    categories.data?.categories.sort(
      (a, b) =>
        (template.data?.categoryOrder?.indexOf(a.id) ?? 0) -
        (template.data?.categoryOrder?.indexOf(b.id) ?? 0)
    ) ?? [];

  let content: JSX.Element | null = null;
  switch (categories.status) {
    case "success":
      if (categories.data?.categories.length === 0) {
        content = <EmptyPane>{t("No categories")}</EmptyPane>;
      } else {
        content = (
          <ResourceList
            type="category"
            items={orderedCategories.map(value => ({
              type: "item",
              value,
            }))}
            onReorder={newOrder =>
              reorderCategories.mutateAsync({
                categoryOrder: newOrder
                  .map(c => (c.type === "item" ? c.value.id : ""))
                  .filter(id => id),
              })
            }
            actions={[
              {
                type: "link",
                label: "Add/Edit Subcategories",
                color: "secondary",
                to: category => `/report/settings/categories/${category.id}`,
              },
              {
                type: "action",
                label: "Remove",
                color: "danger",
                onClick: category => {
                  const connectionId = categories.data?.connections.get(
                    category.id
                  )?.id;
                  if (connectionId) {
                    removeCategory.mutateAsync({
                      categoryId: category.id,
                      connectionId,
                    });
                  }
                },
              },
            ]}
          />
        );
      }
      break;
    case "error":
      content = <ErrorPane>{t("Failed to load categories")}</ErrorPane>;
      break;
    case "loading":
      content = <LoadingPane />;
      break;
  }

  return (
    <>
      <h2 className="mb-2">{t("Add or Remove Categories")}</h2>
      <p className="mb-4">
        {t(
          "Templates control which categories are included in a report, in addition to the order that they appear."
        )}
      </p>
      <div className="mb-3">
        <CategorySearch templateId={props.template.id} />
      </div>
      {content}
    </>
  );
}
