import { Label } from "reactstrap";
import React, { useState, useEffect } from "react";

export default function StandardSwitches({
  name,
  label = "",
  options = [],
  onChange,
}) {
  const [values, setValues] = useState([]);

  useEffect(() => {
    let initialValues = [];
    options.forEach(option => {
      if (option.checked) {
        initialValues.push(option.value);
      }
      setValues(initialValues);
    });
  }, [options]);

  const internalOnChange = event => {
    let newCheckedList = values;
    if (values.includes(event.target.value) && !event.target.checked) {
      newCheckedList = values.filter(item => item !== event.target.value);
    } else if (event.target.checked) {
      newCheckedList.push(event.target.value);
    }

    setValues(newCheckedList);

    if (newCheckedList.length === 0) newCheckedList = [];

    onChange(newCheckedList);
  };

  return (
    <div className="switch-list-container">
      <div className="switch-list-label">
        {label ? (
          <Label for={name} className="col-form-label font-size-13 pb-3">
            {label}
          </Label>
        ) : (
          ""
        )}
      </div>
      <div className="switch-list">
        {options.map((option, idx) => (
          <div
            className="form-check form-switch form-switch form-check-primary mb-1"
            key={option.id}
          >
            <label
              className="form-check-label"
              htmlFor={option.value || option.id}
            >
              <input
                type="checkbox"
                className="form-check-input"
                checked={option.checked}
                value={option.value || option.id}
                id={option.value || option.id}
                name={name}
                onChange={internalOnChange}
              />
              {option.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}
