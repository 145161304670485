import React from "react";
import { Formik, Form, Field } from "formik";
import { useCompany } from "hooks/company";
import { useTranslation } from "react-i18next";
import { Button, Col, Label, Spinner } from "reactstrap";
import LeavingForm from "components/Common/LeavingForm";
import { ICompanyData } from "pages/Company/interfaces/ICompanyData";

export default function GeneralTab() {
  const { t } = useTranslation();
  const company = useCompany();
  function handleFormSubmit(values: ICompanyData) {
    company.mutation.mutate({
      ...company.data,
      workOrderNumber: values.workOrderNumber,
    });
  }

  return (
    <>
      {company.isLoading ? (
        <Spinner></Spinner>
      ) : (
        <Formik
          initialValues={company.data}
          enableReinitialize={false}
          onSubmit={(values, actions) => {
            handleFormSubmit(values);
            actions.setTouched({});
          }}
        >
          {formik => (
            <>
              <Form>
                <h2 className="mb-4">General</h2>
                <Col>
                  <Label for="workOrderNumber" className="col-form-label">
                    Work order number
                    <div className="note">
                      <div>
                        This number will be used as the ID for new reports and
                        will continue to increase as new reports are created.
                      </div>
                      <div>
                        You can modify this number to specify the next number
                        that will be used.
                      </div>
                    </div>
                  </Label>
                  <Field
                    name="workOrderNumber"
                    type="text"
                    className="form-control w-25"
                  />
                </Col>
                <div className="mt-4">
                  <Button
                    color="primary"
                    type="submit"
                    onClick={() => {
                      formik.handleSubmit();
                    }}
                  >
                    {t("save_changes", "Save Changes")}
                  </Button>
                  <LeavingForm formikProps={formik} />
                </div>
              </Form>
            </>
          )}
        </Formik>
      )}
    </>
  );
}
