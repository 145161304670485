import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { logoutUser } from "../../store/actions";

export default function Logout(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  /**
   * Redirect to login
   */
  //  componentDidMount
  useEffect(() => {
    dispatch(logoutUser(history));
  }, []);

  return <React.Fragment></React.Fragment>;
}
