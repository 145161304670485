import React from "react";
import { useTranslation } from "react-i18next";
import TeamTable from "./components/_teamTable";
import { Container } from "reactstrap";

//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb";
import MetaTags from "react-meta-tags";

export default function CompanyTeam(props) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            {t("company_team_page_title", "Manage my team")} | Horizon Core
          </title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb
            title={t("company_team_page_title", "Manage my team")}
            icon="bx bx-buildings"
            breadcrumbItems={[
              {
                uri: "/company/team",
                text: t("company", "Company"),
              },
              {
                uri: "/company/team",
                text: t("my_team", "My team"),
                active: true,
              },
            ]}
          />
          <TeamTable />
        </Container>
      </div>
    </React.Fragment>
  );
}
