import Icon from "components/Common/Icons";
import PropTypes from "prop-types";
import { Component } from "react";
import { Modal } from "reactstrap";

class HelpModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };

    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  render() {
    return (
      <span>
        <Icon
          name="help-circle"
          style={{
            cursor: "pointer",
            marginLeft: "5px",
            fontSize: "16px",
            position: "relative",
            top: "2px",
          }}
          onClick={this.toggle}
        />
        <Modal
          size="md"
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          centered={true}
        >
          <div className="p-4 bg-question">{this.props.children}</div>
        </Modal>
      </span>
    );
  }
}

HelpModal.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.any,
};

export default HelpModal;
