import { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import API, { graphqlOperation, GraphQLQuery } from "@aws-amplify/api";
import { Nav, NavItem, NavLink } from "reactstrap";
import { useTranslation } from "react-i18next";

import {
  ListTemplatesQuery,
  ListTemplatesQueryVariables,
  OwnershipLevel,
  Template,
} from "API";
import { useTemplates } from "hooks/templates";
import { useProfile } from "hooks/profile";
import { ResourceInput } from "components/Settings/ResourceInput";
import { ResourceFilter } from "components/Settings/ResourceFilter";
import { LoadingPane } from "components/Settings/LoadingPane";
import { EmptyPane } from "components/Settings/EmptyPane";
import { ResourceList } from "components/Settings/ResourceList";
import { useQuery } from "@tanstack/react-query";
import { getAccessToken } from "helpers/graphql";
import { ResourceFilterInput } from "components/Settings/ResourceFilterInput";

export function TemplatesTab() {
  const { createTemplate } = useTemplates();
  const { createOwnerId } = useProfile();
  const { push } = useHistory();
  const { t } = useTranslation("templates");
  const [filter, setFilter] = useState("");
  const [tab, setTab] = useState(OwnershipLevel.USER);
  const templates = useQuery({
    queryKey: ["templates", tab],
    queryFn: async (): Promise<Array<Template>> => {
      const { data } = await API.graphql<GraphQLQuery<ListTemplatesQuery>>(
        graphqlOperation(
          `
            query ListTemplates($ownerId: String!) {
              listTemplates(ownerId: $ownerId) {
                items {
                  id
                  name
                  categories {
                    items {
                      id
                    }
                  }
                }
              }
            }
          `,
          {
            ownerId: createOwnerId(tab),
          } as ListTemplatesQueryVariables,
          await getAccessToken()
        )
      );

      return data?.listTemplates?.items.filter(t => Boolean(t)) as Template[];
    },
    enabled: Boolean(createOwnerId(tab)),
  });

  return (
    <Fragment>
      <h2 className="mb-4">{t("Templates")}</h2>
      <Nav tabs className="mb-3">
        <NavItem>
          <NavLink
            onClick={() => setTab(OwnershipLevel.USER)}
            active={tab === OwnershipLevel.USER}
          >
            Mine
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            onClick={() => setTab(OwnershipLevel.COMPANY)}
            active={tab === OwnershipLevel.COMPANY}
          >
            Library
          </NavLink>
        </NavItem>
      </Nav>
      <div
        className="mb-3 d-flex justify-content-between align-items-center"
        style={{ gap: 16 }}
      >
        {tab === OwnershipLevel.USER ? (
          <ResourceFilterInput
            resource="templates"
            filter={filter}
            setFilter={setFilter}
            onCreate={async name => {
              const template = await createTemplate.mutateAsync({ name });
              return push(`/report/settings/templates/${template.id}`);
            }}
          />
        ) : (
          <ResourceFilter
            resource="templates"
            filter={filter}
            setFilter={setFilter}
          />
        )}
      </div>
      <div>
        {templates.data ? (
          templates.data.length ? (
            <ResourceList
              type="templates"
              items={templates.data
                .filter(t =>
                  t.name.toLowerCase().includes(filter.toLowerCase())
                )
                .map(t => ({
                  type: "item",
                  value: t,
                }))}
              badges={[
                {
                  color: t =>
                    t.categories?.items.length ? "primary" : "warning",
                  content: t =>
                    `${t.categories?.items.length} Categor${
                      t.categories?.items.length === 1 ? "y" : "ies"
                    }`,
                },
              ]}
              actions={[
                {
                  type: "link",
                  label: t("Add/Edit Categories"),
                  color: "secondary",
                  to: template => `/report/settings/templates/${template.id}`,
                },
              ]}
            />
          ) : (
            <EmptyPane style={{ gridColumn: "1 / -1" }}>
              {t("No templates found")}
            </EmptyPane>
          )
        ) : (
          <LoadingPane style={{ gridColumn: "1 / -1" }} />
        )}
      </div>
    </Fragment>
  );
}
