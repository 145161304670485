import { useJsApiLoader } from "@react-google-maps/api";
import Icon from "components/Common/Icons";
import { DisplayAddress } from "components/Fields/Address";
import { formatDate } from "devextreme/localization";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Badge, Button, Card, CardBody, Col, Row } from "reactstrap";
import mapMarkers from "../../../../assets/images/map-marker-sprite-set.png";

export default function InspectionCard(props) {
  const { t } = useTranslation("translation");
  const history = useHistory();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const address = DisplayAddress(props.workOrder.inspection.address);

  useEffect(() => {
    if (isLoaded) {
      const google = window.google;
      let geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: address }, (results, status) => {
        if (status == "OK") {
          const map = new google.maps.Map(
            document.getElementById("map_workOrder_" + props.workOrder.id),
            {
              center: results[0].geometry.location,
              zoom: 14,
            }
          );

          const marker = new google.maps.Marker({
            position: results[0].geometry.location,
            map: map,
          });
        }
      });
    }
  }, [isLoaded]);

  function editWorkOrder(e) {
    history.push("/work/order/" + encodeURI(props.workOrder.id));
  }

  function editReport(e) {
    alert("Not yet implemented");
  }

  function getContractOptions() {
    switch (props.workOrder.contractOptions) {
      case undefined:
      case "":
      case "awaitingAcceptance":
        return (
          <Badge color="warning" className="transparent text-sm">
            {t("awaiting_acceptance", "Not accepted")}
          </Badge>
        );
      case "confirmedInspection":
        return (
          <Badge color="success" className="transparent text-sm">
            {t("confirmed_inspection", "Confirmed")}
          </Badge>
        );
      case "rejected":
        return (
          <Badge color="errors" className="transparent text-sm">
            {t("contract_rejected", "Contract rejected")}
          </Badge>
        );
    }
  }

  const getInspectorCardClass = useCallback(index => {
    let cssClass =
      "float-end map-marker number-" +
      props.index +
      "-" +
      (((props.index + 1) % 5) - 1);
    return cssClass;
  });

  return (
    <Card className={"mb-2"}>
      <CardBody className="pt-3 pb-3">
        <h2 className="mb-4">
          {formatDate(
            new Date(props.workOrder.inspection.inspectionDate),
            "shortTime"
          )}
          {" ("}
          {Intl.NumberFormat().format(props.workOrder.inspection.duration)}{" "}
          {t("hour", {
            count: props.workOrder.inspection.duration,
          })}
          {")"}
          <div className={getInspectorCardClass(props.index)}></div>
        </h2>
        <Row>
          <Col>
            <h3>{t("clients", "Clients")}</h3>
            {props.workOrder.clientInformation.map((client, index) => {
              return (
                <Row key={index}>
                  <Col>
                    {client.firstname} {client.lastname}
                  </Col>
                  <Col>
                    {client.primaryPhone}{" "}
                    <a href={"tel:" + client.primaryPhone}>
                      <Icon name="phone"></Icon>
                    </a>
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Row>
              <Col>
                <div
                  className="border border-1 rounded"
                  style={{ minHeight: "100px" }}
                  id={"map_workOrder_" + props.workOrder.id}
                ></div>
                <div className="mt-1">
                  <a
                    target="_blank"
                    href={
                      "https://www.google.com/maps/dir/?api=1&origin=" +
                      encodeURI(props.fromAddress) +
                      "&destination=" +
                      encodeURI(address)
                    }
                  >
                    {t("get_directions", "Get directions")}
                    <Icon name="link-external" className="ml-1" />
                  </a>
                </div>
              </Col>
            </Row>
          </Col>
          <Col className="d-flex flex-column">
            <span className="text-dark">{address}</span>
            <Row className="mt-2">
              <Col>
                <a onClick={() => alert("Not yet implemented")}>
                  {getContractOptions()}
                </a>
              </Col>
              <Col>
                <a onClick={() => alert("Not yet implemented")}>
                  <Badge color="danger" className="transparent text-sm">
                    {t("payment_not_made", "Not paid")}
                  </Badge>
                </a>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <div className="text-dark">
                  {t("inspectors", "Inspectors")}:
                </div>
                {props.workOrder?.inspection?.inspectors
                  ? props.workOrder?.inspection?.inspectors
                      .map(inspector => inspector.text)
                      .join(", ")
                  : t("no_inspectors_assigned", "No inspectors assigned")}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-2 flex-grow-1 align-items-end">
          <Col>
            <Button onClick={editReport} color="light">
              {t("write_report", "Write report")}
              <Icon
                className="ml-1"
                name="reviewReport"
                hint={t("write_report", "Write report")}
              />
            </Button>
          </Col>
          <Col>
            <Button onClick={editWorkOrder} color="light">
              {t("view_work_order", "View work order")}
              <Icon
                className="ml-1"
                name="reviewWorkOrder"
                hint={t("view_work_order", "View work order")}
              />
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
