export const FETCH_TEMPLATES = "FETCH_TEMPLATES" as const;
export const FETCH_TEMPLATES_SUCCESS = "FETCH_TEMPLATES_SUCCESS" as const;

export const FETCH_TEMPLATE = "FETCH_TEMPLATE" as const;
export const FETCH_TEMPLATE_SUCCESS = "FETCH_TEMPLATE_SUCCESS" as const;

export const CREATE_TEMPLATE = "CREATE_TEMPLATE" as const;
export const RENAME_TEMPLATE = "RENAME_TEMPLATE" as const;

export const FETCH_TEMPLATE_SYSTEMS = "FETCH_TEMPLATE_SYSTEMS" as const;
export const FETCH_TEMPLATE_SYSTEMS_SUCCESS =
  "FETCH_TEMPLATE_SYSTEMS_SUCCESS" as const;

export const ADD_SYSTEM_TO_TEMPLATE = "ADD_SYSTEM_TEMPLATE" as const;
export const REMOVE_SYSTEM_FROM_TEMPLATE = "REMOVE_SYSTEM_TEMPLATE" as const;

export const DELETE_TEMPLATE = "DELETE_TEMPLATE" as const;
export const DELETE_TEMPLATE_SUCCESS = "DELETE_TEMPLATE_SUCCESS" as const;
