import OriginalDataGrid from "devextreme-react/data-grid";

import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";

export default function DataGrid(props: any) {
  return (
    <OriginalDataGrid
      {...props}
      showColumnLines={false}
      showRowLines={true}
      dataSource={props.dataSource}
      onRowPrepared={props?.onRowPrepared}
      onToolbarPreparing={e => {
        let toolbarItems = e.toolbarOptions.items;
        if (toolbarItems !== undefined) {
          toolbarItems.forEach(function (item) {
            if (item.name === "addRowButton") {
              item.showText = "always";
              item.cssClass = "btn btn-primary btn-sm";
              item.location = "before";
              item.options.icon = null;
            }
          });
        }
      }}
      onInitNewRow={props.onInitNewRow ? props.onInitNewRow : null}
      onEditingStart={props.onEditingStart ? props.onEditingStart : null}
      repaintChangesOnly={props.repaintChangesOnly}
      ref={props.ref}
    >
      {props.children}
    </OriginalDataGrid>
  );
}
