import React from "react";
import { useTranslation } from "react-i18next";
import { getCSSClass } from "components/Common/Icons";
import StandardPage from "components/Common/StandardPage";
import EULA from "./components/eula";

export default function AuthEula() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <StandardPage
        pageid="eula"
        pagetitle={t("eula", "End User License Agreement (EULA)")}
        breadcumbIcon={getCSSClass("eula")}
        breadcrumbItems={[
          {
            uri: "/eula",
            text: t("eula_short", "EULA"),
            active: true,
          },
        ]}
      >
        <EULA></EULA>
      </StandardPage>
    </React.Fragment>
  );
}
