import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextInput } from "components/Fields";
import { Row, Col, Card, CardBody } from "reactstrap";
import Address from "components/Fields/Address";
import { useFormikContext } from "formik";
import { StandardMap } from "components/Custom/Maps/Standard";

export default function InspectionAddress(props) {
  const { t } = useTranslation();
  const formik = useFormikContext();
  const [fullAddress, setFullAddress] = useState("");

  useEffect(() => {
    let address = formik.values.inspection?.address || "";
    if (
      address === "" ||
      address.address1 === undefined ||
      address.city === undefined
    )
      return;

    if (address.address1.trim() === "" && address.city.trim() === "") {
      setFullAddress("");
      return;
    }

    let str =
      address.address1 +
      " " +
      address.address2 +
      " " +
      address.city +
      " " +
      address.region +
      " " +
      address.country;

    setFullAddress(str);
  }, [formik.values?.inspection?.address]);

  return (
    <>
      <Card className="mb-0 border-bottom">
        <CardBody>
          <h2 className="mt-2 mb-2">
            {t("inspection_address", "Inspection address")}
          </h2>
          <Address formik={formik} prefix="inspection.address" />
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <h3 className="mb-2">
            {t("address_mapping", "Mapping information")}
          </h3>
          <Row>
            <Col sm={6}>
              <TextInput
                name="inspection.address.intersection"
                label={t("inters1ection", "Intersection")}
                showErrors={true}
              />
              <TextInput
                name="inspection.address.mapNumber"
                label={t("map_number", "Map number")}
              />
              <TextInput
                name="inspection.address.parkingInformation"
                label={t("parking_information", "Parking information")}
                note={t(
                  "parking_information_note",
                  "Are you aware of any on-street parking restrictions the inspector should be aware of?"
                )}
              />
            </Col>
            <Col sm={6}>
              <div
                className="address-map border"
                style={{ height: "100%", borderRadius: "5px" }}
              >
                <StandardMap
                  address={fullAddress || ""}
                  settings={{
                    zoom: 14,
                  }}
                ></StandardMap>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
