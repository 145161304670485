import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Nav, NavItem, NavLink } from "reactstrap";
import { useHistory } from "react-router-dom";
import { OwnershipLevel } from "API";
import { useCategories } from "hooks/categories";
import { ResourceFilter } from "components/Settings/ResourceFilter";
import { ResourceList } from "components/Settings/ResourceList";
import { EmptyPane } from "components/Settings/EmptyPane";
import { ErrorPane } from "components/Settings/ErrorPane";
import { LoadingPane } from "components/Settings/LoadingPane";
import { ResourceFilterInput } from "components/Settings/ResourceFilterInput";

export function CategoriesTab() {
  const { t } = useTranslation("categories");
  const { push } = useHistory();
  const { byOwner, createCategory } = useCategories();
  const [tab, setTab] = useState(OwnershipLevel.USER);
  const [filter, setFilter] = useState("");

  const activeQuery = byOwner.get(tab);
  const categories =
    activeQuery?.data?.pages
      .map(page =>
        page.categories.filter(category =>
          category.name.toLowerCase().includes(filter.toLowerCase())
        )
      )
      .flat() ?? [];

  let content: JSX.Element | null = null;
  switch (activeQuery?.status) {
    case "success":
      if (categories?.length === 0) {
        content = <EmptyPane>{t("No categories found")}</EmptyPane>;
      } else {
        content = (
          <ResourceList
            type="Category"
            items={categories.map(value => ({
              type: "item",
              value,
            }))}
            actions={[
              {
                type: "link",
                label: "Add/Edit Subcategories",
                to: category => `/report/settings/categories/${category.id}`,
                color: "secondary",
              },
            ]}
          />
        );
      }
      break;
    case "error":
      content = <ErrorPane>{t("Failed to load categories")}</ErrorPane>;
      break;
    case "loading":
      content = <LoadingPane />;
  }

  return (
    <>
      <h2 className="mb-4">Categories</h2>
      <Nav tabs className="mb-3">
        <NavItem>
          <NavLink
            active={tab === OwnershipLevel.USER}
            onClick={() => setTab(OwnershipLevel.USER)}
          >
            Mine
          </NavLink>
        </NavItem>
        {byOwner.get(OwnershipLevel.FRANCHISEE) && (
          <NavItem>
            <NavLink
              active={tab === OwnershipLevel.FRANCHISEE}
              onClick={() => setTab(OwnershipLevel.FRANCHISEE)}
            >
              Franchisee
            </NavLink>
          </NavItem>
        )}
        <NavItem>
          <NavLink
            active={tab === OwnershipLevel.COMPANY}
            onClick={() => setTab(OwnershipLevel.COMPANY)}
          >
            Library
          </NavLink>
        </NavItem>
      </Nav>
      <div
        className="mb-3 d-flex justify-content-between align-items-center"
        style={{ gap: 16 }}
      >
        {tab === OwnershipLevel.USER ? (
          <ResourceFilterInput
            resource="categories"
            filter={filter}
            setFilter={setFilter}
            onCreate={async name => {
              const category = await createCategory.mutateAsync({ name });
              push(`/report/settings/categories/${category.id}`);
            }}
          />
        ) : (
          <ResourceFilter
            resource="categories"
            filter={filter}
            setFilter={setFilter}
          />
        )}
      </div>
      {content}
    </>
  );
}
