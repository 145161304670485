import {
  takeEvery,
  fork,
  put,
  call,
  all,
  StrictEffect,
} from "redux-saga/effects";

import { Category } from "API";
import * as types from "./actionTypes";
import * as actions from "./actions";
import * as effects from "./effects";

function* fetchCategories(): Generator<StrictEffect, void, Category[]> {
  try {
    const categories = yield call(effects.getCategories);
    yield put(actions.fetchCategoriesSuccess(categories));
  } catch (error) {
    console.error(error);
    yield put(actions.fetchCategoriesError(error));
  }
}

function* createCategory({
  payload: name,
}: ReturnType<typeof actions.createCategory>): Generator<StrictEffect> {
  try {
    yield call(effects.createCategory, name);
    yield fork(fetchCategories);
  } catch (error) {
    console.error(error);
    yield put(actions.createCategoryError(error));
  }
}

function* watchCategories() {
  yield takeEvery(types.FETCH_SYSTEMS, fetchCategories);
  yield takeEvery(types.CREATE_SYSTEM, createCategory);
}

export default function* rootSaga() {
  yield all([fork(watchCategories)]);
}
