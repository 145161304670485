import { Template, TemplateCategoryConnection } from "API";
import * as types from "./actionTypes";

export function fetchTemplates(ownerId: string) {
  return {
    type: types.FETCH_TEMPLATES,
    payload: ownerId,
  };
}

export function fetchTemplatesSuccess(payload: {
  ownerId: string;
  templates: Template[];
}) {
  return {
    type: types.FETCH_TEMPLATES_SUCCESS,
    payload,
  };
}

export function fetchTemplate(payload: { id: string }) {
  return {
    type: types.FETCH_TEMPLATE,
    payload,
  };
}

export function fetchTemplateSuccess(payload: {
  id: string;
  template: Template;
}) {
  return {
    type: types.FETCH_TEMPLATE_SUCCESS,
    payload,
  };
}

export function createTemplate() {
  return {
    type: types.CREATE_TEMPLATE,
  };
}

type DeleteTemplatePayload = {
  ownerId: string;
  id: string;
};

export function deleteTemplate(payload: DeleteTemplatePayload) {
  return {
    type: types.DELETE_TEMPLATE,
    payload,
  };
}

export function deleteTemplateSuccess(payload: DeleteTemplatePayload) {
  return {
    type: types.DELETE_TEMPLATE_SUCCESS,
    payload,
  };
}

export function renameTemplate(payload: {
  ownerId: string;
  id: string;
  name: string;
}) {
  return {
    type: types.RENAME_TEMPLATE,
    payload,
  };
}

export function fetchTemplateCategories(payload: {
  ownerId: string;
  id: string;
}) {
  return {
    type: types.FETCH_TEMPLATE_SYSTEMS,
    payload,
  };
}

export function fetchTemplateCategoriesSuccess(payload: {
  ownerId: string;
  id: string;
  categories: TemplateCategoryConnection[];
}) {
  return {
    type: types.FETCH_TEMPLATE_SYSTEMS_SUCCESS,
    payload,
  };
}

export function addCategoryToTemplate(payload: {
  templateOwnerId: string;
  templateId: string;
  categoryOwnerId: string;
  categoryId: string;
}) {
  return {
    type: types.ADD_SYSTEM_TO_TEMPLATE,
    payload,
  };
}

export function removeCategoryFromTemplate(payload: {
  input: {
    templateId: string;
    orderIndex: number;
    categoryId: string;
  };
  templateOwnerId: string;
}) {
  return {
    type: types.REMOVE_SYSTEM_FROM_TEMPLATE,
    payload,
  };
}
