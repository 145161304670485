import { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Col, Row, TabPane, TabContent } from "reactstrap";

import { useSubcategory } from "hooks/subcategories";
import SideMenu from "components/Menus/SideMenu";
import StandardPage from "components/Common/StandardPage";
import SideMenuNavItem from "components/Menus/SideMenuNavItem";
import { ManageTab } from "./components/ManageTab";
import { ItemsTab } from "./components/ItemsTab";
import { useCategory } from "hooks/categories";

export function EditSubcategory() {
  const { categoryId, subcategoryId } = useParams<{
    categoryId: string;
    subcategoryId: string;
  }>();
  const { t } = useTranslation("subcategories");
  const [activeTab, setActiveTab] = useState("items");
  const { category } = useCategory(categoryId);
  const { subcategory } = useSubcategory(subcategoryId);

  return (
    <StandardPage
      pageid="edit-subcategory"
      pagetitle={`${t("Edit Subcategory")} "${subcategory.data?.name}"`}
      breadcrumbItems={[
        {
          uri: "/",
          text: t("report", "Report"),
          active: false,
        },
        {
          uri: "/report/settings",
          text: t("settings", "Settings"),
          active: false,
        },
        {
          uri: "/report/settings/categories",
          text: t("categories", "Category"),
          active: false,
        },
        {
          uri: `/report/settings/categories/${categoryId}`,
          text: category.data?.name,
          active: false,
        },
        {
          uri: `/report/settings/categories/${categoryId}/subcategories`,
          text: t("Subcategory"),
          active: false,
        },
        {
          uri: `/report/settings/categories/${categoryId}/subcategories/${subcategoryId}`,
          text: subcategory.data?.name,
          active: true,
        },
      ]}
    >
      <Row>
        <Col md={3} className="pr-0 side-nav-column mobile-shadow">
          <SideMenu prefix="edit_subcategory_menu">
            <SideMenuNavItem
              key="items"
              id="items"
              label={t("Items")}
              activeTab={activeTab}
              toggleTab={setActiveTab}
            />
            <SideMenuNavItem
              key="manage"
              id="manage"
              label={t("Manage")}
              activeTab={activeTab}
              toggleTab={setActiveTab}
            />
          </SideMenu>
        </Col>
        <Col md={9} className="bg-white p-0">
          <TabContent activeTab={activeTab}>
            <TabPane tabId="items">
              <ItemsTab subcategoryId={subcategoryId} />
            </TabPane>
            <TabPane tabId="manage">
              <ManageTab subcategoryId={subcategoryId} />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </StandardPage>
  );
}
