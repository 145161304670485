import { Context, createContext, ReactNode, useContext } from "react";

export type ResourceListContext = {
  canReorder?: boolean;

  handleDragStart?: () => void;
  handleDragEnd?: () => void;

  handleHover?: (dragIndex: number[], hoverIndex: number[]) => void;
  handleDrop?: () => void;

  onRename?: (id: string, newName: string) => Promise<any> | any;

  showDropZones?: boolean;
};

export const resourceListContext = createContext<ResourceListContext>({
  canReorder: false,
  showDropZones: false,
});

export function ResourceListProvider({
  children,
  value,
}: {
  children: ReactNode;
  value: ResourceListContext;
}) {
  return (
    <resourceListContext.Provider value={value}>
      {children}
    </resourceListContext.Provider>
  );
}

export function useResourceListContext() {
  const context = useContext<ResourceListContext>(
    resourceListContext as unknown as Context<ResourceListContext>
  );

  if (!context) {
    throw new Error(
      "useResourceListContext must be used within a ResourceListContext.Provider"
    );
  }

  return context;
}
