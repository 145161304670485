import {
  SNIPPETS_ERROR,
  SNIPPET_ERROR,
  EDIT_SNIPPET,
  EDIT_SNIPPET_SUCCESS,
  ADD_SNIPPET,
  ADD_SNIPPET_SUCCESS,
  HYDRATE_SNIPPETS,
  HYDRATE_SNIPPETS_SUCCESS,
  HYDRATE_SNIPPET,
  HYDRATE_SNIPPET_SUCCESS,
} from "./actionTypes";

export const addSnippet = Snippet => {
  return {
    type: ADD_SNIPPET,
    payload: { Snippet },
  };
};

export const addSnippetSuccess = status => {
  return {
    type: ADD_SNIPPET_SUCCESS,
    payload: status,
  };
};

export const editSnippet = Snippet => {
  return {
    type: EDIT_SNIPPET,
    payload: { Snippet },
  };
};

export const editSnippetSuccess = status => {
  return {
    type: EDIT_SNIPPET_SUCCESS,
    payload: status,
  };
};

export const hydrateSnippet = id => {
  return {
    type: HYDRATE_SNIPPET,
    id: id,
  };
};

export const hydrateSnippetSuccess = snippets => {
  return {
    type: HYDRATE_SNIPPET_SUCCESS,
    payload: snippets,
  };
};

export const hydrateSnippets = () => {
  return {
    type: HYDRATE_SNIPPETS,
  };
};

export const hydrateSnippetsSuccess = snippets => {
  return {
    type: HYDRATE_SNIPPETS_SUCCESS,
    payload: snippets,
  };
};

export const SnippetsError = error => {
  return {
    type: SNIPPETS_ERROR,
    payload: error,
  };
};

export const SnippetError = error => {
  return {
    type: SNIPPET_ERROR,
    payload: error,
  };
};
