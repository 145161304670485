import PropTypes from "prop-types";
import React from "react";

import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import SideMenu from "components/Menus/SideMenu";
import SideMenuNavItem from "components/Menus/SideMenuNavItem";
import { useProfile } from "hooks/profile";
import { useQuery } from "@tanstack/react-query";

const Menu = props => {
  const profile = useProfile();
  const { data: profileData } = useQuery(["profile"], profile.fetch);
  return (
    <React.Fragment>
      <SideMenu prefix="personal_settings_menu">
        <SideMenuNavItem
          id="profile"
          label={
            profileData?.id !== props.editing.id
              ? `${props.editing.firstName} ${props.editing.lastName} Profile`
              : "My Profile"
          }
          activeTab={props.activeTab}
          toggleTab={props.toggleTab}
        />
        <SideMenuNavItem
          id="availability"
          label="Availability"
          activeTab={props.activeTab}
          toggleTab={props.toggleTab}
        />
        <SideMenuNavItem
          id="specialities"
          label="Specialities and services"
          activeTab={props.activeTab}
          toggleTab={props.toggleTab}
        />
        <SideMenuNavItem
          id="roles"
          label="Roles and access"
          activeTab={props.activeTab}
          toggleTab={props.toggleTab}
        />
        <SideMenuNavItem
          id="license"
          label="Licences and Certificates"
          activeTab={props.activeTab}
          toggleTab={props.toggleTab}
        />
      </SideMenu>
    </React.Fragment>
  );
};

Menu.propTypes = {
  t: PropTypes.any,
  toggleTab: PropTypes.func,
  activeTab: PropTypes.string,
};

export default withTranslation()(withRouter(Menu));
