export type Permission = {
  id: string;
  label: string;
  description?: string;
};

export const permissions: Permission[] = [
  { id: "permission_all_profile", label: "*All profile permissions" },
  { id: "permission_edit_fees", label: "Edit inspection fees" },
  { id: "permission_manage_agents", label: "Manage real estate agents" },
  {
    id: "permission_modify_summary_section",
    label: "Modify summary section name",
  },
  {
    id: "permission_modify_business_reports",
    label: "Modify business reports",
  },
  { id: "permission_modify_templates", label: "Modify templates" },
  { id: "permission_manage_inspectors", label: "Manage inspectors" },
  {
    id: "permission_edit_company",
    label: "Edit company",
    description: "includes logos, title page, header and footer",
  },
  { id: "permission_modify_text_options", label: "Modify text options" },
  { id: "permission_modify_contract", label: "Modify contract" },
  { id: "permission_modify_work_order", label: "Customize work order" },
  { id: "permission_allow_book_online", label: "Book online options" },
  { id: "permission_allow_data_exports", label: "Data exports" },
  { id: "permission_allow_pivot_tables", label: "Pivot table reporting" },
  { id: "permission_modify_title_page", label: "Title page setup" },
  {
    id: "permission_modify_online_payments",
    label: "Online payment account setup",
  },
  { id: "permission_allow_horizon_marketer", label: "Horizon marketer setup" },
  { id: "permission_modify_base_template", label: "Base template setup" },
];
