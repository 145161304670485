import { useTranslation } from "react-i18next";
import { TextInput } from "components/Fields";
import StandardSwitches from "components/Fields/Switches/StandardSwitches";
import { Card, CardBody } from "reactstrap";

import { useFormikContext } from "formik";

export default function InspectionAttendance(_props) {
  const { t } = useTranslation();
  const formik = useFormikContext();

  const onChange = values => {
    formik.setFieldValue("inspectionAttendance", values);
  };

  return (
    <Card>
      <CardBody>
        <h2 className="mt-2 mb-2">
          {t("inspection_attendance", "Inspection attendance")}
        </h2>
        <StandardSwitches
          name="inspectionAttendance"
          formik={formik}
          onChange={onChange}
          options={[
            {
              value: "buyer",
              label: t("buyer", "Buyer"),
              checked: formik?.values?.inspectionAttendance
                ? formik.values.inspectionAttendance.includes("buyer")
                : false,
            },
            {
              value: "buyersAgent",
              label: t("buyers_agent", "Buyer's agent"),
              checked: formik?.values?.inspectionAttendance
                ? formik.values.inspectionAttendance.includes("buyersAgent")
                : false,
            },
            {
              value: "seller",
              label: t("seller", "Seller"),
              checked: formik?.values?.inspectionAttendance
                ? formik.values.inspectionAttendance.includes("seller")
                : false,
            },
            {
              value: "sellersAgent",
              label: t("sellers_agent", "Seller's agent"),
              checked: formik?.values?.inspectionAttendance
                ? formik.values.inspectionAttendance.includes("sellersAgent")
                : false,
            },
            {
              value: "other",
              label: t("other", "Other"),
              checked: formik?.values?.inspectionAttendance
                ? formik.values.inspectionAttendance.includes("other")
                : false,
            },
          ]}
        />
        {formik?.values?.inspectionAttendance &&
        formik.values.inspectionAttendance.includes("other") ? (
          <TextInput
            label={t("other_please_specify", "Other (please specify)")}
            name="inspectionAttendanceOther"
            formik={formik}
          />
        ) : (
          ""
        )}
      </CardBody>
    </Card>
  );
}
