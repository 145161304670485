import { useCallback } from "react";
import { v4 as uuid } from "uuid";
import { Badge, TabPane } from "reactstrap";
import { useTranslation } from "react-i18next";
import {
  Column,
  Editing,
  Texts,
  Form,
  Lookup,
  Popup,
  Button,
  SearchPanel,
} from "devextreme-react/data-grid";
import DataGrid from "components/Tables/DataGrid";
import CustomStore from "devextreme/data/custom_store";
import { Item, SimpleItem } from "devextreme-react/form";
import { useProducts } from "hooks/products";
import { Product } from "API";
import { useProfile } from "hooks/profile";
import { getCSSClass } from "components/Common/Icons";

function Products() {
  const { byOwner, deleteProduct, createProduct, updateProduct } =
    useProducts();
  const { myId } = useProfile();

  const { t } = useTranslation();

  const productStore = new CustomStore({
    key: "id",
    load: () => byOwner?.data?.products || [],
    insert: values => insertData(values),
    update: (key, values) => updateData(key, values),
    remove: key => removeData(key),
  });

  const categories = [
    {
      key: "PRODUCT",
      text: t("product", "Product"),
      color: "primary",
    },
    {
      key: "SERVICE",
      text: t("service", "Service"),
      color: "secondary",
    },
  ];

  const validationRules = {
    name: [
      {
        type: "required",
        message: t("name_is_required", "Name is required"),
      },
    ],
    price: [
      {
        type: "required",
        message: t("price_is_required", "Price is required"),
      },
    ],
    taxExempt: [],
    visibility: [
      {
        type: "required",
        message: t(
          "booking_visibility_is_required",
          "Booking visibility is required"
        ),
      },
    ],
    category: [
      {
        type: "required",
        message: t("category_is_required", "Category is required"),
      },
    ],
  };

  const editorOptions = {
    duration: { format: "#,##0.0 " + t("hours", "hours") },
    price: { format: "$ #,##0.00" },
  };

  const format = {
    duration: "#,##0.0 " + t("hours", "hours"),
    price: { type: "currency", precision: "2" },
  };

  const bookingVisibilities = [
    {
      key: "SHOWDESCRIPTIONANDFEE",
      text: t("show_description_and_fee", "Show description and fee"),
      color: "success",
    },
    {
      key: "SHOWDESCRIPTIONONLY",
      text: t("show_description_only", "Show description only"),
      color: "warning",
    },
    {
      key: "HIDE",
      text: t("hide", "Hide"),
      color: "danger",
    },
  ];

  const defaultSizes = [
    { key: 0, text: t("not_applicable", "Not Applicable") },
    { key: 1000, text: "< 1000" },
    { key: 2999, text: "1001 - 2999" },
    { key: 4999, text: "3000 - 4999" },
    { key: 5999, text: "5000 - 5999" },
    { key: 100000, text: "> 6000" },
  ];

  const onInitNewRow = useCallback((e: any) => {
    e.data.taxExempt = false;
    e.data.category = "SERVICE";
    e.data.duration = 1;
    e.data.visibility = "SHOWDESCRIPTIONANDFEE";
    e.data.size = 0;
  }, []);

  async function insertData(values: Product) {
    let newProduct = {
      ...values,
      id: uuid(),
    };
    let response = createProduct.mutate({
      ...newProduct,
      ownerId: myId,
    });
    return newProduct;
  }

  async function updateData(key: string, values: Product) {
    let newProduct = byOwner.data?.products.filter(
      product => product.id == key
    )[0];

    newProduct = {
      ...newProduct,
      ...values,
    };

    const { createdAt, updatedAt, ...updatedProduct } = newProduct;

    updateProduct.mutate(updatedProduct);
    return newProduct;
  }

  async function removeData(key: string) {
    deleteProduct.mutate({ id: key, ownerId: myId });
  }

  const bookingVisibilityRender = useCallback((data: any) => {
    const bookingVisibility = bookingVisibilities.find(
      f => f.key === data.value
    );
    const color: string = bookingVisibility?.color || "";
    return <Badge color={color}>{data.text}</Badge>;
  }, []);

  const categoryRender = useCallback((data: any) => {
    const category = categories.find(f => f.key === data.value);
    const color = category?.color || "";
    return <Badge color={color}>{data.text}</Badge>;
  }, []);

  return (
    <TabPane tabId="products">
      <h2>
        {t("products_and_services_tab_title", "Products and services")}
        <p className="card-title-desc">
          {t(
            "products_and_services_tab_subtitle",
            "Edit the products and services you can offer to customers"
          )}
        </p>
      </h2>
      <DataGrid dataSource={productStore} onInitNewRow={onInitNewRow}>
        <SearchPanel visible={true} highlightCaseSensitive={true} />
        <Editing
          mode="popup"
          allowUpdating={true}
          allowAdding={true}
          allowDeleting={true}
        >
          <Texts
            confirmDeleteMessage={t(
              "products_and_services_confirm_delete",
              "Are you sure you want to delete this product/service?"
            )}
          />
          <Popup
            title={t("new_product_or_service", "New product or service")}
            showTitle={true}
            width={700}
            height={400}
          />
          <Form>
            <Item itemType="group" colCount={2} colSpan={2}>
              <SimpleItem
                dataField="category"
                validationRules={validationRules.category}
              />
              <SimpleItem
                dataField="name"
                validationRules={validationRules.name}
              />
              <SimpleItem dataField="size" />
              <SimpleItem
                dataField="duration"
                editorType="dxNumberBox"
                editorOptions={editorOptions.duration}
              />
              <SimpleItem dataField="description" />
              <SimpleItem
                dataField="price"
                editorType="dxNumberBox"
                editorOptions={editorOptions.price}
                validationRules={validationRules.price}
              />
              <SimpleItem
                dataField="taxExempt"
                editorType="dxCheckBox"
                validationRules={validationRules.taxExempt}
              />
              <SimpleItem
                dataField="visibility"
                validationRules={validationRules.visibility}
              />
            </Item>
          </Form>
        </Editing>
        <Column
          dataField="category"
          caption={t("category", "Category")}
          cellRender={categoryRender}
          hidingPriority={2}
        >
          <Lookup dataSource={categories} valueExpr="key" displayExpr="text" />
        </Column>
        <Column
          dataField="name"
          caption={t("name", "Name")}
          hidingPriority={10}
        />
        <Column dataField="size" caption={t("size", "Size")} hidingPriority={5}>
          <Lookup
            dataSource={defaultSizes}
            valueExpr="key"
            displayExpr="text"
          />
        </Column>
        <Column
          dataField="duration"
          caption={t("duration_hours", "Duration")}
          format={format.duration}
          hidingPriority={6}
        />
        <Column
          dataField="description"
          caption={t("description", "Description")}
          hidingPriority={1}
        />
        <Column
          dataField="price"
          caption={t("price", "Price")}
          format={format.price}
          hidingPriority={9}
        />
        <Column
          dataField="taxExempt"
          caption={t("tax_exempt", "Tax exempt")}
          hidingPriority={3}
        />
        <Column
          dataField="visibility"
          caption={t("booking_visibility", "Booking visibility")}
          cellRender={bookingVisibilityRender}
          hidingPriority={4}
        >
          <Lookup
            dataSource={bookingVisibilities}
            valueExpr="key"
            displayExpr="text"
          />
        </Column>
        <Column
          type="buttons"
          caption={t("options", "Options")}
          hidingPriority={10}
        >
          <Button
            name="edit"
            icon={getCSSClass("edit")}
            hint={t("edit", "Edit")}
          />
          <Button
            name="delete"
            icon={getCSSClass("delete")}
            hint={t("delete", "Delete")}
          />
        </Column>
      </DataGrid>
    </TabPane>
  );
}

export default Products;
