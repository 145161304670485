import API, { graphqlOperation, GraphQLQuery } from "@aws-amplify/api";

import {
  listTemplates as listTemplatesQuery,
  templatesById as templateByIdQuery,
  listTemplateCategoryConnections,
} from "graphql/queries";
import {
  createTemplate as createTemplateMutation,
  deleteTemplate as deleteTemplateMutation,
  createTemplateCategoryConnection as createTemplateCategoryConnectionMutation,
  deleteTemplateCategoryConnection as deleteTemplateCategoryConnectionMutation,
  updateTemplate as updateTemplateMutation,
} from "graphql/mutations";
import {
  ListTemplatesQuery,
  TemplatesByIdQuery,
  CreateTemplateMutation,
  Template,
  UpdateTemplateMutation,
  DeleteTemplateMutation,
  CreateTemplateCategoryConnectionMutation,
  DeleteTemplateCategoryConnectionMutation,
  ListTemplateCategoryConnectionsQuery,
} from "API";
import { getAccessToken } from "helpers/graphql";

export async function fetchTemplates(ownerId: string) {
  const res = await API.graphql<GraphQLQuery<ListTemplatesQuery>>(
    graphqlOperation(listTemplatesQuery, { ownerId }, await getAccessToken())
  );

  const templates: Template[] = [];
  for (const template of res.data?.listTemplates?.items ?? []) {
    if (template) {
      templates.push({ ...template, categories: null });
    }
  }

  return templates;
}

export async function getTemplate(input: { id: string }) {
  const res = await API.graphql<GraphQLQuery<TemplatesByIdQuery>>(
    graphqlOperation(templateByIdQuery, input, await getAccessToken())
  );

  return res.data?.templatesById?.items[0];
}

export async function createTemplate() {
  const res = await API.graphql<GraphQLQuery<CreateTemplateMutation>>(
    graphqlOperation(
      createTemplateMutation,
      {
        input: {
          name: "New Template",
        },
      },
      await getAccessToken()
    )
  );

  return res.data?.createTemplate;
}

export async function deleteTemplate(input: { ownerId: string; id: string }) {
  await API.graphql<GraphQLQuery<DeleteTemplateMutation>>(
    graphqlOperation(
      deleteTemplateMutation,
      {
        input,
      },
      await getAccessToken()
    )
  );
}

export async function renameTemplate(input: {
  ownerId: string;
  id: string;
  name: string;
}) {
  await API.graphql<GraphQLQuery<UpdateTemplateMutation>>(
    graphqlOperation(
      updateTemplateMutation,
      {
        input,
      },
      await getAccessToken()
    )
  );
}

export async function getTemplateCategories(templateId: string) {
  const res = await API.graphql<
    GraphQLQuery<ListTemplateCategoryConnectionsQuery>
  >(
    graphqlOperation(
      listTemplateCategoryConnections,
      {
        templateId,
      },
      await getAccessToken()
    )
  );

  return res.data?.listTemplateCategoryConnections?.items ?? [];
}

export async function addCategoryToTemplate(input: {
  templateOwnerId: string;
  templateId: string;
  categoryOwnerId: string;
  categoryId: string;
}) {
  await API.graphql<GraphQLQuery<CreateTemplateCategoryConnectionMutation>>(
    graphqlOperation(
      createTemplateCategoryConnectionMutation,
      {
        input,
      },
      await getAccessToken()
    )
  );
}

export async function removeCategoryFromTemplate(input: {
  templateId: string;
  orderIndex: number;
  categoryId: string;
}) {
  await API.graphql<GraphQLQuery<DeleteTemplateCategoryConnectionMutation>>(
    graphqlOperation(
      deleteTemplateCategoryConnectionMutation,
      {
        input,
      },
      await getAccessToken()
    )
  );
}
