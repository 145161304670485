import { TextInput } from "components/Fields";
import { Field, FormikValues, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "reactstrap";

const { v4: uuidv4 } = require("uuid");

export type LicenseObject = {
  id?: string;
  licenseType: string;
  licenseNumber: string;
};

export default function LicensesForm() {
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const { t } = useTranslation();

  // Function for Create Input Fields
  function handleAddFields() {
    const newLicenses = values.licenses || [];
    newLicenses.push({ id: uuidv4(), licenseType: "", licenseNumber: "" });
    setFieldValue("licenses", newLicenses);
  }

  // Function for Remove Input Fields
  function handleRemoveFields(index: number) {
    const newLicenses = values.licenses || [];
    newLicenses.splice(index, 1);
    setFieldValue("licenses", newLicenses);
  }

  return (
    <>
      <Row>
        <div className="inner-repeater mb-4">
          <div className="inner form-group mb-0 row">
            <div className="inner col-lg-12 ms-md-auto" id="repeater">
              {values &&
                values.licenses?.map((_license: LicenseObject, key: number) => (
                  <div
                    key={"_field_" + key}
                    className="mt-0 row align-items-center license"
                  >
                    <Col md="5">
                      <Field
                        type="hidden"
                        name={"licenses[" + key + "].id"}
                        value={values.licenses[key].id}
                      />
                      <TextInput
                        name={"licenses[" + key + "].licenseNumber"}
                        label={
                          t("license_number", "License or certificate number") +
                          "*"
                        }
                        showErrors={true}
                      />
                    </Col>
                    <Col md="5">
                      <TextInput
                        name={"licenses[" + key + "].licenseType"}
                        label={t("license_type", "License Type") + "*"}
                        showErrors={true}
                      />
                    </Col>
                    <Col md="2">
                      <div className="mt-4 mt-md-0 d-grid">
                        <Button
                          color="primary"
                          className="inner btn-sm"
                          onClick={() => {
                            handleRemoveFields(key);
                          }}
                        >
                          {t("delete", "Delete")}
                        </Button>
                      </div>
                    </Col>
                  </div>
                ))}
            </div>
          </div>
          <Row className="justify-content-end">
            <Col lg="12">
              <Button
                color="success"
                className="inner btn-sm mt-3"
                onClick={handleAddFields}
              >
                {values && values.licenses?.length > 0
                  ? t("add_another_license", "Add another license")
                  : t("add_a_license", "Add a license")}
              </Button>
            </Col>
          </Row>
        </div>
      </Row>
      <Row>
        <Col sm={6}>
          <TextInput
            name="companyLicense"
            label={t("company_license", "Company License")}
            showErrors={true}
          />
        </Col>
      </Row>
    </>
  );
}
