import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useProfile } from "hooks/profile";
import {
  addDiscountFn,
  deleteDiscountFn,
  getDiscountById,
  getDiscounts,
  updateDiscountFn,
} from "./effects";
import toast from "toastr";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

export function useDiscounts() {
  const queryClient = useQueryClient();
  const { myId } = useProfile();
  const { t } = useTranslation();
  const history = useHistory();

  const byOwner = useQuery(["discounts", myId], getDiscounts, {
    enabled: Boolean(myId),
  });

  const createDiscount = useMutation(addDiscountFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(["discounts", myId]);
      toast.success(t("discount_create_success", "New discount created"));
      history.push("/company/settings/discounts");
    },
    onError(error) {
      console.error(error);
      toast.error(t("discount_create_failed", "Failed to create new discount"));
    },
  });

  const deleteDiscount = useMutation(deleteDiscountFn, {
    onSuccess: (_, input) => {
      queryClient.invalidateQueries(["discounts", input.ownerId]);
      toast.success("Discount deleted");
    },
    onError(error) {
      console.error(error);
      toast.error("Failed to delete discount");
    },
  });

  return {
    byOwner,
    createDiscount,
    deleteDiscount,
  };
}

export function useDiscount(id: string) {
  const queryClient = useQueryClient();
  const discount = useQuery(["discounts", id], getDiscountById, {
    enabled: Boolean(id),
  });
  const history = useHistory();

  const updateDiscount = useMutation(updateDiscountFn, {
    onSuccess: (_, input) => {
      queryClient.invalidateQueries({
        queryKey: ["discounts"],
      });
      toast.success("Discount updated");
      history.push("/company/settings/discounts");
    },
    onError(error) {
      console.error(error);
      toast.error("Failed to update discount");
    },
  });

  return { discount, updateDiscount };
}
