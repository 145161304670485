import PropTypes from "prop-types";
import { Component } from "react";

class StandardSwitch extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={"switch-list-container " + this.props.className}>
        <div
          className="form-check form-switch form-switch form-check-primary mb-1"
          key={this.props.value}
        >
          <label className="form-check-label" htmlFor={this.props.id}>
            <input
              type="checkbox"
              className="form-check-input"
              checked={this.props.checked ? this.props.checked : false}
              value={this.props.value}
              id={this.props.id}
              name={this.props.name}
              onChange={this.props.onChange}
            />
            {this.props.label}
          </label>
        </div>
      </div>
    );
  }
}

StandardSwitch.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  showErrors: PropTypes.bool,
  errors: PropTypes.any,
  touched: PropTypes.any,
  disabled: PropTypes.any,
  value: PropTypes.any,
  default: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  className: PropTypes.string,
};

export { StandardSwitch };
