import React, { useState } from "react";
import { Label, Button } from "reactstrap";
import { Field, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useProfile } from "hooks/profile";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import toastr from "toastr";
import { useHistory } from "react-router-dom";

export default function DataPrivacy(props) {
  const { t } = useTranslation();
  const profile = useProfile();
  const queryClient = useQueryClient();
  const history = useHistory();
  const [profileData, setProfileData] = useState();
  useQuery(["profile"], profile.fetch, {
    onSuccess: data => {
      setProfileData(data);
    },
  });
  const saveProfile = useMutation(
    values => {
      return profile.save(values);
    },
    {
      onSuccess: () => {
        toastr.success(
          t("profile_saved_success", "Profile saved successfully")
        );
        queryClient.invalidateQueries(["profile"]);
      },
    }
  );
  function handleFormSubmit(values) {
    saveProfile.mutate(values);
  }
  return (
    <React.Fragment>
      <Formik
        enableReinitialize={true}
        onSubmit={handleFormSubmit}
        validationSchema={profile.validation}
        initialValues={profileData}
      >
        {props => (
          <>
            <h2>
              {t(
                "company_data_privacy_tab_title",
                "Data and privacy within Horizon"
              )}
              <p className="card-title-desc">
                {t(
                  "company_data_privacy_tab_subtitle",
                  "Define how you would like Horizon to store and use your data."
                )}
              </p>
            </h2>
            <h5 className="mt-3">
              {t(
                "data_sharing_business_partners",
                "Data sharing with business partners"
              )}
            </h5>
            <div className="row mx-0">
              <div className="col-md-4 p-3">
                <div className="btn-group" role="group">
                  <Field
                    id="dataSharingYes"
                    type="radio"
                    className="btn-check"
                    name="settings.dataSharing"
                    value="1"
                  />
                  <label
                    className="btn btn-sm btn-outline-secondary success"
                    htmlFor="dataSharingYes"
                  >
                    Yes
                  </label>

                  <Field
                    id="dataSharingNo"
                    type="radio"
                    className="btn-check"
                    name="settings.dataSharing"
                    value="0"
                  />
                  <label
                    className="btn btn-sm btn-outline-secondary danger"
                    htmlFor="dataSharingNo"
                  >
                    No
                  </label>
                </div>
              </div>
              <div className="col-md-8 border-start p-3">
                <Label>
                  {t(
                    "data_sharing_business_partners_description",
                    "Allow sharing of additional information with Carson Dunlop's business partners"
                  )}
                </Label>
                <p className="mb-0">
                  {t(
                    "data_sharing_business_partners_detail",
                    "Carson Dunlop always shares information with business partners as a way to run and improve its products. When enabled, this allows Carson Dunlop to share additional information with those partners to help support running Carson Dunlop's business."
                  )}
                </p>
              </div>
            </div>
            <h5 className="mt-4 pt-3 border-top">
              {t("location_sharing_title", "Location information")}
            </h5>
            <div className="row mx-0">
              <div className="col-md-4 p-3">
                <div className="btn-group" role="group">
                  <Field
                    id="locationSharingYes"
                    type="radio"
                    className="btn-check"
                    name="settings.locationSharing"
                    value="1"
                  />
                  <label
                    className="btn btn-sm btn-outline-secondary success"
                    htmlFor="locationSharingYes"
                  >
                    Yes
                  </label>

                  <Field
                    id="locationSharingNo"
                    type="radio"
                    className="btn-check"
                    name="settings.locationSharing"
                    value="0"
                  />
                  <label
                    className="btn btn-sm btn-outline-secondary danger"
                    htmlFor="locationSharingNo"
                  >
                    No
                  </label>
                </div>
              </div>
              <div className="col-md-8 p-3 border-start">
                <Label for="shareLocationInfo">
                  {t(
                    "location_sharing_description",
                    "Allow sharing of location information"
                  )}
                </Label>
                <p className="mb-0">
                  {t(
                    "location_sharing_detail",
                    "Horizon uses some information, like where you signed up and your current location to provide booking, travel time, directions and other location-based services."
                  )}
                </p>
              </div>
            </div>
            <div className="mt-5">
              <Button
                color="primary"
                type="submit"
                onClick={() => {
                  props.handleSubmit();
                }}
              >
                {t("save_changes", "Save Changes")}
              </Button>
              <Button
                type="reset"
                color="outline-blue"
                className="ms-2"
                onClick={() => {
                  history.goBack();
                }}
              >
                {t("cancel", "Cancel")}
              </Button>
            </div>
          </>
        )}
      </Formik>
    </React.Fragment>
  );
}
