export const COMPANY_SETTINGS_ERROR = "COMPANY_SETTINGS_ERROR";
export const EDIT_COMPANY_SETTINGS = "EDIT_COMPANY_SETTINGS";
export const EDIT_COMPANY_SETTINGS_SUCCESS = "EDIT_COMPANY_SETTINGS_SUCCESS";
export const HYDRATE_COMPANY_SETTINGS = "HYDRATE_COMPANY_SETTINGS";
export const HYDRATE_COMPANY_SETTINGS_SUCCESS =
  "HYDRATE_COMPANY_SETTINGS_SUCCESS";

export const FETCH_COMPANY_DISCOUNTS = "FETCH_COMPANY_DISCOUNTS";
export const FETCH_COMPANY_DISCOUNTS_SUCCESS =
  "FETCH_COMPANY_DISCOUNTS_SUCCESS";
export const FETCH_COMPANY_DISCOUNTS_ERROR = "FETCH_COMPANY_DISCOUNTS_ERROR";

export const CREATE_DISCOUNT = "CREATE_DISCOUNT";
export const CREATE_DISCOUNT_SUCCESS = "CREATE_DISCOUNT_SUCCESS";
export const CREATE_DISCOUNT_ERROR = "CREATE_DISCOUNT_ERROR";

export const FETCH_DISCOUNT = "FETCH_DISCOUNT";
export const FETCH_DISCOUNT_SUCCESS = "FETCH_DISCOUNT_SUCCESS";

export const UPDATE_DISCOUNT = "UPDATE_DISCOUNT";
export const UPDATE_DISCOUNT_SUCCESS = "UPDATE_DISCOUNT_SUCCESS";

export const RESET_DISCOUNT = "RESET_DISCOUNT";

export const DELETE_DISCOUNT = "DELETE_DISCOUNT";
export const DELETE_DISCOUNT_SUCCESS = "DELETE_DISCOUNT_SUCCESS";
