import { InputGroup, Input, InputGroupText, Button, Spinner } from "reactstrap";
import { Field, Form, Formik } from "formik";

import Icon from "components/Common/Icons";
import { useTranslation } from "react-i18next";

interface ResourceInputProps {
  placeholder?: string;
  onCreate: (name: string) => Promise<any>;
}

export function ResourceInput(props: ResourceInputProps) {
  const { t } = useTranslation("components");

  return (
    <Formik
      initialValues={{ templateName: "" }}
      onSubmit={async (values, helpers) => {
        await props.onCreate(values.templateName);
        helpers.resetForm();
      }}
    >
      {bag => (
        <Form>
          <InputGroup>
            <InputGroupText>
              <Icon name="plus" />
            </InputGroupText>
            <Field
              as={Input}
              type="text"
              name="templateName"
              placeholder={t(props.placeholder ?? "")}
            />
            <Button
              type="submit"
              color={bag.dirty ? "success" : "secondary"}
              disabled={bag.isSubmitting || !bag.dirty}
              className="d-flex justify-items-center align-items-center"
            >
              {bag.isSubmitting ? (
                <Spinner size="sm" color="light" />
              ) : (
                "Create"
              )}
            </Button>
          </InputGroup>
        </Form>
      )}
    </Formik>
  );
}
