import { InputGroup, Button, Input, InputGroupText } from "reactstrap";

import Icon from "components/Common/Icons";

interface ResourceFilterProps {
  resource: string;
  filter: string;
  setFilter: (value: string) => void;
}

export function ResourceFilter(props: ResourceFilterProps) {
  const { filter, setFilter } = props;

  return (
    <InputGroup style={{ flex: 4 }}>
      <InputGroupText>
        <Icon name="search" />
      </InputGroupText>
      <Input
        placeholder={`Filter ${props.resource}...`}
        value={filter}
        onChange={e => {
          setFilter(e.currentTarget.value);
        }}
      />
      <Button
        disabled={!Boolean(filter.trim())}
        onClick={() => {
          setFilter("");
        }}
      >
        Clear Filter
      </Button>
    </InputGroup>
  );
}
