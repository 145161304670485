import React, { useState } from "react";
import { Col, Container, Row, TabContent } from "reactstrap";
import MetaTags from "react-meta-tags";

// Redux
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb";

// Tab Panes
import SideMenu from "./menu";
import TabProfile from "pages/My/Settings/components/_tabProfile";
import TabRoles from "pages/Company/Team/components/_tabUserAccess";
import TabLicense from "pages/My/Settings/components/_tabLicenses";
import TabSpeciality from "pages/My/Settings/components/_tabSpeciality";
import TabAvailability from "pages/My/Settings/components/_tabAvailability";

export default function EditMember(props) {
  const history = useHistory();
  const { t } = useTranslation();
  const defaultState = {
    activeTab: "profile",
    editing: props.location.state,
  };
  const [editMemberState, setEditMemberState] = useState(defaultState);

  /* Method to toggle the clicked tab */
  function toggleTab(tab) {
    if (editMemberState.activeTab !== tab) {
      setEditMemberState({
        ...editMemberState,
        activeTab: tab,
      });
    }
  }

  // Redirect if no team member selected
  if (props.location.state === undefined) {
    history.replace("/company/team");
    return null;
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{t("edit_member", "Edit Member")} | Horizon Core</title>
        </MetaTags>
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumb
            title={t("manage_my_team", "Manage my team")}
            icon="bx bxs-user-rectangle"
            breadcrumbItems={[
              {
                uri: "/company/team",
                text: t("company", "Company"),
                active: true,
              },
              {
                uri: "/company/team",
                text: t("my_team", "My team"),
                active: true,
              },
              {
                uri: "/company/team/edit",
                text:
                  editMemberState.editing.firstName +
                  " " +
                  editMemberState.editing.lastName,
                active: true,
              },
            ]}
          />

          <Row>
            <Col md="3" className="pr-0 side-nav-column mobile-shadow">
              <SideMenu
                activeTab={editMemberState.activeTab}
                toggleTab={toggleTab}
                editing={editMemberState.editing}
              />
            </Col>
            <Col className="bg-white px-0">
              <TabContent activeTab={editMemberState.activeTab}>
                <TabProfile editing={editMemberState.editing} />
                <TabAvailability
                  show={editMemberState.activeTab === "availability"}
                  editing={editMemberState.editing}
                />
                <TabRoles editing={editMemberState.editing} />
                <TabLicense editing={editMemberState.editing} />
                <TabSpeciality editing={editMemberState.editing} />
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
