const timezones = {
  "(GMT-10:00) Hawaii Time": "Pacific/Honolulu",
  "(GMT-08:00) Pacific Time": "America/Los_Angeles",
  "(GMT-07:00) Mountain Time": "America/Denver",
  "(GMT-07:00) Mountain Time - Arizona": "America/Phoenix",
  "(GMT-06:00) Central Time": "America/Chicago",
  "(GMT-06:00) Central Time - Regina": "America/Regina",
  "(GMT-05:00) Eastern Time": "America/New_York",
  "(GMT-04:00) Atlantic Time - Halifax": "America/Halifax",
  "(GMT-03:30) Newfoundland Time - St. Johns": "America/St_Johns",
};

export default timezones;
