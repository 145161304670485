import { TextInput } from "components/Fields";
import { useFormikContext } from "formik";
import { type } from "os";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, Col, Row } from "reactstrap";

export default function DetailsSection(props: any) {
  const { t } = useTranslation();
  const [nameLabel, setNameLabel] = useState<string>("");
  const [autoType, setAutoType] = useState<boolean>(false);
  const { values, setFieldValue } = useFormikContext<any>();

  useEffect(() => {
    const typeValue = values?.type;
    setAutoType(typeValue == "AUTO");

    const label =
      (autoType
        ? t("discount_name_label", "Discount name")
        : t("discount_code_label", "Discount code")) + "*";
    setNameLabel(label);
  });

  const makeCode = (length: number) => {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const generateCode = () => {
    setFieldValue("name", makeCode(8));
  };

  if (!values?.type) return <></>;

  return (
    <Card className="mb-0 border-bottom">
      <CardBody>
        <h3 className="mb-0">
          {t("discount_details_label", "Discount details")}
        </h3>
        <p>
          {t(
            "discount_details_description",
            "Define how the new discount should work"
          )}
        </p>
        <Row>
          <Col className={autoType ? "col-lg-12 pe-2" : "col-sm-4 pe-2"}>
            <TextInput name="name" label={nameLabel} showErrors={true} />
          </Col>
          {!autoType ? (
            <Col
              sm="2"
              className="d-flex flex-column align-items-start justify-content-start ps-0"
              style={{
                paddingTop: "33px",
              }}
            >
              <Button
                color="secondary"
                className=""
                onClick={generateCode}
                style={{
                  position: "relative",
                  top: "3px",
                }}
              >
                {t("generate_code", "Generate")}
              </Button>
            </Col>
          ) : (
            ""
          )}
        </Row>
        <Row className="mt-4">
          <Col lg="12">
            <TextInput
              name="description"
              label={t("discount_description", "Description") + "*"}
              showErrors={true}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
