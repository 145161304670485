import { QueryFunctionContext, QueryFunction } from "@tanstack/react-query";
import API, { graphqlOperation, GraphQLQuery } from "@aws-amplify/api";

import {
  Category,
  Subcategory,
  ListCategoriesQuery,
  CategoriesByIdQuery,
  ListSubcategoriesByCategoryIdQuery,
  ListSubcategoriesByCategoryIdQueryVariables,
  ModelSubcategoryConnection,
  ModelItemConnection,
} from "API";
import { listCategories, categoriesById } from "graphql/queries";
import { getAccessToken } from "helpers/graphql";

export async function getCategories({
  queryKey,
}: QueryFunctionContext<["categories", string]>) {
  const res = await API.graphql<GraphQLQuery<ListCategoriesQuery>>(
    graphqlOperation(
      listCategories,
      { ownerId: queryKey[1] },
      await getAccessToken()
    )
  );

  const categories: Category[] = [];
  for (const category of res.data?.listCategories?.items ?? []) {
    if (category) {
      categories.push({ ...category, subcategories: null, templates: null });
    }
  }

  return {
    categories,
    nextToken: res.data?.listCategories?.nextToken ?? undefined,
  };
}

export const getCategoryById: QueryFunction<
  Category,
  ["categories", string]
> = async ({ queryKey }) => {
  const res = await API.graphql<GraphQLQuery<CategoriesByIdQuery>>(
    graphqlOperation(
      categoriesById,
      { id: queryKey[1] },
      await getAccessToken()
    )
  );

  if (res.data?.categoriesById?.items[0]) {
    return {
      ...res.data?.categoriesById.items[0],
      subcategories: null,
      templates: null,
    };
  } else {
    throw new Error("Category not found");
  }
};

/** See src/graphql/queries.ts for query updates */
const hierarchicalSubcategoryQuery = /* GraphQL */ `
  query ListSubcategoriesByCategoryId(
    $categoryId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSubcategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubcategoriesByCategoryId(
      categoryId: $categoryId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        categoryId
        categoryOwnerId
        name
        notes
        itemOrder
        parentId
        subcategoryOrder
        children {
          items {
            id
            categoryId
            categoryOwnerId
            name
            notes
            itemOrder
            parentId
            subcategoryOrder
            createdAt
            updatedAt
          }
          nextToken
        }
        items {
          items {
            id
            name
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getCategorySubcategories: QueryFunction<
  Subcategory[],
  ["categories", string, "subcategories"]
> = async ({ queryKey }) => {
  const res = await API.graphql<
    GraphQLQuery<ListSubcategoriesByCategoryIdQuery>
  >(
    graphqlOperation(
      hierarchicalSubcategoryQuery,
      {
        categoryId: queryKey[1],
        filter: { parentId: { attributeExists: false } },
      } as ListSubcategoriesByCategoryIdQueryVariables,
      await getAccessToken()
    )
  );

  const subcategories: Subcategory[] = [];
  for (const subcategory of res.data?.listSubcategoriesByCategoryId?.items ??
    []) {
    if (subcategory) {
      subcategories.push({
        ...subcategory,
        category: undefined,
        parent: undefined,
        items: subcategory.items as ModelItemConnection,
        children: subcategory.children as ModelSubcategoryConnection,
      });
    }
  }

  return subcategories;
};
