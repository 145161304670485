import GoogleMapReact from "google-map-react";
import { useEffect, useState } from "react";
import Geocode from "react-geocode";
import { useTranslation } from "react-i18next";

export function StandardMap(props) {
  const { t } = useTranslation();
  const [center, setCenter] = useState([]);
  const [addressTimeout, setAddressTimeout] = useState();
  const [showMap, setShowMap] = useState(false);
  const [markers, setMarkers] = useState([]);

  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);

  useEffect(() => {
    // setAddressTimeout(
    setTimeout(() => {
      Geocode.fromAddress(props.address).then(
        data => {
          setCenter(data.results[0].geometry.location);
          setShowMap(true);
          const newMarkers = [];
          newMarkers.push({
            location: data.results[0].geometry.location,
          });
          setMarkers(newMarkers);
        },
        () => {
          setCenter([]);
          setShowMap(false);
        }
      );
    }, 1000);
    // );
  }, [props.address]);

  // TODO: add a bounds function for initialization

  if (showMap) {
    return (
      <GoogleMapReact
        center={center}
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        }}
        {...props.settings}
      >
        {props.children}
        {markers.map((marker, idx) => (
          <Marker
            key={idx}
            lat={marker.location.lat}
            lng={marker.location.lng}
          />
        ))}
      </GoogleMapReact>
    );
  } else {
    return (
      <div className="d-flex align-items-center justify-content-center h-100">
        {t(
          "map_enter_valid_address",
          "Please enter a valid address to show the map"
        )}
      </div>
    );
  }
}

const Marker = props => {
  return <div className="map-pin"></div>;
};
