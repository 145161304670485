import { useQuery } from "@tanstack/react-query";
import { API, Storage } from "aws-amplify";
import _ from "lodash";

const cacheKey = "team";

async function getTeam() {
  let dataUser = await API.get("UserMeta", "/userMeta", {});
  if (dataUser.length === 1) {
    dataUser = dataUser[0];
  }
  const team = await API.get(
    "UserMeta",
    "/userMeta/company/" + dataUser["companies"][0],
    {}
  );

  for (let i = 0; i < team.length; i++) {
    if (team[i]["photo"] != "" && team[i]["photo"] != null) {
      const photo = await Storage.get(team[i]["photo"], {
        level: "public",
      });

      team[i]["photo"] = photo;
    }
  }

  return team;
}

export function useTeam() {
  const { data, isLoading, refetch } = useQuery([cacheKey], getTeam);

  return {
    data,
    isLoading,
  };
}
