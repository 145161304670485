import { useEffect } from "react";
import toastr from "toastr";
import API, { graphqlOperation, GraphQLQuery } from "@aws-amplify/api";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useSelector, useDispatch } from "react-redux";

import { useProfile } from "../profile";
import { State } from "store/templates/types";
import {
  CreateTemplateCategoryConnectionMutation,
  CreateTemplateInput,
  CreateTemplateMutation,
  DeleteTemplateCategoryConnectionMutation,
  OwnershipLevel,
  Template,
  UpdateTemplateMutation,
} from "API";
import { fetchTemplates } from "store/templates/actions";

import {
  deleteTemplateFn,
  getTemplate,
  getTemplateCategoryConnections,
  publishTemplate as publishTemplateFn,
} from "./effects";
import {
  updateTemplate,
  createTemplate as createTemplateMutation,
  createTemplateCategoryConnection,
  deleteTemplateCategoryConnection,
} from "graphql/mutations";
import { getAccessToken } from "helpers/graphql";

export function useTemplates() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { myId, createOwnerId } = useProfile();
  const { byOwner } = useSelector((state: any) => state.Templates as State);
  const createTemplate = useMutation({
    mutationKey: ["createTemplate"],
    async mutationFn(input: CreateTemplateInput): Promise<Template> {
      const { data } = await API.graphql<GraphQLQuery<CreateTemplateMutation>>(
        graphqlOperation(
          createTemplateMutation,
          {
            input,
          },
          await getAccessToken()
        )
      );

      if (!data?.createTemplate) throw new Error("Failed to create template");

      return {
        ...data.createTemplate,
        categories: undefined,
      };
    },
    onSuccess() {
      queryClient.invalidateQueries(["templates"]);
      toastr.success("Template created");

      dispatch(fetchTemplates(createOwnerId(OwnershipLevel.COMPANY)));
      dispatch(fetchTemplates(createOwnerId(OwnershipLevel.FRANCHISEE)));
      dispatch(fetchTemplates(createOwnerId(OwnershipLevel.USER)));
    },
    onError(error) {
      console.error(error);
      toastr.error("Failed to create template");
    },
  });

  const deleteTemplate = useMutation({
    mutationKey: ["deleteTemplate"],
    mutationFn: deleteTemplateFn,
    onSuccess(data) {
      toastr.success("Template deleted");

      queryClient.invalidateQueries(["template", data, "categories"]);
      dispatch(fetchTemplates(createOwnerId(OwnershipLevel.COMPANY)));
      dispatch(fetchTemplates(createOwnerId(OwnershipLevel.FRANCHISEE)));
      dispatch(fetchTemplates(createOwnerId(OwnershipLevel.USER)));
    },
    onError(error) {
      console.error(error);
      toastr.error("Failed to delete template");
    },
  });

  useEffect(() => {
    if (myId) {
      dispatch(fetchTemplates(createOwnerId(OwnershipLevel.COMPANY)));
      dispatch(fetchTemplates(createOwnerId(OwnershipLevel.FRANCHISEE)));
      dispatch(fetchTemplates(createOwnerId(OwnershipLevel.USER)));
    }
  }, [dispatch, myId, createOwnerId]);

  return { byOwner, createTemplate, deleteTemplate };
}

export function useTemplate(id: string) {
  const queryClient = useQueryClient();
  const template = useQuery(["template", id], getTemplate, {
    enabled: !!id,
  });
  const categories = useQuery(
    ["template", id, "categories"],
    getTemplateCategoryConnections,
    {
      enabled: !!id,
    }
  );

  const addCategory = useMutation({
    mutationKey: ["addCategoryToTemplate", id],
    async mutationFn(input: { categoryId: string; categoryOwnerId: string }) {
      if (!template.data) throw new Error("Template has not loaded yet");

      const [{ data }] = await Promise.all([
        await API.graphql<GraphQLQuery<UpdateTemplateMutation>>(
          graphqlOperation(
            updateTemplate,
            {
              input: {
                id: template.data.id,
                ownerId: template.data.ownerId,
                categoryOrder: [
                  ...(template.data.categoryOrder ?? []),
                  input.categoryId,
                ],
              },
            },
            await getAccessToken()
          )
        ),
        await API.graphql<
          GraphQLQuery<CreateTemplateCategoryConnectionMutation>
        >(
          graphqlOperation(
            createTemplateCategoryConnection,
            {
              input: {
                templateId: template.data.id,
                templateOwnerId: template.data.ownerId,
                categoryId: input.categoryId,
                categoryOwnerId: input.categoryOwnerId,
              },
            },
            await getAccessToken()
          )
        ),
      ]);

      if (data?.updateTemplate) {
        queryClient.setQueryData(["template", id], {
          ...data.updateTemplate,
          categories: null,
        });
      }

      queryClient.invalidateQueries(["template", id]);
      toastr.success("Category added to template");
    },
  });

  const publishTemplate = useMutation({
    mutationKey: ["publishTemplate", id],
    async mutationFn() {
      if (!template.data) throw new Error("Template has not loaded yet");

      await publishTemplateFn({
        id: template.data.id,
        ownerId: template.data.ownerId,
      });
    },
    onSuccess() {
      queryClient.invalidateQueries(["template", id]);
      toastr.success("Template published");
    },
    onError(error) {
      console.log(error);
      toastr.error("Failed to publish template");
    },
  });

  const removeCategory = useMutation({
    mutationKey: ["removeCategoryFromTemplate", id],
    async mutationFn(input: { connectionId: string; categoryId: string }) {
      if (!template.data) throw new Error("Template has not loaded yet");

      const [{ data }] = await Promise.all([
        await API.graphql<GraphQLQuery<UpdateTemplateMutation>>(
          graphqlOperation(
            updateTemplate,
            {
              input: {
                id: template.data.id,
                ownerId: template.data.ownerId,
                categoryOrder: template.data.categoryOrder.filter(
                  id => id !== input.categoryId
                ),
              },
            },
            await getAccessToken()
          )
        ),
        await API.graphql<
          GraphQLQuery<DeleteTemplateCategoryConnectionMutation>
        >(
          graphqlOperation(
            deleteTemplateCategoryConnection,
            { input: { id: input.connectionId } },
            await getAccessToken()
          )
        ),
      ]);

      if (data?.updateTemplate) {
        queryClient.setQueryData(["template", id], {
          ...data.updateTemplate,
          categories: null,
        });
      }

      queryClient.invalidateQueries(["template", id]);
      toastr.success("Category removed from template");
    },
  });

  const reorderCategories = useMutation({
    mutationKey: ["reorderCategories", id],
    async mutationFn(input: { categoryOrder: string[] }) {
      if (!template.data) throw new Error("Template has not loaded yet");

      await API.graphql<GraphQLQuery<UpdateTemplateMutation>>(
        graphqlOperation(
          updateTemplate,
          {
            input: {
              id: template.data.id,
              ownerId: template.data.ownerId,
              categoryOrder: input.categoryOrder,
            },
          },
          await getAccessToken()
        )
      );
    },
    onSuccess() {
      queryClient.invalidateQueries(["template", id]);
    },
    onError(error) {
      console.log(error);
      toastr.error("Failed to reorder categories");
    },
  });

  return {
    template,
    categories,
    publishTemplate,
    addCategory,
    removeCategory,
    reorderCategories,
  };
}
