import WorkOrdersGrid from "components/Tables/WorkOrdersGrid";
import { useWorkOrders } from "hooks/workorders/workorders";
import React from "react";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import DatePicker from "./_myJobsDateSelector";

export default function JobList(props: any) {
  const { workOrders } = useWorkOrders(props.currentDate);

  return (
    <React.Fragment>
      <DatePicker
        onDateChanged={props.setCurrentDate}
        defaultValue={props.currentDate}
      ></DatePicker>
      {!workOrders.isSuccess || workOrders.data.length === 0 ? (
        <div className="alert alert-danger">
          <Trans i18nKey="no_work_orders_assigned_to_you">
            No work orders have been assigned to you for the day selected. To
            display all work orders, including those that are past or not yet
            available, go to{" "}
            <Link to="/work/orders">work orders and reports</Link> in the main
            menu.
          </Trans>
        </div>
      ) : (
        <WorkOrdersGrid dataSource={workOrders.data} allowPaging={false} />
      )}
    </React.Fragment>
  );
}
