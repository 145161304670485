import React, { useState } from "react";
import { Link } from "react-router-dom";

// Import menuDropdown
import LanguageDropdown from "components/Layout/Dropdowns/LanguageDropdown";
import NotificationDropdown from "components/Layout/Dropdowns/NotificationDropdown";
import ProfileMenu from "components/Layout/Dropdowns/ProfileMenu";
import logo from "assets/images/horizon-logo-no-text.svg";

//i18n
import { useTranslation } from "react-i18next";
import SettingsMenu from "./Dropdowns/SettingsMenu";

export default function Header(props) {
  const defaultState = {
    isSearch: false,
    open: false,
    position: "right",
    toggledMenu: false,
  };
  const [headerState, setHeaderState] = useState(defaultState);
  const { t } = useTranslation();

  function toggleMenu() {
    props.toggleMenuCallback();
    setHeaderState(s => ({ ...s, toggledMenu: !s.toggledMenu }));
  }

  function hamburgerIconClassName() {
    if (window.screen.width <= 998) {
      return "fa fa-fw fa-bars";
    } else {
      return headerState.toggledMenu
        ? "fas fa-chevron-right"
        : "fas fa-chevron-left";
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex search-block">
            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={toggleMenu}
              className="btn btn-sm px-3 header-item no-hover"
              id="vertical-menu-btn"
            >
              <i className={hamburgerIconClassName()}></i>
            </button>

            <form className="app-search">
              <div className="bar">
                <div className="line"></div>
              </div>
              <div className="position-relative d-flex flex-column justify-content-center h-100 w-100">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("search", "Search") + "..."}
                />
                <span className="bx bx-search-alt"></span>
              </div>
            </form>
          </div>
          <div className="d-flex">
            <div className="dropdown d-inline-block d-sm-none ms-2">
              <button
                onClick={() => {
                  setHeaderState(s => ({ ...s, isSearch: !s.isSearch }));
                }}
                type="button"
                className="btn header-item noti-icon d-flex align-items-center justify-content-center"
                id="page-header-search-dropdown"
              >
                <i className="mdi mdi-magnify"></i>
              </button>
              <div
                className={
                  headerState.isSearch
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <LanguageDropdown />
            <NotificationDropdown />
            <SettingsMenu />
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
}
