import PropTypes from "prop-types";
import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import PerPageListing from "components/Fields/dropdowns/PerPageListing";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Row, Col } from "reactstrap";

import { withTranslation } from "react-i18next";

// Get the number of records shown on the current page
const getRecordsShown = props => {
  const absTotal = props.page * props.sizePerPage;
  const oneLessTotal = absTotal - props.sizePerPage;
  let start = props.page === 1 ? 1 : oneLessTotal + 1;
  let end = 1;
  if (absTotal > props.totalSize) {
    end = props.totalSize;
  } else {
    end = absTotal;
  }

  if (absTotal > props.totalSize) {
    return props.totalSize - oneLessTotal;
  }

  return {
    start,
    end,
  };
};

class DataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRows: [],
    };
  }

  async updateSelection(state, isSelected, mainState) {
    const id = state.id;
    if (isSelected) {
      const found = mainState.state.selectedRows.find(row => row.id === id);
      if (!found) {
        await mainState.setState({
          ...mainState.state,
          selectedRows: [...mainState.state.selectedRows, state],
        });
      }
    } else {
      await mainState.setState({
        ...mainState.state,
        selectedRows: mainState.state.selectedRows.filter(row => row.id !== id),
      });
    }
    if (mainState.props.onSelectChange)
      mainState.props.onSelectChange(mainState.state.selectedRows);
    return true;
  }

  render() {
    const sizePerPageRenderer = ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <PerPageListing
        options={
          this.props.sizePerPageOptions
            ? this.props.sizePerPageOptions
            : options
        }
        currSizePerPage={currSizePerPage}
        onChange={onSizePerPageChange}
      />
    );

    //pagination customization
    const pageOptions = {
      sizePerPage: 10,
      totalSize: this.props.data.length,
      custom: true,
      sizePerPageRenderer,
      ...(this.props.pageOptions ? this.props.pageOptions : {}),
    };

    const defaultSorted = this.props.defaultSorted
      ? this.props.defaultSorted
      : [
          {
            dataField: "name",
            order: "asc",
          },
        ];

    const { SearchBar } = Search;

    return (
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField="id"
        columns={this.props.columns}
        data={this.props.data}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="id"
            columns={this.props.columns}
            data={this.props.data}
            search
          >
            {toolkitProps => (
              <React.Fragment>
                <Row className="mb-2 justify-content-between">
                  <Col md="8" className="d-flex align-items-center">
                    {this.props.actions}
                  </Col>
                  {this.props.search ? (
                    <Col
                      md="4"
                      className="d-flex justify-content-end align-items-center"
                    >
                      <div className="search-box d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
                <BootstrapTable
                  keyField={"id"}
                  bordered={false}
                  striped={false}
                  defaultSorted={defaultSorted}
                  hover={true}
                  rowEvents={this.props.rowEvents}
                  classes={"table align-middle table-responsive mb-0"}
                  headerWrapperClasses={"thead-light"}
                  {...toolkitProps.baseProps}
                  {...paginationTableProps}
                  data={this.props.data}
                />
                <Row className="align-items-md-center mt-30 inner-custom-pagination">
                  {this.props.showRecordTotals ? (
                    <Col className="total-records">
                      <span>
                        {this.props.t(
                          "pagination_records_total",
                          "showing {{start}} - {{end}} of {{numTotal}} total",
                          {
                            ...getRecordsShown(paginationProps),
                            numTotal: paginationProps.totalSize,
                          }
                        )}
                      </span>
                    </Col>
                  ) : (
                    ""
                  )}
                  <Col className=" d-flex justify-content-end">
                    {this.props.recordsPerPage ? (
                      <div className="size-per-page">
                        <span>Rows per page:</span>
                        <SizePerPageDropdownStandalone {...paginationProps} />
                      </div>
                    ) : (
                      ""
                    )}
                    {paginationProps.sizePerPage / paginationProps.totalSize <
                    1 ? (
                      <div className="text-md-right ml-4">
                        <PaginationListStandalone
                          {...paginationProps}
                          className="btn-group-small"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
    );
  }
}

DataTable.propTypes = {
  actions: PropTypes.any,
  data: PropTypes.array,
  columns: PropTypes.array,
  pageOptions: PropTypes.object,
  defaultSorted: PropTypes.array,
  sizePerPageOptions: PropTypes.array,
  search: PropTypes.bool,
  recordsPerPage: PropTypes.bool,
  showRecordTotals: PropTypes.bool,
  t: PropTypes.func,
  onSelectChange: PropTypes.func,
  rowEvents: PropTypes.object,
};

export default withTranslation()(DataTable);
