import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Collapse } from "reactstrap";
import LicensesForm from "../../../My/Settings/components/forms/_formLicenses";

export default function SectionLicense() {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  function toggle() {
    setIsOpen(isOpen => !isOpen);
  }

  return (
    <div className="accordion card mb-0 pb-0">
      <div className={"accordion-item " + (isOpen ? "active" : "")}>
        <h3
          className="accordion-header"
          id="licenses-certificates"
          onClick={() => toggle()}
        >
          {t(
            "invite_member_licenses_accordion_title",
            "Licenses / Certificates"
          )}
        </h3>

        <Collapse isOpen={isOpen} className="accordion-collapse">
          <div className="accordion-body" style={{ backgroundColor: "white" }}>
            <LicensesForm />
          </div>
        </Collapse>
      </div>
    </div>
  );
}
