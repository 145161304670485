/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCategory = /* GraphQL */ `
  query GetCategory($ownerId: String!, $id: ID!) {
    getCategory(ownerId: $ownerId, id: $id) {
      ownerId
      id
      name
      subcategoryOrder
      owner {
        level
        id
      }
      templates {
        items {
          templateOwnerId
          templateId
          categoryOwnerId
          categoryId
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      subcategories {
        items {
          id
          categoryId
          categoryOwnerId
          name
          notes
          itemOrder
          parentId
          subcategoryOrder
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCategories = /* GraphQL */ `
  query ListCategories(
    $ownerId: String
    $id: ModelIDKeyConditionInput
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCategories(
      ownerId: $ownerId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        ownerId
        id
        name
        subcategoryOrder
        owner {
          level
          id
        }
        templates {
          nextToken
        }
        subcategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const categoriesById = /* GraphQL */ `
  query CategoriesById(
    $id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    categoriesById(
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ownerId
        id
        name
        subcategoryOrder
        owner {
          level
          id
        }
        templates {
          nextToken
        }
        subcategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDiscount = /* GraphQL */ `
  query GetDiscount($ownerId: String!, $id: ID!) {
    getDiscount(ownerId: $ownerId, id: $id) {
      id
      ownerId
      name
      description
      type
      appliesTo
      option
      value
      minPurchase
      minPurchaseAmount
      limit
      onePerCustomer
      fixedLimit
      fixedLimitAmount
      startDate
      endDate
      owner {
        level
        id
      }
      createdAt
      updatedAt
    }
  }
`;
export const listDiscounts = /* GraphQL */ `
  query ListDiscounts(
    $ownerId: String
    $id: ModelIDKeyConditionInput
    $filter: ModelDiscountFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDiscounts(
      ownerId: $ownerId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        ownerId
        name
        description
        type
        appliesTo
        option
        value
        minPurchase
        minPurchaseAmount
        limit
        onePerCustomer
        fixedLimit
        fixedLimitAmount
        startDate
        endDate
        owner {
          level
          id
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const discountById = /* GraphQL */ `
  query DiscountById(
    $id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDiscountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    discountById(
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        name
        description
        type
        appliesTo
        option
        value
        minPurchase
        minPurchaseAmount
        limit
        onePerCustomer
        fixedLimit
        fixedLimitAmount
        startDate
        endDate
        owner {
          level
          id
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInspection = /* GraphQL */ `
  query GetInspection($ownerId: String!, $id: ID!) {
    getInspection(ownerId: $ownerId, id: $id) {
      ownerId
      id
      workOrder
      owner {
        level
        id
      }
      createdAt
      updatedAt
    }
  }
`;
export const listInspections = /* GraphQL */ `
  query ListInspections(
    $ownerId: String
    $id: ModelIDKeyConditionInput
    $filter: ModelInspectionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInspections(
      ownerId: $ownerId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        ownerId
        id
        workOrder
        owner {
          level
          id
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getItem = /* GraphQL */ `
  query GetItem($id: ID!) {
    getItem(id: $id) {
      id
      subcategoryId
      name
      subcategory {
        id
        categoryId
        categoryOwnerId
        name
        notes
        itemOrder
        parentId
        subcategoryOrder
        parent {
          id
          categoryId
          categoryOwnerId
          name
          notes
          itemOrder
          parentId
          subcategoryOrder
          createdAt
          updatedAt
        }
        children {
          nextToken
        }
        category {
          ownerId
          id
          name
          subcategoryOrder
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listItems = /* GraphQL */ `
  query ListItems(
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        subcategoryId
        name
        subcategory {
          id
          categoryId
          categoryOwnerId
          name
          notes
          itemOrder
          parentId
          subcategoryOrder
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listItemsBySubcategoryId = /* GraphQL */ `
  query ListItemsBySubcategoryId(
    $subcategoryId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItemsBySubcategoryId(
      subcategoryId: $subcategoryId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        subcategoryId
        name
        subcategory {
          id
          categoryId
          categoryOwnerId
          name
          notes
          itemOrder
          parentId
          subcategoryOrder
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($ownerId: String!, $id: ID!) {
    getProduct(ownerId: $ownerId, id: $id) {
      id
      ownerId
      name
      description
      category
      size
      duration
      price
      owner {
        level
        id
      }
      taxExempt
      visibility
      createdAt
      updatedAt
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $ownerId: String
    $id: ModelIDKeyConditionInput
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProducts(
      ownerId: $ownerId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        ownerId
        name
        description
        category
        size
        duration
        price
        owner {
          level
          id
        }
        taxExempt
        visibility
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const productById = /* GraphQL */ `
  query ProductById(
    $id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productById(
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        name
        description
        category
        size
        duration
        price
        owner {
          level
          id
        }
        taxExempt
        visibility
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReport = /* GraphQL */ `
  query GetReport($ownerId: String!, $id: ID!) {
    getReport(ownerId: $ownerId, id: $id) {
      id
      ownerId
      workOrderId
      owner {
        level
        id
      }
      createdAt
      updatedAt
    }
  }
`;
export const listReports = /* GraphQL */ `
  query ListReports(
    $ownerId: String
    $id: ModelIDKeyConditionInput
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listReports(
      ownerId: $ownerId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        ownerId
        workOrderId
        owner {
          level
          id
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const reportById = /* GraphQL */ `
  query ReportById(
    $id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportById(
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerId
        workOrderId
        owner {
          level
          id
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSubcategory = /* GraphQL */ `
  query GetSubcategory($id: ID!) {
    getSubcategory(id: $id) {
      id
      categoryId
      categoryOwnerId
      name
      notes
      itemOrder
      parentId
      subcategoryOrder
      parent {
        id
        categoryId
        categoryOwnerId
        name
        notes
        itemOrder
        parentId
        subcategoryOrder
        parent {
          id
          categoryId
          categoryOwnerId
          name
          notes
          itemOrder
          parentId
          subcategoryOrder
          createdAt
          updatedAt
        }
        children {
          nextToken
        }
        category {
          ownerId
          id
          name
          subcategoryOrder
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        createdAt
        updatedAt
      }
      children {
        items {
          id
          categoryId
          categoryOwnerId
          name
          notes
          itemOrder
          parentId
          subcategoryOrder
          createdAt
          updatedAt
        }
        nextToken
      }
      category {
        ownerId
        id
        name
        subcategoryOrder
        owner {
          level
          id
        }
        templates {
          nextToken
        }
        subcategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      items {
        items {
          id
          subcategoryId
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSubcategories = /* GraphQL */ `
  query ListSubcategories(
    $filter: ModelSubcategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubcategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        categoryId
        categoryOwnerId
        name
        notes
        itemOrder
        parentId
        subcategoryOrder
        parent {
          id
          categoryId
          categoryOwnerId
          name
          notes
          itemOrder
          parentId
          subcategoryOrder
          createdAt
          updatedAt
        }
        children {
          nextToken
        }
        category {
          ownerId
          id
          name
          subcategoryOrder
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listSubcategoriesByCategoryId = /* GraphQL */ `
  query ListSubcategoriesByCategoryId(
    $categoryId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSubcategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubcategoriesByCategoryId(
      categoryId: $categoryId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        categoryId
        categoryOwnerId
        name
        notes
        itemOrder
        parentId
        subcategoryOrder
        parent {
          id
          categoryId
          categoryOwnerId
          name
          notes
          itemOrder
          parentId
          subcategoryOrder
          createdAt
          updatedAt
        }
        children {
          nextToken
        }
        category {
          ownerId
          id
          name
          subcategoryOrder
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listSubcategoriesByParentId = /* GraphQL */ `
  query ListSubcategoriesByParentId(
    $parentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSubcategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubcategoriesByParentId(
      parentId: $parentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        categoryId
        categoryOwnerId
        name
        notes
        itemOrder
        parentId
        subcategoryOrder
        parent {
          id
          categoryId
          categoryOwnerId
          name
          notes
          itemOrder
          parentId
          subcategoryOrder
          createdAt
          updatedAt
        }
        children {
          nextToken
        }
        category {
          ownerId
          id
          name
          subcategoryOrder
          createdAt
          updatedAt
        }
        items {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTemplate = /* GraphQL */ `
  query GetTemplate($ownerId: String!, $id: ID!) {
    getTemplate(ownerId: $ownerId, id: $id) {
      ownerId
      id
      name
      categoryOrder
      owner {
        level
        id
      }
      categories {
        items {
          templateOwnerId
          templateId
          categoryOwnerId
          categoryId
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTemplates = /* GraphQL */ `
  query ListTemplates(
    $ownerId: String
    $id: ModelIDKeyConditionInput
    $filter: ModelTemplateFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTemplates(
      ownerId: $ownerId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        ownerId
        id
        name
        categoryOrder
        owner {
          level
          id
        }
        categories {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const templatesById = /* GraphQL */ `
  query TemplatesById(
    $id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    templatesById(
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ownerId
        id
        name
        categoryOrder
        owner {
          level
          id
        }
        categories {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTemplateCategoryConnection = /* GraphQL */ `
  query GetTemplateCategoryConnection($id: ID!) {
    getTemplateCategoryConnection(id: $id) {
      templateOwnerId
      templateId
      categoryOwnerId
      categoryId
      template {
        ownerId
        id
        name
        categoryOrder
        owner {
          level
          id
        }
        categories {
          nextToken
        }
        createdAt
        updatedAt
      }
      category {
        ownerId
        id
        name
        subcategoryOrder
        owner {
          level
          id
        }
        templates {
          nextToken
        }
        subcategories {
          nextToken
        }
        createdAt
        updatedAt
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const listTemplateCategoryConnections = /* GraphQL */ `
  query ListTemplateCategoryConnections(
    $filter: ModelTemplateCategoryConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTemplateCategoryConnections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        templateOwnerId
        templateId
        categoryOwnerId
        categoryId
        template {
          ownerId
          id
          name
          categoryOrder
          createdAt
          updatedAt
        }
        category {
          ownerId
          id
          name
          subcategoryOrder
          createdAt
          updatedAt
        }
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCategoriesByTemplateId = /* GraphQL */ `
  query ListCategoriesByTemplateId(
    $templateId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTemplateCategoryConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategoriesByTemplateId(
      templateId: $templateId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        templateOwnerId
        templateId
        categoryOwnerId
        categoryId
        template {
          ownerId
          id
          name
          categoryOrder
          createdAt
          updatedAt
        }
        category {
          ownerId
          id
          name
          subcategoryOrder
          createdAt
          updatedAt
        }
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listTemplatesByCategoryId = /* GraphQL */ `
  query ListTemplatesByCategoryId(
    $categoryId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTemplateCategoryConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTemplatesByCategoryId(
      categoryId: $categoryId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        templateOwnerId
        templateId
        categoryOwnerId
        categoryId
        template {
          ownerId
          id
          name
          categoryOrder
          createdAt
          updatedAt
        }
        category {
          ownerId
          id
          name
          subcategoryOrder
          createdAt
          updatedAt
        }
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWorkflow = /* GraphQL */ `
  query GetWorkflow($ownerId: String!, $id: ID!) {
    getWorkflow(ownerId: $ownerId, id: $id) {
      id
      ownerId
      name
      owner {
        level
        id
      }
      createdAt
      updatedAt
    }
  }
`;
export const listWorkflows = /* GraphQL */ `
  query ListWorkflows(
    $ownerId: String
    $id: ModelIDKeyConditionInput
    $filter: ModelWorkflowFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWorkflows(
      ownerId: $ownerId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        ownerId
        name
        owner {
          level
          id
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
