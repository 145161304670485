const Statuses: Status = {
  disabled: {
    color: "danger",
  },
  available: {
    color: "success",
  },
  invited: {
    color: "warning",
  },
  inspection_in_progress: {
    color: "info",
  },
  on_vacation: {
    color: "dark",
  },
  booked: {
    color: "success",
  },
  cancelled: {
    color: "danger",
  },
  completed: {
    color: "secondary",
  },
};

export default Statuses;

interface Status {
  [key: string]: {
    color: string;
  };
}
