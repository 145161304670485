import {
  InputGroup,
  Input,
  InputGroupText,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Spinner,
} from "reactstrap";
import { Form, Formik } from "formik";
import { useState } from "react";

import Icon from "components/Common/Icons";
import { useCategories } from "hooks/categories";
import { useTemplate } from "hooks/templates";
import { useTranslation } from "react-i18next";

interface CategorySearchProps {
  templateId: string;
}

export function CategorySearch(props: CategorySearchProps) {
  const { t } = useTranslation("Categories");
  const [query, setQuery] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const { template, categories, addCategory } = useTemplate(props.templateId);
  const { all } = useCategories();

  const filteredCategories = all.filter(category => {
    if (query) {
      if (categories.data?.categories.find(c => c.id === category.id)) {
        return false;
      } else if (category.name.toLowerCase().includes(query.toLowerCase())) {
        if (!categories.data?.categories.find(c => c.id === category.id)) {
          return true;
        }
      } else {
        return false;
      }
    }
    return true;
  });

  return (
    <Formik
      initialValues={{ categoryId: "", categoryOwnerId: "" }}
      onSubmit={async (values, helpers) => {
        setShowDropdown(false);
        if (template.data) {
          await addCategory.mutateAsync({
            categoryId: values.categoryId,
            categoryOwnerId: values.categoryOwnerId,
          });

          helpers.resetForm();
          setQuery("");
        }
      }}
    >
      {bag => (
        <Form className="position-relative" autoComplete="off">
          <Dropdown
            isOpen={showDropdown}
            toggle={() => setShowDropdown(v => !!v)}
          >
            <InputGroup>
              <InputGroupText>
                <Icon name="search" />
              </InputGroupText>
              <Input
                type="text"
                placeholder="Search for categories..."
                name="query"
                value={query}
                onChange={e => {
                  if (e.currentTarget.value.trim()) {
                    setShowDropdown(true);
                  } else {
                    setShowDropdown(false);
                  }

                  setQuery(e.currentTarget.value);
                }}
              />
              <Button
                disabled={!bag.touched.categoryId || addCategory.isLoading}
                type="submit"
              >
                {addCategory.isLoading ? (
                  <Spinner size="sm" />
                ) : (
                  t("Add Category")
                )}
              </Button>
            </InputGroup>
            <DropdownMenu>
              {filteredCategories.length > 0 ? (
                filteredCategories.map(category => (
                  <DropdownItem
                    key={category.id}
                    onClick={() => {
                      setQuery(category.name);
                      bag.setFieldTouched("categoryId", true);
                      bag.setValues({
                        categoryId: category.id,
                        categoryOwnerId: category.ownerId,
                      });
                      setShowDropdown(false);
                    }}
                  >
                    {category.name}
                  </DropdownItem>
                ))
              ) : (
                <DropdownItem disabled>No categories found</DropdownItem>
              )}
            </DropdownMenu>
          </Dropdown>
        </Form>
      )}
    </Formik>
  );
}
