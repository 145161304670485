import { UseQueryResult } from "@tanstack/react-query";
import StandardPage from "components/Common/StandardPage";
import { useWorkOrder, WorkOrder } from "hooks/workorders/workorder";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";

export default function ReportPage(props: any) {
  const { t } = useTranslation("report");
  const id = props.match.params.id;

  const { workOrder } = useWorkOrder(id);
  const address = buildAddress(workOrder?.data?.inspection.address);

  console.log(workOrder?.data);

  function buildAddress(address: any) {
    if (!address) return "";
    let str = "";
    if (address.address2 && address.address2 !== "") {
      str += address.address2 + " - ";
    }

    str += address.address1 + ", " + address.city + ", " + address.region;

    return str;
  }

  return (
    <StandardPage
      pageid="work_orders"
      pagetitle={t("work_orders_page_title", address)}
      breadcrumbItems={[
        {
          uri: "/work",
          text: t("work", "Work"),
        },
        {
          uri: "/work/orders",
          text: t("orders", "Orders"),
        },
        {
          uri: "/work/order/" + id,
          text: "#" + workOrder?.data?.reportNumber,
        },
        {
          uri: "/work/order/" + id + "/report",
          text: t("workorder_report", "Report"),
          active: true,
        },
      ]}
      topRight={<RightSide workOrder={workOrder || null} />}
      breadcrumbIcon="bx bx-home-circle"
    >
      <div className="card">
        <div className="card-body">
          <div className="report-header">
            <h1>{t("inspection_report_title", "Inspection report")}</h1>
          </div>
        </div>
      </div>
    </StandardPage>
  );
}

function RightSide(props: { workOrder: UseQueryResult<WorkOrder | null> }) {
  const { t } = useTranslation();
  const history = useHistory();

  const date = moment(props.workOrder?.data?.inspection.inspectionDate).format(
    "MMMM D, Y"
  );
  const id = props.workOrder?.data?.id;

  return (
    <div className="d-flex flex-column align-items-end">
      <Button
        color="outline-blue"
        className="mb-1"
        size="sm"
        onClick={() => history.push("/work/order/" + id)}
      >
        {t("back_to_work_order", "Back to work order")}
      </Button>
      <div className="inspection-date">
        {t("inspection_date", "Inspection date")}: {date}
      </div>
    </div>
  );
}
