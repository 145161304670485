import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import classnames from "classnames";

class Breadcrumbs extends Component {
  render() {
    return (
      <React.Fragment>
        <Row className="top-bar">
          <Col xs="12" md="6" className="top-bar-container">
            <div className="page-title-box">
              <i className={this.props.icon} />
              <h1 className="mb-1">{this.props.title}</h1>
              <div className="page-title-right">
                <nav className="">
                  <ol className="breadcrumb m-0">
                    {this.props.breadcrumbItems.map((item, i) => {
                      return (
                        <li
                          key={i}
                          className={classnames({
                            "breadcrumb-item": true,
                            active: item.active === true,
                          })}
                          aria-current={item.active ? "page" : false}
                        >
                          <Link to={item.uri}>{item.text}</Link>
                        </li>
                      );
                    })}
                  </ol>
                </nav>
              </div>
            </div>
          </Col>
          <Col xs="12" md="6">
            <div className="text-md-end">{this.props.topRight}</div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

Breadcrumbs.propTypes = {
  breadcrumbItems: PropTypes.array,
  title: PropTypes.string,
  icon: PropTypes.string,
};

export default Breadcrumbs;
