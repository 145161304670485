import React, { useCallback, useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import DataGrid from "components/Tables/DataGrid";
import {
  Column,
  Editing,
  Button as DataGridButton,
  Texts,
  RequiredRule,
  NumericRule,
  CustomRule,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import * as actions from "store/actions";
import { getCSSClass } from "components/Common/Icons";
import { Col, Row, Button, TabPane } from "reactstrap";
import CompanySettingsSchema from "../schemas/_companySettings";
import { useFormik } from "formik";

export default function TravelFees(props) {
  const company = useSelector(state => state.CompanySettings);
  const { t } = useTranslation();
  const schema = new CompanySettingsSchema({ t: t });
  const history = useHistory();

  const [measurementSystem, setMeasurementSystem] = useState(1);

  const handleFormSubmit = () => {
    let { photo, error, success, ...newValues } = company;
    let saveData = {
      ...newValues,
      ...formik.values,
    };

    dispatch(actions.editCompanySettings(saveData));
  };

  const handleReset = useCallback(() => {
    history.goBack();
  }, [history]);

  const formik = useFormik({
    initialValues: {
      travelFees: {
        perMileFee: 0.0,
        ranges: [],
      },
    },
    enableReinitialize: true,
    validationSchema: schema.travelFeesValidation(t),
    onSubmit: handleFormSubmit,
    handleReset: handleReset,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.hydrateCompanySettings());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [travelFees, setTravelFees] = useState([]);
  const [hydrated, setHydrated] = useState(false);

  const onInitNewRow = e => {
    // Find empty ranges
    if (travelFees.length === 0) {
      e.data.minimum = 0;
      e.data.maximum = 10;
      e.data.fee = 1.0;
    } else {
      let max = undefined;
      let min = undefined;
      let lastMax = -1;
      let addToEnd = true;

      let newTravelFees = travelFees.sort((a, b) => a.minimum - b.minimum);

      newTravelFees.forEach(fee => {
        if (addToEnd && fee.minimum - lastMax > 1) {
          addToEnd = false;
          min = lastMax + 1;
          max = fee.minimum - 1;
        }
        if (addToEnd && (max === undefined || fee.maximum > max))
          max = fee.maximum + 1;
        lastMax = fee.maximum;
      });
      if (addToEnd) {
        e.data.minimum = max;
        e.data.maximum = max + 9;
        e.data.fee = 1.0;
      } else {
        e.data.minimum = min;
        e.data.maximum = max;
        e.data.fee = 1.0;
      }
    }
  };

  const compareRange = options => {
    return options.data.minimum < options.data.maximum;
  };

  useEffect(() => {
    if (!hydrated && company.id) hydrateState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  const hydrateState = async () => {
    setTravelFees(company?.travelFees?.ranges ? company.travelFees.ranges : []);

    formik.setValues({
      travelFees: {
        perMileFee:
          company.travelFees?.perMileFee !== undefined
            ? company.travelFees.perMileFee
            : 0,
      },
    });
    setHydrated(true);
  };

  const travelFeeStore = new CustomStore({
    key: "id",
    load: loadOptions => travelFees,
    insert: values => insertData(values),
    update: (key, values) => updateData(key, values),
    remove: key => removeData(key),
  });

  const insertData = values => {
    let newTravelFees;
    newTravelFees = travelFees;
    values.id = uuid();
    newTravelFees.push(values);
    saveGrid(newTravelFees);
  };

  const saveGrid = values => {
    let newTravelFees = values.sort((a, b) => a.minimum - b.minimum);
    setTravelFees(newTravelFees);
    formik.setFieldValue("travelFees.ranges", newTravelFees);
  };

  const updateData = (key, values) => {
    let newTravelFees;
    newTravelFees = travelFees;
    values.id = key;

    let index = travelFees
      .map(function (travelFee) {
        return travelFee.id;
      })
      .indexOf(key);
    newTravelFees[index] = { ...newTravelFees[index], ...values };
    saveGrid(newTravelFees);
  };

  const removeData = key => {
    let newTravelFees = travelFees.filter(function (travelFee) {
      return travelFee.id !== key;
    });
    saveGrid(newTravelFees);
  };

  const getDataGridError = () => {
    if (formik.errors?.travelFees?.ranges !== undefined) {
      return formik.errors.travelFees.ranges;
    } else {
      return "";
    }
  };

  function updateMeasurementSystem(e) {
    setMeasurementSystem(e.target.value);
  }

  return (
    <TabPane tabId="travel_fees">
      <h2>
        {t("travel_fees", "Travel fees")}
        <p className="card-title-desc">
          {t(
            "travel_fees_subtitle",
            "Travel fee tiers allow you to charge an amount based on the distance travelled to an inspection."
          )}
        </p>
      </h2>
      <h3 className="mb-0 d-flex justify-content-between w-100 align-items-center">
        {t("flat_rate_fees", "Flat rate fees")}
        <div className="btn-group d-flex align-items-center mb-0" role="group">
          <span className="mr-3 font-weight-normal">Measurement system:</span>
          <input
            id="metricYes"
            type="radio"
            className="btn-check"
            name="settings.metric"
            value="1"
            checked={measurementSystem == 1}
            onChange={updateMeasurementSystem}
          />
          <label
            className="btn btn-sm btn-outline-secondary success mb-0"
            htmlFor="metricYes"
          >
            km
          </label>
          <input
            id="metricNo"
            type="radio"
            className="btn-check"
            name="settings.metric"
            value="0"
            checked={measurementSystem == 0}
            onChange={updateMeasurementSystem}
          />
          <label
            className="btn btn-sm btn-outline-secondary danger mb-0"
            htmlFor="metricNo"
          >
            mi
          </label>
        </div>
      </h3>
      <p>
        {t(
          "travel_fees_flat_rate_description",
          "Apply a flat rate based on distance travelled"
        )}
      </p>
      <DataGrid
        dataSource={travelFeeStore}
        onInitNewRow={onInitNewRow}
        cacheEnabled={false}
        name="travelFees.ranges"
        className={
          "form-control p-0 border-0 " +
          (getDataGridError() !== "" ? "is-invalid " : "")
        }
      >
        <Editing
          mode="cell"
          allowUpdating={true}
          allowAdding={true}
          allowDeleting={true}
          texts={{
            addRow: t("travel_fees_add_new_tier", "Add a travel fee tier"),
          }}
        >
          <Texts
            confirmDeleteMessage={t(
              "travel_fees_confirm_delete",
              "Are you sure you want to delete this travel fee setting?"
            )}
          />
        </Editing>
        <Column
          dataField="minimum"
          caption={t("travel_fees_minimum", "From")}
          alignment="left"
          cellRender={data => {
            return (
              data.displayValue + " " + (measurementSystem == 1 ? "km" : "mi")
            );
          }}
        >
          <RequiredRule
            message={t(
              "travel_fee_minimum_required",
              "Please enter a from distance."
            )}
          />
          <NumericRule
            message={t(
              "travel_fee_minimum_numeric",
              "From distance must be a number."
            )}
          />
        </Column>
        <Column
          dataField="maximum"
          caption={t("travel_fees_maximum", "To")}
          alignment="left"
          cellRender={data => {
            return (
              "<= " +
              data.displayValue +
              " " +
              (measurementSystem == 1 ? "km" : "mi")
            );
          }}
        >
          <RequiredRule
            message={t(
              "travel_fee_maximum_required",
              "Please enter a to distance."
            )}
          />
          <NumericRule
            message={t(
              "travel_fee_maximum_numeric",
              "To distance must be a number."
            )}
          />
          <CustomRule
            message={t(
              "travel_fee_range_invalid",
              "The to distance must be higher than the from distance."
            )}
            validationCallback={compareRange}
          />
        </Column>
        <Column
          dataField="fee"
          caption={t("fee", "Fee")}
          format={"$##0.00"}
          alignment="left"
        >
          <RequiredRule
            message={t("travel_fee_fee_required", "Please enter a fee.")}
          />
          <NumericRule
            message={t(
              "travel_fee_minimum_numeric",
              "Travel fee must be a number."
            )}
          />
        </Column>
        <Column
          type="buttons"
          caption={t("options", "Options")}
          hidingPriority={10}
        >
          <DataGridButton
            name="edit"
            icon={getCSSClass("edit")}
            hint={t("edit", "Edit")}
          />
          <DataGridButton
            name="delete"
            icon={getCSSClass("delete")}
            hint={t("delete", "Delete")}
          />
          <DataGridButton
            name="save"
            icon={getCSSClass("save")}
            hint={t("save", "Save")}
          />
        </Column>
      </DataGrid>
      <div className="invalid-feedback">{getDataGridError()}</div>
      <h3 className="mt-4 mb-2">
        {measurementSystem == 1
          ? t("per_km_fees", "Per kilometer fee")
          : t("per_mile_fees", "Per mile fee")}
      </h3>
      <p>
        {measurementSystem == 1
          ? t(
              "travel_fees_per_kilomenter_description",
              "Optionally, you can apply a flat per kilometer fee to the distance travelled for inspections."
            )
          : t(
              "travel_fees_per_mile_description",
              "Optionally, you can apply a flat per mile fee to the distance travelled for inspections."
            )}
      </p>
      <Row>
        <Col>
          <input
            name="travelFees.perMileFee"
            type="text"
            value={formik.values.travelFees.perMileFee}
            onChange={formik.handleChange}
            className={
              "form-control " +
              (formik.errors?.travelFees?.perMileFee !== undefined &&
              formik.errors?.travelFees?.perMileFee !== ""
                ? "is-invalid "
                : "") +
              "mb-2"
            }
            style={{
              maxWidth: "100px",
            }}
          />
          <div className="invalid-feedback">
            {formik.errors?.travelFees?.perMileFee !== undefined
              ? formik.errors.travelFees.perMileFee
              : ""}
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="mt-5">
          <Button color="primary" type="submit" onClick={handleFormSubmit}>
            {t("save_changes", "Save Changes")}
          </Button>
          <Button
            type="reset"
            color="outline-blue"
            onClick={handleReset}
            className="ms-2"
          >
            {t("cancel", "Cancel")}
          </Button>
        </Col>
      </Row>
    </TabPane>
  );
}
