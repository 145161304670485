import { QueryFunction } from "@tanstack/react-query";
import API, { GraphQLQuery, graphqlOperation } from "@aws-amplify/api";

import { getAccessToken } from "helpers/graphql";
import {
  Template,
  TemplatesByIdQuery,
  ListTemplateCategoryConnectionsQuery,
  Category,
  TemplateCategoryConnection,
  ListTemplateCategoryConnectionsQueryVariables,
  GetTemplateQueryVariables,
  GetTemplateQuery,
  CreateTemplateMutation,
  CreateTemplateMutationVariables,
  DeleteTemplateMutation,
  DeleteTemplateMutationVariables,
  UpdateTemplateCategoryConnectionMutation,
  CreateCategoryMutation,
  CreateCategoryInput,
  DeleteTemplateInput,
} from "API";
import {
  listTemplateCategoryConnections,
  templatesById,
} from "graphql/queries";
import {
  createCategory,
  createTemplate,
  deleteTemplate,
  deleteTemplateCategoryConnection,
  updateTemplateCategoryConnection,
} from "graphql/mutations";
import {
  createPromotedCategory,
  deleteCategoryFn,
  updatePromotedSubcategories,
} from "hooks/categories/effects";

export const getTemplate: QueryFunction<
  Template,
  ["template", string]
> = async ({ queryKey }) => {
  const id = queryKey[1];
  const res = await API.graphql<GraphQLQuery<TemplatesByIdQuery>>(
    graphqlOperation(
      templatesById,
      {
        id,
      },
      await getAccessToken()
    )
  );

  if (res.data?.templatesById?.items[0]) {
    return {
      ...res.data?.templatesById.items[0],
      categories: undefined,
    };
  } else {
    throw new Error("Template not found");
  }
};

export const getTemplateCategoryConnections: QueryFunction<
  {
    categories: Category[];
    connections: Map<string, TemplateCategoryConnection>;
  },
  ["template", string, "categories"]
> = async ({ queryKey }) => {
  const templateId = queryKey[1];
  const res = await API.graphql<
    GraphQLQuery<ListTemplateCategoryConnectionsQuery>
  >(
    graphqlOperation(
      listTemplateCategoryConnections,
      {
        filter: {
          templateId: {
            eq: templateId,
          },
        },
      } as ListTemplateCategoryConnectionsQueryVariables,
      await getAccessToken()
    )
  );

  const categories: Category[] = [];
  const connections: Map<string, TemplateCategoryConnection> = new Map();

  for (const connection of res.data?.listTemplateCategoryConnections?.items ??
    []) {
    if (connection) {
      categories.push(connection.category);
      connections.set(connection.category.id, connection);
    }
  }

  return { categories, connections };
};

export const publishTemplate = async (input: GetTemplateQueryVariables) => {
  if (input.ownerId.split("/").length !== 3) {
    throw new Error("Template is already published");
  }

  const newOwnerId = input.ownerId.split("/")[0];
  let rollbackFns = new Set<() => Promise<void>>();

  // Retrieve current template data
  const { data: oldTemplateData } = await API.graphql<
    GraphQLQuery<GetTemplateQuery>
  >(
    graphqlOperation(
      `query GetTemplate($ownerId: String!, $id: ID!) {
        getTemplate(ownerId: $ownerId, id: $id) {
          id
          name
          ownerId
          categoryOrder
          categories {
            items {
              id
              categoryId
              categoryOwnerId
              templateId
              templateOwnerId
            }
          }
        }
      }`,
      input,
      await getAccessToken()
    )
  );

  if (!oldTemplateData?.getTemplate) {
    throw new Error("Template not found");
  }

  const { categories, ...oldTemplate } = oldTemplateData.getTemplate;

  // Create template with new owner ID
  const { data } = await API.graphql<GraphQLQuery<CreateTemplateMutation>>(
    graphqlOperation(
      createTemplate,
      {
        input: {
          ...oldTemplate,
          ownerId: newOwnerId,
        },
      } as CreateTemplateMutationVariables,
      await getAccessToken()
    )
  );

  if (!data?.createTemplate) {
    throw new Error("Failed to publish template");
  }

  const template = data.createTemplate;

  rollbackFns.add(async () => {
    await API.graphql<GraphQLQuery<DeleteTemplateMutation>>(
      graphqlOperation(
        deleteTemplate,
        {
          input: {
            id: template.id,
            ownerId: template.ownerId,
          },
        } as DeleteTemplateMutationVariables,
        await getAccessToken()
      )
    );
  });

  // Publish referenced categories
  // Update category connections to point to new categories
  if (categories?.items) {
    try {
      for (const connection of categories.items) {
        if (!connection) continue;

        let categoryId = connection.categoryId;
        let categoryOwnerId = connection.categoryOwnerId;

        if (categoryOwnerId !== newOwnerId) {
          // Create promoted category
          const category = await createPromotedCategory(
            connection.categoryId,
            connection.categoryOwnerId,
            newOwnerId
          );

          rollbackFns.add(async () => {
            await deleteCategoryFn({
              id: category.id,
              ownerId: category.ownerId,
            });
          });

          // Update child subcategories to use new category
          rollbackFns = new Set([
            ...rollbackFns,
            ...(await updatePromotedSubcategories(
              category.id,
              categoryOwnerId,
              category.ownerId
            )),
          ]);

          // Remove old category
          await deleteCategoryFn({
            id: connection.categoryId,
            ownerId: connection.categoryOwnerId,
          });

          rollbackFns.add(async () => {
            await API.graphql<GraphQLQuery<CreateCategoryMutation>>(
              graphqlOperation(
                createCategory,
                {
                  input: {
                    id: connection.categoryId,
                    ownerId: connection.categoryOwnerId,
                    name: category.name,
                    subcategoryOrder: category.subcategoryOrder,
                  } as CreateCategoryInput,
                },
                await getAccessToken()
              )
            );
          });

          categoryId = category.id;
          categoryOwnerId = category.ownerId;
        }

        // Update connection to point to new category and template
        await API.graphql<
          GraphQLQuery<UpdateTemplateCategoryConnectionMutation>
        >(
          graphqlOperation(
            updateTemplateCategoryConnection,
            {
              input: {
                id: connection.id,
                templateId: template.id,
                templateOwnerId: template.ownerId,
                categoryId,
                categoryOwnerId,
              },
            },
            await getAccessToken()
          )
        );

        rollbackFns.add(async () => {
          await API.graphql<
            GraphQLQuery<UpdateTemplateCategoryConnectionMutation>
          >(
            graphqlOperation(
              updateTemplateCategoryConnection,
              {
                input: {
                  ...connection,
                },
              },
              await getAccessToken()
            )
          );
        });
      }
    } catch (error) {
      console.error(error);

      let rollbackError: Error = new Error(
        "Publish unsuccessful, all changes were rolled back"
      );

      for (const fn of rollbackFns) {
        try {
          await fn();
        } catch (error) {
          console.error(error);
          rollbackError = new Error(
            "Publish unsuccessful, some changes were not rolled back"
          );

          continue;
        }
      }

      throw rollbackError;
    }
  }

  await API.graphql<GraphQLQuery<DeleteTemplateMutation>>(
    graphqlOperation(
      deleteTemplate,
      {
        input: {
          id: oldTemplate.id,
          ownerId: oldTemplate.ownerId,
        },
      } as DeleteTemplateMutationVariables,
      await getAccessToken()
    )
  );

  return template;
};

const affectedRelationshipsQuery = /* GraphQL */ `
  query GetAffectedRelationships($ownerId: String!, $id: ID!) {
    getTemplate(ownerId: $ownerId, id: $id) {
      id
      categories {
        items {
          id
        }
      }
    }
  }
`;

export async function deleteTemplateFn(input: DeleteTemplateInput) {
  const { data } = await API.graphql<GraphQLQuery<GetTemplateQuery>>(
    graphqlOperation(
      affectedRelationshipsQuery,
      {
        ownerId: input.ownerId,
        id: input.id,
      },
      await getAccessToken()
    )
  );

  if (!data?.getTemplate) {
    throw new Error("Template not found");
  }

  if (data.getTemplate.categories?.items) {
    for (const conn of data.getTemplate.categories.items) {
      if (!conn) continue;

      await API.graphql(
        graphqlOperation(
          deleteTemplateCategoryConnection,
          {
            input: {
              id: conn.id,
            },
          },
          await getAccessToken()
        )
      );
    }
  }

  await API.graphql(
    graphqlOperation(
      deleteTemplate,
      {
        input,
      },
      await getAccessToken()
    )
  );

  return input.id;
}
