import React from "react";
import { Card, CardBody } from "reactstrap";
import UserAccessForm from "./forms/_userAccess";

export default function SectionUserAccess() {
  return (
    <React.Fragment>
      <Card className="">
        <CardBody className="pb-3">
          <UserAccessForm />
        </CardBody>
      </Card>
    </React.Fragment>
  );
}
