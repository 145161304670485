import { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import i18n from "i18n";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import languages from "locales/languages";

// Toasting
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { API, Auth, Hub } from "aws-amplify";

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/AppRoute";

// layouts
import VerticalLayout from "./components/Layout";
import NonAuthLayout from "./components/Layout/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";

// Import cognito configuration and helperspp
import { initCognitoBackend } from "helpers/cognito_helper";

initCognitoBackend();

const queryClient = new QueryClient();

function App() {
  const messageHub = useSelector(state => state.MessageHub);
  const [prevToast, setPrevToast] = useState("");

  // ON MOUNT
  useEffect(() => {
    // Check if user is already logged in
    if (localStorage.getItem("authUser")) {
      Auth.currentSession().then(
        () => {},
        error => {
          console.log(error);
          localStorage.removeItem("authUser");
        }
      );
    }

    // Ensure proper language selection
    checkLanguage();

    // Check for custom branding
    checkBranding();

    // Listen for login events
    Hub.listen("auth", async data => {
      const { payload } = data;
      const { event } = payload;

      if (event !== "signIn") return;
      localStorage.setItem("authUser", "true");
    });
  }, []);

  // ON UPDATE
  useEffect(() => {
    if (messageHub.toast && prevToast !== messageHub.message) {
      if (!messageHub.type) return;
      switch (messageHub.type) {
        case "error":
        case "danger":
          toastr.error(messageHub.message);
          break;
        case "success":
          toastr.success(messageHub.message, "", {
            positionClass: "toast-bottom-right",
          });
          break;
        default:
          break;
      }

      setPrevToast(messageHub.message);
    }
  });

  const checkLanguage = () => {
    const currentLanguage = localStorage.getItem("I18N_LANGUAGE");
    if (!Object.keys(languages).includes(currentLanguage)) {
      localStorage.setItem("I18N_LANGUAGE", "en-US");
      localStorage.setItem("i18nextLng", "en-US");
      i18n.changeLanguage("en-US");
    }
    document.documentElement.setAttribute("lang", currentLanguage);
  };

  function checkBranding() {
    if (window.location.href.indexOf("npi") !== -1) {
      document.querySelector("body").dataset.brand = "npi";
    }
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={VerticalLayout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact={route.exact}
              />
            ))}
          </Switch>
        </Router>
      </QueryClientProvider>
    </DndProvider>
  );
}

export default App;
