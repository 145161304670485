import React from "react";
import { Label } from "reactstrap";
import { Field, useFormikContext } from "formik";

const StandardDropdown = props => {
  const valueField = props.valueField ? props.valueField : "value";
  const labelField = props.labelField ? props.labelField : "label";
  const formik = useFormikContext();

  const showError = () => {
    if (formik.errors[props.name] !== "") {
      return (
        <div className="invalid-feedback">{formik.errors[props.name]}</div>
      );
    }
  };
  return (
    <React.Fragment>
      {props.label ? (
        <Label for={props.name} className="col-form-label">
          {props.label}
        </Label>
      ) : (
        ""
      )}
      <Field
        name={props.name}
        id={props.name}
        disabled={props.disabled ? true : false}
        as="select"
        className={
          "form-select " +
          props.className +
          (formik.errors[props.name] &&
          (formik.touched[props.name] || formik.submitCount > 0)
            ? " is-invalid"
            : "")
        }
        onChange={e => {
          formik.handleChange(e);
          if (props.onChange) {
            props.onChange(e);
          }
        }}
      >
        {props.options.map(option => (
          <option value={option[valueField]} key={option[valueField]}>
            {option[labelField]}
          </option>
        ))}
      </Field>
      {props.showErrors ? showError() : ""}
    </React.Fragment>
  );
};

export { StandardDropdown };
