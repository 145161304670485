import { Permission } from "./Permissions";

export const otherPermissions: Permission[] = [
  {
    id: "privilages_view_business_at_a_glance",
    label: "View business at a glance",
  },
  {
    id: "privilages_view_accounts_receivable_reports",
    label: "View accounts receivable reports",
  },
  {
    id: "privilages_access_all_inspectors_on_the_scheduler",
    label: "Access all inspectors on the scheduler",
  },
  {
    id: "privilages_access_other_users_work_orders_and_reports",
    label: "Access other users' work orders and reports",
  },
  {
    id: "privilages_create_and_modify_callbacks",
    label: "Create and modify callbacks",
  },
  {
    id: "privilages_user_can_auto_include_files_in_all_future_inspections",
    label: "User can auto-include files in all future inspections",
  },
  {
    id: "privilages_mark_this_user_as_a_type_non_inspector",
    label: "Mark this User as type 'non-inspector'",
  },
  {
    id: "privilages_hide_report_writing_from_this_year",
    label: "Hide report writing from this User",
  },
  {
    id: "privilages_show_email_bounse_back_feature_on_home_screen",
    label: "Show email bounce back feature on home screen",
  },
  {
    id: "privilages_customize_report_database_for_company",
    label: "Customize report database for company  ",
  },
];
