import { takeEvery, put, call, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
import { loginSuccess, logoutUserSuccess, apiError } from "./actions";

import {
  messageHubChange,
  messageHubReset,
  hydrateProfile,
} from "store/actions";
import { getCognitoBackend } from "helpers/cognito_helper";

const cognitoBackend = getCognitoBackend();

function* loginUser({ payload: { user, history } }) {
  try {
    yield put(messageHubReset());
    if (process.env.REACT_APP_DEFAULTAUTH === "cognito") {
      const response = yield call(
        cognitoBackend.loginUser,
        user.email,
        user.password
      );
      yield put(hydrateProfile());
      yield put(messageHubChange("Signed in successfully!", "success", true));
      yield put(loginSuccess(response));
    }
    history.push("/");
  } catch (error) {
    // yield put(apiError(error))
    console.log(error);
    yield put(messageHubChange("Incorrect username / password.", "danger"));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    yield put(messageHubReset());
    if (process.env.REACT_APP_DEFAULTAUTH === "cognito") {
      const response = yield call(cognitoBackend.logout);
      localStorage.removeItem("loaded");
      yield put(logoutUserSuccess(response));
    }
    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* socialLogin({ payload: { data, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "cognito") {
      const response = yield call(cognitoBackend.socialLogin, data);
      yield put(loginSuccess(response));
      history.push("/login");
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
