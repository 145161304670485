import { useJsApiLoader } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";

//https://www.npmjs.com/package/@react-google-maps/api

export function Distance({ startAddress, endAddress, system }) {
  let _distance = useDistance(startAddress, endAddress, system);
  if (_distance === undefined) return <React.Fragment></React.Fragment>;
  return (
    <React.Fragment>
      {_distance.duration.text}, {_distance.distance.text}
    </React.Fragment>
  );
}

export function useDistance(startAddress, endAddress, system) {
  const [distance, setDistance] = useState();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });
  useEffect(() => {
    if (isLoaded) {
      const google = window.google;
      let service = new google.maps.DistanceMatrixService();
      service.getDistanceMatrix(
        {
          origins: startAddresses,
          destinations: endAddresses,
          travelMode: "DRIVING",
          unitSystem:
            system === "metric"
              ? google.maps.UnitSystem.METRIC
              : google.maps.UnitSystem.IMPERIAL,
        },
        DistanceMatrixServiceCallBack
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  function DistanceMatrixServiceCallBack(response, status) {
    setDistance(response.rows[0].elements[0]);
  }

  let startAddresses = [startAddress];
  let endAddresses = [endAddress];

  return distance;
}

export function useGeoLocate(address) {
  const [latLng, setLatLng] = useState(undefined);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  useEffect(() => {
    if (isLoaded) {
      const google = window.google;
      let geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: address }, (results, status) => {
        // eslint-disable-next-line
        if (status == "OK") {
          setLatLng(results[0].geometry.location);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  return latLng;
}
