import { QueryFunction } from "@tanstack/react-query";
import API, { graphqlOperation, GraphQLQuery } from "@aws-amplify/api";

import {
  Item,
  Subcategory,
  GetSubcategoryQuery,
  ListItemsBySubcategoryIdQuery,
  ListSubcategoriesQueryVariables,
} from "API";
import { getAccessToken } from "helpers/graphql";
import { listItemsBySubcategoryId, getSubcategory } from "graphql/queries";

export const getSubcategoryById: QueryFunction<
  Subcategory,
  ["subcategories", string]
> = async ({ queryKey }) => {
  const res = await API.graphql<GraphQLQuery<GetSubcategoryQuery>>(
    graphqlOperation(
      getSubcategory,
      { id: queryKey[1] },
      await getAccessToken()
    )
  );

  if (res.data?.getSubcategory) {
    return {
      ...res.data?.getSubcategory,
      items: null,
      category: null,
      parent: null,
    };
  } else {
    throw new Error("Subcategory not found");
  }
};

export const getSubcategoryItems: QueryFunction<
  Item[],
  ["subcategories", string, "items"]
> = async ({ queryKey }) => {
  const res = await API.graphql<GraphQLQuery<ListItemsBySubcategoryIdQuery>>(
    graphqlOperation(
      listItemsBySubcategoryId,
      {
        subcategoryId: queryKey[1],
      } as ListSubcategoriesQueryVariables,
      await getAccessToken()
    )
  );

  const items: Item[] = [];
  for (const item of res.data?.listItemsBySubcategoryId?.items ?? []) {
    if (item) {
      items.push({ ...item });
    }
  }

  return items;
};
