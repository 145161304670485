import * as types from "./actionTypes";
import { State, Action } from "./types";

const initialState: State = {
  categoriesList: {
    categories: null,
    error: "",
  },
  creationError: "",
};

export default function categoriesReducer(
  state = initialState,
  action: Action
) {
  switch (action.type) {
    case types.FETCH_SYSTEMS:
      return { ...state };
    case types.FETCH_SYSTEMS_SUCCESS:
      return {
        ...state,
        categoriesList: {
          categories: action.payload,
          error: "",
        },
      };
    case types.FETCH_SYSTEMS_ERROR:
      return {
        ...state,
        categoriesList: {
          error: action.payload,
          categories: state.categoriesList.categories,
        },
      };
    case types.CREATE_SYSTEM:
      return { ...state };
    case types.CREATE_SYSTEM_SUCCESS:
      return { ...state, creationError: "" };
    case types.CREATE_SYSTEM_ERROR:
      return { ...state, creationError: action.payload };
    default:
      return state;
  }
}
