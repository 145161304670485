import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import PrivacyPolicy from "./privacyPolicy";

export default function PrivacyModal(props) {
  const [show, setShow] = useState(false);
  function toggle() {
    setShow(flag => !flag);
  }
  return (
    <>
      <button
        type="button"
        className="btn btn-white px-1 py-1 align-baseline"
        onClick={toggle}
      >
        {props.linkLabel}
      </button>
      <Modal
        isOpen={show}
        toggle={toggle}
        className={props.className}
        size={props.size}
      >
        <ModalHeader toggle={toggle}>{props.modalTitle}</ModalHeader>
        <ModalBody>
          <PrivacyPolicy />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
