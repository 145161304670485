import React from "react";
import { Alert, Card, CardBody, Col, Container, Label, Row } from "reactstrap";
import MetaTags from "react-meta-tags";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// action
import { userForgetPassword } from "../../store/actions";

// import images
import homeImage from "../../assets/images/home-magnifier.png";
import horizonLogo from "../../assets/images/horizon-logo-no-text.svg";
import npiLogo from "assets/images/npi-logo-sm.png";

import PrivacyModal from "pages/PrivacyPolicy/components/privacyModal";
import EulaModal from "pages/EULA/components/eulaModal";

export default function ForgetPasswordPage(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { forgetError, forgetSuccessMsg } = useSelector(
    state => state.ForgetPassword
  );
  const dispatch = useDispatch();
  let logo = horizonLogo;
  let title = "Horizon";
  let copyright =
    "© " + new Date().getFullYear() + " Carson Dunlop & Associates";
  if (window.location.href.indexOf("npi") !== -1) {
    logo = npiLogo;
    title = "NPI";
    copyright =
      "© " + new Date().getFullYear() + " National Property Inspections";
  }
  const defaultState = {
    logo,
    title,
    copyright,
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>
          {t("forgot_password_page_title", "Forgot Password")} |{" "}
          {defaultState.title}
        </title>
      </MetaTags>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-white p-4">
                        <h5 className="text-white">
                          {t("password_reset", "Password Reset")}
                        </h5>
                        <p>
                          {t(
                            "password_reset_detail",
                            "Enter your email address to continue"
                          )}
                        </p>
                      </div>
                    </Col>
                    <Col
                      className="position-absolute mr-3 col-6"
                      style={{
                        right: "0",
                        top: "20px",
                      }}
                    >
                      <img src={homeImage} className="img-fluid" alt="" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="auth-logo mt-n4">
                    <Link to="/" className="auth-logo-dark">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-white">
                          <img
                            src={defaultState.logo}
                            alt="Horizon Logo"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {forgetError && !forgetError.name ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    ) : null}

                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        email: (defaultState && defaultState.email) || "",
                      }}
                      validationSchema={Yup.object().shape({
                        email: Yup.string().required(
                          t("email_error_blank", "Please enter your email")
                        ),
                      })}
                      onSubmit={values => {
                        dispatch(userForgetPassword(values, history));
                      }}
                    >
                      {({ errors, touched, values }) => (
                        <Form className="form-horizontal">
                          <div className="mb-3">
                            <Label for="email" className="form-label">
                              {t("email", "Email")}
                            </Label>
                            <Field
                              name="email"
                              type="text"
                              className={
                                "form-control" +
                                (errors.email && touched.email
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="text-end">
                            <button
                              className="btn btn-primary w-md"
                              type="submit"
                            >
                              {t("reset", "Reset")}
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  <Link to="/login" className="fw-medium text-primary">
                    {t("go_back_to_login", "Go back to login")}
                  </Link>
                </p>
                <p>{defaultState.copyright}</p>
                <PrivacyModal
                  linkLabel={t("privacy_policy", "Privacy Policy")}
                  modalTitle={t("privacy_policy", "Privacy Policy")}
                  size="xl"
                ></PrivacyModal>
                |
                <EulaModal
                  linkLabel={t("eula_short", "EULA")}
                  modalTitle={t("eula", "End User License Agreement (EULA)")}
                  size="xl"
                ></EulaModal>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
