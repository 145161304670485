import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

//i18n
import SidebarContent from "./SidebarContent";
import horizonLogo from "../../assets/images/horizon-logo-full.svg";
import horizonLogoSm from "../../assets/images/horizon-logo-no-text.svg";
import npiLogo from "assets/images/npi-logo.png";
import npiLogoSm from "assets/images/npi-logo-sm.png";
import { useCompany } from "hooks/company";
import { Storage } from "aws-amplify";

async function getStoredLogo(key) {
  return await Storage.get(key, {
    level: "protected",
  });
}

export default function Sidebar(props) {
  let logo = horizonLogo;
  let logoSm = horizonLogoSm;
  const { isLoading, data: companyData } = useCompany();
  const [companyLogo, setCompanyLogo] = useState();
  if (window.location.href.indexOf("npi") !== -1) {
    logo = npiLogo;
    logoSm = npiLogoSm;
  }
  const defaultState = {
    logo,
    logoSm,
  };
  useEffect(() => {
    if (!isLoading) {
      if (companyData && companyData.photo) {
        getStoredLogo(companyData.photo).then(data => {
          setCompanyLogo(data);
        });
      } else {
        setCompanyLogo(defaultState.logo);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to="/" className="logo">
            <span className="logo-sm">
              <img src={companyLogo || ""} alt="" height="30" />
            </span>
            <span className="logo-lg">
              <img src={companyLogo || ""} alt="" height="60" />
            </span>
          </Link>
        </div>
        <div data-simplebar className="simplebar">
          <SidebarContent />
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  );
}
