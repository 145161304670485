import { StandardDropdown } from "components/Fields";
import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import { WorkOrder } from "hooks/workorders/workorder";
import DateBox from "devextreme-react/date-box";

export default function FilterMenu(props: any) {
  const { t } = useTranslation();

  const dateRangeOptions = [
    { value: "all", label: t("all_dates", "All dates") },
    { value: "last_week", label: t("last_week", "Last week") },
    { value: "last_4_weeks", label: t("last_4_weeks", "Last 4 weeks") },
    { value: "last_3_months", label: t("last_3_months", "Last 3 months") },
    { value: "last_6_months", label: t("last_6_months", "Last 6 months") },
    { value: "last_12_months", label: t("last_12_months", "Last 12 months") },
    { value: "custom", label: t("custom_date_range", "Custom date range") },
  ];

  const calendarOptions = [
    { value: "all", label: t("all_calendars", "All calendars") },
    { value: "my", label: t("my_calendar", "My calendar") },
  ];

  const bookingOptions = [
    { value: "all", label: t("all", "All statuses") },
    { value: "booked", label: t("booked", "Booked") },
  ];

  const filterByDays = (workOrders: [WorkOrder], days: number) => {
    const today = new Date();
    const startDate = new Date(today.getTime() - days * 24 * 60 * 60 * 1000);

    return workOrders.filter(
      (workOrder: WorkOrder) =>
        new Date(workOrder.inspection.inspectionDate) > startDate &&
        new Date(workOrder.inspection.inspectionDate) < today
    );
  };

  const onSubmit = (values: any) => {
    let filteredWorkOrders = props.workOrders;

    if (values.dateRange !== "all") {
      switch (values.dateRange) {
        case "last_week":
          filteredWorkOrders = filterByDays(filteredWorkOrders, 7);
          break;
        case "last_4_weeks":
          filteredWorkOrders = filterByDays(filteredWorkOrders, 28);
          break;
        case "last_3_months":
          filteredWorkOrders = filterByDays(filteredWorkOrders, 90);
          break;
        case "last_6_months":
          filteredWorkOrders = filterByDays(filteredWorkOrders, 180);
          break;
        case "last_12_months":
          filteredWorkOrders = filterByDays(filteredWorkOrders, 365);
          break;
        case "custom":
          filteredWorkOrders = filteredWorkOrders.filter(
            (workOrder: WorkOrder) => {
              return (
                new Date(workOrder.inspection.inspectionDate) >
                  new Date(values.fromDate) &&
                new Date(workOrder.inspection.inspectionDate) <
                  new Date(values.toDate)
              );
            }
          );
          break;
      }
    }
    if (values.bookingOptions !== "all") {
      filteredWorkOrders = filteredWorkOrders.filter(
        (workOrder: WorkOrder) => workOrder.status === values.bookingOptions
      );
    }

    props.onFilter(filteredWorkOrders);
  };

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Formik
            enableReinitialize={true}
            initialValues={{
              dateRange: "all",
              calendar: "all",
              bookingOptions: "all",
              fromDate: "",
              toDate: "",
            }}
            onSubmit={onSubmit}
          >
            {({ values, setFieldValue }) => {
              return (
                <Form>
                  <Row>
                    <Col>
                      <StandardDropdown
                        name="dateRange"
                        options={dateRangeOptions}
                        label={t("date_range", "Date range")}
                      />
                    </Col>
                    <Col>
                      <StandardDropdown
                        name="calendar"
                        options={calendarOptions}
                        label={t("calendar", "Calendar")}
                      />
                    </Col>
                    <Col>
                      <StandardDropdown
                        name="bookingOptions"
                        options={bookingOptions}
                        label={t("booking_status", "Booking status")}
                      />
                    </Col>
                    <Col>
                      <Label
                        for="fromDate"
                        className="col-form-label form-label"
                      >
                        {t("from_date", "From date")}
                      </Label>
                      <DateBox
                        name="fromDate"
                        disabled={values.dateRange !== "custom"}
                        onValueChanged={(e: any) => {
                          console.log(new Date(e.value).getTime());
                          setFieldValue("fromDate", e.value);
                        }}
                      ></DateBox>
                    </Col>
                    <Col>
                      <Label for="toDate" className="col-form-label form-label">
                        {t("to_date", "To date")}
                      </Label>
                      <DateBox
                        name="toDate"
                        disabled={values.dateRange !== "custom"}
                        onValueChanged={(e: any) => {
                          setFieldValue("toDate", e.value);
                        }}
                      ></DateBox>
                    </Col>
                    <Col className="d-flex flex-column justify-content-end">
                      <Button name="filterSubmit" type="submit" color="primary">
                        {t("filter", "Filter")}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}
