import PropTypes from "prop-types";
import * as Yup from "yup";
import { Regex } from "components/Common/Regex";

class CompanySettingsSchema {
  constructor(props) {
    this.t = props.t;
  }

  validation(_props) {
    return Yup.object().shape({
      name: Yup.string().required(
        this.t("error_company_name_blank", "Please enter a company name")
      ),
      primaryPhone: Yup.string()
        .matches(
          Regex.phone,
          this.t("error_phone_invalid", "Phone number is not valid")
        )
        .required(this.t("error_phone_blank", "Please enter a phone number")),
      secondaryPhone: Yup.string().matches(
        Regex.phone,
        this.t("error_phone_invalid", "Phone number is not valid")
      ),
      website: Yup.string().matches(
        Regex.website,
        this.t("error_website_invalid", "Website address is not valid")
      ),
      email: Yup.string().matches(
        Regex.email,
        this.t("error_email_invalid", "Email address is not valid")
      ),
      address1: Yup.string().required(
        this.t("error_address_blank", "Please enter a street address")
      ),
      city: Yup.string().required(
        this.t("error_city_blank", "Please enter a city")
      ),
      region: Yup.string().required(
        this.t("error_region_blank", "Please enter a state")
      ),
      postCode: Yup.string().required(
        this.t("error_postcode_blank", "Please enter a zip code.")
      ),
      country: Yup.string()
        .min(1)
        .required(this.t("error_country_blank", "Please select a country")),
      description: Yup.string().required(
        this.t(
          "error_company_description_blank",
          "Please enter a company description"
        )
      ),
      serviceArea: Yup.string().required(
        this.t("error_service_area_blank", "Please enter a service area")
      ),
      timezone: Yup.string().required(
        this.t("error_timezone_blank", "Please select a timezone")
      ),
      earliestAvailability: Yup.string().required(
        this.t(
          "error_earliest_availability_blank",
          "Please enter an earliest availability"
        )
      ),
      facebook: Yup.string().matches(
        Regex.website,
        this.t("error_facebook_invalid", "Facebook address is not valid.")
      ),
      instagram: Yup.string().matches(
        Regex.website,
        this.t("error_instagram_invalid", "Instagram address is not valid.")
      ),
      yelp: Yup.string().matches(
        Regex.website,
        this.t("error_yelp_invalid", "Yelp address is not valid.")
      ),
      angie: Yup.string().matches(
        Regex.website,
        this.t("error_angie_invalid", "Angie's List address is not valid.")
      ),
      google: Yup.string().matches(
        Regex.website,
        this.t("error_google_invalid", "Google address is not valid.")
      ),
      homestars: Yup.string().matches(
        Regex.website,
        this.t("error_homestars_invalid", "HomeStars address is not valid.")
      ),
    });
  }

  productValidation(_props) {
    return Yup.object().shape({});
  }

  travelFeesValidation(_props) {
    return Yup.object().shape({
      travelFees: Yup.object().shape({
        perMileFee: Yup.number()
          .typeError(
            this.t("per_mile_fee_number", "Per mile fee must be a number.")
          )
          .required(
            this.t("per_mile_fee_required", "Please enter a per mile fee.")
          )
          .min(
            0,
            this.t("per_mile_fee_positive", "Per mile fee cannot be negative.")
          ),
        ranges: Yup.array().test(
          "range-test",
          "Please check your range as it has either gaps or overlaps",
          function (value) {
            let lastMax = undefined;
            let sane = true;
            if (value !== undefined) {
              value.forEach(range => {
                if (lastMax !== undefined && range.minimum !== lastMax + 1)
                  sane = false;
                lastMax = range.maximum;
              });
            }
            return sane;
          }
        ),
      }),
    });
  }

  dataPrivacyValidation(_props) {
    return Yup.object().shape({});
  }
}

CompanySettingsSchema.propTypes = {
  t: PropTypes.func,
  state: PropTypes.any,
};

export default CompanySettingsSchema;
