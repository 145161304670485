import { BaseResource, Group, ListItems, ListItem } from "./types";

export function recurseAndRemove<Resource extends BaseResource>(
  indices: number[],
  objs: ListItems<Resource>
): ListItem<Resource> {
  if (indices.length > 1 && objs[indices[0]].type === "group") {
    return recurseAndRemove<Resource>(
      indices.slice(1),
      (objs[indices[0]] as unknown as Group<Resource>).items
    );
  }

  const [item] = objs.splice(indices[0], 1);

  return item;
}

export function recurseAndAdd<Resource extends BaseResource>(
  indices: number[],
  objs: ListItems<Resource>,
  obj: ListItem<Resource>
): void {
  if (!objs[indices[0]]) {
    objs.push(obj);

    return;
  } else if (indices.length > 1 && objs[indices[0]].type === "group") {
    return recurseAndAdd<Resource>(
      indices.slice(1),
      (objs[indices[0]] as unknown as Group<Resource>).items,
      obj
    );
  }

  objs.splice(indices[0], 0, obj);
}

export function calculateRelationship(
  fromIndex: number[],
  toIndex: number[]
): boolean {
  if (fromIndex.length === toIndex.length) {
    return fromIndex[fromIndex.length - 1] < toIndex[toIndex.length - 1];
  }

  if (fromIndex.length > toIndex.length) {
    return fromIndex[toIndex.length - 1] < toIndex[toIndex.length - 1];
  }

  return toIndex[fromIndex.length - 1] > fromIndex[toIndex.length - 1];
}
