import { Trans } from "react-i18next";
import { Card, CardBody, Col, Row } from "reactstrap";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useReducer, useState } from "react";
import { API } from "aws-amplify";
import { DisplayAddress } from "components/Fields/Address";
import DrivingCard from "./_drivingCard";
import InspectionCard from "./_inspectionCard";
import { useJsApiLoader } from "@react-google-maps/api";
import * as url from "../icons/home.png";
import { formatDate } from "devextreme/localization";
import { Link, matchPath } from "react-router-dom";
import DatePicker from "./_myJobsDateSelector";
import mapMarkers from "../../../../assets/images/map-marker-sprite-set.png";

function addMarker(map, action) {
  const google = window.google;
  if (action.number !== undefined) {
    // let mIcon = {
    //   path: google.maps.SymbolPath.CIRCLE,
    //   fillOpacity: 1,
    //   fillColor: "#fff",
    //   strokeOpacity: 1,
    //   strokeWeight: 1,
    //   strokeColor: "#333",
    //   scale: 10,
    // };
    const mIcon = {
      anchor: new google.maps.Point(11, 30),
      origin: new google.maps.Point(
        22 * action.number,
        30 * ((action.number % 5) - 1)
      ),
      size: new google.maps.Size(22, 30),
      //scaledSize: new google.maps.Size(87, 120),
      url: mapMarkers,
    };
    // let mLabel = {
    //   color: "#000",
    //   fontSize: "12px",
    //   fontWeight: "600",
    //   text: action.number,
    // };
    map.markers.push(
      new google.maps.Marker({
        position: action.position,
        map: map.map,
        //title: action.title,
        icon: mIcon,
        //label: mLabel,
      })
    );

    // const numberMarker = new google.maps.Marker({
    //   position: action.position,
    //   map: map.map,
    //   icon: numberIcon,
    //   label: {
    //     text: action.number,
    //     color: "#fff",
    //     fontSize: "12px",
    //     fontWeight: "600",
    //   },
    // });

    // map.markers.push(numberMarker);
  } else {
    let mIcon = {
      url: url.default,
    };
    map.markers.push(
      new google.maps.Marker({
        position: action.position,
        map: map.map,
        title: action.title,
        icon: mIcon,
      })
    );
  }
  map.bounds.extend(action.position);
}

function mapsReducer(map, action) {
  const routeColors = ["#ea5405", "#6fc8d5", "#fccb00", "#94bf33"];
  const google = window.google;
  switch (action.type) {
    case "createMap":
      map.map = new google.maps.Map(action.div, {
        center: action.center,
        zoom: 8,
      });
      map.bounds = new google.maps.LatLngBounds();
      map.path = [];
      break;
    case "addPlace":
      map.places.push({ address: action.address });
      break;
    case "addMarker":
      addMarker(map, action);
      break;
    case "addCallback":
      map.callbacks++;
      break;
    case "removeCallback":
      map.callbacks--;
      if (map.callbacks === 0) {
        map.places.forEach((place, placeIndex) => {
          if (placeIndex > 0) {
            if (place.status === "OK") {
              let legs = place.results.routes[0].legs;

              addMarker(map, {
                type: "addMarker",
                position: legs[0].end_location,
                title: legs[0].end_address,
                number: placeIndex.toString(),
              });

              map.path[placeIndex - 1] = new google.maps.MVCArray();
              map.path[placeIndex - 1].push(legs[0].start_location);

              legs.forEach((leg, legIndex) => {
                let steps = leg.steps;
                steps.forEach((step, stepIndex) => {
                  let segments = step.path;
                  segments.forEach((segment, segmentIndex) => {
                    map.path[placeIndex - 1].push(segment);
                  });
                });
              });
            }

            let route = new google.maps.Polyline({
              path: map.path[placeIndex - 1],
              strokeColor: routeColors[(placeIndex % 5) - 1],
              strokeOpacity: 0.8,
              strokeWeight: 5,
            });
            route.setMap(map.map);
          }
        });

        map.map.fitBounds(map.bounds);
      }
      break;
    default:
      break;
  }
  return map;
}

export default function AtAGlance(props) {
  const [map, dispatch] = useReducer(mapsReducer, {
    map: undefined,
    places: [],
    markers: [],
    callbacks: 0,
  });

  const workOrderQuery = useQuery(
    ["work_orders", props.currentDate],
    async () =>
      API.get(
        "WorkOrder",
        "/work/order/byDate/" + formatDate(props.currentDate, "y-MM-dd")
      ),
    {
      retry: 0,
      refetchOnWindowFocus: false,
    }
  );

  if (workOrderQuery.isSuccess) {
    workOrderQuery.data.sort((a, b) => {
      return (
        new Date(a.inspection.inspectionDate) -
        new Date(b.inspection.inspectionDate)
      );
    });
  }

  let companyDetails = undefined;
  const companyDetailsQuery = useQuery(
    ["company_details"],
    async () => API.get("Company", "/company"),
    {}
  );
  if (companyDetailsQuery.isSuccess) {
    companyDetails = companyDetailsQuery.data[[0]];
  }

  let [isLoaded] = useState(
    useJsApiLoader({
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    })
  );

  let startAddress = DisplayAddress(companyDetails);

  function geocoderCallback(results, status) {
    const google = window.google;

    if (status === "OK") {
      let startLatLng = results[0].geometry.location;
      dispatch({
        type: "createMap",
        div: document.getElementById("map_my_jobs"),
        center: startLatLng,
      });
      const directionsService = new google.maps.DirectionsService();

      dispatch({
        type: "addPlace",
        address: startAddress,
      });

      workOrderQuery.data.forEach(workOrder => {
        dispatch({
          type: "addPlace",
          address: DisplayAddress(workOrder.inspection.address),
        });
      });

      dispatch({
        type: "addMarker",
        position: startLatLng,
        title: startAddress,
      });

      let lastPlace = undefined;
      map.places.forEach((place, placeIndex) => {
        if (lastPlace !== undefined) {
          dispatch({
            type: "addCallback",
          });
          directionsService.route(
            {
              origin: lastPlace,
              destination: place.address,
              travelMode: "DRIVING",
            },
            (res, sts) => {
              map.places[placeIndex].results = res;
              map.places[placeIndex].status = sts;
              dispatch({
                type: "removeCallback",
              });
            }
          );
        }
        lastPlace = place.address;
      });
    }
  }

  useEffect(() => {
    if (
      isLoaded &&
      companyDetailsQuery.isSuccess &&
      companyDetails !== undefined &&
      workOrderQuery.isSuccess &&
      workOrderQuery.data.length > 0
    ) {
      const google = window.google;

      let geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: startAddress }, geocoderCallback);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded, companyDetailsQuery.isSuccess, workOrderQuery.isSuccess]);

  let previousAddress = startAddress;

  return (
    <React.Fragment>
      <DatePicker
        onDateChanged={props.setCurrentDate}
        defaultValue={props.currentDate}
      ></DatePicker>
      {!workOrderQuery.isSuccess || workOrderQuery.data.length === 0 ? (
        <div className="alert alert-danger">
          <Trans i18nKey="no_work_orders_assigned_to_you">
            No work orders have been assigned to you for the day selected. To
            display all work orders, including those that are past or not yet
            available, go to{" "}
            <Link to="/work/orders">work orders and reports</Link> in the main
            menu.
          </Trans>
        </div>
      ) : (
        <Row>
          <Col className="pr-1" id="inspection-cards">
            {workOrderQuery.data.map((workOrder, index) => {
              let fromAddress = previousAddress;
              let toAddress = DisplayAddress(workOrder.inspection.address);
              previousAddress = toAddress;

              return (
                <React.Fragment key={workOrder.id}>
                  <DrivingCard
                    fromAddress={fromAddress}
                    toAddress={toAddress}
                    nextInspectionTime={workOrder.inspection.inspectionDate}
                    nextInspectionId={workOrder.id}
                    index={index + 1}
                  />{" "}
                  <InspectionCard
                    workOrder={workOrder}
                    fromAddress={fromAddress}
                    index={index + 1}
                  />
                </React.Fragment>
              );
            })}
          </Col>
          <Col className="pl-1">
            <Card className="h-100 mb-2">
              <CardBody>
                <div style={{ height: "100%" }} id="map_my_jobs"></div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
}
