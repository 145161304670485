import {
  useMutation,
  useQuery,
  QueryFunction,
  useQueryClient,
  QueryFunctionContext,
} from "@tanstack/react-query";
import { API, Auth } from "aws-amplify";
import _ from "lodash";
import toast from "toastr";
const { v4: uuid } = require("uuid");

export function useWorkOrder(id: string) {
  const queryClient = useQueryClient();

  const workOrder = useQuery(["workOrders", id], getWorkOrderById, {});

  const updateWorkOrder = useMutation(
    async (delta: Omit<Partial<WorkOrder>, "id">) => {
      if (!workOrder.data) throw new Error("Work order has not been fetched");
      let newWorkOrder = _.merge(workOrder.data, delta, { id });

      newWorkOrder = {
        ...newWorkOrder,
        reportNumber:
          newWorkOrder.reportNumber === undefined
            ? await generateNewReportNumber()
            : newWorkOrder.reportNumber,
      };

      return API.put("WorkOrder", "/work/order", {
        body: newWorkOrder,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["workOrders", id]);
        toast.success("Work order updated");
      },
      onError(error: Error) {
        console.error(error);
        toast.error("Failed to update work order. Error: " + error);
      },
    }
  );

  const generateNewReportNumber = async () => {
    let workOrders: WorkOrder[] = await getWorkOrders();
    workOrders = workOrders.filter(
      workOrder =>
        workOrder.reportNumber !== "" && workOrder.reportNumber !== undefined
    );
    workOrders = _.sortBy(workOrders, workOrder => workOrder.reportNumber);

    if (workOrders.length === 0) {
      return "1000";
    } else {
      const lastWorkOrder = _.last(workOrders);
      const lastReportNumber = lastWorkOrder?.reportNumber;
      const newReportNumber = lastReportNumber
        ? (parseInt(lastReportNumber) + 1).toString()
        : 1000;
      return newReportNumber.toString();
    }
  };

  const createWorkOrder = useMutation(
    async (workOrder: Omit<Partial<WorkOrder>, "id">) => {
      const _id = uuid();
      const user = await Auth.currentUserInfo();
      const userId = user["id"];
      const newWorkOrder = {
        ...workOrder,
        id: _id,
        hasAccess: [userId],
        reportNumber:
          workOrder.reportNumber === undefined || workOrder.reportNumber === ""
            ? await generateNewReportNumber()
            : workOrder.reportNumber,
      };
      return API.post("WorkOrder", "/work/order", {
        body: newWorkOrder,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["workOrders"]);
        toast.success("Work order created");
      },
      onError(error: Error) {
        console.error(error);
        toast.error("Failed to create work order.  Error: " + error);
      },
    }
  );

  return { workOrder, updateWorkOrder, createWorkOrder };
}

const getWorkOrderById: QueryFunction<
  WorkOrder,
  ["workOrders", string]
> = async ({ queryKey }: QueryFunctionContext) => {
  if (queryKey[1] === "new") {
    return emptyWorkOrder;
  } else {
    return API.get("WorkOrder", "/work/order/" + queryKey[1], {});
  }
};

const getWorkOrders = async (): Promise<any> => {
  return API.get("WorkOrder", "/work/order", {});
};

export type ClientInformation = {
  firstName: string;
  lastName: string;
  primaryPhone: string;
  alternatePhone: string;
  company: string;
  email: string;
  notes: string;
  address: {
    address1: string;
    address2: string;
    city: string;
    country: string;
    postCode: string;
    region: string;
  };
  inspectionAddressSame: false;
};

export type Address = {
  address1: string;
  address2: string;
  city: string;
  country: string;
  postCode: string;
  region: string;
  intersection: string | undefined;
  mapNumber: string | undefined;
  parkingInformation: string | undefined;
};

export type WorkOrder = {
  id: string | undefined;
  inspectionAttendance: Array<string>;
  inspectionAttendanceOther: string;
  inspection: {
    address: Address;
    inspectors: Array<string>;
    inspectionDate: string;
    duration: string;
  };
  clientInformation: Array<ClientInformation>;
  contractOptions: string;
  communicationOptions: Array<string>;
  propertyDescription: {
    propertyType: string;
    buildingType: string;
    floors: string;
    rooms: string;
    yearBuilt: string;
    houseFacing: string;
    lockboxCode: string;
    bedrooms: {
      aboveGrade: string;
      belowGrade: string;
    };
    bathrooms: string;
    metric: true;
    sqft: string;
    finishedSqft: string;
    squareMetres: string;
    finishedSquareMetres: string;
    lotSize: string;
    price: {
      current: string;
      lastSoldPrice: string;
      lastSoldDate: string;
    };
    occupancy: string;
    occupancyOther: string;
    propertyTitle: string;
  };
  referral: {
    source: string;
    firstName: string;
    lastName: string;
    email: string;
    companyName: string;
    notes: string;
  };
  bookedby: {
    source: string;
    firstName: string;
    lastName: string;
    email: string;
    companyName: string;
    notes: string;
  };
  reportNumber: string;
  status: string;
};

export const emptyWorkOrder: Omit<Partial<WorkOrder>, "id"> = {
  inspectionAttendance: [],
  inspectionAttendanceOther: "",
  inspection: {
    address: {
      address1: "",
      address2: "",
      city: "",
      country: "",
      postCode: "",
      region: "",
      intersection: "",
      mapNumber: "",
      parkingInformation: "",
    },
    inspectors: [],
    inspectionDate: "",
    duration: "",
  },
  clientInformation: [
    {
      firstName: "",
      lastName: "",
      primaryPhone: "",
      alternatePhone: "",
      company: "",
      email: "",
      notes: "",
      address: {
        address1: "",
        address2: "",
        city: "",
        country: "",
        postCode: "",
        region: "",
      },
      inspectionAddressSame: false,
    },
  ],
  contractOptions: "",
  communicationOptions: [],
  propertyDescription: {
    propertyType: "",
    buildingType: "",
    floors: "",
    rooms: "",
    yearBuilt: "",
    houseFacing: "",
    lockboxCode: "",
    bedrooms: {
      aboveGrade: "",
      belowGrade: "",
    },
    bathrooms: "",
    metric: true,
    sqft: "",
    finishedSqft: "",
    squareMetres: "",
    finishedSquareMetres: "",
    lotSize: "",
    price: {
      current: "",
      lastSoldPrice: "",
      lastSoldDate: "",
    },
    occupancy: "",
    occupancyOther: "",
    propertyTitle: "",
  },
  referral: {
    source: "",
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    notes: "",
  },
  bookedby: {
    source: "",
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    notes: "",
  },
  reportNumber: "",
};
