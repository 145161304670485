import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  addProductFn,
  deleteProductFn,
  getProducts,
  updateProductFn,
} from "./effects";
import { useProfile } from "../profile";
import toast from "toastr";

export function useProducts() {
  const queryClient = useQueryClient();
  const { myId } = useProfile();
  const { t } = useTranslation();
  const history = useHistory();

  const byOwner = useQuery(["products", myId], getProducts, {
    enabled: Boolean(myId),
  });

  const createProduct = useMutation(addProductFn, {
    onSuccess: () => {
      queryClient.invalidateQueries(["products", myId]);
      toast.success(t("product_create_success", "New product created"));
      history.push("/company/settings/products");
    },
    onError(error) {
      console.error(error);
      toast.error(t("product_create_failed", "Failed to create new product"));
    },
  });

  const updateProduct = useMutation(updateProductFn, {
    onSuccess: (_, input) => {
      queryClient.invalidateQueries(["products", input.ownerId]);
      toast.success(t("product_update_success", "Product updated"));
    },
    onError(error) {
      console.error(error);
      toast.error(t("product_update_failed", "Failed to update product"));
    },
  });

  const deleteProduct = useMutation(deleteProductFn, {
    onSuccess: (_, input) => {
      queryClient.invalidateQueries(["products", input.ownerId]);
      toast.success("Product deleted");
    },
    onError(error) {
      console.error(error);
      toast.error("Failed to delete product");
    },
  });

  return {
    byOwner,
    createProduct,
    deleteProduct,
    updateProduct,
  };
}
