import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { TextInput } from "components/Fields";
import { Card, CardBody, Row, Col } from "reactstrap";
import { TreeViewDropDown } from "components/Fields/dropdowns/TreeViewDropDown";
import { useFormikContext } from "formik";
import { useQuery } from "@tanstack/react-query";
import { API } from "aws-amplify";

export default function ReferralBookingSource(props) {
  const { t } = useTranslation();
  const formik = useFormikContext();
  let [referralSources, setReferralSources] = useState([]);
  let [bookedBySources, setBookedBySources] = useState([]);
  const snippets = ["referral_source", "booked_by_source"];

  useQuery(
    snippets,
    async () => {
      return API.get("Snippet", "/snippet/snippetType/" + snippets.join(","));
    },
    {
      onSettled: data => {
        let newResults = {};
        data.forEach(snippet => {
          newResults[snippet.snippetType] = {
            ...snippet,
          };
        });

        setReferralSources(newResults["referral_source"].values);
        setBookedBySources(newResults["booked_by_source"].values);
      },
    }
  );

  return (
    <React.Fragment>
      <Card className="mb-0 border-bottom">
        <CardBody>
          <h2 className="mt-2 mb-2">
            {t("referral_booking_source", "Referral and booking source")}
          </h2>
          <TreeViewDropDown
            name="referral.source"
            label={t("referral_source", "Referral source")}
            dataSource={referralSources}
            value={
              formik.values.referral !== undefined
                ? formik.values.referral.source
                : ""
            }
            valueExpr="text"
            displayExpr="text"
            onChange={value => formik.setFieldValue("referral.source", value)}
          />
          {formik.values.referral !== undefined &&
          formik.values.referral.source !== "None" &&
          formik.values.referral.source !== "" ? (
            <React.Fragment>
              <Row>
                <Col>
                  <TextInput
                    label={t("first_name", "First name")}
                    name="referral.firstName"
                    formik={formik}
                  />
                </Col>
                <Col>
                  <TextInput
                    label={t("last_name", "Last name")}
                    name="referral.lastName"
                    formik={formik}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextInput
                    label={t("email", "Email")}
                    name="referral.email"
                    formik={formik}
                  />
                </Col>
                <Col>
                  <TextInput
                    label={t("agency_company_name", "Agency/company name")}
                    name="referral.companyName"
                    formik={formik}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextInput
                    label={t("notes", "Notes")}
                    name="referral.notes"
                    formik={formik}
                  />
                </Col>
              </Row>
            </React.Fragment>
          ) : (
            ""
          )}
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <TreeViewDropDown
            name="bookedby.source"
            label={t("booked_by_source", "Booked by")}
            dataSource={bookedBySources}
            value={
              formik.values.bookedby !== undefined
                ? formik.values.bookedby.source
                : ""
            }
            valueExpr="text"
            displayExpr="text"
            onChange={value => formik.setFieldValue("bookedby.source", value)}
          />
          {formik.values.bookedby !== undefined &&
          formik.values.bookedby.source !== "None" &&
          formik.values.bookedby.source !== "" ? (
            <React.Fragment>
              <Row>
                <Col>
                  <TextInput
                    label={t("first_name", "First name")}
                    name="bookedby.firstName"
                    formik={formik}
                  />
                </Col>
                <Col>
                  <TextInput
                    label={t("last_name", "Last name")}
                    name="bookedby.lastName"
                    formik={formik}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextInput
                    label={t("email", "Email")}
                    name="bookedby.email"
                    formik={formik}
                  />
                </Col>
                <Col>
                  <TextInput
                    label={t("agency_company_name", "Agency/company name")}
                    name="bookedby.companyName"
                    formik={formik}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextInput
                    label={t("notes", "Notes")}
                    name="bookedby.notes"
                    formik={formik}
                  />
                </Col>
              </Row>
            </React.Fragment>
          ) : (
            ""
          )}
        </CardBody>
      </Card>
    </React.Fragment>
  );
}
