import DataGrid from "components/Tables/DataGrid";
import {
  Column,
  FilterRow,
  MasterDetail,
  Pager,
  Paging,
  Scrolling,
  Toolbar,
} from "devextreme-react/data-grid";
import { useHistory } from "react-router-dom";
import { Badge, Button } from "reactstrap";
import Statuses from "helpers/StaticLists/Statuses";
import { useTranslation } from "react-i18next";
import React, { ReactElement } from "react";
import { ClientInformation } from "hooks/workorders/workorder";
import Icon from "components/Common/Icons";
import DetailTemplate from "pages/Work/Orders/_detailTemplate";

export default function WorkOrdersGrid(props: any) {
  const { t } = useTranslation();
  const history = useHistory();
  const editWorkOrder = (id: string) => {
    history.push("/work/order/" + encodeURI(id));
  };

  const openReport = (id: string) => {
    history.push("/work/order/report/" + encodeURI(id));
  };

  const initializeDataGrid = (e: any) => {
    if (props.setDataGrid) {
      props.setDataGrid(e.component);
    }
  };

  const inspectorCellRender = (e: any) => {
    try {
      return e.data.inspection.inspectors[0].text;
    } catch (error) {
      return "";
    }
  };

  const addressCellRender = (e: any) => {
    let address = e.data.inspection.address;
    if (address !== undefined) {
      return (
        <React.Fragment>
          {renderCellsWithData(address.address1)}
          {renderCellsWithData(address.address2)}
          {renderCellsWithData(address.city)}
          {renderCellsWithData(address.region)}
          {renderCellsWithData(address.postCode)}
          {renderCellsWithData(address.country)}
        </React.Fragment>
      );
    }
  };

  const renderCellsWithData = (field: string) => {
    return field ? (
      <React.Fragment>
        {field}
        <br />
      </React.Fragment>
    ) : (
      ""
    );
  };

  const clientCellRender = (e: any) => {
    return (
      <React.Fragment>
        {e.data.clientInformation.map(
          (client: ClientInformation, key: string) => {
            return (
              <React.Fragment key={key}>
                {client.firstName} {client.lastName}{" "}
                {client.primaryPhone ? (
                  <Icon
                    name="mobile"
                    onClick={() => {
                      window.open("tel:" + client.primaryPhone);
                    }}
                  ></Icon>
                ) : (
                  ""
                )}
                {client.email ? (
                  <Icon
                    name="envelope"
                    onClick={() => {
                      window.open("mailto:" + client.email);
                    }}
                  ></Icon>
                ) : (
                  ""
                )}
              </React.Fragment>
            );
          }
        )}
      </React.Fragment>
    );
  };

  return (
    <DataGrid
      dataSource={props.dataSource}
      keyExpr="id"
      allowColumnResizing={true}
      columnResizingMode="nextColumn"
      onCellClick={(e: any) => {
        if (
          e.columnIndex > 0 &&
          e.rowType !== "filter" &&
          e.rowType !== "header"
        ) {
          console.log(e);
          editWorkOrder(e.key);
        }
      }}
      onInitialized={initializeDataGrid}
    >
      <Toolbar>
        {props.toolbarItems &&
          props.toolbarItems.props.children.map((child: ReactElement) => child)}
      </Toolbar>
      {props.allowFiltering ? <FilterRow visible={true} /> : ""}
      <Scrolling rowRenderingMode="virtual"></Scrolling>
      {props.allowPaging ? <Paging defaultPageSize={10} /> : ""}
      {props.allowPaging ? (
        <Pager
          visible={true}
          allowedPageSizes={[5, 10, 20, 50, "all"]}
          displayMode="full"
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
      ) : (
        ""
      )}
      <Column
        dataField="reportNumber"
        caption={t("report_number", "Report #")}
        width="120"
        allowResizing={false}
        alignment="center"
      />
      <Column
        dataField="inspector"
        caption={t("inspector", "Inspector")}
        minWidth="100"
        cellRender={inspectorCellRender}
      />
      <Column
        dataField="inspection.address.address1"
        caption={t("address", "Address")}
        minWidth="150"
        cellRender={addressCellRender}
      />
      <Column
        dataField="client"
        caption={t("client", "Client")}
        minWidth="100"
        cellRender={clientCellRender}
      />
      <Column
        dataField="status"
        caption={t("status", "Status")}
        minWidth="100"
        allowSearch={false}
        cellComponent={data => {
          const key: string = data.data.value;
          return (
            <Badge
              color={key ? Statuses[key].color : ""}
              className={key ? "bg-" + Statuses[key].color : ""}
            >
              {t("status_" + key)}
            </Badge>
          );
        }}
      />
      <Column
        dataField="inspection.inspectionDate"
        caption={t("date", "Date")}
        dataType="datetime"
        minWidth="100"
        defaultSortIndex={0}
        defaultSortOrder="asc"
      />
      <Column
        caption={t("actions", "Actions")}
        allowResizing={false}
        allowSearch={false}
        allowSorting={false}
        cellRender={data => (
          <>
            <Button
              color="light"
              size="sm"
              className="mr-1"
              hint={t("review_work_order", "Review work order")}
            >
              Edit
            </Button>
          </>
        )}
      ></Column>
      <MasterDetail enabled={true} component={DetailTemplate}></MasterDetail>
    </DataGrid>
  );
}
