import { useTranslation } from "react-i18next";

import { useSubcategory } from "hooks/subcategories";
import { ResourceList } from "components/Settings/ResourceList";
import { ResourceInput } from "components/Settings/ResourceInput";
import { EmptyPane } from "components/Settings/EmptyPane";
import { ErrorPane } from "components/Settings/ErrorPane";
import { LoadingPane } from "components/Settings/LoadingPane";

interface ItemsTabProps {
  subcategoryId: string;
}

export function ItemsTab(props: ItemsTabProps) {
  const { t } = useTranslation();
  const {
    subcategory,
    items,
    createItem,
    updateItem,
    removeItem,
    reorderItems,
  } = useSubcategory(props.subcategoryId);

  const orderedItems =
    (subcategory.data &&
      items.data?.sort(
        (a, b) =>
          (subcategory.data.itemOrder?.indexOf(a.id) ?? 0) -
          (subcategory.data.itemOrder?.indexOf(b.id) ?? 0)
      )) ??
    [];

  let content: JSX.Element | null = null;
  switch (items.status) {
    case "success":
      if (items.data.length) {
        content = (
          <ResourceList
            type="item"
            items={orderedItems.map(value => ({
              type: "item",
              value,
            }))}
            actions={[
              {
                type: "action",
                onClick: item => removeItem.mutateAsync({ id: item.id }),
                color: "danger",
                label: t("Delete"),
              },
            ]}
            onRename={(id, name) => updateItem.mutateAsync({ id, name })}
            onReorder={items =>
              reorderItems.mutateAsync({
                itemOrder: items
                  .map(item => (item.type === "item" ? item.value.id : ""))
                  .filter(id => id),
              })
            }
          />
        );
      } else {
        content = <EmptyPane>{t("No items found")}</EmptyPane>;
      }
      break;
    case "error":
      content = <ErrorPane>{t("Failed to load subcategories")}</ErrorPane>;
      break;
    case "loading":
      content = <LoadingPane />;
  }

  return (
    <>
      <h2 className="mb-2">{t("Add or Remove Items")}</h2>
      <p className="mb-4">
        {t(
          "Items represent observations recorded and limitations encountered during a home inspection."
        )}
      </p>
      <div className="mb-3">
        <ResourceInput
          placeholder="Item Name"
          onCreate={name => createItem.mutateAsync({ name })}
        />
      </div>
      {content}
    </>
  );
}
