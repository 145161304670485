import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
  Col,
  Button,
} from "reactstrap";
import SimpleBar from "simplebar-react";

//i18n
import { withTranslation } from "react-i18next";

class NotificationDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu,
    }));
  }
  render() {
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="dropdown d-inline-block"
          tag="li"
        >
          <DropdownToggle
            className="btn header-item noti-icon d-flex align-items-center"
            tag="button"
            id="page-header-notifications-dropdown"
          >
            <i className="bx bx-bell" />
            <span className="badge badge-sm bg-danger rounded-pill">2</span>
          </DropdownToggle>

          <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0">
                    {" "}
                    {this.props.t("notifications", "Notifications")}{" "}
                  </h6>
                </Col>
                <div className="col-auto">
                  <Button color="white" size="sm">
                    {this.props.t("view_all", "View All")}
                  </Button>
                </div>
              </Row>
            </div>

            <SimpleBar style={{ maxHeight: "300px" }}>
              <Link to="" className="text-reset notification-item">
                <div className="media">
                  <div className="avatar-xs me-3">
                    <span className="avatar-title bg-primary rounded-circle font-size-16">
                      <i className="bx bx-time" />
                    </span>
                  </div>
                  <div className="media-body">
                    <h6 className="mt-0 mb-1">
                      {this.props.t(
                        "inspection_coming_up",
                        "You have an inspection coming up!"
                      )}
                    </h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">
                        {this.props.t(
                          "inspection_coming_up_detail",
                          "Your next inspection is coming up at {{time}} on {{date}}",
                          {
                            time: "11:30am",
                            date: this.props.t("april", "April") + " 19",
                          }
                        )}
                      </p>
                      <p className="mb-0">
                        <i className="mdi mdi-clock-outline" />{" "}
                        {"3 " +
                          this.props.t("minutes_ago_abbrieviated", "min ago")}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="" className="text-reset notification-item">
                <div className="media">
                  <div className="avatar-xs me-3">
                    <span className="avatar-title bg-success rounded-circle font-size-16">
                      <i className="bx bx-badge-check" />
                    </span>
                  </div>
                  <div className="media-body">
                    <h6 className="mt-0 mb-1">
                      {this.props.t(
                        "customer_signed_contract",
                        "Customer signed a contract!"
                      )}
                    </h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">
                        {this.props.t(
                          "customer_signed_contract_detail",
                          "{{user}} signed the contract you created on {{date}}",
                          {
                            user: "Joe Michieli",
                            date: this.props.t("april", "April") + " 17",
                          }
                        )}
                      </p>
                      <p className="mb-0">
                        <i className="mdi mdi-clock-outline" />{" "}
                        {"10 " +
                          this.props.t("minutes_ago_abbrieviated", "min ago")}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </SimpleBar>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

NotificationDropdown.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(NotificationDropdown);
