import { InputGroup, Button, Input, InputGroupText, Spinner } from "reactstrap";

import Icon from "components/Common/Icons";
import { useMutation } from "@tanstack/react-query";

interface ResourceFilterInputProps {
  resource: string;
  filter: string;
  setFilter: (value: string) => void;
  onCreate: (val: string) => Promise<void>;
}

export function ResourceFilterInput(props: ResourceFilterInputProps) {
  const { resource, filter, setFilter, onCreate } = props;
  const createMutation = useMutation({
    mutationFn: onCreate,
  });

  return (
    <InputGroup style={{ flex: 4 }}>
      <InputGroupText>
        <Icon name="search" />
      </InputGroupText>
      {filter.trim() && (
        <Button
          outline
          onClick={() => {
            setFilter("");
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Clear Filter
          </div>
        </Button>
      )}
      <Input
        placeholder={`Filter or create ${props.resource}...`}
        value={filter}
        onChange={e => {
          setFilter(e.currentTarget.value);
        }}
      />
      <Button
        disabled={!Boolean(filter.trim())}
        color={filter.trim() ? "success" : "secondary"}
        onClick={() => {
          onCreate(filter);
        }}
      >
        {createMutation.isLoading ? (
          <Spinner size="sm" />
        ) : (
          `Create ${filter.trim() ? ` "${filter}"` : `${resource}`}`
        )}
      </Button>
    </InputGroup>
  );
}
