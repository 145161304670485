import { useTranslation } from "react-i18next";

export function useValidation() {
  const { t } = useTranslation();

  return {
    tax_id_name: [
      {
        type: "required",
        message: t("tax_id_name_required", "Tax ID name is required"),
      },
    ],
    tax_id_number: [
      {
        type: "required",
        message: t("tax_id_number_required", "Tax ID number is required"),
      },
    ],
    tax_name: [
      {
        type: "required",
        message: t("tax_name_required", "Tax name is required"),
      },
    ],
    tax_rate: [
      {
        type: "required",
        message: t("tax_rate_required", "Tax rate is required"),
      },
    ],
    internal_description: [],
    tax_group_name: [
      {
        type: "required",
        message: t("tax_group_name_required", "Tax group name is required"),
      },
    ],
    tax_group_rate: [
      {
        type: "required",
        message: t("tax_group_rate_required", "Tax group rate is required"),
      },
    ],
    tax_group_rates: [
      {
        type: "required",
        message: t("tax_group_rate_required", "Tax group rates are required"),
      },
    ],
  };
}
