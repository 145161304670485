import { useTranslation } from "react-i18next";
import * as Yup from "yup";

export default function useWorkOrderValidation() {
  const { t } = useTranslation();

  const addressValidation = Yup.object().shape({
    address1: Yup.string().required(
      t("address_required", "Please fill in the address")
    ),
    city: Yup.string().required(t("city_required", "Please fill in the city")),
    country: Yup.string().required(
      t("country_required", "Please select a country")
    ),
    postCode: Yup.string().required(
      t("postcode_required", "Please enter a postal code")
    ),
    region: Yup.string().required(
      t("region_required", "Please select a region")
    ),
  });

  const schema = Yup.object().shape({
    inspection: Yup.object().shape({
      address: addressValidation,
    }),
    clientInformation: Yup.array()
      .min(1, t("one_client_required", "Please enter at least one client"))
      .of(
        Yup.object().shape({
          firstName: Yup.string().required(
            t(
              "client_firstname_required",
              "Please enter the client's first name"
            )
          ),
          lastName: Yup.string().required(
            t("client_lastname_required", "Please enter the client's last name")
          ),
          primaryPhone: Yup.string().required(
            t(
              "client_phone_required",
              "Please enter the client's primary phone number"
            )
          ),
          email: Yup.string()
            .required(
              t(
                "client_email_required",
                "Please enter the client's email address"
              )
            )
            .email(
              t("client_email_invalid", "Client's email appears to be invalid")
            ),
          address: Yup.object().when("inspectionAddressSame", {
            is: inspectionAddressSame => inspectionAddressSame !== true,
            then: addressValidation,
          }),
        })
      ),
    bookedby: Yup.object().shape({
      source: Yup.string().required(
        t("booked_by_required", "Please select a booked by source")
      ),
    }),
  });
  return { schema };
}
