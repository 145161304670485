import { API, graphqlOperation } from "aws-amplify";
import { getDiscount as getDiscountQuery } from "graphql/queries";
import {
  createDiscount as createDiscountMutation,
  updateDiscount as updateDiscountMutation,
  deleteDiscount as deleteDiscountMutation,
} from "graphql/mutations";

import { listDiscounts as listDiscountsQuery } from "graphql/queries";

import { getAccessToken } from "helpers/graphql";
const { v4: uuidv4 } = require("uuid");

// Get Discounts
const getDiscountsQuery = /* GraphQL */ `
  query ListDiscounts(
    $filter: ModelDiscountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDiscounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        type
        appliesTo
        startDate
        endDate
        owner {
          level
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export async function fetchDiscounts(ownerId) {
  const res = await API.graphql(
    graphqlOperation(listDiscountsQuery),
    { ownerId },
    await getAccessToken()
  );
  console.log(res);

  // return res.data.listDiscounts.items.map(discount => ({
  //   ...discount,
  // }));
}

export async function getDiscount(id) {
  const res = await API.graphql(graphqlOperation(getDiscountQuery, { id }));
  return res.data?.getDiscount;
}

export async function updateDiscount(input) {
  // delete input.owner;
  delete input.createdAt;
  delete input.updatedAt;
  const res = await API.graphql(
    graphqlOperation(updateDiscountMutation, { input })
  );
  return res.data?.updateDiscount;
}

export async function createDiscount(input) {
  input.value = parseFloat(input.value);
  console.log(input);
  const res = await API.graphql(
    graphqlOperation(
      createDiscountMutation,
      {
        input: input,
      },
      await getAccessToken()
    )
  );

  return {
    ...res.data.createDiscount,
  };
}

// Delete Discounts
export async function deleteDiscount(input) {
  const res = await API.graphql(
    graphqlOperation(deleteDiscountMutation, {
      input: {
        id: input,
      },
    })
  );
}
