export default function StickyOnChange(props) {
  if (
    document.querySelector("#sticky_on_change") &&
    props.formikProps.dirty &&
    props.formikProps.touched &&
    Object.keys(props.formikProps.touched).length > 0
  ) {
    document
      .getElementsByClassName("tab-pane active")[0]
      ?.querySelector("#sticky_on_change")
      ?.previousElementSibling.classList.add("bottom-float-btn-group");
  } else {
    document
      .getElementsByClassName("tab-pane active")[0]
      ?.querySelector("#sticky_on_change")
      ?.previousElementSibling.classList.remove("bottom-float-btn-group");
  }
  return <div id="sticky_on_change"></div>;
}
