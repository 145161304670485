import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Nav } from "reactstrap";

class SideMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerContent: false,
      drawerOpen: false,
    };

    this.prefixedTranslation = this.prefixedTranslation.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);
  }
  prefixedTranslation(key, label) {
    return this.props.t(this.props.prefix + "_" + key, label);
  }

  componentDidUpdate() {
    window.setupShadows();
  }

  toggleDrawer(content) {
    if (this.state.drawerOpen) {
      this.setState({
        drawerOpen: false,
      });
      setTimeout(() => {
        this.setState({
          drawerContent: false,
        });
      }, 500);
    } else {
      this.setState({
        drawerContent: content,
      });
      setTimeout(() => {
        this.setState({
          drawerOpen: true,
        });
        window.setupShadows();
      }, 100);
    }
  }
  render() {
    return (
      <React.Fragment>
        <Nav
          pills
          vertical
          className="flex-column scroll-shadow"
          id="side-nav"
          onScroll={this.props.onScroll}
        >
          {React.Children.map(this.props.children, child => {
            return React.cloneElement(child, {
              t: this.prefixedTranslation,
              toggleDrawer: this.toggleDrawer,
            });
          })}
        </Nav>
        <div
          id="submenu-drawer"
          className={this.state.drawerOpen ? "open" : ""}
        >
          {this.state.drawerContent}
        </div>
      </React.Fragment>
    );
  }
}

SideMenu.propTypes = {
  children: PropTypes.array,
  prefix: PropTypes.string,
  t: PropTypes.func,
  onScroll: PropTypes.func,
};

export default withTranslation()(SideMenu);
