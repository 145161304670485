import React, { useState } from "react";

import StandardPage from "components/Common/StandardPage";

// Redux
import { Link } from "react-router-dom";

// actions
import WorkOrdersGrid from "components/Tables/WorkOrdersGrid";
import { Item } from "devextreme-react/data-grid";
import { Button as ReactButton } from "reactstrap";
import FilterMenu from "./_filterMenu";
import { useWorkOrders } from "hooks/workorders/workorders";
import { useTranslation } from "react-i18next";
import { WorkOrder } from "hooks/workorders/workorder";

// Tab Content

export default function WorkOrders(props: any) {
  const { workOrders } = useWorkOrders();
  let [filteredWorkOrders, setFilteredWorkOrders] = useState<
    WorkOrder[] | undefined
  >();
  let [dataGrid, setDataGrid] = useState<any>();
  const { t } = useTranslation();

  const searchGrid = (e: any) => {
    if (dataGrid !== undefined) dataGrid.searchByText(e.value);
  };

  const onFilter = (filteredData: WorkOrder[]) => {
    setFilteredWorkOrders(filteredData);
  };

  return (
    <StandardPage
      pageid="work_orders"
      pagetitle={t("work_orders_page_title", "Work orders and reports")}
      breadcrumbItems={[
        {
          uri: "/work",
          text: t("work", "Work"),
        },
        {
          uri: "/work/orders",
          text: t("orders_listing", "Order listing"),
          active: true,
        },
      ]}
      breadcrumbIcon="bx bx-spreadsheet"
    >
      <FilterMenu workOrders={workOrders.data} onFilter={onFilter}></FilterMenu>
      <WorkOrdersGrid
        setDataGrid={setDataGrid}
        dataSource={filteredWorkOrders || workOrders.data}
        allowPaging={true}
        allowFiltering={true}
        toolbarItems={
          <React.Fragment>
            <Item location="before">
              <Link to={"/work/order/new"}>
                <ReactButton
                  className="btn btn-sm"
                  color="primary"
                  type="button"
                >
                  {t("add_work_order", "Add work order")}
                </ReactButton>
              </Link>
            </Item>
            <Item
              widget="dxTextBox"
              options={{
                type: "search",
                placeholder: t("search", "Search") + "...",
                onValueChanged: searchGrid,
              }}
              location="after"
            />
          </React.Fragment>
        }
      ></WorkOrdersGrid>
    </StandardPage>
  );
}
