import Icon from "components/Common/Icons";
import { formatDate } from "devextreme/localization";
import { useDistance } from "hooks/distance";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, Col, Collapse, Row } from "reactstrap";
import { HashLink } from "react-router-hash-link";

export default function DrivingCard(props) {
  const { t } = useTranslation();
  let [active, setActive] = useState(false);
  let drive = useDistance(props.fromAddress, props.toAddress, "metric");
  let driveTime =
    drive && drive.status == "OK" ? drive.duration.value * 1000 : 0;
  let inspectionTime = new Date(props.nextInspectionTime);
  let leaveTime = new Date(inspectionTime - driveTime);

  return (
    <Card className="mb-0 border-bottom">
      <CardBody className="p-0">
        <div className="accordion">
          <div className="accordion-item border-0 p-0">
            <h2
              className={
                "font-weight-normal accordion-header " +
                (active ? "active" : "")
              }
              id={props.nextInspectionId}
              onClick={() => setActive(!active)}
              style={{ cursor: "pointer" }}
            >
              <Icon name="car" />{" "}
              {t("suggested_departure_time", "Suggested departure time")} :{" "}
              {drive && drive.status == "OK"
                ? formatDate(leaveTime, "longTime")
                : "(calculating)"}
            </h2>
            <Collapse isOpen={active} className="accordion-collapse">
              <div className="accordion-body bg-light bg-opacity-50">
                <Row>
                  <Col>
                    {t("from", "From")}{" "}
                    <HashLink to="/company/settings#address">
                      ({t("change_location", "change location")})
                    </HashLink>
                  </Col>
                  <Col>{t("to", "To")}</Col>
                </Row>
                <Row>
                  <Col>{props.fromAddress}</Col>
                  <Col>{props.toAddress}</Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <h3>{t("distance", "Distance")}</h3>
                  </Col>
                  <Col>
                    {drive && drive.status == "OK"
                      ? drive.distance.text
                      : t("address_not_found", "Address not found")}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h3>{t("time", "Time")}</h3>
                  </Col>
                  <Col>
                    {drive && drive.status == "OK"
                      ? drive.duration.text
                      : t("address_not_found", "Address not found")}
                  </Col>
                </Row>
              </div>
            </Collapse>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}
