import { StandardDropdown, TextInput } from "components/Fields";
import { StandardSwitch } from "components/Fields/Switches/StandardSwitch";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, Col, Label, Row } from "reactstrap";

export default function LimitSection(props: any) {
  const { t } = useTranslation();
  const { values, errors, handleChange } = useFormikContext<any>();

  if (!values?.type) {
    return <></>;
  }

  return (
    <Card>
      <CardBody>
        <Row>
          <Col lg={6}>
            <Label className="col-form-label">
              {t("discount_limit_label", "Apply usage limits?")}
            </Label>
            <StandardSwitch
              name="limit"
              id="limit"
              label={t("yes", "Yes")}
              onChange={handleChange}
              checked={values?.limit}
            />
            {values?.limit ? (
              <>
                <Label className="col-form-label">
                  {t(
                    "discount_limit_one_per_customer_label",
                    "One per customer?"
                  )}
                </Label>
                <StandardSwitch
                  name="onePerCustomer"
                  id="onePerCustomer"
                  label={t("yes", "Yes")}
                  onChange={handleChange}
                  checked={values?.onePerCustomer}
                />
              </>
            ) : (
              ""
            )}
          </Col>
          {values?.limit ? (
            <Col lg={6}>
              <Label className="col-form-label">
                {t(
                  "discount_limit_fixed_amount",
                  "Limited number of times that can be used?"
                )}
              </Label>
              <StandardSwitch
                name="fixedLimit"
                id="fixedLimit"
                label={t("yes", "Yes")}
                onChange={handleChange}
                checked={values?.fixedLimit}
              />
              {values?.fixedLimit ? (
                <TextInput
                  name="fixedLimitAmount"
                  label={t(
                    "discount_limit_fixed_amount_label",
                    "Number of times"
                  )}
                  showErrors={true}
                />
              ) : (
                ""
              )}
            </Col>
          ) : (
            ""
          )}
        </Row>
      </CardBody>
    </Card>
  );
}
