import { API, Auth, Storage } from "aws-amplify";

import noAvatar from "assets/images/no-avatar.png";
import noLogo from "assets/images/no-logo.png";

import { faker } from "@faker-js/faker";

const { v4: uuid } = require("uuid");

class BackendHelper {
  queryApi = async (api, path) => {
    return API.get(api, path)
      .then(async response => {
        return response;
      })
      .catch(error => {
        console.log(error);
        return false;
      });
  };

  forgotPassword = async obj => {
    Auth.forgotPassword(obj.email)
      .then(data => console.log(data))
      .catch(err => console.log(err));
  };

  recoverPassword = async details => {
    if (!details.confirm) {
      return Auth.forgotPasswordSubmit(
        details.email,
        details.code,
        details.password
      )
        .then(data => {
          return {
            success: true,
            message: "Password reset!",
          };
        })
        .catch(err => {
          return {
            success: false,
            message: err.message,
          };
        });
    } else {
      if (!details.privacy_policy || !details.eula) {
        return {
          success: false,
          confirm: true,
          message: "Please accept the privacy policy and EULA",
        };
      }

      //sign in first and then change password
      const signIn = await Auth.signIn({
        username: details.email,
        password: process.env.REACT_APP_NEW_USER_TEMP_PASSWORD,
      });
      if (!signIn) {
        return {
          success: false,
          message: "Account already confirmed",
        };
      }
      const user = await Auth.currentAuthenticatedUser();
      console.log(user);
      const response = await Auth.changePassword(
        user,
        process.env.REACT_APP_NEW_USER_TEMP_PASSWORD,
        details.password
      )
        .then(async data => {
          const dataUser = await API.get("UserMeta", "/userMeta", {});
          await API.put("UserMeta", "/userMeta", {
            body: {
              ...dataUser[0],
              confirmed: true,
              status: "available",
            },
          });

          // Update user attributes in cognito
          await Auth.updateUserAttributes(user, {
            "custom:privacy_policy": new Date().toISOString(),
            "custom:eula": new Date().toISOString(),
          });

          return {
            success: true,
            confirm: true,
            message: "Password set!",
          };
        })
        .catch(err => {
          return {
            success: false,
            message: err.message,
          };
        });

      return response;
    }
  };

  editTeamMember = async user => {
    if (user?.photo && user.photo.indexOf("no-avatar") !== -1) {
      user.photo = "";
    }

    let result = false;
    try {
      result = await API.put("UserMeta", "/userMeta", {
        body: user,
      });
    } catch (err) {
      console.log(err);
    }
    console.log(result);

    return result;
  };

  editProfile = async user => {
    const photo = user.photo;
    let dataUser = await this.queryApi("UserMeta", "/userMeta");
    if (dataUser.length === 1) {
      dataUser = dataUser[0];
    }
    user["photo"] = photo;
    const oldId = dataUser["id"];

    for (let key of Object.keys(user)) {
      if (
        typeof user[key] !== "undefined" &&
        (typeof user[key] === "object" || user[key].length > 0)
      ) {
        dataUser[key] = user[key];
      }
    }

    if (dataUser["id"] !== oldId) {
      return false;
    }

    if (dataUser?.photo && dataUser.photo.indexOf("no-avatar") !== -1) {
      dataUser.photo = "";
    }

    let result = false;
    try {
      result = await API.put("UserMeta", "/userMeta", {
        body: dataUser,
      });
    } catch (err) {
      console.log(err);
    }

    return result;
  };

  addTeamMember = async newMember => {
    let result;
    try {
      result = await API.post("UserMeta", "/userMeta", {
        body: newMember,
      });
      result.success = true;
    } catch (err) {
      console.log(err);
      result.success = false;
    }

    return result.success;
  };

  hydrateProfile = async () => {
    let dataUser = await this.queryApi("UserMeta", "/userMeta");
    if (dataUser.length === 1) {
      dataUser = dataUser[0];
    }

    let photoCheck = false;
    if (dataUser.photo !== undefined && dataUser.photo !== "") {
      photoCheck = await Storage.list(dataUser.photo, {
        level: "protected",
      });
    }
    let photo = noAvatar;
    if (photoCheck) {
      photo = await Storage.get(dataUser.photo, {
        level: "protected",
      });
    }
    dataUser.photo = photo;
    return dataUser;
  };

  hydrateWorkOrders = async () => {
    return {
      workorders: await this.queryApi("WorkOrder", "/work/order"),
      error: false,
      success: true,
    };
  };

  hydrateWorkOrder = async id => {
    return {
      workOrder: await this.queryApi("WorkOrder", "/work/order/" + id),
      error: false,
      success: true,
    };
  };

  editWorkOrder = async WorkOrder => {
    let dataWorkOrder = await this.queryApi(
      "WorkOrder",
      "/work/order/" + WorkOrder.id
    );
    const oldId = dataWorkOrder["id"];

    for (let key of Object.keys(WorkOrder)) {
      dataWorkOrder[key] = WorkOrder[key];
    }
    if (dataWorkOrder["id"] !== oldId) {
      return false;
    }

    dataWorkOrder["status"] = "booked";
    let result = {
      success: false,
      fail: false,
      workOrder: null,
    };
    try {
      result.workOrder = await API.put("WorkOrder", "/work/order", {
        body: dataWorkOrder,
      });
      result.success = true;
    } catch (err) {
      console.log(err);
      result.fail = true;
    }
    return result;
  };

  addWorkOrder = async WorkOrder => {
    let result = {
      success: false,
      fail: false,
      workOrder: null,
    };
    WorkOrder["status"] = "booked";
    try {
      console.log("putting");
      result.workOrder = await API.put("WorkOrder", "/work/order", {
        body: WorkOrder,
      });
      result.success = true;
    } catch (err) {
      console.log(err);
      result.fail = true;
    }
    return result;
  };

  hydrateSnippet = async snippetType => {
    let results = await this.queryApi(
      "Snippet",
      "/snippet/snippetType/" + snippetType.join(",")
    );
    let newResults = {};
    results.forEach(snippet => {
      newResults[snippet.snippetType] = {
        ...snippet,
      };
    });

    return newResults;
  };

  hydrateSnippets = async () => {
    let results = await this.queryApi("Snippet", "/snippet");
    if (results === false || results.length === 0) {
      this.generateSystemSnippets();
      results = await this.queryApi("Snippet", "/snippet");
    }
    return results;
  };

  createSnippet(snippetType, category, values) {
    let snippet = {
      id: uuid(),
      snippetType: snippetType,
      category: category,
      companyId: "",
      franchisorId: "",
      userId: "",
      values: [],
    };
    let count = 0;
    values.forEach(item => {
      snippet.values.push({
        id: uuid(),
        language: "en-US",
        orderNumber: count++,
        text: item,
      });
    });
    return snippet;
  }

  generateSystemSnippets() {
    let snippet = this.createSnippet("property_type", "work_order", [
      "Single Family",
      "Duplex",
      "Triplex",
      "Fourplex",
      "Multi-unit",
      "Condo/Strata",
      "Single + Suite",
      "Multi Family",
      "Single + Bsmt Apt.",
    ]);
    this.addSnippet(snippet);
    snippet = this.createSnippet("building_type", "work_order", [
      "Unknown",
      "Attached",
      "Detached",
      "Semi-detached",
      "Backsplit",
      "Sidesplit",
      "Townhome",
      "Condo - high rise",
      "Condo - townhome",
      "Condo - stacked",
      "Bi - level",
      "Tri - level",
      "Apartment",
      "Manufactured",
    ]);
    this.addSnippet(snippet);
    snippet = this.createSnippet("floors", "work_order", [
      "Bungalow (1)",
      "1+",
      "2",
      "2+",
      "3",
      "3+",
      "4",
      "4+",
      "5",
      "5+",
      "6",
      "6+",
    ]);
    this.addSnippet(snippet);
    snippet = this.createSnippet("rooms", "work_order", [
      "1+",
      "2",
      "2+",
      "3",
      "3+",
      "4",
      "4+",
      "5",
      "5+",
      "6",
      "6+",
    ]);
    this.addSnippet(snippet);
    snippet = this.createSnippet("property_title", "work_order", [
      "Freehold",
      "Co-operative",
      "Condominium/strata",
      "Leasehold",
    ]);
    this.addSnippet(snippet);
    snippet = this.createSnippet("heating_type", "work_order", [
      "Forced Air-1",
      "Forced Air-2",
      "Baseboard",
      "Fan Coil",
      "In Floor Heat System",
      "Gravity",
      "Hot Water",
      "Heat Pump",
      "Space Heater",
      "Steam",
      "Wood Stove",
    ]);
    this.addSnippet(snippet);
    snippet = this.createSnippet("heating_fuel", "work_order", [
      "Coal",
      "Electric",
      "Geo Thermal",
      "Natural Gas",
      "Oil",
      "Propane",
      "Solar",
      "Wood",
    ]);
    this.addSnippet(snippet);
    snippet = this.createSnippet("cooling_type", "work_order", [
      "Central air conditioning",
      "Portable Air conditioning",
      "Window Air conditioning",
      "Ductless Split Air conditioning",
      "Air-Sourced Central Heat Pump",
      "Ductless Split Heat Pump",
      "Geothermal Heat Pump",
    ]);
    this.addSnippet(snippet);
    snippet = this.createSnippet("features", "work_order", [
      "Swimming Pool",
      "Balconies",
      "Assisted Living",
      "Balconies",
      "Beach Access",
      "Boating",
      "Club House",
      "Fireplaces",
      "Private Fishing",
      "Gazebo",
      "Guest Suite",
      "Laundry In-Suite",
      "Laundry Same-Floor",
      "Party Room",
      "Picnic Area",
      "Playground",
      "Pool Indoor",
      "Pool Outdoor",
      "Private Park Access",
      "Parking Extra",
      "Sauna/Swirlpool/Steam Room",
      "Storage In-Suite",
      "Storage – Locker Room",
      "Social Rooms",
      "Swirlpool Bath (Jacuzzi)",
      "Tennis Courts",
      "Workshop",
      "Car Wash",
      "Daycare Centre",
      "Elevator Freight",
      "Elevator Passenger",
      "Exercise Room",
      "Fenced",
      "Handicap Access",
      "Handicap Interior Accessories",
      "Intercom",
      "Lake Privileges",
      "Laundry Coin-Op",
      "Laundry Common",
      "Parking Plug-Ins",
      "Parking – Visitor",
      "Racquet Courts",
      "Recreation Room/Centre",
      "R.V. Storage",
      "Satellite TV Connection",
      "Sauna",
      "Security Door",
      "Secured Parking",
      "Security Personnel",
      "Skating",
      "Workshop",
    ]);
    this.addSnippet(snippet);
    snippet = this.createSnippet("parking", "work_order", [
      "Detached Garage",
      "Breezeways",
      "Attached Garages",
      "Parkade Parking",
      "Outdoor Stalls",
      "Parking Pad",
      "Carport",
      "On-Street Parking",
      "Off-Street Parking",
      "Indoor Parking",
      "Underground Parking",
    ]);
    this.addSnippet(snippet);
    snippet = this.createSnippet("roof_type", "work_order", [
      "Asphalt Shingles",
      "Clay Tile",
      "Concrete Tiles",
      "EPDM* Membrane",
      "Fiberglass",
      "Metal",
      "Cedar Shakes",
      "Pine Shakes",
      "Tar & Gravel",
      "Vinyl Shingles",
      "Wood Shingles",
      "Roll Roofing",
    ]);
    this.addSnippet(snippet);
    snippet = this.createSnippet("occupancy", "work_order", [
      "Furnished",
      "Occupied",
      "Utilities off",
      "Vacant",
    ]);
    this.addSnippet(snippet);
    snippet = this.createSnippet("duration", "inspection", [
      "0.0",
      "0.5",
      "1.0",
      "1.5",
      "2.0",
      "2.5",
      "3.0",
      "3.5",
      "4.0",
      "4.5",
      "5.0",
    ]);
    this.addSnippet(snippet);
    snippet = this.createSnippet("referral_source", "booking", [
      "None",
      "Buyer's agent",
      "Seller's agent",
      "Friend",
      "Relative",
      "Lender",
      "Relocation Co.",
      "Title Co.",
      "Other (please specify)",
    ]);
    this.addSnippet(snippet);
    snippet = this.createSnippet("booked_by_source", "booking", [
      "None",
      "Buyer's agent",
      "Seller's agent",
      "Homeowner",
      "Home buyer",
      "Relocation Co.",
      "Other (please specify)",
    ]);
    this.addSnippet(snippet);
    snippet = this.createSnippet("house_facing", "work_order", [
      "North",
      "East",
      "South",
      "West",
    ]);
    this.addSnippet(snippet);
  }

  editSnippet = async Snippet => {
    let dataSnippet = await this.queryApi(
      "Snippet",
      "/snippet/" + Snippet.id + "/" + Snippet.snippetType
    );
    const oldId = dataSnippet["id"];

    for (let key of Object.keys(Snippet)) {
      dataSnippet[key] = Snippet[key];
    }

    if (dataSnippet["id"] !== oldId) {
      return false;
    }

    let result = {
      success: false,
      fail: false,
      Snippet: null,
    };
    try {
      result.company = await API.put("Snippet", "/snippet", {
        body: dataSnippet,
      });
      result.success = true;
    } catch (err) {
      console.log(err);
      result.fail = true;
    }
    return result;
  };

  addSnippet = async Snippet => {
    let result = {
      success: false,
      fail: false,
      snippet: null,
    };
    try {
      result.snippet = await API.put("Snippet", "/snippet", {
        body: Snippet,
      });
      result.success = true;
    } catch (err) {
      console.log(err);
      result.fail = true;
    }
    return result;
  };

  hydrateCompanySettings = async () => {
    let dataCompany = await this.queryApi("Company", "/company");
    dataCompany = dataCompany[0];
    let photoCheck = false;
    if (dataCompany.photo !== undefined && dataCompany.photo !== "") {
      try {
        photoCheck = await Storage.list(dataCompany.photo, {
          level: "protected",
        });
      } catch (e) {
        console.log(e);
      }
    }
    let photo = noLogo;
    if (photoCheck) {
      photo = await Storage.get(dataCompany.photo, {
        level: "protected",
      });
    }
    dataCompany.photo = photo;
    return dataCompany;
  };

  editCompanySettings = async companySettings => {
    let dataCompany = await this.queryApi("Company", "/company");
    dataCompany = dataCompany[0];

    const oldId = dataCompany["id"];

    for (let key of Object.keys(companySettings)) {
      dataCompany[key] = companySettings[key];
    }

    if (dataCompany["id"] !== oldId) {
      return false;
    }

    if (dataCompany.photo && dataCompany.photo.indexOf("no-logo") !== -1) {
      dataCompany.photo = "";
    }

    let result = {
      success: false,
      fail: false,
      company: null,
    };

    try {
      result.company = await API.put("Company", "/company", {
        body: dataCompany,
      });
      result.success = true;
    } catch (err) {
      console.log(err);
      result.fail = true;
    }
    return result;
  };

  getInspections = async () => {
    const inspections = [];
    for (let i = 0; i < 20; i++) {
      inspections.push(createRandomInspection());
    }

    return { inspections: inspections, success: true };
  };

  getCompanyTeam = async () => {
    let dataUser = await this.queryApi("UserMeta", "/userMeta");
    if (dataUser.length === 1) {
      dataUser = dataUser[0];
    }
    const team = await this.queryApi(
      "UserMeta",
      "/userMeta/company/" + dataUser["companies"][0]
    );

    await team.forEach(async (user, index) => {
      if (user["photo"] != "" && user["photo"] != undefined) {
        team[index]["photo"] = await Storage.get(user["photo"], {
          level: "protected",
        });
      }
    });

    return { team: team, success: true };
  };
}

const statuses = [
  {
    text: "Available",
    color: "success",
  },
  {
    text: "Inspection in progress",
    color: "info",
  },
  {
    text: "Disabled",
    color: "danger",
  },
  {
    text: "On Vacation",
    color: "dark",
  },
];

const createRandomInspection = () => {
  const types = ["Buyers", "Sellers"];

  const inspectors = ["Dory Aric", "Harvey Lew", "Isador Darin"];

  const inspectorStatuses = [
    {
      text: "Booked",
      color: "success",
    },
    {
      text: "Ready for Field",
      color: "success",
    },
    {
      text: "In the Field",
      color: "warning",
    },
    {
      text: "In Progress",
      color: "warning",
    },
    {
      text: "Completed",
      color: "success",
    },
    {
      text: "Cancelled",
      color: "danger",
    },
    {
      text: "Template",
      color: "dark",
    },
  ];

  const dates = [faker.date.past(1), faker.date.future(1)];
  const startDate = dates[faker.datatype.number({ min: 0, max: 1 })];
  let endDate = new Date();
  endDate.setTime(
    startDate.getTime() +
      faker.datatype.number({ min: 2, max: 4 }) * 60 * 60 * 1000
  );

  return {
    id: faker.datatype.uuid(),
    inspectionStart: startDate,
    inspectionEnd: endDate,
    type: types[faker.datatype.number({ min: 0, max: 1 })],
    unit: faker.datatype.number({ min: 0, max: 2000 }),
    streetAddress: faker.address.streetAddress(),
    city: faker.address.city(),
    region: faker.address.state(),
    postCode: faker.address.zipCode(),
    country: "USA",
    inspectors:
      inspectors[faker.datatype.number({ min: 0, max: inspectors.length - 1 })],
    status:
      inspectorStatuses[
        faker.datatype.number({ min: 0, max: statuses.length - 1 })
      ],
    buyersAgent: faker.name.firstName() + " " + faker.name.lastName(),
    sellersAgent: faker.name.firstName() + " " + faker.name.lastName(),
    clients: [
      {
        name: "Isbel Davey",
        contact: {
          mobile: "4165555555",
          email: "FakeAddress@gmail.com",
        },
      },
    ],
  };
};

const getBackendHelper = () => {
  return new BackendHelper();
};

export { getBackendHelper };
