import {
  TEAM_ERROR,
  TEAM_SUCCESS,
  RESET_TEAM_FLAG,
  HYDRATE_TEAM,
  HYDRATE_TEAM_SUCCESS,
  ADD_TEAM_MEMBER,
  EDIT_TEAM_MEMBER,
  EDIT_TEAM_MEMBER_SUCCESS,
  ADD_TEAM_MEMBER_SUCCESS,
} from "./actionTypes";

export const teamSuccess = msg => {
  return {
    type: TEAM_SUCCESS,
    payload: msg,
  };
};

export const teamError = error => {
  return {
    type: TEAM_ERROR,
    payload: error,
  };
};

export const resetTeamFlag = () => {
  return {
    type: RESET_TEAM_FLAG,
  };
};

export const hydrateTeam = () => {
  return {
    type: HYDRATE_TEAM,
  };
};

export const hydrateTeamSuccess = team => {
  return {
    type: HYDRATE_TEAM_SUCCESS,
    payload: team,
  };
};

export const addTeamMember = newMember => {
  return {
    type: ADD_TEAM_MEMBER,
    payload: newMember,
  };
};

export const editTeamMember = member => {
  return {
    type: EDIT_TEAM_MEMBER,
    payload: member,
  };
};

export const editTeamMemberSuccess = member => {
  return {
    type: EDIT_TEAM_MEMBER_SUCCESS,
    payload: member,
  };
};

export const addTeamMemberSuccess = newMemberId => {
  return {
    type: ADD_TEAM_MEMBER_SUCCESS,
    payload: newMemberId,
  };
};
