import React from "react";
import { Alert, Card, CardBody, Col, Container, Row, Label } from "reactstrap";
import MetaTags from "react-meta-tags";
import { PasswordInput } from "components/Fields";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// action
import { registerUser } from "../../store/actions";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";

// import images
import homeImage from "../../assets/images/home-magnifier.png";
import horizonLogo from "../../assets/images/horizon-logo-no-text.svg";
import npiLogo from "assets/images/npi-logo-sm.png";

import PrivacyModal from "pages/PrivacyPolicy/components/privacyModal";
import EulaModal from "pages/EULA/components/eulaModal";

export default function Register(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { registrationError } = useSelector(state => state.Account);
  let logo = horizonLogo;
  let title = "Horizon";
  let copyright =
    "© " + new Date().getFullYear() + " Carson Dunlop & Associates";
  if (window.location.href.indexOf("npi") !== -1) {
    logo = npiLogo;
    title = "NPI";
    copyright =
      "© " + new Date().getFullYear() + " National Property Inspections";
  }
  const defaultState = {
    logo,
    title,
    copyright,
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>
          {t("register_page_title", "Register")} | {defaultState.title}
        </title>
      </MetaTags>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-white p-4">
                        <h5 className="text-white">
                          {t(
                            "register_account_title",
                            "Register a new account"
                          )}
                        </h5>
                        <p>
                          {t(
                            "register_account_subtitle",
                            "Please fill out the form below"
                          )}
                        </p>
                      </div>
                    </Col>
                    <Col
                      className="position-absolute mr-3 col-6"
                      style={{
                        right: "0",
                        top: "20px",
                      }}
                    >
                      <img src={homeImage} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="auth-logo mt-n4">
                    <Link to="/" className="auth-logo-dark">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-white">
                          <img src={defaultState.logo} alt="" height="34" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {registrationError && registrationError.message ? (
                      <Alert color="danger">{registrationError.message}</Alert>
                    ) : null}
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        first_name:
                          (defaultState && defaultState.first_name) || "",
                        last_name:
                          (defaultState && defaultState.last_name) || "",
                        email: (defaultState && defaultState.email) || "",
                        password: "",
                        confirmPassword: "",
                        privacy_policy: false,
                        eula: false,
                      }}
                      validationSchema={Yup.object().shape({
                        first_name: Yup.string().required(
                          t(
                            "error_first_name_invalid",
                            "Please enter a first name"
                          )
                        ),
                        last_name: Yup.string().required(
                          t(
                            "error_last_name_invalid",
                            "Please enter a last name"
                          )
                        ),
                        email: Yup.string()
                          .email(
                            t(
                              "error_email_invalid",
                              "Please enter a valid email address"
                            )
                          )
                          .required(
                            t("error_email_blank", "Please Enter Your Email")
                          ),
                        password: Yup.string().required(
                          t(
                            "error_password_invalid",
                            "Please Enter Valid Password"
                          )
                        ),
                        confirmPassword: Yup.string()
                          .oneOf(
                            [Yup.ref("password"), null],
                            t(
                              "error_passwords_do_not_match",
                              "Passwords do not match"
                            )
                          )
                          .required(
                            t(
                              "error_confirm_password_blank",
                              "Please confirm your new password"
                            )
                          ),
                        privacy_policy: Yup.bool().oneOf(
                          [true],
                          "You need to agree the privacy policy."
                        ),
                        eula: Yup.bool().oneOf(
                          [true],
                          "You need to agree the End User License Agreement (EULA)"
                        ),
                      })}
                      onSubmit={values => {
                        console.log("values", values);
                        debugger;
                        values.self_register = true;
                        dispatch(registerUser(values, history));
                      }}
                    >
                      {({ errors, touched, values }) => (
                        <Form className="form-horizontal">
                          <Row>
                            <div className="mb-3 col-md-6">
                              <Label for="first_name" className="form-label">
                                {t("first_name", "First name")}
                              </Label>
                              <Field
                                name="first_name"
                                type="first_name"
                                className={
                                  "form-control" +
                                  (errors.first_name && touched.first_name
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="first_name"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mb-3 col-md-6">
                              <Label for="last_name" className="form-label">
                                {t("last_name", "Last name")}
                              </Label>
                              <Field
                                name="last_name"
                                type="last_name"
                                className={
                                  "form-control" +
                                  (errors.last_name && touched.last_name
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="last_name"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                          </Row>
                          <div className="mb-3">
                            <Label for="email" className="form-label">
                              {t("email", "Email")}
                            </Label>
                            <Field
                              name="email"
                              type="email"
                              className={
                                "form-control" +
                                (errors.email && touched.email
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="mb-3">
                            <PasswordInput
                              name="password"
                              label={t("password", "Password")}
                              showErrors={true}
                              formik={{ values, errors, touched }}
                              allowShowPassword={true}
                            />
                          </div>
                          <div className="mb-3">
                            <PasswordInput
                              name="confirmPassword"
                              label={t("confirm_password", "Confirm password")}
                              showErrors={true}
                              formik={{ values, errors, touched }}
                              allowShowPassword={true}
                            />
                          </div>
                          <div className="mt-4">
                            <Field
                              type="checkbox"
                              name="privacy_policy"
                              className={
                                "mr-2" +
                                (errors.privacy_policy && touched.privacy_policy
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <Label className="mb-0" for="privacy_policy">
                              I accept the
                              <PrivacyModal
                                linkLabel={t(
                                  "privacy_policy",
                                  "Privacy Policy"
                                )}
                                modalTitle={t(
                                  "privacy_policy",
                                  "Privacy Policy"
                                )}
                                size="xl"
                              ></PrivacyModal>
                            </Label>
                            <ErrorMessage
                              name="privacy_policy"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="mt-1">
                            <Field
                              type="checkbox"
                              name="eula"
                              className={
                                "mr-2 " +
                                (errors.eula && touched.eula
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <Label className="mb-0" for="eula">
                              I accept the
                              <EulaModal
                                linkLabel={t(
                                  "eula",
                                  "End User License Agreement (EULA)"
                                )}
                                modalTitle={t(
                                  "eula",
                                  "End User License Agreement (EULA)"
                                )}
                                size="xl"
                              ></EulaModal>
                            </Label>
                            <ErrorMessage
                              name="eula"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="mt-5 d-grid">
                            <button
                              className="btn btn-primary btn-block"
                              type="submit"
                            >
                              {t("register", "Register")}
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-4 text-center">
                <p>
                  {t(
                    "already_have_account_question",
                    "Already have an account?"
                  )}{" "}
                  <Link to="/login" className="fw-medium text-primary">
                    {t("login", "Login")}
                  </Link>
                </p>
                <p>{defaultState.copyright}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
