import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { map } from "lodash";
import { connect } from "react-redux";

//i18n
import i18n from "i18n";
import { withTranslation } from "react-i18next";

import languages from "locales/languages";
import Icon from "components/Common/Icons";

class LanguageDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      selectedLang: "en-US",
    };
  }

  componentDidMount() {
    let currentLanguage = localStorage.getItem("I18N_LANGUAGE");
    if (currentLanguage === undefined) currentLanguage = "en-US";
    this.changeLanguageAction(currentLanguage);
    localStorage.setItem("loaded", "true");
  }

  changeLanguageAction = async lang => {
    //set language as i18n
    i18n.changeLanguage(lang);
    localStorage.setItem("I18N_LANGUAGE", lang);
    await this.setState({ selectedLang: lang });
    document.documentElement.setAttribute("lang", lang);
  };

  toggle = () => {
    this.setState(prevState => ({
      menu: !prevState.menu,
    }));
  };

  render() {
    const { selectedLang, menu } = this.state;

    return (
      <React.Fragment>
        <Dropdown isOpen={menu} toggle={this.toggle} className="d-inline-block">
          <DropdownToggle
            className="btn header-item d-flex align-items-center"
            tag="button"
          >
            <Icon name="world" />
            {window.innerWidth > 900 ? (
              <span style={{ marginLeft: "10px" }}>
                {languages[selectedLang].label}
              </span>
            ) : (
              ""
            )}
          </DropdownToggle>
          <DropdownMenu className="language-switch dropdown-menu-end text-end">
            {map(Object.keys(languages), key => (
              <DropdownItem
                key={key}
                onClick={() => this.changeLanguageAction(key)}
                className={`notify-item ${
                  selectedLang === key ? "active" : "none"
                }`}
              >
                <span className="align-middle">
                  {this.props.t(
                    languages[key].label + "_label",
                    languages[key].label
                  )}
                </span>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

LanguageDropdown.propTypes = {
  t: PropTypes.any,
  language: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    language: state.Profile.language,
  };
};

export default withTranslation()(connect(mapStateToProps)(LanguageDropdown));
