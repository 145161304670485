import { StandardDropdown, TextInput } from "components/Fields";
import { StandardRadio } from "components/Fields/Radios/StandardRadios";
import { StandardSwitch } from "components/Fields/Switches/StandardSwitch";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, Col, Label, Row } from "reactstrap";

export default function OptionsSection(props: any) {
  const { t } = useTranslation();
  const [applicationAmountLabel, setLabel] = useState<any>("");
  const { values, errors, handleChange } = useFormikContext<any>();

  useEffect(() => {
    switch (values?.option) {
      case "PERCENTAGE":
        setLabel(
          t("discount_percentage_amount_label", "Percentage to discount")
        );
        break;
      default:
        setLabel(t("discount_amount_label", "Amount to discount"));
        break;
    }
  });

  if (!values?.type) {
    return <></>;
  }

  return (
    <Card className="mb-0 border-bottom">
      <CardBody>
        <Row>
          <Col lg={6}>
            <StandardDropdown
              label={t("discount_options_label", "Discount options") + "*"}
              name="option"
              showErrors={true}
              onChange={handleChange}
              value={values?.option}
              className={errors["option"] ? " is-invalid" : ""}
              options={[
                {
                  label: t("discount_type_percentage_label", "Percentage"),
                  value: "PERCENTAGE",
                },
                {
                  label: t("discount_type_fixed_amount_label", "Fixed amount"),
                  value: "FIXED",
                },
                {
                  label: t("discount_type_free_amount_label", "Free"),
                  value: "FREE",
                },
              ]}
            />
          </Col>
          <Col lg={6}>
            {values?.option && values?.option !== "FREE" ? (
              <TextInput
                name="value"
                label={applicationAmountLabel + "*"}
                showErrors={true}
              />
            ) : (
              ""
            )}
          </Col>
        </Row>
        <Row className="mt-4">
          <Col lg={6}>
            <Label className="col-form-label">
              {t(
                "discount_minimum_purchase_required_label",
                "Minimum subtotal required"
              )}
            </Label>
            <StandardSwitch
              name="minPurchase"
              id="minPurchase"
              label={t("yes", "Yes")}
              onChange={handleChange}
              checked={values?.minPurchase}
            />
          </Col>
          {values?.minPurchase ? (
            <Col lg={6}>
              <TextInput
                name="minPurchaseAmount"
                className="w-sm w-auto"
                label={
                  t("discount_minimum_purchase_label", "Minimum subtotal") + "*"
                }
                showErrors={true}
              />
            </Col>
          ) : (
            ""
          )}
        </Row>
      </CardBody>
    </Card>
  );
}
