import PropTypes from "prop-types";
import React from "react";
import SideMenuNavItem from "components/Menus/SideMenuNavItem";
import SideMenu from "components/Menus/SideMenu";

import { withTranslation } from "react-i18next";

const Menu = props => {
  return (
    <React.Fragment>
      <SideMenu prefix="personal_settings_menu">
        <SideMenuNavItem
          id="profile"
          label={props.t("my_profile", "My Profile")}
          activeTab={props.activeTab}
          toggleTab={props.toggleTab}
        />
        <SideMenuNavItem
          id="availability"
          label={props.t("availability", "Availability")}
          activeTab={props.activeTab}
          toggleTab={props.toggleTab}
        />
        <SideMenuNavItem
          id="specialities"
          label={props.t(
            "specialities_and_services",
            "Specialities and services"
          )}
          activeTab={props.activeTab}
          toggleTab={props.toggleTab}
        />
        <SideMenuNavItem
          id="notifications"
          label={props.t("notifications", "Notifications")}
          activeTab={props.activeTab}
          toggleTab={props.toggleTab}
        />
        <SideMenuNavItem
          id="security"
          label={props.t(
            "security_and_account_access",
            "Security and account access"
          )}
          activeTab={props.activeTab}
          toggleTab={props.toggleTab}
        />
        <SideMenuNavItem
          id="license"
          label={props.t(
            "licenses_and_certificates",
            "Licences and Certificates"
          )}
          activeTab={props.activeTab}
          toggleTab={props.toggleTab}
        />
        <SideMenuNavItem
          id="dataPrivacy"
          label={props.t("data_and_privacy", "Data and privacy")}
          activeTab={props.activeTab}
          toggleTab={props.toggleTab}
        />
      </SideMenu>
    </React.Fragment>
  );
};

Menu.propTypes = {
  t: PropTypes.any,
  toggleTab: PropTypes.func,
  activeTab: PropTypes.string,
};

export default withTranslation()(Menu);
