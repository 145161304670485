import React, { useCallback, useEffect, useState } from "react";
import { Label, Input, Row, Col, Collapse, Button, TabPane } from "reactstrap";
import { Field, Formik, Form } from "formik";
import {
  TextInput,
  CountryDropdown,
  RegionDropdown,
  TimezoneDropdown,
  StandardDropdown,
} from "components/Fields";
import "reactstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css";
import { Storage } from "aws-amplify";
import noLogo from "assets/images/no-logo.png";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import BusinessHourRow from "components/Fields/BusinessHourRow";
import { days } from "constants/days";
import { months } from "constants/months";
import { times } from "constants/times";
import { currencies } from "constants/currencies";
import { useCompany } from "hooks/company";
import CompanySettingsSchema from "../schemas/_companySettings";
import moment from "moment";
import toast from "toastr";
import StickyOnChange from "components/Common/StickyOnChange";
import LeavingForm from "components/Common/LeavingForm";

export default function Details(props) {
  const { t } = useTranslation();
  const company = useCompany();
  const [dateFormatPreview, setDateFormatPreview] = useState(
    moment().format("YYYY-MM-DD")
  );
  const defaultState = {
    businessHours: {
      sunday: {
        label: t("sunday", "Sunday"),
        open: false,
        start: "09:00",
        end: "17:00",
      },
      monday: {
        label: t("monday", "Monday"),
        open: true,
        start: "09:00",
        end: "17:00",
      },
      tuesday: {
        label: t("tuesday", "Tuesday"),
        open: true,
        start: "09:00",
        end: "17:00",
      },
      wednesday: {
        label: t("wednesday", "Wednesday"),
        open: true,
        start: "09:00",
        end: "17:00",
      },
      thursday: {
        label: t("thursday", "Thursday"),
        open: true,
        start: "09:00",
        end: "17:00",
      },
      friday: {
        label: t("friday", "Friday"),
        open: true,
        start: "09:00",
        end: "17:00",
      },
      saturday: {
        label: t("saturday", "Saturday"),
        open: false,
        start: "09:00",
        end: "17:00",
      },
    },
    photo: "",
    google: "",
    name: "",
    country: "",
    primaryPhone: "",
    city: "",
    twitter: "",
    instagram: "",
    yelp: "",
    region: "",
    address1: "",
    facebook: "",
    homestars: "",
    angie: "",
    earliestAvailability: 1,
    postCode: "",
    timezone: "",
    serviceArea: "",
    description: "",
  };
  const schema = new CompanySettingsSchema({ t: t });
  const [photo, setPhoto] = useState(noLogo);
  const [uploadPhoto, setUploadPhoto] = useState();
  const [accordion, setAccordion] = useState({
    "service-details": true,
  });
  const [detailData, setDetailData] = useState(defaultState);
  const [invalidBusinessDays, setInvalidBusinessDays] = useState([]);

  function handleDateFormatChange(e) {
    const val = e.target.value;
    try {
      formatDate(val || "YYYY-MM-DD");
    } catch (err) {
      setDateFormatPreview(
        t(
          "error_date_format_unrecognized",
          "Unrecognized date format, using default YYYY-MM-DD"
        )
      );
    }
  }

  function formatDate(format) {
    const date = moment().format(format);
    setDateFormatPreview(date);
  }

  useEffect(() => {
    async function updatePhoto() {
      if (company.isLoading === false) {
        setDetailData(d => ({
          ...d,
          ...company.data,
        }));
        if (company.data.photo) {
          const newPhoto = await Storage.get(company.data.photo, {
            level: "protected",
          });
          setPhoto(newPhoto);
        }
      }
    }
    updatePhoto();
  }, [company.data, company.isLoading]);

  const toggleOpen = useCallback(
    day => {
      if (invalidBusinessDays.includes(day)) {
        let filteredArr = invalidBusinessDays.filter(item => item !== day);
        setInvalidBusinessDays(filteredArr);
      }
      setDetailData({
        ...detailData,
        businessHours: {
          ...detailData.businessHours,
          [day]: {
            ...detailData.businessHours[day],
            open: !detailData.businessHours[day].open,
          },
        },
      });
    },
    [detailData, invalidBusinessDays]
  );

  const timeChange = useCallback(
    (day, cap, time) => {
      if (invalidBusinessDays.includes(day)) {
        let filteredArr = invalidBusinessDays.filter(item => item !== day);
        setInvalidBusinessDays(filteredArr);
      }
      setDetailData({
        ...detailData,
        businessHours: {
          ...detailData.businessHours,
          [day]: {
            ...detailData.businessHours[day],
            [cap]: time,
          },
        },
      });
    },
    [detailData, invalidBusinessDays]
  );

  function toggleAccordionItem(item) {
    const newState = {
      ...accordion,
    };
    if (!accordion[item]) {
      newState[item] = true;
    } else {
      newState[item] = !accordion[item];
    }
    setAccordion(newState);
  }

  function handlePhotoChange(e, formik) {
    setPhoto(URL.createObjectURL(e.target.files[0]));
    setUploadPhoto(e.target.files[0]);
  }

  const uploadFile = useCallback(
    async file => {
      const splitName = file.name.split(".");
      const ext = splitName[splitName.length - 1];
      const key = company.data.id + "-company-logo." + ext;
      await Storage.put(key, file, {
        level: "protected",
        contentType: file.type,
      });
      const newPhoto = await Storage.get(key, {
        level: "protected",
      });
      return { key, newPhoto };
    },
    [company.data?.id]
  );

  const handleFormSubmit = useCallback(
    async values => {
      let businessHours = { ...values }.businessHours;
      for (const day in businessHours) {
        if (businessHours[day].open) {
          let start = businessHours[day].start.split(":");
          let end = businessHours[day].end.split(":");
          if (
            parseInt(start[0]) * 60 + parseInt(start[1]) >=
            parseInt(end[0]) * 60 + parseInt(end[1])
          ) {
            setInvalidBusinessDays(d => [...new Set([...d, day])]);
            toast.error("Business hours are invalid.");
            return;
          }
        }
      }
      if (
        values.earliestAvailability &&
        (values.earliestAvailability < 0 || values.earliestAvailability > 12)
      )
        return;
      let response = {
        newPhoto: "",
      };
      if (uploadPhoto) {
        response = await uploadFile(uploadPhoto);
        values.photo = response.key;
      } else {
        values.photo = detailData.photo;
      }
      company.mutation.mutate({ ...values });

      values.photo = response.newPhoto;
    },
    [uploadPhoto, uploadFile, company, detailData]
  );
  return (
    <TabPane tabId="details">
      <Formik
        initialValues={detailData}
        enableReinitialize={true}
        onSubmit={handleFormSubmit}
        validationSchema={schema.validation()}
      >
        {props => (
          <Form>
            <h2>
              {t("company_details_tab_title", "Company details")}
              <p className="card-title-desc">
                {t(
                  "company_details_tab_subtitle",
                  "Additional details that will be used throughout the software and as a way to contact you."
                )}
              </p>
            </h2>
            <h5 className="mb-4 mt-0">Branding details</h5>
            <Row>
              <Col lg={6}>
                <Row>
                  <Col sm={12}>
                    <TextInput
                      name="name"
                      label={t("company_name", "Company name") + "*"}
                      showErrors={true}
                      formik={props}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <TextInput
                      name="primaryPhone"
                      label={t("primary_phone", "Primary phone") + "*"}
                      showErrors={true}
                      formik={props}
                      className="mb-2"
                    />
                  </Col>
                  <Col md={6}>
                    <TextInput
                      name="secondaryPhone"
                      label={t("secondary_phone", "Secondary phone")}
                      showErrors={true}
                      formik={props}
                      className="mb-2"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextInput
                      name="website"
                      label={t("website_url", "Website URL")}
                      showErrors={true}
                      formik={props}
                      className="mb-2"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TextInput
                      name="email"
                      label={t("email_address", "Email address")}
                      note={t(
                        "email_address_note",
                        "Used for PDF branding and as the reply-to address on all outgoing emails"
                      )}
                      showErrors={true}
                      formik={props}
                      className="mb-2"
                    />
                  </Col>
                </Row>
                <Row>
                  {/* -- Company Description -- */}
                  <Col sm={12}>
                    <TextInput
                      name="description"
                      type="textarea"
                      label={
                        t("company_description", "Company description") + "*"
                      }
                      note={t(
                        "company_description_note",
                        "Used for online booking and marketing"
                      )}
                      showErrors={true}
                      formik={props}
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={6} className="d-flex flex-column">
                <Label>
                  {t("company_logo", "Company logo")}
                  <div className="note">
                    {t(
                      "company_logo_description",
                      "This logo will appear on all outgoing transactional and marketing materials."
                    )}
                  </div>
                </Label>
                <div className="d-flex justify-content-center align-items-center flex-grow-1 mb-3">
                  <img
                    className="company-logo p-2 w-100 h-100 border-0"
                    style={{ maxWidth: "150px", objectFit: "contain" }}
                    alt={t("company_logo_alt", "Company logo image")}
                    src={photo}
                  />
                </div>
                <Label for="logo_upload">
                  {t("upload_new_logo", "Upload a new logo")}
                  <div className="note">
                    {t(
                      "upload_new_logo_details",
                      "Logo should be no larger than 512px x 512px"
                    )}
                  </div>
                </Label>
                <div>
                  <Input
                    type="file"
                    id="logo_upload"
                    name="logo_upload"
                    onChange={e => handlePhotoChange(e, props)}
                  />
                </div>
              </Col>
            </Row>
            <h3 className="mb-4 mt-5 pt-2 border-top">
              {("address_details", "Address details")}
            </h3>
            <Row>
              <Col>
                <TextInput
                  name="address1"
                  label={t("address_line_1", "Address line 1")}
                  showErrors={true}
                  formik={props}
                  className="mb-2"
                />
                <TextInput
                  name="address2"
                  label={t("address_line_2", "Address line 2")}
                  showErrors={true}
                  formik={props}
                  className="mb-2"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextInput
                  name="city"
                  label={t("city", "City")}
                  showErrors={true}
                  formik={props}
                  className="mb-2"
                />
              </Col>
              <Col>
                <TextInput
                  name="postCode"
                  label={t("postal_code", "ZIP / Postal code")}
                  showErrors={true}
                  formik={props}
                  className="mb-2"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CountryDropdown
                  name="country"
                  label={t("country", "Country") + "*"}
                  showErrors={true}
                  formik={props}
                  value={props.values?.country ? props.values.country : ""}
                />
              </Col>
              <Col>
                <RegionDropdown
                  name="region"
                  label={t("region", "Region") + "*"}
                  showErrors={true}
                  formik={props}
                  country={props.values?.country ? props.values.country : ""}
                  value={
                    props.values?.country && props.values?.region
                      ? props.values.region
                      : ""
                  }
                />
              </Col>
            </Row>
            <div className="accordion mt-5 pt-4 border-top">
              <div className="accordion-item">
                <h5
                  className={
                    "accordion-header " +
                    (accordion["service-details"] ? "active" : "")
                  }
                  id="service-details"
                  onClick={() => toggleAccordionItem("service-details")}
                >
                  {t("service_details", "Service details")}
                </h5>
                <Collapse
                  isOpen={accordion["service-details"]}
                  className="accordion-collapse"
                >
                  <div className="accordion-body">
                    <p className="card-title-desc">
                      {t(
                        "company_details_service_area_description",
                        "The postal / zip code you enter determines how inspections are booked to you.  Please input the postal / zip code closest to the area you will be servicing."
                      )}
                    </p>
                    <Row>
                      <Col>
                        <TextInput
                          name="serviceArea"
                          label={t("service_area", "Postal / zip code") + "*"}
                          showErrors={true}
                          formik={props}
                          className="mb-2"
                        />
                      </Col>
                      <Col>
                        <TimezoneDropdown
                          name="timezone"
                          label={t("timezone", "Time zone") + "*"}
                          formik={props}
                          value={
                            props.values?.timezone ? props.values.timezone : ""
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextInput
                          name="dateTimeFormat"
                          label={t("date_time_format", "Date/time format")}
                          showErrors={true}
                          formik={props}
                          className="mb-2"
                          placeholder="YYYY-MM-DD"
                          onChange={e => {
                            handleDateFormatChange(e);
                            props.handleChange(e);
                          }}
                        />
                        <div className="label-preview">{dateFormatPreview}</div>
                      </Col>
                      <Col>
                        <StandardDropdown
                          name="startOfWeek"
                          label={t("start_of_week", "Start of the week") + "*"}
                          showErrors={true}
                          formik={props}
                          default="0"
                          options={Object.keys(days).map(key => ({
                            label: t(days[key]),
                            value: days[key],
                          }))}
                          value={
                            props.values?.startOfWeek
                              ? props.values.startOfWeek
                              : ""
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <StandardDropdown
                          name="currencyFormat"
                          label={t("currency_format", "Currency format") + "*"}
                          showErrors={true}
                          formik={props}
                          default="0"
                          options={Object.keys(currencies).map(key => ({
                            label: currencies[key].label,
                            value: key,
                          }))}
                          value={
                            defaultState.currencyFormat
                              ? defaultState.currencyFormat
                              : ""
                          }
                        />
                      </Col>
                      <Col>
                        <StandardDropdown
                          name="fiscalYearStartDate"
                          label={
                            t(
                              "fiscal_year_start_date",
                              "Fiscal year start date"
                            ) + "*"
                          }
                          showErrors={true}
                          formik={props}
                          options={Object.keys(months).map(key => ({
                            label: months[key],
                            value: key,
                          }))}
                          value={
                            props.values?.fiscalYearStartDate
                              ? props.values.fiscalYearStartDate
                              : ""
                          }
                        />
                        <br />
                        <em>
                          <Trans
                            i18nKey="fiscal_year_start_date_note"
                            defaults="(Enter the month representing the start of your company's fiscal year (used in <Link>Business at a glance</Link>); all fiscal years are calculated from the 1st of the month selected.)"
                            components={{ Link: <Link to="/#" /> }}
                          />
                        </em>
                      </Col>
                    </Row>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <h3
                  className={
                    "accordion-header " +
                    (accordion["business-hours"] ? "active" : "")
                  }
                  id="business-hours"
                  onClick={() => toggleAccordionItem("business-hours")}
                >
                  {t("business_hours", "Business hours")}
                </h3>
                <Collapse
                  isOpen={accordion["business-hours"]}
                  className="accordion-collapse"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col>
                        <table cellPadding="5px" className="border mt-3">
                          <thead className="border">
                            <tr style={{ height: "50px" }}>
                              <th colSpan="2" className="text-center fw-medium">
                                {t("day_of_the_week", "Day of the Week")}
                              </th>
                              <th
                                colSpan="3"
                                className="text-center border-start fw-medium"
                              >
                                {t("hours_of_operation", "Hours of operation")}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {days.map(function (item, _index, _arr) {
                              return (
                                <BusinessHourRow
                                  key={_index}
                                  index={item}
                                  day={props.values.businessHours[item]}
                                  toggleOpen={toggleOpen}
                                  timeChange={timeChange}
                                  invalid={
                                    invalidBusinessDays.includes(item)
                                      ? true
                                      : false
                                  }
                                  setFieldValue={props.setFieldValue}
                                />
                              );
                            }, this)}
                          </tbody>
                        </table>
                      </Col>
                      <Col>
                        <h3 className="mb-4 pt-4 ">
                          {t("earliest_availability", "Earliest availability")}
                        </h3>
                        <p className="text-muted mb-0">
                          {t(
                            "earliest_availability_description",
                            "Specify the earliest date your clients can select an appointment when booking online."
                          )}
                        </p>
                        <div className="mt-2 text-muted">
                          <Trans
                            i18nKey="earlist_availability_text"
                            defaults="Accept appointments as early as <Number /> <Metric /> away."
                            formik={props}
                            components={{
                              Number: (
                                <Field
                                  name="earliestAvailability"
                                  type="number"
                                  style={{ width: "50px" }}
                                  formik={props}
                                  min="0"
                                  max="12"
                                  className="form-control d-inline-block mx-2 pl-2 pr-1"
                                />
                              ),
                              Metric: (
                                <StandardDropdown
                                  name="earliestAvailabilityMetric"
                                  showErrors={true}
                                  formik={props}
                                  className="d-inline-block w-auto mx-2"
                                  options={Object.keys(times).map(key => ({
                                    label: times[key],
                                    value: key,
                                  }))}
                                  value={
                                    props.values?.earliestAvailabilityMetric
                                      ? props.values.earliestAvailabilityMetric
                                      : ""
                                  }
                                />
                              ),
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <h3
                  className={
                    "accordion-header " + (accordion["social"] ? "active" : "")
                  }
                  id="social"
                  onClick={() => toggleAccordionItem("social")}
                >
                  {t("social_networks", "Social networks")}
                </h3>
                <Collapse
                  isOpen={accordion["social"]}
                  className="accordion-collapse"
                >
                  <div className="accordion-body">
                    <Row>
                      <Col>
                        <TextInput
                          name="facebook"
                          label={t("facebook_page_url", "Facebook page URL")}
                          showErrors={true}
                          formik={props}
                          className="mb-2"
                        />
                      </Col>
                      <Col>
                        <TextInput
                          name="twitter"
                          label={t(
                            "twitter_account_url",
                            "Twitter account URL"
                          )}
                          showErrors={true}
                          formik={props}
                          className="mb-2"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextInput
                          name="instagram"
                          label={t(
                            "instagram_account_url",
                            "Instagram account URL"
                          )}
                          showErrors={true}
                          formik={props}
                          className="mb-2"
                        />
                      </Col>
                      <Col>
                        <TextInput
                          name="yelp"
                          label={t("yelp_url", "Yelp URL")}
                          showErrors={true}
                          formik={props}
                          className="mb-2"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextInput
                          name="angie"
                          label={t("angies_list_url", "Angie's List URL")}
                          showErrors={true}
                          formik={props}
                          className="mb-2"
                        />
                      </Col>
                      <Col>
                        <TextInput
                          name="google"
                          label={t(
                            "google_business_profile_url",
                            "Google business profile URL"
                          )}
                          showErrors={true}
                          formik={props}
                          className="mb-2"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextInput
                          name="homestars"
                          label={t("homestars_url", "HomeStars URL")}
                          showErrors={true}
                          formik={props}
                          className="mb-2"
                        />
                      </Col>
                      <Col></Col>
                    </Row>
                  </div>
                </Collapse>
              </div>
            </div>
            <div className={(!props.isValid ? "is-invalid" : "") + " mt-5"}>
              <Button color="primary" type="submit">
                {t("save_changes", "Save Changes")}
              </Button>
              <LeavingForm formikProps={props} />
            </div>
            <StickyOnChange formikProps={props} />
            {!props.isValid ? (
              <div className="invalid-feedback mt-3">
                {t(
                  "form_has_validation_errors",
                  "Could not submit, there are errors within the form."
                )}
              </div>
            ) : (
              ""
            )}
          </Form>
        )}
      </Formik>
    </TabPane>
  );
}
