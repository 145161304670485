import React from "react";
import { Label } from "reactstrap";
import { RegionDropdown as RegionDrop } from "react-country-region-selector";
import { useFormikContext } from "formik";

const RegionDropdown = props => {
  const formik = useFormikContext();

  const showError = () => {
    try {
      if (eval("formik.errors." + props.name) !== "") {
        return (
          <div className="invalid-feedback">
            {eval("formik.errors." + props.name)}
          </div>
        );
      }
    } catch (ex) {
      return "";
    }
  };

  let value = "";
  try {
    value = eval("formik.values." + props.name);
  } catch (e) {}

  let className = "form-select ";
  try {
    className +=
      eval("formik.errors." + props.name) !== undefined ? " is-invalid" : "";
  } catch (e) {}

  return (
    <React.Fragment>
      {props.label ? (
        <Label for={props.name} className="col-form-label">
          {props.label}
        </Label>
      ) : (
        ""
      )}
      <RegionDrop
        name={props.name}
        id={props.name}
        className={className}
        country={props.country}
        disabled={props.disabled}
        value={value}
        onChange={e => {
          formik.setFieldValue(props.name, e);
        }}
      />
      {props.showErrors ? showError() : ""}
    </React.Fragment>
  );
};

export { RegionDropdown };
