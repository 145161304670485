import { Row, Col } from "reactstrap";
import "reactstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css";
import { TextInput } from "components/Fields";
import { Trans, useTranslation } from "react-i18next";

export default function ServiceArea(props: { newUser?: boolean }) {
  const { t } = useTranslation();
  return (
    <>
      <Row>
        <Col sm={6}>
          <TextInput
            name="home_start_address"
            label={t("start_address_zip", "Home/Start of Day Address")}
            showErrors={true}
          />
          <span className="note">
            <Trans i18nKey="service_area_zip_disclaimer">
              Providing correct details here will enable us to provide more
              accurate match for potential inspections.
            </Trans>
          </span>
        </Col>
        <Col sm={6}>
          <TextInput
            name="radius"
            label={t("start_radius", "Radius (in miles)")}
            showErrors={true}
          />
          <span className="note">
            <Trans i18nKey="service_area_radius_disclaimer">
              The radius from your home address of which you will allow to be
              assigned inspections from.
            </Trans>
          </span>
        </Col>
      </Row>
    </>
  );
}
