import React from "react";
import { useTranslation } from "react-i18next";
import { getCSSClass } from "components/Common/Icons";
import PrivacyPolicy from "./components/privacyPolicy";
import StandardPage from "components/Common/StandardPage";

export default function AuthPrivacyPolicy() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <StandardPage
        pageid="privacy_policy"
        pagetitle={t("privacy_policy", "Privacy Policy")}
        breadcumbIcon={getCSSClass("privacy")}
        breadcrumbItems={[
          {
            uri: "/privacy",
            text: t("privacy_policy", "Privacy Policy"),
            active: true,
          },
        ]}
      >
        <PrivacyPolicy></PrivacyPolicy>
      </StandardPage>
    </React.Fragment>
  );
}
