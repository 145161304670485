import PropTypes from "prop-types";
import { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";

class DateTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hydrated: false,
      timezone: this.props.useTimezone ? this.props.timezone : "UTC",
    };
  }
  render() {
    if (this.props.type === "shortTime") {
      return moment("1970-01-01T" + this.props.time)
        .format("h:mm a")
        .replace("am", "a.m.")
        .replace("pm", "p.m.");
    }
    let str = Intl.DateTimeFormat("en", {
      hour: "numeric",
      timeZone: this.state.timezone,
      minute: "numeric",
    }).format(new Date("1970-01-01T" + this.props.time + ":00Z"));
    str = str.replace("AM", "a.m.").replace("PM", "p.m.");
    return str;
  }
}

DateTime.propTypes = {
  timezone: PropTypes.string,
  useTimezone: PropTypes.bool,
  time: PropTypes.string,
  type: PropTypes.string,
};

const mapStateToProps = state => {
  const timezone = state.Profile.timezone;
  return { timezone };
};

export default connect(mapStateToProps)(DateTime);
