import PropTypes from "prop-types";
import DataTable from "components/Tables/DataTable";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Icon from "components/Common/Icons";
import { Badge } from "reactstrap";
import NewInspection from "./_btnNewInspection";

class InspectionsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hydrated: false,
      inspections: [],
      columns: [
        {
          dataField: "inspectionStart",
          text: "Date",
          formatter: (cell, row, _rowIdx) => {
            return (
              <span>
                {cell.toLocaleDateString()}
                <br />
                {cell.toLocaleTimeString() +
                  " - " +
                  row.inspectionEnd.toLocaleTimeString()}
                <br />
                {Math.round((row.inspectionEnd - cell) / 1000 / 60 / 60.0)}{" "}
                hours
              </span>
            );
          },
        },
        {
          dataField: "status",
          text: "Status",
          formatter: (cell, _row, _rowIndex) => {
            if (cell === undefined) return "";
            return (
              <Badge color={cell.color} className={"bg-" + cell.color}>
                {cell.text}
              </Badge>
            );
          },
        },
        {
          dataField: "inspectors",
          text: "Inspectors",
        },
        {
          dataField: "streetAddress",
          text: "Address",
          formatter: (_cell, row, _rowIdx) => {
            return (
              <span>
                {row.unit === "" ? "" : row.unit + " - "}
                {row.streetAddress}{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={
                    "https://maps.google.com/?q=" +
                    row.streetAddress +
                    "," +
                    row.city +
                    "," +
                    row.region +
                    "," +
                    row.postCode +
                    "," +
                    row.country
                  }
                >
                  <Icon name="googleMaps" />
                </a>
                <br />
                {row.city}, {row.region}
                <br />
                {row.postCode}
                <br />
                {row.country}
              </span>
            );
          },
        },
        {
          dataField: "clients",
          text: "Clients",
          formatter: (cell, _row, _rowIdx) => {
            return (
              <span>
                {cell.map(client => (
                  <span key={client.name}>
                    {client.name}
                    {client.contact.mobile ? (
                      <a href={"tel:" + client.contact.mobile}>
                        <Icon name="mobile" />
                      </a>
                    ) : (
                      ""
                    )}
                    {client.contact.email ? (
                      <a href={"mailto:" + client.contact.email}>
                        <Icon name="email" />
                      </a>
                    ) : (
                      ""
                    )}
                  </span>
                ))}
              </span>
            );
          },
        },
      ],
    };
  }

  componentDidUpdate = async () => {
    if (!this.state.hydrated && this.props.inspections !== undefined) {
      await this.setState({
        ...this.state,
        inspections: this.props.inspections,
        hydrated: true,
      });
    }
  };

  hydrateState = async () => {
    try {
      this.setState({
        ...this.state,
        inspections: this.props.inspections,
        hydrated: true,
      });
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    return (
      <DataTable
        data={this.state.inspections}
        columns={this.state.columns}
        search={true}
        recordsPerPage={true}
        showRecordTotals={true}
        actions={
          // action buttons appear at the top of the table
          <React.Fragment>
            <NewInspection></NewInspection>
          </React.Fragment>
        }
        pageOptions={{
          sizePerPage: 10, // default size per page (doesn't have to be a part of the dropdown)
        }}
        sizePerPageOptions={[
          // size per page options
          {
            text: "10",
            page: 10,
          },
          {
            text: "25",
            page: 25,
          },
          {
            text: "50",
            page: 50,
          },
        ]}
      />
    );
  }
}

InspectionsTable.propTypes = {
  hydrateTeam: PropTypes.func,
  team: PropTypes.array,
  t: PropTypes.func,
  // hydrateInspectionlisting: PropTypes.func,
  inspections: PropTypes.array,
};

const mapStateToProps = state => {
  const inspections = state.InspectionListing.inspections;
  return { inspections };
};

export default withTranslation()(
  connect(mapStateToProps, {})(InspectionsTable)
);
