import { Auth, Amplify, API } from "aws-amplify";
import awsExports from "aws-exports";

Amplify.configure(awsExports);
API.configure(awsExports);

class CognitoBackend {
  /**
   * Registers the user with given details
   */
  registerUser = data => {
    return new Promise(async (resolve, reject) => {
      try {
        const registerDate = new Date().toISOString();
        const registerResponse = await Auth.signUp({
          username: data.email,
          password: data.password,
          attributes: {
            "custom:first_name": data.first_name,
            "custom:last_name": data.last_name,
            "custom:privacy_policy": registerDate,
            "custom:eula": registerDate,
            "custom:self_register": data.self_register ? "1" : "0",
          },
        });

        resolve(registerResponse);
      } catch (error) {
        reject(error);
      }
    });
  };

  /**
   * Login user with given details
   */
  loginUser = (email, password) => {
    return new Promise(async (resolve, reject) => {
      try {
        const loginResponse = await Auth.signIn({
          username: email,
          password,
        });
        resolve(loginResponse);
      } catch (error) {
        // console.log(error);
        // clear local data store
        reject(error);
      }
    });
  };

  /**
   * Logout the current user
   */
  logout = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const logoutResponse = await Auth.signOut();
        localStorage.removeItem("CognitoIdentityServiceProvidor*");
        localStorage.removeItem("authUser");
        resolve(logoutResponse);
      } catch (error) {
        reject(error);
      }
    });
  };

  changePassword = credentials => {
    return new Promise(async (resolve, reject) => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        const response = await Auth.changePassword(
          user,
          credentials.currentPassword,
          credentials.newPassword
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  };

  // socialLogin = data => {
  //   return new Promise(async (resolve, reject) => {
  //     let user = await DataStore.query(UserMeta);
  //     let userDetails;

  //     // if user record doesn't exist, attempt to create one
  //     if (user.lengt === ) {
  //       user = data.user;
  //       userDetails = {
  //         first_name: user.name.split(" ")[0],
  //         last_name: user.name.split(" ")[1],
  //         email: user.email,
  //       };
  //       const newUserResponse = await DataStore.save(new UserMeta(userDetails));
  //     } else {
  //       userDetails = {
  //         first_name: user[0].first_name,
  //         last_name: user[0].last_name,
  //         email: user[0].email,
  //       };
  //     }

  //     localStorage.setItem("authUser", JSON.stringify(userDetails));
  //     resolve(userDetails);
  //   });
  // };
}

let _cognitoBackend = null;

/**
 * Initilize the backend
 * @param {*} config
 */
const initCognitoBackend = config => {
  if (!_cognitoBackend) {
    _cognitoBackend = new CognitoBackend(config);
  }
  return _cognitoBackend;
};

/**
 * Returns the firebase backend
 */
const getCognitoBackend = () => {
  return _cognitoBackend;
};

export { initCognitoBackend, getCognitoBackend };
