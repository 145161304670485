import { StandardRadio } from "components/Fields/Radios/StandardRadios";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { Card, CardBody, Col, Row } from "reactstrap";

export default function DiscountTypeSection(props: any) {
  const { t } = useTranslation();
  const { values, errors, handleChange } = useFormikContext<any>();

  return (
    <Card>
      <CardBody className="">
        <Row>
          <Col lg="12">
            <h3 className="mb-0">
              {t("discount_type_label", "Discount type")}
            </h3>
            <p>
              {t(
                "discount_type_description",
                "Please select the type of discount you wish to create"
              )}
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <StandardRadio
              name="type"
              showErrors={true}
              onChange={handleChange}
              value={values?.type}
              className={"user-roles " + (errors["type"] ? " is-invalid" : "")}
              options={[
                {
                  label: t("discount_code", "Discount code"),
                  value: "CHECKOUT",
                  description: t(
                    "discount_code_description",
                    "Customers will get a discount if they enter a code at checkout"
                  ),
                },
                {
                  label: t("automatic_discount", "Automatic discount"),
                  value: "AUTO",
                  description: t(
                    "discount_code_description",
                    "Customers will get a discount automatically based on specified conditions"
                  ),
                },
              ]}
            />
            {errors["type"] ? <div className="invalid-feedback"></div> : ""}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
