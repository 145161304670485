import { Button, Spinner } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Formik, FormikErrors, Form, Field, ErrorMessage } from "formik";

import { useCategories, useCategory } from "hooks/categories";

export function ManageTab(props: { id: string }) {
  const { t } = useTranslation("categories");
  const { replace } = useHistory();
  const { deleteCategory } = useCategories();
  const { category, updateCategory, publishCategory } = useCategory(props.id);

  function fieldClassName(dirty: any, name: any) {
    if (!dirty) return "";
    if (name) return "is-invalid";
    return "is-valid";
  }

  return (
    <>
      <h2 className="mb-2">{t("Manage Category")}</h2>
      <p className="mb-4">
        {t(
          "Update information about this category, or modify its availability."
        )}
      </p>
      <section className="mb-4">
        <h3>{t("Update Name")}</h3>
        <Formik
          enableReinitialize
          validate={values => {
            const errors: FormikErrors<{ name: string }> = {};

            if (!values.name) {
              errors.name = t("A name is required");
            }

            return errors;
          }}
          initialValues={{ name: category.data?.name ?? "" }}
          onSubmit={async ({ name }) => {
            if (category?.data) {
              await updateCategory.mutateAsync({
                id: category.data.id,
                ownerId: category.data.ownerId,
                name,
              });
            }
          }}
        >
          {({ dirty, errors }) => (
            <Form>
              <div className="d-flex">
                <Field
                  name="name"
                  className={`form-control ${fieldClassName(
                    dirty,
                    errors.name
                  )}`}
                />
                <Button
                  type="submit"
                  className="ml-2"
                  color="warning"
                  disabled={!dirty || !!errors.name}
                >
                  Rename
                </Button>
              </div>
              <ErrorMessage name="name">
                {msg => <div className="text-danger">{msg}</div>}
              </ErrorMessage>
            </Form>
          )}
        </Formik>
      </section>
      <section className="mb-4">
        <h3>{t("Edit Description")}</h3>
        <p>Not Implemented</p>
      </section>
      {category.data?.owner?.level !== "COMPANY" && (
        <section className="mb-4">
          <h3>{t("Publish Category")}</h3>
          <p>
            {t(
              "Publishing this category will allow others in your company to use this category in their templates and reports."
            )}
          </p>
          <Button
            color="warning"
            onClick={async () => {
              await publishCategory.mutateAsync();
            }}
          >
            {category.isLoading || publishCategory.isLoading ? (
              <Spinner size="sm" />
            ) : (
              t("Publish Category")
            )}
          </Button>
        </section>
      )}
      <section>
        <h3>{t("Archive/Delete Category")}</h3>
        <Button
          color="danger"
          onClick={async () => {
            if (category.data) {
              await deleteCategory.mutateAsync({
                id: category.data?.id ?? "",
                ownerId: category.data?.ownerId ?? "",
              });

              // TODO: Redirect to the categories list
              replace("/report/settings");
            }
          }}
        >
          {category.isLoading || deleteCategory.isLoading ? (
            <Spinner size="sm" />
          ) : (
            t("Delete Category")
          )}
        </Button>
      </section>
    </>
  );
}
