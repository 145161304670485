import { takeEvery, put, call } from "redux-saga/effects";

// Login Redux States
import { FORGET_PASSWORD, RECOVER_PASSWORD } from "./actionTypes";
import { messageHubChange, userForgetPasswordError } from "store/actions";
import { getBackendHelper } from "helpers/backend_helper";
import { success } from "toastr";

const backendHelper = getBackendHelper();

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {
    yield call(backendHelper.forgotPassword, { email: user.email });
    yield put(messageHubChange("Password reset email sent!", "success", true));
    history.push({
      pathname: "/login",
      search: "?resetSent=true",
    });
  } catch (error) {
    yield put(userForgetPasswordError(error));
  }
}

function* recoverPassword({ payload: { details, history } }) {
  try {
    const response = yield call(backendHelper.recoverPassword, details);
    if (response.success) {
      if (response.confirm) {
        yield put(messageHubChange("Successfully logged in!", "success", true));
        setTimeout(() => {
          history.replace("/my/settings");
        }, 1000);
      } else {
        yield put(
          messageHubChange("Password successfully reset!", "success", true)
        );
        history.replace("/login?resetSuccess=true");
      }
    } else {
      yield put(userForgetPasswordError(response.message));
    }
  } catch (error) {
    yield put(userForgetPasswordError(error));
  }
}

function* forgetPasswordSaga() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
  yield takeEvery(RECOVER_PASSWORD, recoverPassword);
}

export default forgetPasswordSaga;
