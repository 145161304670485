import React from "react";
import { Card, CardBody, List, Row } from "reactstrap";
export default function PrivacyPolicy() {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Row>
            <h2>CARSON, DUNLOP & ASSOCIATES LTD.</h2>
            <p>PRIVACY POLICY (Last Update January, 2023)</p>
            <div className="mb-3">
              This privacy policy explains the privacy practices of Carson,
              Dunlop & Associates Ltd. and its affiliated entities (collectively
              referred to herein as either "Carson Dunlop", "us", "we" or
              "our"). This privacy policy (the "<b>Policy</b>") applies to the
              collection, use and disclosure of personal information, as well as
              providing access to personal information, in connection with
              Carson Dunlop operated websites and in connection with the
              provision of Carson Dunlop's products, services and benefits
              including, without limitation: (i) Horizon Inspection Software by
              Carson Dunlop ("<b>Horizon</b>"); (ii), Home Reference Book®;
              (iii) Carson Dunlop's home and commercial inspection and related
              training programs; (iv) Carson Dunlop's property inspection
              business; and (v) Carson Dunlop's home inspection franchise
              business. Our Policy may be amended from time to time, so we
              encourage you to periodically check our web site at
              http://www.carsondunlop.com or http://www.discoverhorizon.com for
              the current version. Printed copies of our current Policy are also
              available by contacting our Chief Privacy Officer at the address
              below.
            </div>
            <h3 className="mb-0">Our Commitment to Your Privacy</h3>
            <div className="mb-3">
              <div>
                The relationship between you and Carson Dunlop is based on
                trust. We value the trust you have placed in us and are taking
                all appropriate measures to safeguard personal information and
                to maintain your confidence. Carson Dunlop has established this
                Policy to ensure that all personal information is protected and
                that our practices and procedures comply with relevant personal
                information protection and privacy legislation. Accordingly, we
                adhere to the privacy principles set out below, which are based
                on the fair information principles set out in Schedule 1 of the
                Personal Information Protection and Electronic Documents Act
                (Canada). As used in our Policy, the term "personal information"
                means information about an identifiable individual, but does not
                include the name, title or business address or telephone number
                of an employee of an organization or an individual's residential
                address, if isolated from any identifiable individual
                information.
              </div>
            </div>
            <h3 className="mb-2">Carson Dunlop's Privacy Principles:</h3>
            <div className="mb-3">
              <h5 className="mb-0">1) Accountability</h5>
              <div>
                <div className="mb-2">
                  Carson Dunlop is responsible for all personal information
                  under our possession or control, whether supplied to us
                  directly by you or by a third party, or that we have provided
                  to a third party for processing.
                </div>
                <div className="mb-2">
                  If we provide you with products, services or benefits which
                  require you to disclose personal information of your clients,
                  we will co-operate with you to review and reasonably abide by
                  the terms of your privacy commitment to your clients as such
                  are communicated to us.
                </div>
                <div className="mb-2">
                  Accountability for our compliance with this Policy and related
                  procedures rests with our Chief Privacy Officer. Other
                  individuals within Carson Dunlop may, however, have
                  responsibility for the day-to-day collection, use and
                  processing of personal information.
                </div>
                <div className="mb-2">
                  Contact information for our Chief Privacy Officer is set out
                  below:
                </div>
                <div className="pl-5">
                  <b>John Kwasnik</b>
                </div>
                <div className="pl-5">Chief Privacy Officer</div>
                <div className="pl-5">Carson, Dunlop & Associates Ltd.</div>
                <div className="pl-5">120 Carlton Street</div>
                <div className="pl-5">Suite 407</div>
                <div className="pl-5">Toronto, Ontario</div>
                <div className="pl-5">Canada</div>
                <div className="pl-5">Tel: 416-964-9415</div>
                <div className="pl-5">Fax: 416-964-0683</div>
                <div className="pl-5 mb-2">
                  E-mail: privacy@carsondunlop.com
                </div>
                <div className="mb-2">
                  iii) information collected from other sources, such as through
                  home inspectors using our products and services.
                </div>
                <div>
                  By using Carson Dunlop's products or services, you acknowledge
                  and agree that we may process, transfer and store your
                  personal information in the United States and Canada. Further,
                  in order to deliver the highest quality products and services
                  to our clients, it may be necessary to share your personal
                  information with select third party vendors to assist with
                  processing. We use contractual and other means to ensure that
                  third parties to whom we disclose personal information for
                  processing provide a comparable level of protection with
                  respect to personal information in their possession or
                  control.
                </div>
              </div>
            </div>
            <div className="mb-3">
              <h5 className="mb-0">2) Identifying Purposes</h5>
              <div className="mb-2">
                We will clearly inform you of the purposes for which we may
                collect, use, or disclose personal information at or before the
                time of collection. As part of our business operations, we may
                collect and use certain personal information strictly for the
                following purposes:
              </div>
              <List>
                <li>
                  To provide you with Carson Dunlop products, services and
                  benefits that you request and provide you with any on-going
                  service or updates related thereto
                </li>
                <li>
                  To communicate with you on such matters as product and
                  software updates or changes to our terms, conditions, and
                  policies
                </li>
                <li>
                  To offer you other products, services and benefits which may
                  interest you
                </li>
                <li>
                  To provide you with opportunities to participate in relevant
                  surveys, and to provide copies of Carson Dunlop's e-newsletter
                </li>
                <li>
                  To identify trends and patterns related to the use of goods
                  and services in the home inspection and construction
                  industries and to provide information on those trends and
                  patterns to our customers and others
                </li>
                <li>Investigate and settle any claims or security incidents</li>
                <li>Fraud detection and prevention</li>
                <li>
                  To perform administrative tasks such as updating account
                  information and processing payments
                </li>
                <li>
                  Report to regulatory or industry entities, including data
                  sharing agencies
                </li>
                <li>To train employees and monitor for quality assurance</li>
                <li>To otherwise act as required or as authorized by law </li>
              </List>
              <div className="mb-2">
                If we plan to use your personal information for a specific
                purpose not previously identified, we will communicate that
                purpose to you and will seek your consent for such use, in
                accordance with applicable law, before such additional use.
              </div>
              <div className="mb-2">
                We may de-identify information we collect so the information
                cannot reasonably identify you or your device or we may collect
                information that is already in de-identified form. Our use and
                disclosure of de-identified information is not subject to any
                restrictions under this Policy and we may use and disclose it to
                others for any purpose, without limitation.
              </div>
              <div>
                Certain features of our products and services make it possible
                for you to share comments, photos or other content publicly with
                other users, such as Carson Dunlop's forum or social networking
                sites. Any information that you submit through these features is
                not confidential and Carson Dunlop may use it for any purpose
                (including in testimonials or other marketing materials). Any
                information you post openly in these ways will be available to
                the public at large and potentially accessible through
                third-party search engines. Accordingly, please take care when
                using these features.
              </div>
            </div>
            <div className="mb-3">
              <h5 className="mb-0">
                3) Obtaining Consent and Opting Out/Withdrawing Consent
              </h5>
              <div className="mb-2">
                Your consent to the collection, use and disclosure of your
                personal information can either be given expressly or consent
                may be implied by your actions.
              </div>
              <div className="mb-2">
                Express consent can either be given orally or in writing and may
                be in the form of an agreement or a positive action. Express
                consent by agreement may be given orally over the telephone, or
                in writing by signing an application form or an agreement which
                may relate to personal information. Express consent by an action
                can be given, for example, by clicking an accept button on a
                computer screen. If oral express consent is given, we will
                document the conversation, specifically the name, date, and
                details of the conversation in either hard or soft copy within
                the appropriate client file documentation in order that it may
                be easily located and accessed should this be necessary. Express
                consent does not expire, but may be withdrawn by you at any
                time.
              </div>
              <div className="mb-2">
                Implied consent can be inferred from the relationship between
                the parties or from the nature of the dealings between the
                parties. For example, by using the products and services of
                Carson Dunlop and providing your personal information in
                connection therewith, it is reasonable for us to infer that
                there is implied consent to the collection, use and/or
                disclosure of that information for purposes consistent with the
                products and services provided. We will use reasonable efforts
                to seek your express consent for the collection, use or
                disclosure of your personal information at, or prior to, the
                time of collection. In some circumstances, such as where we want
                to use personal information for a purpose that was not
                previously identified, we may have to seek your consent after
                your personal information, and/or that of your clients, has been
                collected but before our use for that purpose.
              </div>
              <div className="mb-2">
                If you are providing personal information in respect of your
                clients, we both require and expect that you have a privacy
                policy communicated with your clients and have acquired the
                necessary consent from your clients, that permits the holding of
                their information in Horizon under the terms expressed herein.
                In choosing to provide us with such information, you are by that
                act affirming that providing such information to us for use in
                accordance with this Policy (as it may be updated from time to
                time) is permitted by such privacy policy as you may have in
                place and under which you collected such information. We reserve
                the right to pursue you for any damages we suffer in the event
                the foregoing proves to be false, untrue or inaccurate.
              </div>
              <div className="mb-2">
                You always have the choice of whether or not you wish to supply
                Carson Dunlop with personal information. If you wish to
                "opt-out" of receiving certain informational and promotional
                materials from us, simply provide written instructions to our
                Chief Privacy Officer at the address set out above, or emailing
                us at either unsubscribe@carsondunlop.com or
                unsubscribe@cdwengineering.com. Similarly, if you wish to
                withdraw your consent with respect to a particular use or
                disclosure of your personal information and that of your
                clients, you may do so at any time by sending written
                instructions to our Chief Privacy Officer at the address set out
                above.
              </div>
              <div className="mb-2">
                In certain limited circumstances, as permitted or required by
                law, we may collect, use or disclose personal information
                without the knowledge or consent of the individual. Although the
                following list is not exhaustive, these circumstance include:
                (i) personal information which is publicly available as defined
                by regulation; (ii) circumstances where collection or use is
                clearly in the interests of the individual and consent cannot be
                obtained in a timely fashion; (iii) to investigate a breach of
                an agreement or a contravention of a law; (iv) to act in respect
                of an emergency that threatens the life, health or security of
                an individual; (v) for debt collection; or (vi) to comply with a
                subpoena, warrant or court order.
              </div>
              <div className="mb-2">
                The California “Shine the Light” law gives residents of
                California the right under certain circumstances to opt out of
                the sharing of certain categories of personal information (as
                defined in the Shine the Light law) with third parties for their
                direct marketing purposes or in the alternative, that we provide
                a cost-free means for consumers to opt out of any such sharing.
                We do not currently share your personal information with third
                parties for their own direct marketing purposes.
              </div>
              <div>
                Under Nevada law, certain Nevada consumers may opt out of the
                sale of “personally identifiable information” for “monetary
                consideration” (as such terms are defined under Nevada law) to a
                person for that person to license or sell such information to
                others. We do not engage in such activity, however, if you are a
                Nevada resident who has purchased goods or services from us, you
                may submit a request to opt out of any future sales under Nevada
                law by contacting us as detailed in the “Bose contact
                information” section below. Please note that we may take
                reasonable steps to verify your identity and the authenticity of
                the request.
              </div>
            </div>
            <div className="mb-3">
              <h5 className="mb-0">4) Limiting Collection</h5>
              <div className="mb-2">
                We will limit the amount and type of personal information
                collected to that which is necessary for our identified
                purposes, and we will only collect personal information by fair
                and lawful means. We may collect the following types of personal
                information from individuals visiting our website or purchasing
                Carson Dunlop products and services:
              </div>
              <List>
                <li>Name</li>
                <li>
                  Credit card number (if purchasing a Carson Dunlop product or
                  service)
                </li>
                <li>
                  Residential address (if purchasing a Carson Dunlop product or
                  service)
                </li>
                <li>
                  Residential telephone number (if purchasing a Carson Dunlop
                  product or service)
                </li>
                <li>e-mail address</li>
              </List>
              <div className="mb-2">
                Through the use of cookies, our websites may automatically
                record some general information about your visit for website
                traffic analysis such as your internet protocol (IP) address and
                type of browser. This information is used to improve your visit
                to our website by personalizing your experience based upon the
                personal information collected through the use of cookies.
              </div>
              <div>
                Our website contains links to other sites. Once you leave our
                website, you are subject to the privacy policies and practices
                of the other sites you visit.
              </div>
            </div>
            <div className="mb-3">
              <h5 className="mb-0">
                5) Limiting Use, Disclosure and Retention
              </h5>
              <div className="mb-2">
                Your personal information collected by Carson Dunlop will be
                used for the purposes identified in this Policy.
              </div>
              <div className="mb-2">
                Carson Dunlop will not use or disclose your personal information
                for purposes other than those for which it is collected, except
                with your express consent or as required by law.
              </div>
              <div className="mb-2">
                Carson Dunlop will retain your personal information, and that of
                your clients or affiliated groups (as applicable), including
                real estate agents, only for as long as is necessary for the
                fulfillment of those purposes, subject to legal requirements.
                Currently, our procedure is to retain personal information for
                as long as the inspection company is a subscriber of a Carson
                Dunlop service and for a minimum period of one year thereafter.
                Personal information of a subscriber's clients will, however, be
                removed at the request of the subscriber. For Carson Dunlop's
                home inspection clients, we keep reports for a minimum of seven
                years, unless clients request otherwise. Clients may request
                their personal information be removed at any time.
              </div>
              <div className="mb-2">
                With respect to Horizon, our electronic report writing system,
                Horizon compiles inspection reports and maintains archive
                records including inspection work orders and reports for your
                use and the use of your clients. We will not access use personal
                information in the archive records in any way for business
                purposes without your express permission. This includes
                contacting clients or real estate agents.
              </div>
              <div className="mb-2">
                Carson Dunlop retains the right to review the data and
                inspection reports created by Horizon, and or stored on the
                website, for the purpose of compiling information related to the
                maintenance or updating of the Software, and or for the purpose
                of accumulating information which may be used from time to time
                for the purpose of research, identifying trends and or products
                or services which may be of interest to you or other customers
                of Carson Dunlop or others. Carson Dunlop will not disclose any
                personal information about any identifiable individual which can
                be directly and specifically connected to any such identifiable
                individual.
              </div>
            </div>
            <div className="mb-3">
              <h5 className="mb-0">6) Accuracy</h5>
              <div>
                We will use our best efforts to ensure that personal information
                under our possession or control that is used on an ongoing
                basis, including information that we disclose to third parties
                in accordance with this Policy, is as accurate, complete, and
                up-to-date as is necessary for the purposes for which it is to
                be used. In order to assist us in that regard, we urge you to
                provide us on a timely basis with updates regarding such
                information and to inform us of any errors affecting your
                personal information that we hold.
              </div>
            </div>
            <div className="mb-3">
              <h5 className="mb-0">7) Safeguards</h5>
              <div className="mb-2">
                Carson Dunlop is committed to protecting the security of all
                personal information under its possession and control by
                employing safeguards appropriate to the sensitivity of the
                information.
              </div>
              <div className="mb-2">
                We employ a variety of technical, physical and organizational
                security measures designed to protect against unauthorized
                access, alteration, disclosure or destruction of information.
                However, no security measures are perfect or impenetrable. As
                such, we cannot guarantee the security of your information.
              </div>
              <div className="mb-2">
                When using a Carson Dunlop or Horizon account, it's important
                that you select a strong password and do not share it with
                others. Alert us immediately if you have any concerns about
                unauthorized use of your account.
              </div>
              <div className="mb-2">
                We regularly monitor our services for possible vulnerabilities
                and attacks. If you have any concerns about the security of our
                services, please contact us as soon as possible so that such
                concerns may be addressed and resolved in a timely and effective
                manner.
              </div>
              <div>
                We retain information for different periods of time depending on
                the purposes for which we collect and use it, as described in
                this Policy. We will delete or de-identify information when it
                is no longer needed to fulfil these purposes, unless a longer
                retention period is required to comply with applicable laws.
                There may be technical or other operational reasons where we are
                unable to fully delete or de-identify your information. Where
                this is the case, we will take reasonable measures to prevent
                further processing your information.
              </div>
            </div>
            <div className="mb-3">
              <h5 className="mb-0">8) Openness</h5>
              <div className="mb-2">
                We will make specific information about our policies readily
                available, except to the extent that it is confidential
                commercial information.
              </div>
              <div className="mb-2">
                The information we will make available will include: (i) how to
                gain access to your personal information and, if applicable,
                that of your clients or affiliated groups, including real estate
                agents; (ii) the type of personal information held by us,
                including a general account of its use; (iii) general
                information concerning the Policy; (iv) what personal
                information is made available to third parties; and (v) how to
                contact our Chief Privacy Officer.
              </div>
              <div>
                Copies of the current version of our Policy are available by
                contacting our Chief Privacy Officer. Our Policy can also be
                viewed at our website at www.discoverhorizon.com or
                www.carsondunlop.com.
              </div>
            </div>
            <div className="mb-3">
              <h5 className="mb-0">9) Individual Access</h5>
              <div className="mb-2">
                Upon written request addressed to our Chief Privacy Officer, we
                will inform you of the existence, use and disclosure of your
                personal information within our possession and control and we
                will give you access to that personal information. You may also
                challenge the accuracy and completeness of your personal
                information under our control and possession and have it amended
                as appropriate.
              </div>
              <div className="mb-2">
                We will respond to your written request within a reasonable time
                (generally within 30 days) and will provide reasonable
                assistance to you should you require help in preparing a
                request. We may require you to provide sufficient information to
                permit us to provide an account of the existence, use and
                disclosure of personal information. While our response will
                typically be provided at no cost to you, depending on the nature
                of the request and the amount of information involved, we
                reserve the right to impose a reasonable cost. In these
                circumstances, we will inform you in advance of the approximate
                cost to provide the response and will only proceed upon payment
                by you of such cost. Requested information will be provided or
                made available in a form that is understandable. Where possible,
                we will indicate the source of the information.
              </div>
              <div className="mb-2">
                If you feel that your personal information, or that of your
                clients or affiliated groups (as applicable), including real
                estate agents, we hold is inaccurate or incomplete, you are
                invited and encouraged to advise our Chief Privacy Officer of
                such inaccuracies and ask that we amend the personal
                information. Any such request must be in writing.
              </div>
              <div className="mb-2">
                In providing an account of third parties to which we may have
                disclosed personal information about an individual, we will
                attempt to be as specific as possible. When it is not possible
                to provide a list of the organizations to which we have actually
                disclosed personal information, we will provide a list of
                organizations to which we may have disclosed the personal
                information.
              </div>
              <div className="mb-2">
                If an individual successfully demonstrates an inaccuracy or
                incompleteness of his or her personal information under our
                control, we will amend the personal information as appropriate.
                If a challenge is not resolved to the satisfaction of the
                individual, we will record the substance of the unresolved
                challenge. Where appropriate, the amended information or the
                existence of the unresolved challenge, as the case may be, will
                be transmitted to third parties having access to the information
                in question.
              </div>
              <div>
                In certain situations, we may refuse a request or not be able to
                provide access to all the personal information we hold about an
                individual. Exceptions to the access requirement will be limited
                and specific, as permitted or required by law. Where permitted,
                the reasons for denying access will be provided to the
                individual upon request.
              </div>
            </div>
            <div className="mb-3">
              <h5 className="mb-0">10) Challenging Compliance</h5>
              <div>
                If you are not completely satisfied with our Policy and wish to
                submit comments or concerns regarding our privacy practices, we
                invite you to contact our Chief Privacy Officer at the address
                above. We will thoroughly investigate all written complaints
                addressed to our Chief Privacy Officer. If we find a written
                complaint to be justified, we will take all appropriate
                measures, including, if necessary, amending our policies and
                practices. If for any reason you are not satisfied with the
                results of our investigation of, and responses to, your
                complaint, you may file a complaint with the Office of the
                Privacy Commissioner of Canada, 30 Victoria Street, Gatineau,
                Quebec, K1A 1H3Telephone: 1-800-282-1376.
              </div>
            </div>
            <div className="text-center">
              Contents of this site Copyright 2023 Carson, Dunlop & Associates
              Ltd. All rights reserved. No text or graphical material may be
              copied from this site without the express written permission of
              Carson, Dunlop & Associates Ltd.
            </div>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}
