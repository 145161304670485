import { StandardDropdown, TextInput } from "components/Fields";
import { StandardSwitch } from "components/Fields/Switches/StandardSwitch";
import { useEffect, useState } from "react";
import DateBox from "devextreme-react/date-box";
import { useTranslation } from "react-i18next";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import { useFormikContext } from "formik";

export default function TimeSection(props: any) {
  const { t } = useTranslation();
  const [minDateValue, setMinDateValue] = useState<any>(null);
  const { values, setFieldValue } = useFormikContext<any>();

  if (!values?.type) {
    return <></>;
  }

  const updateField = (e: any) => {
    if (e.element.id === "startDate") {
      setMinDateValue(e.value ? new Date(e.value) : null);
    }

    setFieldValue(e.element.id, e.value);
  };

  return (
    <Card>
      <CardBody>
        <h3 className="mb-0">{t("discount_period", "Discount period")}</h3>
        <p>
          {t(
            "discount_period_description",
            "Optionally specify a period when the discount can be applied"
          )}
        </p>
        <Row>
          <Col lg={6}>
            <Label className="col-form-label">
              {t("discount_start_date", "Start date (inclusive)")}
            </Label>
            <DateBox
              id="startDate"
              openOnFieldClick={true}
              value={values?.startDate}
              onValueChanged={updateField}
            />
          </Col>
          <Col lg={6}>
            <Label className="col-form-label">
              {t("discount_end_date", "End date (inclusive)")}
            </Label>
            <DateBox
              id="endDate"
              min={minDateValue}
              value={values?.endDate}
              openOnFieldClick={true}
              onValueChanged={updateField}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
