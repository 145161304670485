import Icon from "components/Common/Icons";
import PropTypes from "prop-types";
import React from "react";
import { NavItem, NavLink } from "reactstrap";
import classNames from "classnames";

const SideMenuSubMenu = props => {
  return (
    <ul
      id={props.id}
      className={classNames({
        "side-nav-sub-menu nav-pills scroll-shadow": true,
        open: props.open,
      })}
    >
      <NavItem>
        <NavLink
          style={{ cursor: "pointer" }}
          className="sub-menu-header"
          onClick={() => {
            props.onClose();
            props.toggleDrawer();
            console.log(props);
          }}
        >
          <Icon name="left-arrow-alt" style={{ fontSize: "16px" }} />
          {props.t(props.id, props.header)}
        </NavLink>
      </NavItem>
      {React.Children.map(props.children, function (child) {
        return React.cloneElement(child, {
          t: props.t,
          toggleDrawer: props.toggleDrawer,
        });
      })}
    </ul>
  );
};

SideMenuSubMenu.propTypes = {
  id: PropTypes.string,
  children: PropTypes.array,
  t: PropTypes.func,
  header: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  toggleDrawer: PropTypes.func,
};

export default SideMenuSubMenu;
