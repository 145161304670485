import { MESSAGE_HUB_RESET, MESSAGE_HUB_CHANGE } from "./actionTypes";

const initialState = {
  message: null,
  loading: false,
  type: null,
  toast: false,
};

const messageHub = (state = initialState, action) => {
  switch (action.type) {
    case MESSAGE_HUB_RESET:
      state = {
        ...state,
        message: null,
        loading: false,
        type: null,
      };
      break;
    case MESSAGE_HUB_CHANGE:
      state = {
        ...state,
        message: action.payload.message,
        toast: action.payload.toast,
        loading: false,
        type: action.payload.type,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default messageHub;
