import API, { graphqlOperation, GraphQLQuery } from "@aws-amplify/api";

import {
  CreateCategoryMutation,
  UpdateCategoryMutation,
  DeleteCategoryMutation,
  UpdateCategoryInput,
  DeleteCategoryInput,
  GetCategoryQuery,
  GetCategoryQueryVariables,
  ListSubcategoriesByCategoryIdQuery,
  UpdateSubcategoryMutation,
  UpdateSubcategoryMutationVariables,
  Category,
  CreateCategoryInput,
  DeleteTemplateMutation,
  DeleteTemplateCategoryConnectionMutationVariables,
} from "API";
import { getAccessToken } from "helpers/graphql";
import {
  createCategory,
  updateCategory,
  deleteCategory,
  updateSubcategory,
  deleteTemplateCategoryConnection,
} from "graphql/mutations";
import { getCategory, listSubcategoriesByCategoryId } from "graphql/queries";
import { deleteSubcategoryFn } from "hooks/subcategories/effects";

export async function createCategoryFn(
  input: CreateCategoryInput
): Promise<Category> {
  const res = await API.graphql<GraphQLQuery<CreateCategoryMutation>>(
    graphqlOperation(
      createCategory,
      {
        input,
      },
      await getAccessToken()
    )
  );

  if (!res.data?.createCategory) throw new Error("Failed to create category");

  return {
    ...res.data.createCategory,
    subcategories: undefined,
    templates: undefined,
  };
}

const affectedRelationshipsQuery = /* GraphQL */ `
  query GetAffectedSubcategories($ownerId: String!, $id: ID!) {
    getCategory(ownerId: $ownerId, id: $id) {
      id
      subcategories {
        items {
          id
        }
      }
      templates {
        items {
          id
        }
      }
    }
  }
`;

export async function deleteCategoryFn(input: DeleteCategoryInput) {
  const { data } = await API.graphql<GraphQLQuery<GetCategoryQuery>>(
    graphqlOperation(
      affectedRelationshipsQuery,
      {
        ownerId: input.ownerId,
        id: input.id,
      },
      await getAccessToken()
    )
  );

  if (!data?.getCategory) {
    throw new Error("Category not found");
  }

  if (data.getCategory.subcategories?.items) {
    for (const subcategory of data.getCategory.subcategories.items) {
      if (!subcategory) continue;

      await deleteSubcategoryFn({ id: subcategory.id });
    }
  }

  if (data.getCategory.templates?.items) {
    for (const conn of data.getCategory.templates.items) {
      if (!conn) continue;

      await API.graphql<GraphQLQuery<DeleteTemplateMutation>>(
        graphqlOperation(
          deleteTemplateCategoryConnection,
          {
            input: {
              id: conn.id,
            },
          } as DeleteTemplateCategoryConnectionMutationVariables,
          await getAccessToken()
        )
      );
    }
  }

  await API.graphql<GraphQLQuery<DeleteCategoryMutation>>(
    graphqlOperation(
      deleteCategory,
      {
        input,
      },
      await getAccessToken()
    )
  );
}

export async function updateCategoryFn(input: UpdateCategoryInput) {
  await API.graphql<GraphQLQuery<UpdateCategoryMutation>>(
    graphqlOperation(
      updateCategory,
      {
        input,
      },
      await getAccessToken()
    )
  );
}

export async function createPromotedCategory(
  id: string,
  ownerId: string,
  newOwnerId: string
) {
  if (newOwnerId === ownerId) {
    throw new Error("Category is already published");
  }

  // Get existing Category
  const { data: oldData } = await API.graphql<GraphQLQuery<GetCategoryQuery>>(
    graphqlOperation(
      getCategory,
      {
        id,
        ownerId,
      },
      await getAccessToken()
    )
  );

  if (!oldData?.getCategory) {
    throw new Error("Category not found");
  }

  // Create new Category with updated Owner ID
  const { data } = await API.graphql<GraphQLQuery<CreateCategoryMutation>>(
    graphqlOperation(
      createCategory,
      {
        input: {
          name: oldData.getCategory.name,
          id: oldData.getCategory.id,
          subcategoryOrder: oldData.getCategory.subcategoryOrder,
          ownerId: newOwnerId,
        },
      },
      await getAccessToken()
    )
  );

  if (!data?.createCategory) {
    throw new Error(`Failed to create Category with Owner ID ${newOwnerId}`);
  }

  return data.createCategory;
}

export async function updatePromotedSubcategories(
  categoryId: string,
  ownerId: string,
  newOwnerId: string
): Promise<Set<() => Promise<void>>> {
  // Update all child subcategories
  const rollbackFns: Set<() => Promise<void>> = new Set();
  try {
    const { data: subcategoryData } = await API.graphql<
      GraphQLQuery<ListSubcategoriesByCategoryIdQuery>
    >(
      graphqlOperation(
        listSubcategoriesByCategoryId,
        {
          categoryId,
        },
        await getAccessToken()
      )
    );

    if (subcategoryData?.listSubcategoriesByCategoryId?.items) {
      for (const subcategory of subcategoryData.listSubcategoriesByCategoryId
        .items) {
        if (subcategory) {
          const { data } = await API.graphql<
            GraphQLQuery<UpdateSubcategoryMutation>
          >(
            graphqlOperation(
              updateSubcategory,
              {
                input: {
                  id: subcategory.id,
                  categoryOwnerId: newOwnerId,
                },
              } as UpdateSubcategoryMutationVariables,
              await getAccessToken()
            )
          );

          if (data?.updateSubcategory) {
            rollbackFns.add(async () => {
              await API.graphql<GraphQLQuery<UpdateSubcategoryMutation>>(
                graphqlOperation(
                  updateSubcategory,
                  {
                    input: {
                      id: subcategory.id,
                      categoryOwnerId: ownerId,
                    },
                  } as UpdateSubcategoryMutationVariables,
                  await getAccessToken()
                )
              );
            });
          }
        }
      }
    }
  } catch (e) {
    console.error(e);

    try {
      for (const fn of rollbackFns.values()) {
        await fn();
      }
    } catch (error) {
      console.error(error);

      throw new Error(
        `Failed to update Subcategories. Rollback failed. Please contact support.`
      );
    }
  }

  return rollbackFns;
}

export async function publishCategoryFn(input: GetCategoryQueryVariables) {
  const category = await createPromotedCategory(
    input.id,
    input.ownerId,
    input.ownerId.split("/")[0]
  );

  async function rollbackCategory() {
    await deleteCategoryFn({
      id: category.id,
      ownerId: category.ownerId,
    });
  }

  try {
    await updatePromotedSubcategories(
      category.id,
      input.ownerId,
      category.ownerId
    );
  } catch (error) {
    console.error(error);
    await rollbackCategory();
    throw error;
  }

  // Delete old Category
  await deleteCategoryFn({
    id: input.id,
    ownerId: input.ownerId,
  });

  return category;
}
