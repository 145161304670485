import API, { graphqlOperation, GraphQLQuery } from "@aws-amplify/api";

import type { ListCategoriesQuery, Category } from "API";

import { listCategories as listCategoriesQuery } from "graphql/queries";
import { createCategory as createCategoryMutation } from "graphql/mutations";

export async function getCategories() {
  const res = await API.graphql<GraphQLQuery<ListCategoriesQuery>>(
    graphqlOperation(listCategoriesQuery)
  );

  const categories: Category[] = [];
  for (const category of res.data?.listCategories?.items ?? []) {
    if (category) {
      categories.push({ ...category, templates: null, subcategories: null });
    }
  }

  return categories;
}

export async function createCategory(name: string) {
  await API.graphql(
    graphqlOperation(createCategoryMutation, {
      input: {
        name,
        categoryOrder: [],
      },
    })
  );
}
