import {
  RegionDropdown,
  StandardDropdown,
  TextInput,
  TimezoneDropdown,
} from "components/Fields";
import { Field, FormikValues, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { Col } from "reactstrap";
import languages from "locales/languages";
import { FormProfileProps } from "./_formProfileProps";

export default function FormProfile(props: FormProfileProps) {
  const { t } = useTranslation();
  const { values }: FormikValues = useFormikContext();

  return (
    <>
      <h3
        className="mt-4"
        style={{
          paddingTop: "5px",
        }}
      >
        {t("personal_details", "Personal Details")}
      </h3>
      <Col sm={6}>
        <TextInput
          name="firstName"
          label={t("first_name", "First Name") + "*"}
          showErrors={true}
        />
      </Col>
      {/* -- Last Name -- */}
      <Col sm={6}>
        <TextInput
          name="lastName"
          label={t("last_name", "Last Name") + "*"}
          showErrors={true}
        />
      </Col>

      {/* -- Email -- */}
      <Col sm={6}>
        <TextInput
          name="email"
          disabled={props.newUser ? false : true}
          label={t("email", "Email") + "*"}
          showErrors={true}
        />
      </Col>

      {/* -- Phone Number -- */}
      <Col sm={6}>
        <TextInput
          name="primaryPhone"
          label={t("primary_phone", "Primary Phone") + "*"}
          showErrors={true}
        />
      </Col>
      {/* -- Secondary Phone -- */}
      <Col sm={6}>
        <TextInput
          name="secondaryPhone"
          label={t("secondary_phone", "Secondary Phone")}
          showErrors={true}
        />
      </Col>
      <h5 className="mt-4 pt-3 border-top">
        {t("other_details", "Other Details")}
      </h5>
      <Col sm={6}>
        <StandardDropdown
          name="country"
          label={t("country", "Country") + "*"}
          showErrors={true}
          options={[
            {
              label: t("country_select_default", "Select country"),
              value: "",
            },
            {
              label: t("country_canada", "Canada"),
              value: "Canada",
            },
            {
              label: t("country_usa", "United States"),
              value: "United States",
            },
            {
              label: t(
                "country_usa_islands",
                "United States minor outlying islands"
              ),
              value: "United States Minor Outlying Islands",
            },
          ]}
        />
      </Col>
      <Col sm={6}>
        <RegionDropdown
          name="region"
          label={t("state", "State/Province") + "*"}
          country={values?.country ? values.country : ""}
          value={values?.country && values?.region ? values.region : ""}
          showErrors={true}
        />
      </Col>
      <Col sm={6}>
        <TimezoneDropdown
          name="timezone"
          label={t("timezone_setting", "Timezone Setting") + "*"}
          showErrors={true}
        />
      </Col>
      <Col sm={6}>
        <StandardDropdown
          name="language"
          label={t("language_preference", "Language Preference") + "*"}
          showErrors={true}
          default="en"
          options={Object.keys(languages).map(key => ({
            label: languages[key].label,
            value: key,
          }))}
        />
      </Col>
      {window.location.pathname === "/company/team/edit" &&
        props.loginUser?.role.toLowerCase() === "administrator" &&
        values.id !== props.loginUser?.id && (
          <Col sm={6}>
            <div className="form-check form-switch form-switch form-check-primary mt-3">
              <label className="form-check-label" htmlFor="inactive">
                <Field
                  type="checkbox"
                  className="form-check-input"
                  id="inactive"
                  name="inactive"
                />
                Inactive
              </label>
            </div>
          </Col>
        )}
    </>
  );
}
