import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import API, {
  graphqlOperation,
  GraphQLResult,
  GraphQLQuery,
} from "@aws-amplify/api";

import {
  Subcategory,
  UpdateCategoryMutation,
  UpdateCategoryMutationVariables,
  UpdateSubcategoryMutation,
  UpdateSubcategoryMutationVariables,
} from "API";
import {
  updateCategory as updateCategoryMutation,
  updateSubcategory,
} from "graphql/mutations";
import { useCategory } from "hooks/categories";
import { useSubcategories } from "hooks/subcategories";
import { ResourceList } from "components/Settings/ResourceList";
import { ListItems } from "components/Settings/ResourceList/types";
import { ResourceInput } from "components/Settings/ResourceInput";
import { ErrorPane } from "components/Settings/ErrorPane";
import { LoadingPane } from "components/Settings/LoadingPane";
import { EmptyPane } from "components/Settings/EmptyPane";
import { ItemAction, ItemLink } from "components/Settings/ResourceList/types";
import { getAccessToken } from "helpers/graphql";

type Change = {
  parentId?: string | null;
  subcategoryOrder?: string[];
};

interface SubcategoriesTabProps {
  categoryId: string;
}

export function SubcategoriesTab(props: SubcategoriesTabProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { category, subcategories, updateCategory } = useCategory(
    props.categoryId
  );
  const { createSubcategory, deleteSubcategory } = useSubcategories();
  const updateSubcategoryOrder = useMutation({
    mutationKey: ["updateSubcategoryOrder", props.categoryId],
    mutationFn: async ({
      id,
      subcategoryOrder,
    }: {
      id: string;
      subcategoryOrder: string[];
    }) => {
      const result = await API.graphql<GraphQLQuery<UpdateSubcategoryMutation>>(
        graphqlOperation(
          updateSubcategory,
          {
            input: {
              id,
              subcategoryOrder,
            },
          },
          await getAccessToken()
        )
      );

      return result;
    },
    onSuccess() {
      queryClient.invalidateQueries([
        "categories",
        props.categoryId,
        "subcategories",
      ]);
    },
  });
  const reorderSubcategories = useMutation({
    mutationKey: ["reorderSubcategories", props.categoryId],
    mutationFn: async ({
      list,
    }: {
      list: ListItems<Subcategory>;
    }): Promise<
      Array<
        | GraphQLResult<UpdateCategoryMutation>
        | GraphQLResult<UpdateSubcategoryMutation>
      >
    > => {
      const changes = new Map<string, Change>();
      const operations: Promise<any>[] = [];
      const rootSubcategoryOrder: string[] = [];

      for (const item of list) {
        rootSubcategoryOrder.push(item.value.id);

        switch (item.type) {
          case "item":
            // handle unparented subcategories
            if (item.value.parentId) {
              const changeset: Change = changes.get(item.value.id) ?? {};

              changes.set(item.value.id, {
                ...changeset,
                parentId: null,
              });
            }
            break;
          case "group": {
            const subcategoryOrder: string[] = [];
            for (const child of item.items) {
              subcategoryOrder.push(child.value.id);

              // handle newly-parented subcategories
              if (child.value.parentId !== item.value.id) {
                const changeset: Change = changes.get(child.value.id) ?? {};

                changes.set(child.value.id, {
                  ...changeset,
                  parentId: item.value.id,
                });
              }
            }

            const hasOrderChanged =
              subcategoryOrder.length !== item.value.subcategoryOrder?.length ||
              !subcategoryOrder.every(
                (n, i) => n === item.value.subcategoryOrder?.[i]
              );

            // handle reordered child subcategories
            if (hasOrderChanged) {
              const changeset = changes.get(item.value.id) ?? {};

              changes.set(item.value.id, {
                ...changeset,
                subcategoryOrder,
              });
            }
            break;
          }
        }
      }

      const hasRootOrderChanged =
        rootSubcategoryOrder.length !==
          category.data?.subcategoryOrder.length ||
        !rootSubcategoryOrder.every(
          (n, i) => n === category.data?.subcategoryOrder?.[i]
        );

      // handle reordered root subcategories
      if (hasRootOrderChanged) {
        const { data } = await API.graphql<
          GraphQLQuery<UpdateCategoryMutation>
        >(
          graphqlOperation(
            updateCategoryMutation,
            {
              input: {
                id: category.data?.id,
                ownerId: category.data?.ownerId,
                subcategoryOrder: rootSubcategoryOrder,
              },
            } as UpdateCategoryMutationVariables,
            await getAccessToken()
          )
        );

        queryClient.setQueryData(["categories", data?.updateCategory?.id], {
          ...category.data,
          subcategoryOrder: rootSubcategoryOrder,
        });
        queryClient.invalidateQueries(["categories", data?.updateCategory?.id]);
      }

      // commit all subcategory changes
      for (const [id, change] of changes) {
        operations.push(
          API.graphql(
            graphqlOperation(
              updateSubcategory,
              {
                input: {
                  id,
                  ...change,
                },
              } as UpdateSubcategoryMutationVariables,
              await getAccessToken()
            )
          ) as Promise<GraphQLResult<UpdateSubcategoryMutation>>
        );
      }

      return Promise.all(operations);
    },
    onSuccess() {
      queryClient.invalidateQueries([
        "categories",
        props.categoryId,
        "subcategories",
      ]);
    },
  });

  const orderedListItems: ListItems<Subcategory> = useMemo(() => {
    if (!category.data || !subcategories.data) {
      return [];
    }

    const items: ListItems<Subcategory> = [];

    for (const id of category.data.subcategoryOrder) {
      const subcategory = subcategories.data.find(s => s.id === id);

      if (!subcategory) continue;

      if (subcategory.children?.items.length) {
        items.push({
          type: "group",
          value: subcategory,
          items: (subcategory.children.items.filter(s => s) as Subcategory[])
            .sort((a, b) => {
              return (
                (subcategory.subcategoryOrder?.indexOf(a.id) ?? 0) -
                (subcategory.subcategoryOrder?.indexOf(b.id) ?? 0)
              );
            })
            .map(s => ({
              type: "item",
              value: s,
            })),
        });
      } else {
        items.push({
          type: "item",
          value: subcategory,
        });
      }
    }

    return items;
  }, [category.data, subcategories.data]);

  const editItemsAction: ItemLink<Subcategory> = useMemo(
    () => ({
      type: "link",
      color: "secondary",
      label: "Add/Edit Items",
      condition: (item: Subcategory) => !Boolean(item.children?.items.length),
      to: (item: Subcategory) =>
        `/report/settings/categories/${props.categoryId}/subcategories/${item.id}`,
    }),
    [props.categoryId]
  );

  const addSubcategoryAction: ItemAction<Subcategory> = useMemo(
    () => ({
      type: "action",
      color: "secondary",
      label: "Add Subcategory",
      condition: (item: Subcategory) => {
        return !Boolean(item.items?.items.length) && !item.parentId;
      },
      onClick: async parent => {
        const data = await createSubcategory.mutateAsync({
          name: "New Subcategory",
          categoryId: parent.categoryId,
          categoryOwnerId: parent.categoryOwnerId,
          parentId: parent.id,
        });

        if (data) {
          await updateSubcategoryOrder.mutateAsync({
            id: parent.id,
            subcategoryOrder: [...(parent.subcategoryOrder ?? []), data.id],
          });
        }
      },
    }),
    [createSubcategory, updateSubcategoryOrder]
  );

  const deleteSubcategoryAction: ItemAction<Subcategory> = useMemo(
    () => ({
      type: "action",
      color: "danger",
      label: "Delete",
      condition: (item: Subcategory) => {
        return (
          !Boolean(item.items?.items.length) &&
          !Boolean(item.itemOrder?.length) &&
          !Boolean(item.children?.items.length)
        );
      },
      onClick: item => {
        deleteSubcategory.mutate({
          id: item.id,
        });
      },
    }),
    [deleteSubcategory]
  );

  let content: JSX.Element | null = null;
  switch (subcategories.status) {
    case "loading":
      content = <LoadingPane />;
      break;
    case "error":
      content = <ErrorPane>{t("Failed to load subcategories")}</ErrorPane>;
      break;
    case "success":
      if (subcategories.data?.length === 0) {
        content = <EmptyPane>{t("No subcategories found")}</EmptyPane>;
      } else {
        content = (
          <ResourceList
            type="subcategory"
            items={orderedListItems}
            canBecomeGroup={item => !item.parentId}
            onReorder={list => reorderSubcategories.mutateAsync({ list })}
            actions={[
              editItemsAction,
              addSubcategoryAction,
              deleteSubcategoryAction,
            ]}
          />
        );
      }
      break;
  }

  return (
    <>
      <h2 className="mb-2">{t("Add or Remove Subcategories")}</h2>
      <p className="mb-4">
        {t(
          "Categories control which subcategories appear on an inspection, as well as the order that they appear."
        )}
      </p>
      <div className="mb-3">
        <ResourceInput
          placeholder={t("Create a subcategory")}
          onCreate={async name => {
            if (!category.data) return;

            const subcategory = await createSubcategory.mutateAsync({
              name,
              categoryId: category.data.id,
              categoryOwnerId: category.data.ownerId,
            });

            if (!subcategory) return;

            return updateCategory.mutateAsync({
              id: category.data.id,
              ownerId: category.data.ownerId,
              subcategoryOrder: [
                ...(category.data.subcategoryOrder ?? []),
                subcategory.id,
              ],
            });
          }}
        />
      </div>
      {content}
    </>
  );
}
