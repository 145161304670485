import PropTypes from "prop-types";
import * as Yup from "yup";

// Phone regex for checking phone numbers
const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

class InviteMemeberSchema {
  constructor(props) {
    this.t = props.t;
  }

  validation(props) {
    return Yup.object().shape({
      firstName: Yup.string().required(
        this.t("error_first_name_blank", "Please enter a name")
      ),
      lastName: Yup.string().required(
        this.t("error_last_name_blank", "Please enter a name")
      ),
      email: Yup.string()
        .email(
          this.t("error_email_invald", "Please enter a valid email address")
        )
        .required(this.t("error_email_blank", "Please enter an email address")),
      website: Yup.string(),
      primaryPhone: Yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .required("Please enter a phone number"),
      secondaryPhone: Yup.string().matches(
        phoneRegExp,
        "Phone number is not valid"
      ),
      country: Yup.string()
        .transform(v => (v === null ? "" : v))
        .required(this.t("error_country_blank", "Please select a country")),
      region: Yup.string()
        .transform(v => (v === null ? "" : v))
        .required(this.t("error_region_blank", "Please select a region")),
      timezone: Yup.string()
        .transform(v => (v === null ? "" : v))
        .required(this.t("error_timezone_blank", "Please select a timezone")),
      role: Yup.string().required(
        this.t("error_role_unselected", "Please select a role")
      ),
    });
  }
}

InviteMemeberSchema.propTypes = {
  t: PropTypes.func,
};

export default InviteMemeberSchema;
