import { Card, CardBody, Row } from "reactstrap";
import ProfileForm from "pages/My/Settings/components/forms/_formProfile";
import { useTranslation } from "react-i18next";

export default function SectionProfile() {
  const { t } = useTranslation();

  return (
    <Card className="">
      <CardBody className="">
        <Row>
          <h2>{t("invite_team_member_title", "Invite a new team member")}</h2>
          <p className="mt-2 mb-0">
            {t(
              "invite_team_member_description",
              "Enter in the team member's name and contact information. At a minimum a valid and unique email is required for the user to be able to log in to Horizon. Once you've saved your team member, an invitation will be sent via email."
            )}
          </p>
          <ProfileForm newUser />
        </Row>
      </CardBody>
    </Card>
  );
}
