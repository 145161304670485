import { Button, TabPane } from "reactstrap";
import { Form, Formik, FormikValues } from "formik";
import * as Yup from "yup";
import "reactstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useProfile } from "hooks/profile";
import toastr from "toastr";
import LicensesForm, { LicenseObject } from "./forms/_formLicenses";
import { useHistory } from "react-router-dom";
import LeavingForm from "components/Common/LeavingForm";

export default function License(props: { newUser?: boolean; editing?: any }) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const history = useHistory();

  const profile = useProfile();
  const { data: profileData } = useQuery(["profile"], profile.fetch);
  let editing = props.editing;
  let profileLicenses = profileData?.licenses;
  let defaultState;

  if (editing && editing.licenses && typeof editing.licenses == "object") {
    editing.licenses = Object.keys(editing.licenses).map((id: string) => {
      return {
        id: id,
        ...editing.licenses[id],
      };
    });
    defaultState = {
      licenses: editing.licenses,
      companyLicense: editing.companyLicense,
    };
  }

  if (!editing) {
    profileLicenses =
      profileData && profileData.licenses
        ? Object.keys(profileData.licenses).map((id: string) => {
            return {
              id: id,
              ...profileData.licenses[id],
            };
          })
        : null;
    defaultState = {
      licenses: profileLicenses,
      companyLicense: profileData?.companyLicense || "",
    };
  }

  const schema = Yup.object().shape({
    licenses: Yup.array(
      Yup.object().shape({
        licenseNumber: Yup.string().required(
          t(
            "error_license_number_blank",
            "Please enter a license or certificate number"
          )
        ),
        licenseType: Yup.string().required(
          t("error_license_type_blank", "Please enter a license type")
        ),
      })
    ),
  });

  const saveProfile = useMutation(
    (values: FormikValues) => {
      return profile.save(values);
    },
    {
      onSuccess: (data: any) => {
        toastr.success(
          t("profile_saved_success", "Profile saved successfully")
        );
        queryClient.invalidateQueries(["profile"]);
        if (editing) {
          history.replace("/company/team/edit", { ...data.data });
          editing.licenses = Object.keys(data.data.licenses).map(
            (id: string) => {
              return {
                id: id,
                ...editing.licenses[id],
              };
            }
          );
        }
      },
      onError: error => {
        console.log(error);
      },
    }
  );

  function handleFormSubmit(values: FormikValues) {
    const newLicenses: {
      [id: string]: LicenseObject;
    } = {};
    values.licenses.forEach((license: LicenseObject) => {
      const { id, ...rest } = license;
      newLicenses[id as string] = rest;
    });
    saveProfile.mutate({
      ...editing,
      licenses: newLicenses,
      companyLicense: values.companyLicense,
    });
  }

  return (
    <TabPane tabId="license">
      {!props.newUser ? (
        <h2>
          {t(
            "personal_settings_license_tab_title",
            "Licenses and certificates"
          )}
        </h2>
      ) : (
        ""
      )}

      <Formik
        initialValues={defaultState as FormikValues}
        validationSchema={schema}
        enableReinitialize={true}
        onSubmit={handleFormSubmit}
      >
        {formik => (
          <Form>
            <LicensesForm />
            <div className="mt-5">
              <Button color="primary" type="submit">
                {t("save_changes", "Save Changes")}
              </Button>
              <LeavingForm formikProps={formik} />
            </div>
          </Form>
        )}
      </Formik>
    </TabPane>
  );
}
