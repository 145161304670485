import { useState } from "react";
import { Button } from "reactstrap";

import moment from "moment";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import Icon from "components/Common/Icons";
import { useTranslation } from "react-i18next";
import DateTime from "components/Common/DateTime";

const DayBlock = props => {
  const { t } = useTranslation();
  const [editing, setEditing] = useState({
    idx: null,
    start: null,
    end: null,
  });
  const [editorOpen, setEditorOpen] = useState(false);

  function setStartTime(value) {
    setEditing({
      ...editing,
      start: value,
    });
  }

  function toggleChange(e) {
    // Don't allow editing of two items at a time
    if (props.toggleEditing(true)) return;

    // Tell other items that I'm being edited
    props.toggleEditing();

    let target = e.target;
    if (!target.dataset.key) {
      target = target.closest(".time-edit");
    }

    if (!target) return;

    const idx = target.dataset.key;
    const time = props.day.times[idx];

    setEditorOpen(true);
    setEditing({
      idx: idx,
      start: moment(time.start, "HH:mm"),
      end: moment(time.end, "HH:mm"),
    });
  }

  function cancel() {
    setEditorOpen(false);
    setEditing({
      idx: null,
      start: null,
      end: null,
    });
    props.toggleEditing();
  }

  function changeTime() {
    props.changeTime(props.idx, editing);
    cancel();
  }

  function setEndTime(value) {
    setEditing({
      ...editing,
      end: value,
    });
  }

  function deleteTime(e, idx) {
    // Don't allow delete when editing another item
    if (props.toggleEditing(true)) return;

    e.stopPropagation();
    props.deleteTime(props.idx, idx);
  }

  function addTime() {
    props.addTime(props.idx);
  }

  const startTimeLabel =
    props.type === "slots"
      ? t("availability_slot_start", "Slot:")
      : t("availability_start", "Start:");

  const startTimeInput = (
    <div className="topRow">
      <label>
        <span className="edit-label">{startTimeLabel}</span>
        <TimePicker
          showSecond={false}
          value={editing.start}
          use12Hours={true}
          minuteStep={15}
          allowEmpty={false}
          onChange={setStartTime}
        />
      </label>
    </div>
  );

  const endTimeInput =
    props.type === "slots" ? (
      ""
    ) : (
      <div className="bottomRow">
        <label>
          <span className="edit-label">End:</span>
          <TimePicker
            showSecond={false}
            value={editing.end}
            use12Hours
            minuteStep={15}
            allowEmpty={false}
            onChange={setEndTime}
          />
        </label>
      </div>
    );

  const timeDisplay = time => (
    <>
      <DateTime type="shortTime" time={time.start} />
      {props.type === "slots" ? (
        ""
      ) : (
        <>
          - <DateTime type="shortTime" time={time.end} />
        </>
      )}
    </>
  );
  return (
    <div
      className={
        "day" +
        (editorOpen ? " open" : "") +
        (props.invalidDay ? " invalidDay" : "")
      }
    >
      <div className="day-title">{props.day.name}</div>
      <div className="day-slot-list">
        {!props.day || props.day.times.length < 1 ? (
          <div className="day-slot no-hover">
            <span className="no-time time-edit">
              {t("do_not_allow_scheduling", "No scheduling")}
            </span>
          </div>
        ) : (
          props.day.times.map((time, idx) => {
            return (
              <div
                key={idx}
                data-key={idx}
                className="day-slot"
                onClick={toggleChange}
              >
                {editing.idx === String(idx) ? (
                  <div data-key={idx} className="time-edit">
                    <Button
                      color="danger"
                      className="btn-sm p-1 time-edit-btn cancel-btn"
                      onClick={cancel}
                    >
                      <Icon name="x" />
                    </Button>
                    {startTimeInput}
                    {endTimeInput}
                    <Button
                      color="success"
                      className="btn-sm p-1 time-edit-btn accept-btn"
                      onClick={changeTime}
                    >
                      <Icon name="check" />
                    </Button>
                  </div>
                ) : (
                  <span key={idx} data-key={idx} className="time time-edit">
                    <Button
                      color="alert"
                      className="btn-sm p-1 time-edit-btn delete-btn"
                      onClick={e => deleteTime(e, idx)}
                    >
                      <Icon name="trash" />
                    </Button>
                    {timeDisplay(time)}
                    <Button
                      color="alert"
                      className="btn-sm p-1 time-edit-btn edit-btn"
                      onClick={toggleChange}
                    >
                      <Icon name="pencil" />
                    </Button>
                  </span>
                )}
              </div>
            );
          })
        )}
      </div>
      <Button
        color="primary"
        className="btn-sm add-time border-0 add-btn"
        onClick={addTime}
      >
        +
      </Button>
    </div>
  );
};

export default DayBlock;
