import { useFormikContext } from "formik";
import { WorkOrder, Address } from "hooks/workorders/workorder";
import { useWorkOrders } from "hooks/workorders/workorders";
import { isDate } from "lodash";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Badge, Card, CardBody, Col, Label, Row } from "reactstrap";

export default function ReportNumber(props: any) {
  const { t } = useTranslation();
  const formik: any = useFormikContext();
  let { workOrders } = useWorkOrders();

  let _otherWorkOrders: WorkOrder[] = [];

  const compareAddresses = (address1: Address, address2: Address) => {
    return (
      address1.address1 === address2.address1 &&
      address1.address2 === address2.address2 &&
      address1.city === address2.city &&
      address1.region === address2.region &&
      address1.postCode === address2.postCode
    );
  };

  const getContractStatus = (workOrder: WorkOrder) => {
    switch (workOrder.contractOptions) {
      case undefined:
      case "":
      case "awaitingAcceptance":
        return (
          <Badge color="warning" className="transparent text-sm">
            {t("awaiting_acceptance", "Not accepted")}
          </Badge>
        );
      case "confirmedInspection":
        return (
          <Badge color="success" className="transparent text-sm">
            {t("confirmed_inspection", "Confirmed inspection")}
          </Badge>
        );
      case "rejected":
        return (
          <Badge color="errors" className="transparent text-sm">
            {t("contract_rejected", "Contract rejected")}
          </Badge>
        );
      default:
        break;
    }
  };

  if (workOrders.isSuccess && formik.values.inspection !== undefined) {
    _otherWorkOrders = workOrders.data.filter(workOrder => {
      return (
        compareAddresses(
          workOrder.inspection.address,
          formik.values.inspection.address
        ) && workOrder.id !== formik.values.id
        //TODO: Add a check for status once we figure out what the statuses are
      );
    });
  }

  if (formik.values.reportNumber === undefined) {
    return "";
  } else {
    return (
      <Card>
        <CardBody>
          <h2 className="mt-2 mb-2">
            {t("report_number", "Report number")}{" "}
            {"Work Order Report Number: " + formik.values.reportNumber ===
            undefined
              ? ""
              : formik.values.reportNumber}
          </h2>
          <Row>
            {_otherWorkOrders.length > 0 ? (
              <Col>
                {t("also_inspected_on", "Also inspected on") + " "}
                {_otherWorkOrders?.map((workOrder, index) => {
                  return (
                    <Link to={"/work/order/" + workOrder.id} key={index}>
                      {new Date(
                        workOrder.inspection.inspectionDate
                      ).toLocaleDateString() +
                        " (" +
                        workOrder.reportNumber +
                        ")"}
                    </Link>
                  );
                })}
              </Col>
            ) : (
              ""
            )}
            <Col>
              <Label for="confirmed">
                {t("booking_status", "Booking status")}
              </Label>
              <div className="form-check form-switch form-switch form-check-primary mb-1">
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={formik.values.confirmed || false}
                  name="confirmed"
                  onChange={e => {
                    // check if a string can be parsed as a date
                    if (!e.target.checked) {
                      formik.setFieldValue("inspection.inspectionDate", false);
                    } else if (
                      isDate(formik.values.inspection.inspectionDate)
                    ) {
                      formik.setFieldValue("confirmed", e.target.checked);
                    } else {
                      alert(
                        t(
                          "inspection_date_required",
                          "Inspection date is required to confirm booking"
                        )
                      );
                    }
                  }}
                />
                {formik.values.confirmed
                  ? t("confirmed", "Confirmed")
                  : t("tentative", "Tentative")}
              </div>
            </Col>
            <Col>
              <Label>{t("contract_status", "Contract status")}</Label>
              <br />
              {getContractStatus(formik.values)}
            </Col>
            <Col>
              <Label>{t("payment_status", "Payment status")}</Label>
              <br />
              <Badge color="danger" className="transparent text-sm">
                {t("payment_not_made", "Not paid")}
              </Badge>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}
