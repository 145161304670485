import { all, fork } from "redux-saga/effects";

//public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./profile/saga";
import LayoutSaga from "./layout/saga";
import MessageHubSaga from "./message/saga";
import CompanyTeam from "./Company/Team/saga";
import CompanySettings from "./Company/Settings/saga";
import WorkOrderSaga from "./Work/Order/saga";
import SnippetSaga from "./Snippet/saga";
import TemplatesSaga from "./templates/saga";
import CategoriesSaga from "./categories/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ProfileSaga),
    fork(ForgetSaga),
    fork(LayoutSaga),
    fork(MessageHubSaga),
    fork(CompanyTeam),
    fork(CompanySettings),
    fork(WorkOrderSaga),
    fork(SnippetSaga),
    fork(TemplatesSaga),
    fork(CategoriesSaga),
  ]);
}
