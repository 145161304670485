import { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Col, Row, TabPane, TabContent } from "reactstrap";

import { useTemplate } from "hooks/templates";
import SideMenu from "components/Menus/SideMenu";
import StandardPage from "components/Common/StandardPage";
import SideMenuNavItem from "components/Menus/SideMenuNavItem";
import { ManageTab } from "./components/ManageTab";
import { CategoriesTab } from "./components/CategoriesTab";
import { DefaultsTab } from "./components/DefaultsTab";

export function EditTemplate() {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation("templates");
  const [activeTab, setActiveTab] = useState("categories");
  const { template } = useTemplate(id);

  return (
    <StandardPage
      pageid="edit-template"
      pagetitle={`${t("Edit Template")} "${template.data?.name}"`}
      breadcrumbItems={[
        {
          uri: "/",
          text: t("report", "Report"),
          active: false,
        },
        {
          uri: "/report/settings",
          text: t("settings", "Settings"),
          active: false,
        },
        {
          uri: "/report/settings/templates",
          text: t("templates", "Templates"),
          active: false,
        },
        {
          uri: `/report/settings/templates/${id}`,
          text: template.data?.name,
          active: true,
        },
      ]}
    >
      <Row>
        <Col md={3} className="pr-0 side-nav-column mobile-shadow">
          <SideMenu prefix="edit_template_menu">
            <SideMenuNavItem
              key="categories"
              id="categories"
              label={t("Categories")}
              activeTab={activeTab}
              toggleTab={setActiveTab}
            />
            <SideMenuNavItem
              key="defaults"
              id="defaults"
              label={t("Defaults")}
              activeTab={activeTab}
              toggleTab={setActiveTab}
            />
            <SideMenuNavItem
              key="general"
              id="general"
              label={t("Manage")}
              activeTab={activeTab}
              toggleTab={setActiveTab}
            />
          </SideMenu>
        </Col>
        <Col className="bg-white p-0">
          <TabContent activeTab={activeTab}>
            <TabPane tabId="categories">
              {template.data && <CategoriesTab template={template.data} />}
            </TabPane>
            <TabPane tabId="defaults">
              {template.data && <DefaultsTab template={template.data} />}
            </TabPane>
            <TabPane tabId="general">
              {template.data && <ManageTab template={template.data} />}
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </StandardPage>
  );
}
