import {
  SNIPPETS_ERROR,
  HYDRATE_SNIPPETS,
  HYDRATE_SNIPPETS_SUCCESS,
  HYDRATE_SNIPPET,
  HYDRATE_SNIPPET_SUCCESS,
  EDIT_SNIPPET,
  EDIT_SNIPPET_SUCCESS,
  ADD_SNIPPET,
  ADD_SNIPPET_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: "",
  success: "",
};

const settings = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_SNIPPET:
      state = { ...state };
      break;
    case EDIT_SNIPPET_SUCCESS:
      state = { ...state, ...action.payload, success: true };
      break;
    case ADD_SNIPPET:
      state = { ...state };
      break;
    case ADD_SNIPPET_SUCCESS:
      state = { ...state, ...action.payload, success: true };
      break;
    case SNIPPETS_ERROR:
      state = { ...state, error: action.payload };
      break;
    case HYDRATE_SNIPPETS:
      state = { ...state };
      break;
    case HYDRATE_SNIPPETS_SUCCESS:
      state = { ...state, data: action.payload, success: true };
      break;
    case HYDRATE_SNIPPET:
      state = { ...state };
      break;
    case HYDRATE_SNIPPET_SUCCESS:
      state = { ...state, ...action.payload, success: true };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default settings;
