import {
  WORK_ORDERS_ERROR,
  WORK_ORDER_ERROR,
  EDIT_WORK_ORDER,
  EDIT_WORK_ORDER_SUCCESS,
  ADD_WORK_ORDER,
  ADD_WORK_ORDER_SUCCESS,
  HYDRATE_WORK_ORDERS,
  HYDRATE_WORK_ORDERS_SUCCESS,
  HYDRATE_WORK_ORDER,
  HYDRATE_WORK_ORDER_SUCCESS,
} from "./actionTypes";

export const editWorkOrder = workOrder => {
  return {
    type: EDIT_WORK_ORDER,
    payload: { workOrder },
  };
};

export const editWorkOrderSuccess = status => {
  return {
    type: EDIT_WORK_ORDER_SUCCESS,
    payload: status,
  };
};

export const addWorkOrder = workOrder => {
  return {
    type: ADD_WORK_ORDER,
    payload: { workOrder },
  };
};

export const addWorkOrderSuccess = status => {
  return {
    type: ADD_WORK_ORDER_SUCCESS,
    payload: status,
  };
};

export const hydrateWorkOrder = id => {
  return {
    type: HYDRATE_WORK_ORDER,
    id: id,
  };
};

export const hydrateWorkOrderSuccess = settings => {
  return {
    type: HYDRATE_WORK_ORDER_SUCCESS,
    payload: settings,
  };
};

export const hydrateWorkOrders = () => {
  return {
    type: HYDRATE_WORK_ORDERS,
  };
};

export const hydrateWorkOrdersSuccess = settings => {
  return {
    type: HYDRATE_WORK_ORDERS_SUCCESS,
    payload: settings,
  };
};

export const WorkOrdersError = error => {
  return {
    type: WORK_ORDERS_ERROR,
    payload: error,
  };
};

export const WorkOrderError = error => {
  return {
    type: WORK_ORDER_ERROR,
    payload: error,
  };
};
