import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";

export default function LeavingForm(props) {
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (
        props.formikProps.dirty &&
        Object.keys(props.formikProps.touched).length > 0
      ) {
        return "Are you sure you want to leave this page without saving?";
      }
      return true;
    });

    return () => {
      unblock();
    };
  }, [props.formikProps.dirty, props.formikProps.touched]);
  return (
    <Button
      type="button"
      color="outline-blue"
      className="ms-2"
      onClick={() => {
        history.goBack();
      }}
    >
      {t("cancel", "Cancel")}
    </Button>
  );
}
