import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
export default function Footer(props) {
  const { t } = useTranslation();
  let copyright =
    "© " + new Date().getFullYear() + " Carson Dunlop & Associates";
  if (window.location.href.indexOf("npi") !== -1) {
    copyright =
      "© " + new Date().getFullYear() + " National Property Inspections";
  }
  return (
    <React.Fragment>
      <footer className="footer">
        <div className="d-flex justify-content-between">
          <div>
            <Link to="/privacy" className="text-secondary mr-2">
              {t("privacy_policy", "Privacy Policy")}
            </Link>
            |
            <Link to="/eula" className="text-secondary ml-2">
              {t("eula_short", "EULA")}
            </Link>
          </div>
          <div>{copyright}</div>
        </div>
      </footer>
    </React.Fragment>
  );
}
