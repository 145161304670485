import { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Col, Row, TabPane, TabContent } from "reactstrap";

import { useCategory } from "hooks/categories";
import SideMenu from "components/Menus/SideMenu";
import StandardPage from "components/Common/StandardPage";
import SideMenuNavItem from "components/Menus/SideMenuNavItem";
import { ManageTab } from "./components/ManageTab";
import { SubcategoriesTab } from "./components/SubcategoriesTab";

export function EditCategory() {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation("categories");
  const [activeTab, setActiveTab] = useState("subcategories");
  const { category } = useCategory(id);

  return (
    <StandardPage
      pageid="edit-template"
      pagetitle={`${t("Edit Category")} "${category.data?.name}"`}
      breadcrumbItems={[
        {
          uri: "/",
          text: t("report", "Report"),
          active: false,
        },
        {
          uri: "/report/settings",
          text: t("settings", "Settings"),
          active: false,
        },
        {
          uri: "/report/settings/categories",
          text: t("Category"),
          active: false,
        },
        {
          uri: `/report/settings/categories/${id}`,
          text: category.data?.name,
          active: true,
        },
      ]}
    >
      <Row>
        <Col md={3} className="pr-0 side-nav-column mobile-shadow">
          <SideMenu prefix="edit_template_menu">
            <SideMenuNavItem
              key="subcategories"
              id="subcategories"
              label={t("Subcategories")}
              activeTab={activeTab}
              toggleTab={setActiveTab}
            />
            <SideMenuNavItem
              key="general"
              id="general"
              label={t("Manage")}
              activeTab={activeTab}
              toggleTab={setActiveTab}
            />
          </SideMenu>
        </Col>
        <Col className="bg-white p-0">
          <TabContent activeTab={activeTab}>
            <TabPane tabId="subcategories">
              <SubcategoriesTab categoryId={id} />
            </TabPane>
            <TabPane tabId="general">
              <ManageTab id={id} />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </StandardPage>
  );
}
