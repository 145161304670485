import Icon from "components/Common/Icons";
import DateBox from "devextreme-react/date-box";
import { formatDate } from "devextreme/localization";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody } from "reactstrap";

export default function DatePicker(props: any) {
  let [showDatePicker, setShowDatePicker] = useState(false);
  let [currentDate, setCurrentDate] = useState(
    props.defaultValue || new Date()
  );
  const { t } = useTranslation();

  return (
    <Card className="mb-2">
      <CardBody className="p-2">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a onClick={() => setShowDatePicker(!showDatePicker)}>
          <Icon name="schedule"></Icon>{" "}
          {formatDate(currentDate, "MM/d/y") ===
          formatDate(new Date(), "MM/d/y")
            ? t("todays_jobs", "Today's jobs")
            : formatDate(currentDate, "longDate")}{" "}
          {t("at_a_glance", "at a glance")} ({t("change_date", "change date")})
        </a>
        {showDatePicker ? (
          <DateBox
            defaultValue={props.defaultValue}
            type="date"
            onValueChanged={e => {
              props.onDateChanged(e.value);
              setCurrentDate(e.value);
              setShowDatePicker(false);
            }}
            style={{ maxWidth: "200px" }}
          />
        ) : (
          ""
        )}
      </CardBody>
    </Card>
  );
}
