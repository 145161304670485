import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useCompany } from "hooks/company";
import * as Yup from "yup";
import ApplySection from "./components/_applySection";
import DetailsSection from "./components/_detailsSection";
import DiscountTypeSection from "./components/_discountTypeSection";
import LimitSection from "./components/_limitSection";
import OptionsSection from "./components/_optionsSection";
import TimeSection from "./components/_timeSection";
import ButtonSection from "./components/_buttonSection";
import { useDiscount, useDiscounts } from "hooks/discounts";
import { Form, Formik } from "formik";
import StandardPage from "components/Common/StandardPage";

export default function AddDiscount(props: any) {
  const { t } = useTranslation();
  const location: any = useLocation();
  const { discount, updateDiscount } = useDiscount(location.state?.id);
  const { createDiscount } = useDiscounts();
  const [editDiscount, setEditDiscount] = useState<any>([]);
  const { data: companyData } = useCompany();

  const defaultValues: any = {
    type: null,
    name: "",
    description: "",
    appliesTo: "ENTIRE_INVOICE",
    option: "PERCENTAGE",
    value: 0,
    minPurchase: false,
    minPurchaseAmount: 0,
    limit: false,
    onePerCustomer: false,
    fixedLimit: false,
    fixedLimitAmount: 0,
    startDate: "",
    endDate: "",
  };

  useEffect(() => {
    setEditDiscount(discount.data);
  }, [discount.data]);

  const formSubmit = (values: any, { validate }: any) => {
    console.log(validate);
    if (values.id) {
      const { createdAt, updatedAt, ...rest } = values;
      updateDiscount.mutate(rest);
    } else {
      createDiscount.mutate(values);
    }
  };

  const invalidNumberError = t(
    "error_invalid_number",
    "Please enter a valid number"
  );

  const validationSchema = Yup.object().shape({
    type: Yup.string().required(
      t("error_discount_type_blank", "Please select a discount type")
    ),
    name: Yup.string().when("type", {
      is: (type: string) => type === "CHECKOUT",
      then: Yup.string().required(
        t("error_discount_code_blank", "Please enter a discount code")
      ),
      otherwise: Yup.string().required(
        t("error_discount_name_blank", "Please enter a discount name")
      ),
    }),
    description: Yup.string().required(
      t(
        "error_discount_description_blank",
        "Please enter a description for this discount"
      )
    ),
    value: Yup.number()
      .when("option", {
        is: (option: string) => option === "PERCENTAGE",
        then: Yup.number()
          .required(
            t(
              "error_discount_option_percentage_blank",
              "Please enter a percentage"
            )
          )
          .typeError(invalidNumberError),
      })
      .when("option", {
        is: (option: string) => option === "FIXED",
        then: Yup.number()
          .required(
            t("error_discount_option_value_blank", "Please enter an amount")
          )
          .typeError(invalidNumberError),
      }),
    minPurchaseAmount: Yup.number().when("minPurchase", {
      is: true,
      then: Yup.number()
        .required(
          t("error_discount_min_purchase_blank", "Please enter an amount")
        )
        .typeError(invalidNumberError),
    }),
    fixedLimitAmount: Yup.number().when("fixedLimit", {
      is: true,
      then: Yup.number()
        .required(
          t("error_discount_fixed_limit_blank", "Please enter an amount")
        )
        .typeError(invalidNumberError),
    }),
  });

  return (
    <StandardPage
      pageid="report_settings"
      pagetitle={
        editDiscount
          ? t("edit_discount_title", "Editing {{discount_name}}", {
              discount_name: editDiscount.name,
            })
          : t("create_discount_title", "Create a new discount")
      }
      breadcumbIcon="bx bxs-user-rectangle"
      breadcrumbItems={[
        {
          uri: "/company/settings",
          text: t("settings", "Settings"),
        },
        {
          uri: "/company/settings",
          text: companyData?.name || "",
        },
        {
          uri: "/company/settings/discounts",
          text: t("discounts", "Discounts"),
          active: true,
        },
      ]}
    >
      <Formik
        enableReinitialize={true}
        initialValues={editDiscount || defaultValues}
        onSubmit={formSubmit}
        validationSchema={validationSchema}
        validateOnChange={false}
      >
        <Form>
          <DiscountTypeSection />
          <DetailsSection />
          <OptionsSection />
          <ApplySection />
          <LimitSection />
          <TimeSection />
          <ButtonSection editing={editDiscount ? true : false} />
        </Form>
      </Formik>
    </StandardPage>
  );
}
