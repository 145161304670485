import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Badge } from "reactstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { editSnippet, hydrateSnippets, addSnippet } from "store/actions";
import { Auth } from "aws-amplify";

import DropDownBox from "devextreme-react/drop-down-box";
import TreeView from "devextreme-react/tree-view";
import CustomStore from "devextreme/data/custom_store";
import DataGrid from "components/Tables/DataGrid";
import { confirm } from "devextreme/ui/dialog";
import { getCSSClass } from "components/Common/Icons";

import {
  Column,
  RowDragging,
  Editing,
  Button,
} from "devextreme-react/data-grid";

const { v4: uuid } = require("uuid");

const move = function (array, from, to) {
  array.splice(to, 0, array.splice(from, 1)[0]);
};

class TabSnippets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hydrated: false,
      treeBoxValue: null,
      isTreeBoxOpened: false,
      treeDataSource: [],
      snippets: null,
      dataGridDataSource: [],
    };
    this.treeView = null;
    this.props.hydrateSnippets();
    this.syncTreeViewSelection = this.syncTreeViewSelection.bind(this);
    this.onTreeBoxOpened = this.onTreeBoxOpened.bind(this);
    this.treeViewRender = this.treeViewRender.bind(this);
    this.treeViewItemSelectionChanged =
      this.treeViewItemSelectionChanged.bind(this);
    this.treeViewOnContentReady = this.treeViewOnContentReady.bind(this);
    this.onTreeItemClick = this.onTreeItemClick.bind(this);
    this.getTreeData = this.getTreeData.bind(this);
    this.getTreeDataByKey = this.getTreeDataByKey.bind(this);
    this.getDropDownData = this.getDropDownData.bind(this);
    this.getDropDownDataByKey = this.getDropDownDataByKey.bind(this);
    this.getDataGridData = this.getDataGridData.bind(this);
    this.onReorder = this.onReorder.bind(this);
    this.renderTreeViewItem = this.renderTreeViewItem.bind(this);
    this.onRowValidating = this.onRowValidating.bind(this);
    this.updateDataGrid = this.updateDataGrid.bind(this);
    this.insertDataGrid = this.insertDataGrid.bind(this);
    this.removeDataGrid = this.removeDataGrid.bind(this);
    this.getBadge = this.getBadge.bind(this);
    this.getConfirmMesssage = this.getConfirmMesssage.bind(this);
  }

  syncTreeViewSelection(e) {
    this.setState({
      treeBoxValue: e.value,
    });
    if (!this.treeView) return;

    if (!e.value) {
      this.treeView.instance.unselectAll();
    } else {
      this.treeView.instance.unselectAll();
      this.treeView.instance.selectItem(e.value);
    }
  }

  onTreeBoxOpened(e) {
    if (e.name === "opened") {
      this.setState({
        isTreeBoxOpened: e.value,
      });
    }
  }

  getBadge(data) {
    let type = "";
    let color = "";
    let show = false;
    if (data.id !== 0) {
      show = true;

      if (!data.franchisorId && !data.companyId && !data.userId) {
        type = this.props.t("system", "System");
        color = "success";
      } else if (data.franchisorId) {
        type = this.props.t("franchisor", "Franchisor");
        color = "warning";
      } else if (data.companyId) {
        type = this.props.t("company", "Company");
        color = "secondary";
      } else if (data.userId) {
        type = this.props.t("user", "User");
        color = "primary";
      }
    }
    return show ? <Badge color={color}>{type}</Badge> : "";
  }

  renderTreeViewItem(data, index) {
    return (
      <div>
        {data.text} {this.getBadge(data)}
      </div>
    );
  }

  treeViewRender() {
    return (
      <TreeView
        dataSource={this.state.snippetData}
        keyExpr="text"
        displayExpr="text"
        ref={ref => {
          this.treeView = ref;
        }}
        selectByClick={true}
        onContentReady={this.treeViewOnContentReady}
        onItemClick={this.onTreeItemClick}
        onItemSelectionChanged={this.treeViewItemSelectionChanged}
        itemRender={this.renderTreeViewItem}
      />
    );
  }

  treeViewItemSelectionChanged(e) {
    this.setState({
      treeBoxValue: e.node.itemData.id,
    });
    this.setState({
      dataGridDataSource: new CustomStore({
        key: "id",
        load: this.getDataGridData,
        update: this.updateDataGrid,
        insert: this.insertDataGrid,
        remove: this.removeDataGrid,
      }),
    });
  }

  async removeDataGrid(key) {
    let snippets = this.state.snippets;
    let index = snippets.findIndex(item => item.id === this.state.treeBoxValue);
    let snippet = snippets[index];

    let snippetValues = snippet.values.filter(item => item.id !== key);

    snippet.values = snippetValues;

    if (!snippet.userId) {
      const user = await Auth.currentUserInfo();
      const userId = user["id"];
      snippet.id = uuid();
      snippet.userId = userId;

      this.props.addSnippet(snippet);

      snippets.push(snippet);
    } else {
      this.props.editSnippet(snippet);
    }
    this.setState({
      treeBoxValue: snippet.id,
      hydrated: false,
    });
    this.treeView.instance.selectItem(snippet.id);
  }

  async insertDataGrid(values) {
    const currentLanguage = localStorage.getItem("I18N_LANGUAGE");
    let snippets = this.state.snippets;
    let index = snippets.findIndex(item => item.id === this.state.treeBoxValue);
    let snippet = snippets[index];

    values = {
      ...values,
      language: currentLanguage,
      id: uuid(),
      orderNumber: -1,
    };

    snippet.values.push(values);

    if (!snippet.userId) {
      snippet.id = uuid();

      await this.setState({
        treeBoxValue: snippet.id,
        hydrated: false,
      });

      const user = await Auth.currentUserInfo();
      const userId = user["id"];
      snippet.userId = userId;

      await this.props.addSnippet(snippet);
      await this.setState({
        treeBoxValue: snippet.id,
        hydrated: false,
      });
    } else {
      await this.props.editSnippet(snippet);
    }

    this.treeView.instance.selectItem(snippet.id);
  }

  async updateDataGrid(key, values) {
    let snippets = this.state.snippets;
    let index = snippets.findIndex(item => item.id === this.state.treeBoxValue);
    let snippet = snippets[index];

    let snippetValues = snippet.values.filter(item => item.id === key)[0];
    let newValues = {
      ...snippetValues,
      ...values,
    };
    let valueIndex = snippet.values.findIndex(item => item.id === key);
    snippet.values[valueIndex] = newValues;

    if (!snippet.userId) {
      const user = await Auth.currentUserInfo();
      const userId = user["id"];
      snippet.id = uuid();
      snippet.userId = userId;

      this.props.addSnippet(snippet);
    } else {
      this.props.editSnippet(snippet);
    }
    this.setState({
      treeBoxValue: snippet.id,
      hydrated: false,
    });
    this.treeView.instance.selectItem(snippet.id);
  }

  treeViewOnContentReady(e) {
    e.component.selectItem(this.state.treeBoxValue);
    this.treeViewComponent = e.component;
  }

  componentDidUpdate = async () => {
    if (!this.state.hydrated && this.props.Snippets.success) {
      await this.setState({
        hydrated: true,
        treeDataSource: new CustomStore({
          key: "id",
          load: this.getTreeData,
          byKey: this.getTreeDataByKey,
        }),
        dropDownDataSource: new CustomStore({
          key: "id",
          load: this.getDropDownData,
          byKey: this.getDropDownDataByKey,
        }),
        snippets: this.formatSnippets(this.props.Snippets.data || []),
        snippetData: this.formatTreeData(this.props.Snippets.data || []),
      });
    }
  };

  formatSnippets(data) {
    if (data.count > 0) {
      let newData = [];
      data.forEach(item => {
        item.text = this.props.t("snippets:" + item.snippetType);
        newData.push(item);
      });
      return newData;
    } else {
      return data;
    }
  }

  formatTreeData(data) {
    let sortedData = data.sort((a, b) => {
      if (a.category < b.category) {
        return -1;
      }
      if (a.category > b.category) {
        return 1;
      }
      return 0;
    });

    let currentCategory = "";
    let newCategory = null;
    let formattedData = [];
    sortedData.forEach(item => {
      if (currentCategory !== item["category"]) {
        currentCategory = item["category"];
        if (newCategory !== null) {
          formattedData.push(newCategory);
        }
        newCategory = {
          id: 0,
          text: this.props.t("snippets:" + item.category),
          expanded: true,
          items: [],
          enabled: false,
        };
      }
      newCategory.items.push({
        id: item["id"],
        text: this.props.t("snippets:" + item["snippetType"]),
        userId: item.userId,
        companyId: item.companyId,
        franchisorId: item.franchisorId,
      });
    });
    formattedData.push(newCategory);
    return formattedData;
  }

  getDropDownData() {
    return this.state.hydrated ? this.state.snippets : [];
  }

  getDropDownDataByKey(id) {
    return this.getDropDownData().find(element => element.id === id);
  }

  getTreeData() {
    return this.state.hydrated ? this.state.snippetData : [];
  }

  getTreeDataByKey(id) {
    return this.getTreeData().find(element => (element.id = id));
  }

  getDataGridData() {
    const currentLanguage = localStorage.getItem("I18N_LANGUAGE");
    if (this.state.hydrated && this.state.treeBoxValue) {
      let snippets = this.state.snippets.filter(
        item => item.id === this.state.treeBoxValue
      );
      let snippet = snippets[0];
      let data = snippet.values.filter(
        item => item.language === currentLanguage
      );
      data.sort(this.sortByOrderNumber);
      return data;
    } else {
      return [];
    }
  }

  sortByOrderNumber(a, b) {
    if (a.orderNumber < b.orderNumber) {
      return -1;
    }
    if (a.orderNumber > b.orderNumber) {
      return 1;
    }
    return 0;
  }

  onTreeItemClick() {
    this.setState({
      isTreeBoxOpened: false,
    });
  }

  onReorder(e) {
    e.promise = this.processReorder(e);
  }

  async processReorder(e) {
    let snippets = this.state.snippets;
    let index = snippets.findIndex(item => item.id === this.state.treeBoxValue);
    let snippet = snippets[index];
    let message = this.getConfirmMesssage(snippet);
    let title = this.props.t("save", "Save");

    let result = confirm(message, title);
    result.then(async dialogResult => {
      if (dialogResult) {
        const currentLanguage = localStorage.getItem("I18N_LANGUAGE");

        let snippetValues = snippet.values.filter(
          item => item.language === currentLanguage
        );
        snippetValues.sort(snippetValues.sortByOrderNumber);
        move(snippetValues, e.fromIndex, e.toIndex);
        let count = 0;
        snippetValues.forEach(item => {
          item.orderNumber = count++;
        });

        snippet.values = snippetValues;

        if (!snippet.userId) {
          const user = await Auth.currentUserInfo();
          const userId = user["id"];
          snippet.id = uuid();
          snippet.userId = userId;

          this.props.addSnippet(snippet);
        } else {
          this.props.editSnippet(snippet);
        }

        snippets[index] = snippet;
        this.setState({
          treeBoxValue: snippet.id,
          hydrated: false,
        });
        this.treeView.instance.selectItem(snippet.id);
        await e.component.refresh();
      }
    });
  }

  skipValidationCheck = false;

  onRowValidating(e) {
    if (this.skipValidationCheck) {
      e.isValid = true;
      this.skipValidationCheck = false;
      return;
    }
    e.isValid = false;

    let snippets = this.state.snippets;
    let index = snippets.findIndex(item => item.id === this.state.treeBoxValue);
    let snippet = snippets[index];
    let message = this.getConfirmMesssage(snippet);
    let title = this.props.t("save", "Save");

    let result = confirm(message, title);
    result.then(dialogResult => {
      if (dialogResult) {
        this.skipValidationCheck = true;
        e.component.saveEditData();
      }
    });
  }

  getConfirmMesssage(snippet) {
    let message = "";
    if (snippet.userId) {
      message = this.props.t("save_confirm", "Are you sure you want to save?");
    } else if (snippet.franchisorId) {
      message = this.props.t(
        "save_confirm_override_franchise",
        "Are you sure you want to save? This is currently a default set by your franchise.  If you continue, you will be creating your own individual snippet."
      );
    } else if (snippet.companyId) {
      message = this.props.t(
        "save_confirm_override_company",
        "Are you sure you want to save? This is currently a default set by your company.  If you continue, you will be creating your own individual snippet."
      );
    } else {
      message = this.props.t(
        "save_confirm_override_company",
        "Are you sure you want to save? This is currently a default set by the system.  If you continue, you will be creating your own individual snippet."
      );
    }
    return message;
  }

  render() {
    return (
      <React.Fragment>
        <h2>
          {this.props.t("snippets", "Snippets")}
          <p className="card-title-desc">
            {this.props.t(
              "snippets_description",
              "Snippets are item narratives that have inherent behavior, groupings or design"
            )}{" "}
          </p>
        </h2>
        <DropDownBox
          value={this.state.treeBoxValue}
          opened={this.state.isTreeBoxOpened}
          valueExpr="id"
          displayExpr="text"
          placeholder={this.props.t(
            "select_a_snippet_to_edit",
            "Select a snippet to edit"
          )}
          showClearButton={true}
          dataSource={this.state.dropDownDataSource}
          onValueChanged={this.syncTreeViewSelection}
          onOptionChanged={this.onTreeBoxOpened}
          contentRender={this.treeViewRender}
          itemRender={this.renderTreeViewItem}
        />
        {this.state.treeBoxValue ? (
          <DataGrid
            dataSource={this.state.dataGridDataSource}
            onRowValidating={this.onRowValidating}
          >
            <Editing
              mode="row"
              allowUpdating={true}
              allowDeleting={true}
              allowAdding={true}
              useIcons={true}
            />
            <RowDragging
              allowReordering={true}
              onReorder={this.onReorder}
              dropFeedbackMode="push"
            />
            <Column dataField="text" />
            <Column type="buttons" caption={this.props.t("actions", "Actions")}>
              <Button name="edit" cssClass={getCSSClass("edit")} />
              <Button name="delete" cssClass={getCSSClass("delete")} />
              <Button name="save" cssClass={getCSSClass("save")} />
            </Column>
          </DataGrid>
        ) : null}
      </React.Fragment>
    );
  }
}

TabSnippets.propTypes = {
  t: PropTypes.any,
  formik: PropTypes.any,
  profile: PropTypes.any,
  hydrateSnippets: PropTypes.func,
  Snippets: PropTypes.any,
  editSnippet: PropTypes.func,
  addSnippet: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    Snippets: state.Snippets,
  };
};

export default withTranslation()(
  withRouter(
    connect(mapStateToProps, { hydrateSnippets, editSnippet, addSnippet })(
      TabSnippets
    )
  )
);
