import { takeEvery, fork, put, call, all } from "redux-saga/effects";

// Login Redux States
import { EDIT_PROFILE, HYDRATE_PROFILE, CHANGE_PASSWORD } from "./actionTypes";
import {
  profileError,
  hydrateProfileSuccess,
  messageHubChange,
  messageHubReset,
} from "../actions";

//Include Both Helper File with needed methods
import { getBackendHelper } from "../../helpers/backend_helper";
import { getCognitoBackend } from "helpers/cognito_helper";

const backend = getBackendHelper();
const cognitoBackend = getCognitoBackend();

function* editProfile({ payload: { user } }) {
  try {
    yield call(backend.editProfile, user);
    yield hydrateProfile();
    yield put(messageHubChange("Profile saved!", "success", true));
    yield put(messageHubReset());
    // yield put(profileSuccess(response))
  } catch (error) {
    console.log(error);
    yield put(profileError(error));
  }
}

function* changePassword({ payload: { credentials } }) {
  try {
    yield call(cognitoBackend.changePassword, {
      ...credentials,
    });
    yield put(messageHubChange("Password changed!", "success", true));
  } catch (error) {
    yield put(messageHubChange(error.message, "danger"));
  }
}

function* hydrateProfile() {
  try {
    const response = yield call(backend.hydrateProfile);
    yield put(hydrateProfileSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(profileError(error));
  }
}

export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile);
  yield takeEvery(HYDRATE_PROFILE, hydrateProfile);
  yield takeEvery(CHANGE_PASSWORD, changePassword);
}

function* ProfileSaga() {
  yield all([fork(watchProfile)]);
}

export default ProfileSaga;
