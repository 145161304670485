import { Auth } from "aws-amplify";
import { put } from "redux-saga/effects";
import { GraphQLResult } from "@aws-amplify/api-graphql";

import { messageHubChange, messageHubReset } from "store/actions";

export async function getAccessToken() {
  const session = await Auth.currentSession();
  return `Token ${session.getAccessToken().getJwtToken()}`;
}

export function* handleSagaGraphqlError(message: string, error: unknown) {
  console.error(error);

  if ((error as GraphQLResult).errors?.length) {
    yield put(
      messageHubChange(
        `${message}: ${(error as GraphQLResult).errors
          ?.map((e: any) => e.message)
          .join(", ")}`,
        "error",
        true
      )
    );
    yield put(messageHubReset());
  }
}
