import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  HYDRATE_PROFILE,
  HYDRATE_PROFILE_SUCCESS,
  CHANGE_PASSWORD,
} from "./actionTypes";

export const editProfile = user => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  };
};

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  };
};

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  };
};

export const resetProfileFlag = () => {
  return {
    type: RESET_PROFILE_FLAG,
  };
};

export const hydrateProfile = () => {
  return {
    type: HYDRATE_PROFILE,
  };
};

export const changePassword = credentials => {
  return {
    type: CHANGE_PASSWORD,
    payload: { credentials },
  };
};

export const hydrateProfileSuccess = user => {
  return {
    type: HYDRATE_PROFILE_SUCCESS,
    payload: user,
  };
};
