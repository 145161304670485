import React, { useState } from "react";
import { v4 as uuid } from "uuid";
import { useTranslation } from "react-i18next";

import DataGrid from "components/Tables/DataGrid";
import DropDownBox from "devextreme-react/drop-down-box";

import {
  Column,
  Editing,
  Texts,
  Form,
  Lookup,
  Popup,
  Button,
  SearchPanel,
  Selection,
  Paging,
  FilterRow,
  Scrolling,
} from "devextreme-react/data-grid";

import CustomStore from "devextreme/data/custom_store";
import { Item, SimpleItem } from "devextreme-react/form";
import { getCSSClass } from "components/Common/Icons";
import { useCompany } from "hooks/company";
import { useValidation } from "./_validation";
import { TabPane } from "reactstrap";

const TabTaxSettings = props => {
  const { t } = useTranslation();
  const validationRules = useValidation();
  const company = useCompany();
  const [gridBoxValue, setGridBoxValue] = useState([]);
  const taxGroupRateStore = 0;
  const taxRateStore = new CustomStore({
    key: "id",
    load: () => company.data?.taxRates || [],
    byKey: key => company.data?.taxRates.find(element => element.id === key),
    insert: values => insertItem("rate", values),
    update: (key, values) => updateItem("rate", key, values),
    remove: key => removeItem("rate", key),
  });

  const taxGroupStore = new CustomStore({
    key: "id",
    load: () => company.data?.taxGroups || [],
    insert: values => insertItem("group", values),
    update: (key, values) => updateItem("group", key, values),
    remove: key => removeItem("group", key),
  });

  const editorOptions = {
    tax_rate: { format: "#0.### %" },
    tax_group_rate: { format: "#0.### %", readOnly: true },
  };

  const formats = {
    tax_rate: "#0.### %",
    tax_group_rate: "#0.### %",
  };

  function updateItem(type, key, values) {
    let newItems;
    if (type === "rate") {
      newItems = company.data.taxRates;
    } else if (type === "group") {
      newItems = company.data.taxGroups;
    }

    const idx = newItems.findIndex(rate => rate.id === key);
    newItems[idx] = {
      ...newItems[idx],
      ...values,
    };

    let mutation = {};
    if (type === "rate") {
      mutation["taxRates"] = newItems;
    } else if (type === "group") {
      mutation["taxGroups"] = newItems;
    }

    company.mutation.mutate(mutation);
  }

  function removeItem(type, key) {
    let newItems;
    if (type === "rate") {
      newItems = company.data.taxRates;
    } else if (type === "group") {
      newItems = company.data.taxGroups;
    }
    newItems = newItems.filter(rate => rate.id !== key);

    let mutation = {};
    if (type === "rate") {
      mutation["taxRates"] = newItems;
    } else if (type === "group") {
      mutation["taxGroups"] = newItems;
    }

    company.mutation.mutate(mutation);
  }

  function insertItem(type, values) {
    let newItems;
    if (type === "rate") {
      newItems = company.data.taxRates;
    } else if (type === "group") {
      newItems = company.data.taxGroups;
    }

    newItems = newItems || [];
    newItems.push({
      ...values,
      id: uuid(),
    });

    let mutation = {};
    if (type === "rate") {
      mutation["taxRates"] = newItems;
    } else if (type === "group") {
      mutation["taxGroups"] = newItems;
    }

    company.mutation.mutate(mutation);
  }

  function dataGridRender() {
    return (
      <DataGrid
        dataSource={taxRateStore}
        columns={[
          {
            dataField: "tax_id_name",
            caption: t("tax_id_name", "Tax ID name"),
          },
          {
            dataField: "tax_rate",
            caption: t("tax_rate", "Tax rate"),
            format: formats.tax_rate,
          },
        ]}
        hoverStateEnabled={false}
        selectedRowKeys={gridBoxValue}
        onSelectionChanged={e => {
          setGridBoxValue(e.selectedRowKeys);
        }}
      >
        <Selection mode="multiple" />
        <Scrolling mode="virtual" />
        <Paging enabled={true} pageSize={10} />
        <FilterRow visible={true} />
      </DataGrid>
    );
  }

  return (
    <TabPane tabId="tax_settings">
      <h2>{t("tax_settings_tab_title", "Tax settings")}</h2>
      <h5 className="mt-4">{t("tax_rates", "Tax rates")}</h5>
      <p className="card-title-desc">{t("tax_settings_rate_description")}</p>
      <DataGrid dataSource={taxRateStore}>
        <SearchPanel visible={true} highlightCaseSensitive={true} />
        <Editing
          mode="popup"
          allowUpdating={true}
          allowAdding={true}
          allowDeleting={true}
          texts={{
            addRow: t("tax_settings_add_new_rate", "Create new tax rate"),
          }}
        >
          <Texts
            confirmDeleteMessage={t(
              "tax_settings_confirm_delete",
              "Are you sure you want to delete this tax setting?"
            )}
          />
          <Popup
            title={t("new_tax_rate_title", "Create new tax rate")}
            showTitle={true}
            width={700}
            height={400}
          />
          <Form>
            <Item itemType="group" colCount={2} colSpan={2}>
              <SimpleItem
                dataField="tax_id_name"
                validationRules={validationRules.tax_id_name}
                helpText={t(
                  "tax_settings_id_name_number_helptext",
                  "Tax ID Name and Number will appear on Invoices"
                )}
              />
              <SimpleItem
                dataField="tax_id_number"
                validationRules={validationRules.tax_id_number}
              />
              <SimpleItem
                dataField="tax_rate"
                editorType="dxNumberBox"
                validationRules={validationRules.tax_rate}
                editorOptions={editorOptions.tax_rate}
              />
              <SimpleItem
                dataField="internal_description"
                colSpan={2}
                validationRules={validationRules.internal_description}
              />
            </Item>
          </Form>
        </Editing>
        <Column
          dataField="tax_id_name"
          caption={t("tax_id_name", "Tax ID name")}
          hidingPriority={9}
        />
        <Column
          dataField="tax_id_number"
          caption={t("tax_id_number", "Tax ID number")}
          hidingPriority={9}
        />
        <Column
          dataField="tax_rate"
          caption={t("tax_rate", "Tax rate")}
          hidingPriority={10}
          format={formats.tax_rate}
        />
        <Column
          dataField="internal_description"
          caption={t("internal_description", "Internal description")}
          hidingPriority={1}
        />
        <Column
          type="buttons"
          caption={t("options", "Options")}
          hidingPriority={10}
        >
          <Button
            name="edit"
            icon={getCSSClass("edit")}
            hint={t("edit", "Edit")}
          />
          <Button
            name="delete"
            icon={getCSSClass("delete")}
            hint={t("delete", "Delete")}
          />
        </Column>
      </DataGrid>
      <h5 className="mt-4">{t("tax_groups", "Tax groups")}</h5>
      <p className="card-title-desc">{t("tax_settings_group_description")}</p>
      <DataGrid
        dataSource={taxGroupStore}
        onInitNewRow={e => {
          e.data["tax_group_rate"] = 0;
          setGridBoxValue([]);
        }}
        onEditingStart={e => {
          setGridBoxValue(e.data.tax_group_rates || []);
        }}
        onSaving={e => {
          e.changes[0].data.tax_group_rate = taxGroupRateStore;
        }}
      >
        <SearchPanel visible={true} highlightCaseSensitive={true} />
        <Editing
          mode="popup"
          allowUpdating={true}
          allowAdding={true}
          allowDeleting={true}
          texts={{
            addRow: t("tax_settings_add_new_group", "Create new tax group"),
          }}
        >
          <Texts
            confirmDeleteMessage={t(
              "tax_settings_confirm_delete",
              "Are you sure you want to delete this tax setting?"
            )}
          />
          <Popup
            title={t("new_tax_group_title", "Create new tax group")}
            showTitle={true}
            width={700}
            height={400}
          />
          <Form>
            <SimpleItem
              dataField="tax_group_name"
              validationRules={validationRules.tax_group_name}
            />
            <SimpleItem
              dataField="tax_group_rate"
              validationRules={validationRules.tax_group_rate}
              editorOptions={editorOptions.tax_group_rate}
              contentRender={() => "test"}
            />
            <SimpleItem
              dataField="tax_group_rates"
              colSpan={2}
              validationRules={validationRules.tax_group_rates}
            >
              <DropDownBox
                // value={gridBoxValue}
                dataSource={company.data?.taxRates || []}
                valueExpr="id"
                deferRendering={false}
                displayExpr="tax_id_name"
                placeholder={t("choose_one_or_more", "Choose one or more")}
                showClearButton={true}
                contentRender={dataGridRender}
              />
            </SimpleItem>
          </Form>
        </Editing>
        <Column
          dataField="tax_group_name"
          caption={t("tax_group_name", "Tax group name")}
          hidingPriority={10}
        />
        <Column
          dataField="tax_group_rate"
          caption={t("tax_group_rate", "Tax group rate")}
          hidingPriority={9}
          format={formats.tax_group_rate}
        />
        <Column
          dataField="tax_group_rates"
          caption={t("tax_rates", "Tax rates")}
          hidingPriority={8}
        >
          <Lookup
            dataSource={company.data?.taxRates}
            valueExpr="id"
            displayExpr="tax_id_name"
          />
        </Column>
        <Column
          type="buttons"
          caption={t("options", "Options")}
          hidingPriority={10}
        >
          <Button
            name="edit"
            icon={getCSSClass("edit")}
            hint={t("edit", "Edit")}
          />
          <Button
            name="delete"
            icon={getCSSClass("delete")}
            hint={t("delete", "Delete")}
          />
        </Column>
      </DataGrid>
    </TabPane>
  );
};

export default TabTaxSettings;
