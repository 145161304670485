import StandardPage from "components/Common/StandardPage";

// Redux
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

// actions
import ClientInformation from "./components/_clientInformation.js";
import ContractAcceptance from "./components/_contractAcceptance";
import InspectionAddress from "./components/_inspectionAddress";
import PropertyDescription from "./components/_propertyDescription";
import InspectionAttendance from "./components/_inspectionAttendance";
import InspectorBooking from "./components/_inspectorBooking";
import ReferralBookingSource from "./components/_referralBookingSource";
import { Formik, Form } from "formik";
import useWorkOrderValidation from "./schemas/_workOrder";
import { Button } from "reactstrap";
import { useWorkOrder } from "hooks/workorders/workorder";
import ReportNumber from "./components/_reportNumber";
import LeavingForm from "components/Common/LeavingForm.js";

export default function WorkOrder(props) {
  const id = props.match.params.id;
  const history = useHistory();
  const { t } = useTranslation();
  const { schema } = new useWorkOrderValidation();
  const { workOrder, updateWorkOrder, createWorkOrder } = useWorkOrder(id);

  async function defaultFormSubmit(values) {
    if (id === "new") {
      createWorkOrder.mutate(values);
    } else {
      let newValues = values;
      delete newValues.id;
      updateWorkOrder.mutate(newValues);
    }
    history.push("/work/orders/");
  }

  function handleReset() {
    history.push("/work/orders/");
  }

  return (
    <StandardPage
      pageid="work_orders"
      pagetitle={t("work_orders_page_title", "Work orders")}
      breadcrumbItems={[
        {
          uri: "/work",
          text: t("work", "Work"),
        },
        {
          uri: "/work/orders",
          text: t("orders", "Orders"),
          active: true,
        },
      ]}
      breadcrumbIcon="bx bx-spreadsheet"
    >
      {workOrder.isLoading ? (
        t("loading", "Loading...")
      ) : (
        <Formik
          initialValues={workOrder.data}
          enableReinitialize={false}
          onSubmit={defaultFormSubmit}
          handleReset={handleReset}
          // validate={validateForm}
          validationSchema={schema}
        >
          {formik => (
            <>
              <Form>
                <ReportNumber />
                <InspectionAddress />
                <ContractAcceptance />
                <InspectorBooking />
                <ClientInformation />
                <InspectionAttendance />
                <PropertyDescription />
                <ReferralBookingSource />
                {/* <HeatingAndCooling /> */}

                <div className="mt-5">
                  <Button color="primary" type="submit">
                    {t("save_changes", "Save Changes")}
                  </Button>
                  <LeavingForm formikProps={formik} />
                </div>
              </Form>
            </>
          )}
        </Formik>
      )}
    </StandardPage>
  );
}
