import React from "react";
import { useTranslation } from "react-i18next";
import { TextInput } from "components/Fields";
import { Row, Col, Button, Card, CardBody } from "reactstrap";
import { StandardSwitch } from "components/Fields/Switches/StandardSwitch";
import Address from "components/Fields/Address";
import { useFormikContext } from "formik";

export default function ClientInformation(_props) {
  const { t } = useTranslation();
  const formik = useFormikContext();

  const inspectionAddressSame_Changed = (e, key) => {
    formik.setFieldValue(
      "clientInformation[" + key + "].inspectionAddressSame",
      e.target.checked
    );
    if (e.target.checked) {
      formik.setFieldValue(
        "clientInformation[" + key + "].address",
        formik.values.inspection.address
      );
    }
  };

  const addEmptyClient = () => {
    let newClients = formik.values.clientInformation;
    newClients.push({});
    formik.setFieldValue("clientInformation", newClients);
  };

  const removeClient = key => {
    let newClients = formik.values.clientInformation;
    newClients.splice(key, 1);
    formik.setFieldValue("clientInformation", newClients);
  };

  return (
    <React.Fragment>
      {formik.values?.clientInformation !== undefined
        ? formik.values.clientInformation.map((field, key) => (
            <React.Fragment key={key}>
              <Card className="mb-0 border-bottom">
                <CardBody>
                  <h2 className="mt-2 mb-2">
                    {t("client_information", "Client #{{number}} information", {
                      number: key + 1,
                    })}
                  </h2>
                  <Button
                    className="btn btn-sm"
                    color="info"
                    onClick={addEmptyClient}
                  >
                    {t("add_another_client", "Add another client")}
                  </Button>{" "}
                  {formik.values.clientInformation.length > 1 ? (
                    <Button
                      className="btn btn-sm"
                      color="info"
                      onClick={() => removeClient(key)}
                    >
                      {t("remove_client", "Remove client")}
                    </Button>
                  ) : (
                    ""
                  )}
                  <Row>
                    <Col>
                      <TextInput
                        name={"clientInformation[" + key + "].firstName"}
                        label={t("firstname", "First name")}
                      />
                    </Col>
                    <Col>
                      <TextInput
                        name={"clientInformation[" + key + "].lastName"}
                        label={t("lastname", "Last name")}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <TextInput
                        name={"clientInformation[" + key + "].primaryPhone"}
                        label={t("primary_phone", "Primary phone")}
                      />
                    </Col>
                    <Col>
                      <TextInput
                        name={"clientInformation[" + key + "].alternatePhone"}
                        label={t("alternate_phone", "Alternate phone")}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <TextInput
                        name={"clientInformation[" + key + "].company"}
                        label={t("company", "Company")}
                      />
                    </Col>
                    <Col>
                      <TextInput
                        name={"clientInformation[" + key + "].email"}
                        label={t("email", "Email")}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col rowSpan="2">
                      <TextInput
                        name={"clientInformation[" + key + "].notes"}
                        type="textarea"
                        label={t("notes", "Notes")}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Address
                    formik={formik}
                    prefix={"clientInformation[" + key + "].address"}
                    disabled={
                      formik.values.clientInformation[key].inspectionAddressSame
                    }
                  >
                    <StandardSwitch
                      name={
                        "clientInformation[" + key + "].inspectionAddressSame"
                      }
                      label={t(
                        "inspection_address_same",
                        "The client address is the same as the inspection address"
                      )}
                      value="inspection_address_same"
                      checked={
                        formik.values.clientInformation
                          ? formik.values.clientInformation[key]
                              .inspectionAddressSame
                          : false
                      }
                      onChange={e => inspectionAddressSame_Changed(e, key)}
                      formik={formik}
                    />
                  </Address>
                </CardBody>
              </Card>
            </React.Fragment>
          ))
        : ""}
    </React.Fragment>
  );
}
