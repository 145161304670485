import {
  TEAM_ERROR,
  TEAM_SUCCESS,
  EDIT_TEAM,
  RESET_TEAM_FLAG,
  HYDRATE_TEAM,
  HYDRATE_TEAM_SUCCESS,
  ADD_TEAM_MEMBER,
  EDIT_TEAM_MEMBER,
  ADD_TEAM_MEMBER_SUCCESS,
  EDIT_TEAM_MEMBER_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: "",
  success: "",
};

const team = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_TEAM:
      state = { ...state };
      break;
    case TEAM_SUCCESS:
      state = { ...state, success: true, id: action.payload };
      break;
    case ADD_TEAM_MEMBER:
      state = { ...action.payload };
      break;
    case EDIT_TEAM_MEMBER:
      state = { ...action.payload };
      break;
    case EDIT_TEAM_MEMBER_SUCCESS:
      state = { ...state, success: action.payload };
      break;
    case ADD_TEAM_MEMBER_SUCCESS:
      state = { ...state, success: action.payload };
      break;
    case TEAM_ERROR:
      state = { ...state, error: action.payload };
      break;
    case RESET_TEAM_FLAG:
      state = { ...state, success: null };
      break;
    case HYDRATE_TEAM:
      state = { ...state };
      break;
    case HYDRATE_TEAM_SUCCESS:
      state = { ...action.payload };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default team;
