import React, { useEffect, useState } from "react";
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Tooltip,
} from "reactstrap";
import MetaTags from "react-meta-tags";
import classnames from "classnames";
import { forgotPassword } from "routes/paths";
import { PasswordInput, TextInput } from "components/Fields";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Formik, Form } from "formik";
import * as Yup from "yup";

// actions
import { loginUser, messageHubChange } from "../../store/actions";

// import images
import homeImage from "../../assets/images/home-magnifier.png";
import horizonLogo from "../../assets/images/horizon-logo-no-text.svg";
import npiLogo from "assets/images/npi-logo-sm.png";

import PrivacyModal from "pages/PrivacyPolicy/components/privacyModal";
import EulaModal from "pages/EULA/components/eulaModal";

export default function Login(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const messageHub = useSelector(state => state.MessageHub);
  const dispatch = useDispatch();
  let logo = horizonLogo;
  let title = "Horizon";
  let copyright =
    "© " + new Date().getFullYear() + " Carson Dunlop & Associates";
  if (window.location.href.indexOf("npi") !== -1) {
    logo = npiLogo;
    title = "NPI";
    copyright =
      "© " + new Date().getFullYear() + " National Property Inspections";
  }

  const defaultState = {
    rememberMe: localStorage.getItem("rememberMe") ? true : false,
    email: localStorage.getItem("rememberMe"),
    logo,
    title,
    copyright,
  };
  const [loginState, setLoginState] = useState(defaultState);

  // componentDidMount
  useEffect(() => {
    // Check for confirmation messages
    const search = props.location.search;
    const confirmSuccess = new URLSearchParams(search).get("confirmSuccess");
    const user = new URLSearchParams(search).get("user");

    if (confirmSuccess && user) {
      history.push("/recover-password?confirm=true&email=" + user);
    }

    if (confirmSuccess) {
      dispatch(
        messageHubChange("Email address confirmed!  Please login.", "success")
      );
    }

    const resetSent = new URLSearchParams(search).get("resetSent");
    if (resetSent) {
      dispatch(
        messageHubChange(
          "An email was sent with instructions on how to reset your password.",
          "success"
        )
      );
    }
    const resetSuccess = new URLSearchParams(search).get("resetSuccess");
    if (resetSuccess) {
      dispatch(
        messageHubChange(
          "Your password was successfully changed!  Please login.",
          "success"
        )
      );
    }
    // eslint-disable-next-line
  }, []);

  function toggleRememberMe() {
    setLoginState({
      ...loginState,
      rememberMe: !loginState.rememberMe,
    });

    localStorage.removeItem("rememberMe");
  }

  function showJiraPopup() {
    window.ATL_JQ_PAGE_PROPS.fieldValues.description =
      "-- Submitted from login page";
    window.ATL_JQ_PAGE_PROPS.fieldValues.fullname = "";
    window.ATL_JQ_PAGE_PROPS.fieldValues.email = "";
    window.showCollectorDialog();
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>
          {t("login_page_title", "Login")} | {loginState.title}
        </title>
      </MetaTags>
      <div className="account-pages my-5 pt-sm-5" id="login">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden mb-3">
                <div className="bg-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-white p-4">
                        <h5 className="text-white">
                          {t("welcome_back", "Welcome back!")}
                        </h5>
                        <p>
                          {t(
                            "sign_in_to_continue",
                            "Sign in to continue to {{title}}",
                            {
                              title: loginState.title,
                            }
                          )}
                        </p>
                      </div>
                    </Col>
                    <Col
                      className="position-absolute mr-3 col-6"
                      style={{
                        right: "0",
                        top: "20px",
                      }}
                    >
                      <img src={homeImage} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="auth-logo mt-n4">
                    <Link to="/" className="auth-logo-dark">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-white">
                          <img src={loginState.logo} alt="" height="34" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {messageHub.message &&
                    messageHub.type &&
                    !messageHub.toast ? (
                      <Alert color={messageHub.type}>
                        {messageHub.message}
                      </Alert>
                    ) : null}
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        email: (loginState && loginState.email) || "",
                        password: (loginState && loginState.password) || "",
                      }}
                      validationSchema={Yup.object().shape({
                        email: Yup.string()
                          .email(
                            t(
                              "error_email_invalid",
                              "Please enter a valid email address"
                            )
                          )
                          .required(
                            t("error_email_blank", "Please enter your email")
                          ),
                        password: Yup.string().required(
                          t(
                            "error_password_blank",
                            "Please enter your password"
                          )
                        ),
                      })}
                      onSubmit={async values => {
                        if (loginState.rememberMe) {
                          localStorage.setItem("rememberMe", values.email);
                        }
                        dispatch(loginUser(values, history));
                      }}
                    >
                      {formik => (
                        <Form className="form-horizontal">
                          <div className="mb-3">
                            <TextInput
                              name="email"
                              type="text"
                              label={t("email", "Email")}
                              showErrors={true}
                              formik={formik}
                            />
                          </div>
                          <div className="mb-3">
                            <PasswordInput
                              name="password"
                              label={t("password", "Password")}
                              showErrors={true}
                              allowShowPassword={true}
                              formik={formik}
                            />
                          </div>

                          <div className="mt-4">
                            <div className="square-switch m-0 d-flex align-items-center">
                              <input
                                type="checkbox"
                                id="rememberMe"
                                switch="success"
                                checked={loginState.rememberMe}
                                onChange={toggleRememberMe}
                              />
                              <label
                                htmlFor="rememberMe"
                                data-on-label=""
                                data-off-label=""
                                className="m-0"
                              />
                              <label
                                className="form-check-label ml-3 fw-normal"
                                htmlFor="rememberMe"
                              >
                                {t("remember_me", "Remember me")}
                              </label>
                            </div>
                          </div>

                          <div className="mt-4 d-grid">
                            <button
                              className="btn btn-primary btn-block"
                              type="submit"
                            >
                              {t("log_in", "Log In")}
                            </button>
                          </div>

                          <div className="my-4 text-center text-muted horizontal-rule">
                            <span className="font-size-12">
                              {t("or", "OR")}
                            </span>
                          </div>

                          <div className="text-center text-muted social-icons">
                            <div className="d-flex flex-column">
                              <div className="mb-2 social-icon">
                                <Tooltip
                                  placement="right"
                                  isOpen={loginState.tt_facebook}
                                  target="facebook-btn"
                                  toggle={() =>
                                    setLoginState({
                                      ...loginState,
                                      tt_facebook: !loginState.tt_facebook,
                                    })
                                  }
                                >
                                  {t(
                                    "to_be_implemented",
                                    "Soon to be implented"
                                  )}
                                </Tooltip>
                                <Tooltip
                                  placement="right"
                                  isOpen={loginState.tt_google}
                                  target="google-btn"
                                  toggle={() =>
                                    setLoginState({
                                      ...loginState,
                                      tt_google: !loginState.tt_google,
                                    })
                                  }
                                >
                                  {t(
                                    "to_be_implemented",
                                    "Soon to be implented"
                                  )}
                                </Tooltip>
                                <Tooltip
                                  placement="right"
                                  isOpen={loginState.tt_apple}
                                  target="apple-btn"
                                  toggle={() =>
                                    setLoginState({
                                      ...loginState,
                                      tt_apple: !loginState.tt_apple,
                                    })
                                  }
                                >
                                  {t(
                                    "to_be_implemented",
                                    "Soon to be implented"
                                  )}
                                </Tooltip>
                                <Button
                                  id="facebook-btn"
                                  className="bg-primary text-white border border-primary p-3 py-2 rounded-pill d-flex align-items-center justify-content-center"
                                >
                                  <i className="mdi mdi-facebook d-inline-block mr-2" />
                                  {t(
                                    "continue_with_facebook",
                                    "Continue with Facebook"
                                  )}
                                </Button>
                              </div>
                              <div className="mb-2 social-icon">
                                <Button
                                  id="google-btn"
                                  className="text-white bg-danger border border-danger p-3 py-2 rounded-pill d-flex align-items-center justify-content-center"
                                >
                                  <i className="mdi mdi-google d-inline-block mr-2" />
                                  {t(
                                    "continue_with_google",
                                    "Continue with Google"
                                  )}
                                </Button>
                              </div>
                              <div className="mb-4 social-icon">
                                <Button
                                  color="white"
                                  id="apple-btn"
                                  style={{
                                    border: "1px solid #e1e1e1",
                                  }}
                                  className="bg-white text-dark p-3 py-2 rounded-pill d-flex align-items-center justify-content-center"
                                >
                                  <i className="mdi mdi-apple d-inline-block mr-2" />
                                  {t(
                                    "continue_with_apple",
                                    "Continue with Apple"
                                  )}
                                </Button>
                              </div>
                            </div>
                          </div>

                          <div className="mt-2 text-center">
                            <Link
                              to={forgotPassword}
                              className="text-primary fw-medium mb-3 d-block"
                            >
                              <i className="mdi mdi-lock me-1" />
                              {t(
                                "forgot_your_password_question",
                                "Forgot your password?"
                              )}
                            </Link>
                            <div>
                              {t(
                                "dont_have_account_question",
                                "Don't have an account?"
                              )}{" "}
                              <Link
                                to="register"
                                className="fw-medium text-primary"
                              >
                                {t("signup_now", "Signup Now")}
                              </Link>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </CardBody>
              </Card>
              <Button
                color="white"
                onClick={showJiraPopup}
                className={classnames("text-center", "d-block", "mx-auto")}
                onMouseEnter={e => e.target.classList.add("btn-primary")}
                onMouseLeave={e => e.target.classList.remove("btn-primary")}
              >
                <i
                  className="bx bx-bug font-size-16"
                  style={{
                    display: "inline-block",
                    position: "relative",
                    top: "2px",
                  }}
                />{" "}
                {t("report_a_problem", "Report a Problem")}
              </Button>
              <div className="mt-2 text-center">
                <p>{loginState.copyright}</p>
              </div>
              <div className="mt-2 text-center">
                <PrivacyModal
                  linkLabel={t("privacy_policy", "Privacy Policy")}
                  modalTitle={t("privacy_policy", "Privacy Policy")}
                  size="xl"
                ></PrivacyModal>
                |
                <EulaModal
                  linkLabel={t("eula_short", "EULA")}
                  modalTitle={t("eula", "End User License Agreement (EULA)")}
                  size="xl"
                ></EulaModal>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
