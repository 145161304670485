/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateCategoryInput = {
  name: string,
  id?: string | null,
  ownerId?: string | null,
  subcategoryOrder?: Array< string > | null,
};

export type ModelCategoryConditionInput = {
  name?: ModelStringInput | null,
  subcategoryOrder?: ModelIDInput | null,
  and?: Array< ModelCategoryConditionInput | null > | null,
  or?: Array< ModelCategoryConditionInput | null > | null,
  not?: ModelCategoryConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type Category = {
  __typename: "Category",
  ownerId: string,
  id: string,
  name: string,
  subcategoryOrder: Array< string >,
  owner?: Owner | null,
  templates?: ModelTemplateCategoryConnectionConnection | null,
  subcategories?: ModelSubcategoryConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type Owner = {
  __typename: "Owner",
  level: OwnershipLevel,
  id?: string | null,
};

export enum OwnershipLevel {
  COMPANY = "COMPANY",
  FRANCHISEE = "FRANCHISEE",
  USER = "USER",
}


export type ModelTemplateCategoryConnectionConnection = {
  __typename: "ModelTemplateCategoryConnectionConnection",
  items:  Array<TemplateCategoryConnection | null >,
  nextToken?: string | null,
};

export type TemplateCategoryConnection = {
  __typename: "TemplateCategoryConnection",
  templateOwnerId: string,
  templateId: string,
  categoryOwnerId: string,
  categoryId: string,
  template: Template,
  category: Category,
  id: string,
  createdAt: string,
  updatedAt: string,
};

export type Template = {
  __typename: "Template",
  ownerId: string,
  id: string,
  name: string,
  categoryOrder: Array< string >,
  owner?: Owner | null,
  categories?: ModelTemplateCategoryConnectionConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelSubcategoryConnection = {
  __typename: "ModelSubcategoryConnection",
  items:  Array<Subcategory | null >,
  nextToken?: string | null,
};

export type Subcategory = {
  __typename: "Subcategory",
  id: string,
  categoryId: string,
  categoryOwnerId: string,
  name: string,
  notes?: string | null,
  itemOrder?: Array< string > | null,
  parentId?: string | null,
  subcategoryOrder?: Array< string > | null,
  parent?: Subcategory | null,
  children?: ModelSubcategoryConnection | null,
  category?: Category | null,
  items?: ModelItemConnection | null,
  createdAt: string,
  updatedAt: string,
};

export type ModelItemConnection = {
  __typename: "ModelItemConnection",
  items:  Array<Item | null >,
  nextToken?: string | null,
};

export type Item = {
  __typename: "Item",
  id: string,
  subcategoryId: string,
  name: string,
  subcategory: Subcategory,
  createdAt: string,
  updatedAt: string,
};

export type UpdateCategoryInput = {
  ownerId: string,
  id: string,
  name?: string | null,
  subcategoryOrder?: Array< string > | null,
  owner?: OwnerInput | null,
};

export type OwnerInput = {
  level: OwnershipLevel,
  id?: string | null,
};

export type DeleteCategoryInput = {
  ownerId: string,
  id: string,
};

export type CreateDiscountInput = {
  id?: string | null,
  ownerId?: string | null,
  name: string,
  description: string,
  type: DiscountType,
  appliesTo: DiscountAppliesTo,
  option?: DiscountOption | null,
  value?: number | null,
  minPurchase?: boolean | null,
  minPurchaseAmount?: number | null,
  limit?: boolean | null,
  onePerCustomer?: boolean | null,
  fixedLimit?: boolean | null,
  fixedLimitAmount?: number | null,
  startDate?: string | null,
  endDate?: string | null,
};

export enum DiscountType {
  AUTO = "AUTO",
  CHECKOUT = "CHECKOUT",
}


export enum DiscountAppliesTo {
  ENTIRE_INVOICE = "ENTIRE_INVOICE",
  SPECIFIC_PRODUCT = "SPECIFIC_PRODUCT",
  SPECIFIC_ORDER = "SPECIFIC_ORDER",
}


export enum DiscountOption {
  PERCENTAGE = "PERCENTAGE",
  FIXED = "FIXED",
  FREE = "FREE",
}


export type ModelDiscountConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  type?: ModelDiscountTypeInput | null,
  appliesTo?: ModelDiscountAppliesToInput | null,
  option?: ModelDiscountOptionInput | null,
  value?: ModelFloatInput | null,
  minPurchase?: ModelBooleanInput | null,
  minPurchaseAmount?: ModelFloatInput | null,
  limit?: ModelBooleanInput | null,
  onePerCustomer?: ModelBooleanInput | null,
  fixedLimit?: ModelBooleanInput | null,
  fixedLimitAmount?: ModelIntInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  and?: Array< ModelDiscountConditionInput | null > | null,
  or?: Array< ModelDiscountConditionInput | null > | null,
  not?: ModelDiscountConditionInput | null,
};

export type ModelDiscountTypeInput = {
  eq?: DiscountType | null,
  ne?: DiscountType | null,
};

export type ModelDiscountAppliesToInput = {
  eq?: DiscountAppliesTo | null,
  ne?: DiscountAppliesTo | null,
};

export type ModelDiscountOptionInput = {
  eq?: DiscountOption | null,
  ne?: DiscountOption | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Discount = {
  __typename: "Discount",
  id: string,
  ownerId: string,
  name: string,
  description: string,
  type: DiscountType,
  appliesTo: DiscountAppliesTo,
  option?: DiscountOption | null,
  value?: number | null,
  minPurchase?: boolean | null,
  minPurchaseAmount?: number | null,
  limit?: boolean | null,
  onePerCustomer?: boolean | null,
  fixedLimit?: boolean | null,
  fixedLimitAmount?: number | null,
  startDate?: string | null,
  endDate?: string | null,
  owner?: Owner | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateDiscountInput = {
  id: string,
  ownerId: string,
  name?: string | null,
  description?: string | null,
  type?: DiscountType | null,
  appliesTo?: DiscountAppliesTo | null,
  option?: DiscountOption | null,
  value?: number | null,
  minPurchase?: boolean | null,
  minPurchaseAmount?: number | null,
  limit?: boolean | null,
  onePerCustomer?: boolean | null,
  fixedLimit?: boolean | null,
  fixedLimitAmount?: number | null,
  startDate?: string | null,
  endDate?: string | null,
  owner?: OwnerInput | null,
};

export type DeleteDiscountInput = {
  ownerId: string,
  id: string,
};

export type CreateInspectionInput = {
  ownerId: string,
  id?: string | null,
  workOrder: string,
  owner?: OwnerInput | null,
};

export type ModelInspectionConditionInput = {
  workOrder?: ModelStringInput | null,
  and?: Array< ModelInspectionConditionInput | null > | null,
  or?: Array< ModelInspectionConditionInput | null > | null,
  not?: ModelInspectionConditionInput | null,
};

export type Inspection = {
  __typename: "Inspection",
  ownerId: string,
  id: string,
  workOrder: string,
  owner?: Owner | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateInspectionInput = {
  ownerId: string,
  id: string,
  workOrder?: string | null,
  owner?: OwnerInput | null,
};

export type DeleteInspectionInput = {
  ownerId: string,
  id: string,
};

export type CreateItemInput = {
  id?: string | null,
  subcategoryId: string,
  name: string,
};

export type ModelItemConditionInput = {
  subcategoryId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  and?: Array< ModelItemConditionInput | null > | null,
  or?: Array< ModelItemConditionInput | null > | null,
  not?: ModelItemConditionInput | null,
};

export type UpdateItemInput = {
  id: string,
  subcategoryId?: string | null,
  name?: string | null,
};

export type DeleteItemInput = {
  id: string,
};

export type CreateProductInput = {
  id?: string | null,
  ownerId: string,
  name: string,
  description: string,
  category: ProductCategory,
  size?: string | null,
  duration?: string | null,
  price: number,
  owner?: OwnerInput | null,
  taxExempt?: boolean | null,
  visibility?: BookingVisibility | null,
};

export enum ProductCategory {
  PRODUCT = "PRODUCT",
  SERVICE = "SERVICE",
}


export enum BookingVisibility {
  SHOWDESCRIPTIONANDFEE = "SHOWDESCRIPTIONANDFEE",
  SHOWDESCRIPTIONONLY = "SHOWDESCRIPTIONONLY",
  HIDE = "HIDE",
}


export type ModelProductConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  category?: ModelProductCategoryInput | null,
  size?: ModelStringInput | null,
  duration?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  taxExempt?: ModelBooleanInput | null,
  visibility?: ModelBookingVisibilityInput | null,
  and?: Array< ModelProductConditionInput | null > | null,
  or?: Array< ModelProductConditionInput | null > | null,
  not?: ModelProductConditionInput | null,
};

export type ModelProductCategoryInput = {
  eq?: ProductCategory | null,
  ne?: ProductCategory | null,
};

export type ModelBookingVisibilityInput = {
  eq?: BookingVisibility | null,
  ne?: BookingVisibility | null,
};

export type Product = {
  __typename: "Product",
  id: string,
  ownerId: string,
  name: string,
  description: string,
  category: ProductCategory,
  size?: string | null,
  duration?: string | null,
  price: number,
  owner?: Owner | null,
  taxExempt?: boolean | null,
  visibility?: BookingVisibility | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateProductInput = {
  id: string,
  ownerId: string,
  name?: string | null,
  description?: string | null,
  category?: ProductCategory | null,
  size?: string | null,
  duration?: string | null,
  price?: number | null,
  owner?: OwnerInput | null,
  taxExempt?: boolean | null,
  visibility?: BookingVisibility | null,
};

export type DeleteProductInput = {
  ownerId: string,
  id: string,
};

export type CreateReportInput = {
  id?: string | null,
  ownerId: string,
  workOrderId: string,
  owner?: OwnerInput | null,
};

export type ModelReportConditionInput = {
  workOrderId?: ModelStringInput | null,
  and?: Array< ModelReportConditionInput | null > | null,
  or?: Array< ModelReportConditionInput | null > | null,
  not?: ModelReportConditionInput | null,
};

export type Report = {
  __typename: "Report",
  id: string,
  ownerId: string,
  workOrderId: string,
  owner?: Owner | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateReportInput = {
  id: string,
  ownerId: string,
  workOrderId?: string | null,
  owner?: OwnerInput | null,
};

export type DeleteReportInput = {
  ownerId: string,
  id: string,
};

export type CreateSubcategoryInput = {
  id?: string | null,
  categoryId: string,
  categoryOwnerId: string,
  name: string,
  notes?: string | null,
  itemOrder?: Array< string > | null,
  parentId?: string | null,
  subcategoryOrder?: Array< string > | null,
};

export type ModelSubcategoryConditionInput = {
  categoryId?: ModelIDInput | null,
  categoryOwnerId?: ModelStringInput | null,
  name?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  itemOrder?: ModelIDInput | null,
  parentId?: ModelIDInput | null,
  subcategoryOrder?: ModelIDInput | null,
  and?: Array< ModelSubcategoryConditionInput | null > | null,
  or?: Array< ModelSubcategoryConditionInput | null > | null,
  not?: ModelSubcategoryConditionInput | null,
};

export type UpdateSubcategoryInput = {
  id: string,
  categoryId?: string | null,
  categoryOwnerId?: string | null,
  name?: string | null,
  notes?: string | null,
  itemOrder?: Array< string > | null,
  parentId?: string | null,
  subcategoryOrder?: Array< string > | null,
};

export type DeleteSubcategoryInput = {
  id: string,
};

export type CreateTemplateInput = {
  name: string,
  id?: string | null,
  ownerId?: string | null,
  categoryOrder?: Array< string > | null,
};

export type ModelTemplateConditionInput = {
  name?: ModelStringInput | null,
  categoryOrder?: ModelIDInput | null,
  and?: Array< ModelTemplateConditionInput | null > | null,
  or?: Array< ModelTemplateConditionInput | null > | null,
  not?: ModelTemplateConditionInput | null,
};

export type UpdateTemplateInput = {
  ownerId: string,
  id: string,
  name?: string | null,
  categoryOrder?: Array< string > | null,
  owner?: OwnerInput | null,
};

export type DeleteTemplateInput = {
  ownerId: string,
  id: string,
};

export type CreateTemplateCategoryConnectionInput = {
  templateOwnerId: string,
  templateId: string,
  categoryOwnerId: string,
  categoryId: string,
  id?: string | null,
};

export type ModelTemplateCategoryConnectionConditionInput = {
  templateOwnerId?: ModelStringInput | null,
  templateId?: ModelIDInput | null,
  categoryOwnerId?: ModelStringInput | null,
  categoryId?: ModelIDInput | null,
  and?: Array< ModelTemplateCategoryConnectionConditionInput | null > | null,
  or?: Array< ModelTemplateCategoryConnectionConditionInput | null > | null,
  not?: ModelTemplateCategoryConnectionConditionInput | null,
};

export type UpdateTemplateCategoryConnectionInput = {
  templateOwnerId?: string | null,
  templateId?: string | null,
  categoryOwnerId?: string | null,
  categoryId?: string | null,
  id: string,
};

export type DeleteTemplateCategoryConnectionInput = {
  id: string,
};

export type CreateWorkflowInput = {
  id?: string | null,
  ownerId: string,
  name: string,
  owner?: OwnerInput | null,
};

export type ModelWorkflowConditionInput = {
  name?: ModelStringInput | null,
  and?: Array< ModelWorkflowConditionInput | null > | null,
  or?: Array< ModelWorkflowConditionInput | null > | null,
  not?: ModelWorkflowConditionInput | null,
};

export type Workflow = {
  __typename: "Workflow",
  id: string,
  ownerId: string,
  name: string,
  owner?: Owner | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateWorkflowInput = {
  id: string,
  ownerId: string,
  name?: string | null,
  owner?: OwnerInput | null,
};

export type DeleteWorkflowInput = {
  ownerId: string,
  id: string,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelCategoryFilterInput = {
  ownerId?: ModelStringInput | null,
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  subcategoryOrder?: ModelIDInput | null,
  and?: Array< ModelCategoryFilterInput | null > | null,
  or?: Array< ModelCategoryFilterInput | null > | null,
  not?: ModelCategoryFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelCategoryConnection = {
  __typename: "ModelCategoryConnection",
  items:  Array<Category | null >,
  nextToken?: string | null,
};

export type ModelDiscountFilterInput = {
  id?: ModelIDInput | null,
  ownerId?: ModelStringInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  type?: ModelDiscountTypeInput | null,
  appliesTo?: ModelDiscountAppliesToInput | null,
  option?: ModelDiscountOptionInput | null,
  value?: ModelFloatInput | null,
  minPurchase?: ModelBooleanInput | null,
  minPurchaseAmount?: ModelFloatInput | null,
  limit?: ModelBooleanInput | null,
  onePerCustomer?: ModelBooleanInput | null,
  fixedLimit?: ModelBooleanInput | null,
  fixedLimitAmount?: ModelIntInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  and?: Array< ModelDiscountFilterInput | null > | null,
  or?: Array< ModelDiscountFilterInput | null > | null,
  not?: ModelDiscountFilterInput | null,
};

export type ModelDiscountConnection = {
  __typename: "ModelDiscountConnection",
  items:  Array<Discount | null >,
  nextToken?: string | null,
};

export type ModelInspectionFilterInput = {
  ownerId?: ModelStringInput | null,
  id?: ModelIDInput | null,
  workOrder?: ModelStringInput | null,
  and?: Array< ModelInspectionFilterInput | null > | null,
  or?: Array< ModelInspectionFilterInput | null > | null,
  not?: ModelInspectionFilterInput | null,
};

export type ModelInspectionConnection = {
  __typename: "ModelInspectionConnection",
  items:  Array<Inspection | null >,
  nextToken?: string | null,
};

export type ModelItemFilterInput = {
  id?: ModelIDInput | null,
  subcategoryId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  and?: Array< ModelItemFilterInput | null > | null,
  or?: Array< ModelItemFilterInput | null > | null,
  not?: ModelItemFilterInput | null,
};

export type ModelProductFilterInput = {
  id?: ModelIDInput | null,
  ownerId?: ModelStringInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  category?: ModelProductCategoryInput | null,
  size?: ModelStringInput | null,
  duration?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  taxExempt?: ModelBooleanInput | null,
  visibility?: ModelBookingVisibilityInput | null,
  and?: Array< ModelProductFilterInput | null > | null,
  or?: Array< ModelProductFilterInput | null > | null,
  not?: ModelProductFilterInput | null,
};

export type ModelProductConnection = {
  __typename: "ModelProductConnection",
  items:  Array<Product | null >,
  nextToken?: string | null,
};

export type ModelReportFilterInput = {
  id?: ModelIDInput | null,
  ownerId?: ModelStringInput | null,
  workOrderId?: ModelStringInput | null,
  and?: Array< ModelReportFilterInput | null > | null,
  or?: Array< ModelReportFilterInput | null > | null,
  not?: ModelReportFilterInput | null,
};

export type ModelReportConnection = {
  __typename: "ModelReportConnection",
  items:  Array<Report | null >,
  nextToken?: string | null,
};

export type ModelSubcategoryFilterInput = {
  id?: ModelIDInput | null,
  categoryId?: ModelIDInput | null,
  categoryOwnerId?: ModelStringInput | null,
  name?: ModelStringInput | null,
  notes?: ModelStringInput | null,
  itemOrder?: ModelIDInput | null,
  parentId?: ModelIDInput | null,
  subcategoryOrder?: ModelIDInput | null,
  and?: Array< ModelSubcategoryFilterInput | null > | null,
  or?: Array< ModelSubcategoryFilterInput | null > | null,
  not?: ModelSubcategoryFilterInput | null,
};

export type ModelTemplateFilterInput = {
  ownerId?: ModelStringInput | null,
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  categoryOrder?: ModelIDInput | null,
  and?: Array< ModelTemplateFilterInput | null > | null,
  or?: Array< ModelTemplateFilterInput | null > | null,
  not?: ModelTemplateFilterInput | null,
};

export type ModelTemplateConnection = {
  __typename: "ModelTemplateConnection",
  items:  Array<Template | null >,
  nextToken?: string | null,
};

export type ModelTemplateCategoryConnectionFilterInput = {
  templateOwnerId?: ModelStringInput | null,
  templateId?: ModelIDInput | null,
  categoryOwnerId?: ModelStringInput | null,
  categoryId?: ModelIDInput | null,
  and?: Array< ModelTemplateCategoryConnectionFilterInput | null > | null,
  or?: Array< ModelTemplateCategoryConnectionFilterInput | null > | null,
  not?: ModelTemplateCategoryConnectionFilterInput | null,
};

export type ModelWorkflowFilterInput = {
  id?: ModelIDInput | null,
  ownerId?: ModelStringInput | null,
  name?: ModelStringInput | null,
  and?: Array< ModelWorkflowFilterInput | null > | null,
  or?: Array< ModelWorkflowFilterInput | null > | null,
  not?: ModelWorkflowFilterInput | null,
};

export type ModelWorkflowConnection = {
  __typename: "ModelWorkflowConnection",
  items:  Array<Workflow | null >,
  nextToken?: string | null,
};

export type CreateCategoryMutationVariables = {
  input: CreateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type CreateCategoryMutation = {
  createCategory?:  {
    __typename: "Category",
    ownerId: string,
    id: string,
    name: string,
    subcategoryOrder: Array< string >,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    templates?:  {
      __typename: "ModelTemplateCategoryConnectionConnection",
      items:  Array< {
        __typename: "TemplateCategoryConnection",
        templateOwnerId: string,
        templateId: string,
        categoryOwnerId: string,
        categoryId: string,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    subcategories?:  {
      __typename: "ModelSubcategoryConnection",
      items:  Array< {
        __typename: "Subcategory",
        id: string,
        categoryId: string,
        categoryOwnerId: string,
        name: string,
        notes?: string | null,
        itemOrder?: Array< string > | null,
        parentId?: string | null,
        subcategoryOrder?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCategoryMutationVariables = {
  input: UpdateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type UpdateCategoryMutation = {
  updateCategory?:  {
    __typename: "Category",
    ownerId: string,
    id: string,
    name: string,
    subcategoryOrder: Array< string >,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    templates?:  {
      __typename: "ModelTemplateCategoryConnectionConnection",
      items:  Array< {
        __typename: "TemplateCategoryConnection",
        templateOwnerId: string,
        templateId: string,
        categoryOwnerId: string,
        categoryId: string,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    subcategories?:  {
      __typename: "ModelSubcategoryConnection",
      items:  Array< {
        __typename: "Subcategory",
        id: string,
        categoryId: string,
        categoryOwnerId: string,
        name: string,
        notes?: string | null,
        itemOrder?: Array< string > | null,
        parentId?: string | null,
        subcategoryOrder?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCategoryMutationVariables = {
  input: DeleteCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type DeleteCategoryMutation = {
  deleteCategory?:  {
    __typename: "Category",
    ownerId: string,
    id: string,
    name: string,
    subcategoryOrder: Array< string >,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    templates?:  {
      __typename: "ModelTemplateCategoryConnectionConnection",
      items:  Array< {
        __typename: "TemplateCategoryConnection",
        templateOwnerId: string,
        templateId: string,
        categoryOwnerId: string,
        categoryId: string,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    subcategories?:  {
      __typename: "ModelSubcategoryConnection",
      items:  Array< {
        __typename: "Subcategory",
        id: string,
        categoryId: string,
        categoryOwnerId: string,
        name: string,
        notes?: string | null,
        itemOrder?: Array< string > | null,
        parentId?: string | null,
        subcategoryOrder?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDiscountMutationVariables = {
  input: CreateDiscountInput,
  condition?: ModelDiscountConditionInput | null,
};

export type CreateDiscountMutation = {
  createDiscount?:  {
    __typename: "Discount",
    id: string,
    ownerId: string,
    name: string,
    description: string,
    type: DiscountType,
    appliesTo: DiscountAppliesTo,
    option?: DiscountOption | null,
    value?: number | null,
    minPurchase?: boolean | null,
    minPurchaseAmount?: number | null,
    limit?: boolean | null,
    onePerCustomer?: boolean | null,
    fixedLimit?: boolean | null,
    fixedLimitAmount?: number | null,
    startDate?: string | null,
    endDate?: string | null,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateDiscountMutationVariables = {
  input: UpdateDiscountInput,
  condition?: ModelDiscountConditionInput | null,
};

export type UpdateDiscountMutation = {
  updateDiscount?:  {
    __typename: "Discount",
    id: string,
    ownerId: string,
    name: string,
    description: string,
    type: DiscountType,
    appliesTo: DiscountAppliesTo,
    option?: DiscountOption | null,
    value?: number | null,
    minPurchase?: boolean | null,
    minPurchaseAmount?: number | null,
    limit?: boolean | null,
    onePerCustomer?: boolean | null,
    fixedLimit?: boolean | null,
    fixedLimitAmount?: number | null,
    startDate?: string | null,
    endDate?: string | null,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteDiscountMutationVariables = {
  input: DeleteDiscountInput,
  condition?: ModelDiscountConditionInput | null,
};

export type DeleteDiscountMutation = {
  deleteDiscount?:  {
    __typename: "Discount",
    id: string,
    ownerId: string,
    name: string,
    description: string,
    type: DiscountType,
    appliesTo: DiscountAppliesTo,
    option?: DiscountOption | null,
    value?: number | null,
    minPurchase?: boolean | null,
    minPurchaseAmount?: number | null,
    limit?: boolean | null,
    onePerCustomer?: boolean | null,
    fixedLimit?: boolean | null,
    fixedLimitAmount?: number | null,
    startDate?: string | null,
    endDate?: string | null,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateInspectionMutationVariables = {
  input: CreateInspectionInput,
  condition?: ModelInspectionConditionInput | null,
};

export type CreateInspectionMutation = {
  createInspection?:  {
    __typename: "Inspection",
    ownerId: string,
    id: string,
    workOrder: string,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateInspectionMutationVariables = {
  input: UpdateInspectionInput,
  condition?: ModelInspectionConditionInput | null,
};

export type UpdateInspectionMutation = {
  updateInspection?:  {
    __typename: "Inspection",
    ownerId: string,
    id: string,
    workOrder: string,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteInspectionMutationVariables = {
  input: DeleteInspectionInput,
  condition?: ModelInspectionConditionInput | null,
};

export type DeleteInspectionMutation = {
  deleteInspection?:  {
    __typename: "Inspection",
    ownerId: string,
    id: string,
    workOrder: string,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateItemMutationVariables = {
  input: CreateItemInput,
  condition?: ModelItemConditionInput | null,
};

export type CreateItemMutation = {
  createItem?:  {
    __typename: "Item",
    id: string,
    subcategoryId: string,
    name: string,
    subcategory:  {
      __typename: "Subcategory",
      id: string,
      categoryId: string,
      categoryOwnerId: string,
      name: string,
      notes?: string | null,
      itemOrder?: Array< string > | null,
      parentId?: string | null,
      subcategoryOrder?: Array< string > | null,
      parent?:  {
        __typename: "Subcategory",
        id: string,
        categoryId: string,
        categoryOwnerId: string,
        name: string,
        notes?: string | null,
        itemOrder?: Array< string > | null,
        parentId?: string | null,
        subcategoryOrder?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      children?:  {
        __typename: "ModelSubcategoryConnection",
        nextToken?: string | null,
      } | null,
      category?:  {
        __typename: "Category",
        ownerId: string,
        id: string,
        name: string,
        subcategoryOrder: Array< string >,
        createdAt: string,
        updatedAt: string,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateItemMutationVariables = {
  input: UpdateItemInput,
  condition?: ModelItemConditionInput | null,
};

export type UpdateItemMutation = {
  updateItem?:  {
    __typename: "Item",
    id: string,
    subcategoryId: string,
    name: string,
    subcategory:  {
      __typename: "Subcategory",
      id: string,
      categoryId: string,
      categoryOwnerId: string,
      name: string,
      notes?: string | null,
      itemOrder?: Array< string > | null,
      parentId?: string | null,
      subcategoryOrder?: Array< string > | null,
      parent?:  {
        __typename: "Subcategory",
        id: string,
        categoryId: string,
        categoryOwnerId: string,
        name: string,
        notes?: string | null,
        itemOrder?: Array< string > | null,
        parentId?: string | null,
        subcategoryOrder?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      children?:  {
        __typename: "ModelSubcategoryConnection",
        nextToken?: string | null,
      } | null,
      category?:  {
        __typename: "Category",
        ownerId: string,
        id: string,
        name: string,
        subcategoryOrder: Array< string >,
        createdAt: string,
        updatedAt: string,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteItemMutationVariables = {
  input: DeleteItemInput,
  condition?: ModelItemConditionInput | null,
};

export type DeleteItemMutation = {
  deleteItem?:  {
    __typename: "Item",
    id: string,
    subcategoryId: string,
    name: string,
    subcategory:  {
      __typename: "Subcategory",
      id: string,
      categoryId: string,
      categoryOwnerId: string,
      name: string,
      notes?: string | null,
      itemOrder?: Array< string > | null,
      parentId?: string | null,
      subcategoryOrder?: Array< string > | null,
      parent?:  {
        __typename: "Subcategory",
        id: string,
        categoryId: string,
        categoryOwnerId: string,
        name: string,
        notes?: string | null,
        itemOrder?: Array< string > | null,
        parentId?: string | null,
        subcategoryOrder?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      children?:  {
        __typename: "ModelSubcategoryConnection",
        nextToken?: string | null,
      } | null,
      category?:  {
        __typename: "Category",
        ownerId: string,
        id: string,
        name: string,
        subcategoryOrder: Array< string >,
        createdAt: string,
        updatedAt: string,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateProductMutationVariables = {
  input: CreateProductInput,
  condition?: ModelProductConditionInput | null,
};

export type CreateProductMutation = {
  createProduct?:  {
    __typename: "Product",
    id: string,
    ownerId: string,
    name: string,
    description: string,
    category: ProductCategory,
    size?: string | null,
    duration?: string | null,
    price: number,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    taxExempt?: boolean | null,
    visibility?: BookingVisibility | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateProductMutationVariables = {
  input: UpdateProductInput,
  condition?: ModelProductConditionInput | null,
};

export type UpdateProductMutation = {
  updateProduct?:  {
    __typename: "Product",
    id: string,
    ownerId: string,
    name: string,
    description: string,
    category: ProductCategory,
    size?: string | null,
    duration?: string | null,
    price: number,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    taxExempt?: boolean | null,
    visibility?: BookingVisibility | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteProductMutationVariables = {
  input: DeleteProductInput,
  condition?: ModelProductConditionInput | null,
};

export type DeleteProductMutation = {
  deleteProduct?:  {
    __typename: "Product",
    id: string,
    ownerId: string,
    name: string,
    description: string,
    category: ProductCategory,
    size?: string | null,
    duration?: string | null,
    price: number,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    taxExempt?: boolean | null,
    visibility?: BookingVisibility | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateReportMutationVariables = {
  input: CreateReportInput,
  condition?: ModelReportConditionInput | null,
};

export type CreateReportMutation = {
  createReport?:  {
    __typename: "Report",
    id: string,
    ownerId: string,
    workOrderId: string,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateReportMutationVariables = {
  input: UpdateReportInput,
  condition?: ModelReportConditionInput | null,
};

export type UpdateReportMutation = {
  updateReport?:  {
    __typename: "Report",
    id: string,
    ownerId: string,
    workOrderId: string,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteReportMutationVariables = {
  input: DeleteReportInput,
  condition?: ModelReportConditionInput | null,
};

export type DeleteReportMutation = {
  deleteReport?:  {
    __typename: "Report",
    id: string,
    ownerId: string,
    workOrderId: string,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSubcategoryMutationVariables = {
  input: CreateSubcategoryInput,
  condition?: ModelSubcategoryConditionInput | null,
};

export type CreateSubcategoryMutation = {
  createSubcategory?:  {
    __typename: "Subcategory",
    id: string,
    categoryId: string,
    categoryOwnerId: string,
    name: string,
    notes?: string | null,
    itemOrder?: Array< string > | null,
    parentId?: string | null,
    subcategoryOrder?: Array< string > | null,
    parent?:  {
      __typename: "Subcategory",
      id: string,
      categoryId: string,
      categoryOwnerId: string,
      name: string,
      notes?: string | null,
      itemOrder?: Array< string > | null,
      parentId?: string | null,
      subcategoryOrder?: Array< string > | null,
      parent?:  {
        __typename: "Subcategory",
        id: string,
        categoryId: string,
        categoryOwnerId: string,
        name: string,
        notes?: string | null,
        itemOrder?: Array< string > | null,
        parentId?: string | null,
        subcategoryOrder?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      children?:  {
        __typename: "ModelSubcategoryConnection",
        nextToken?: string | null,
      } | null,
      category?:  {
        __typename: "Category",
        ownerId: string,
        id: string,
        name: string,
        subcategoryOrder: Array< string >,
        createdAt: string,
        updatedAt: string,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    children?:  {
      __typename: "ModelSubcategoryConnection",
      items:  Array< {
        __typename: "Subcategory",
        id: string,
        categoryId: string,
        categoryOwnerId: string,
        name: string,
        notes?: string | null,
        itemOrder?: Array< string > | null,
        parentId?: string | null,
        subcategoryOrder?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    category?:  {
      __typename: "Category",
      ownerId: string,
      id: string,
      name: string,
      subcategoryOrder: Array< string >,
      owner?:  {
        __typename: "Owner",
        level: OwnershipLevel,
        id?: string | null,
      } | null,
      templates?:  {
        __typename: "ModelTemplateCategoryConnectionConnection",
        nextToken?: string | null,
      } | null,
      subcategories?:  {
        __typename: "ModelSubcategoryConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        subcategoryId: string,
        name: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSubcategoryMutationVariables = {
  input: UpdateSubcategoryInput,
  condition?: ModelSubcategoryConditionInput | null,
};

export type UpdateSubcategoryMutation = {
  updateSubcategory?:  {
    __typename: "Subcategory",
    id: string,
    categoryId: string,
    categoryOwnerId: string,
    name: string,
    notes?: string | null,
    itemOrder?: Array< string > | null,
    parentId?: string | null,
    subcategoryOrder?: Array< string > | null,
    parent?:  {
      __typename: "Subcategory",
      id: string,
      categoryId: string,
      categoryOwnerId: string,
      name: string,
      notes?: string | null,
      itemOrder?: Array< string > | null,
      parentId?: string | null,
      subcategoryOrder?: Array< string > | null,
      parent?:  {
        __typename: "Subcategory",
        id: string,
        categoryId: string,
        categoryOwnerId: string,
        name: string,
        notes?: string | null,
        itemOrder?: Array< string > | null,
        parentId?: string | null,
        subcategoryOrder?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      children?:  {
        __typename: "ModelSubcategoryConnection",
        nextToken?: string | null,
      } | null,
      category?:  {
        __typename: "Category",
        ownerId: string,
        id: string,
        name: string,
        subcategoryOrder: Array< string >,
        createdAt: string,
        updatedAt: string,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    children?:  {
      __typename: "ModelSubcategoryConnection",
      items:  Array< {
        __typename: "Subcategory",
        id: string,
        categoryId: string,
        categoryOwnerId: string,
        name: string,
        notes?: string | null,
        itemOrder?: Array< string > | null,
        parentId?: string | null,
        subcategoryOrder?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    category?:  {
      __typename: "Category",
      ownerId: string,
      id: string,
      name: string,
      subcategoryOrder: Array< string >,
      owner?:  {
        __typename: "Owner",
        level: OwnershipLevel,
        id?: string | null,
      } | null,
      templates?:  {
        __typename: "ModelTemplateCategoryConnectionConnection",
        nextToken?: string | null,
      } | null,
      subcategories?:  {
        __typename: "ModelSubcategoryConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        subcategoryId: string,
        name: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSubcategoryMutationVariables = {
  input: DeleteSubcategoryInput,
  condition?: ModelSubcategoryConditionInput | null,
};

export type DeleteSubcategoryMutation = {
  deleteSubcategory?:  {
    __typename: "Subcategory",
    id: string,
    categoryId: string,
    categoryOwnerId: string,
    name: string,
    notes?: string | null,
    itemOrder?: Array< string > | null,
    parentId?: string | null,
    subcategoryOrder?: Array< string > | null,
    parent?:  {
      __typename: "Subcategory",
      id: string,
      categoryId: string,
      categoryOwnerId: string,
      name: string,
      notes?: string | null,
      itemOrder?: Array< string > | null,
      parentId?: string | null,
      subcategoryOrder?: Array< string > | null,
      parent?:  {
        __typename: "Subcategory",
        id: string,
        categoryId: string,
        categoryOwnerId: string,
        name: string,
        notes?: string | null,
        itemOrder?: Array< string > | null,
        parentId?: string | null,
        subcategoryOrder?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      children?:  {
        __typename: "ModelSubcategoryConnection",
        nextToken?: string | null,
      } | null,
      category?:  {
        __typename: "Category",
        ownerId: string,
        id: string,
        name: string,
        subcategoryOrder: Array< string >,
        createdAt: string,
        updatedAt: string,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    children?:  {
      __typename: "ModelSubcategoryConnection",
      items:  Array< {
        __typename: "Subcategory",
        id: string,
        categoryId: string,
        categoryOwnerId: string,
        name: string,
        notes?: string | null,
        itemOrder?: Array< string > | null,
        parentId?: string | null,
        subcategoryOrder?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    category?:  {
      __typename: "Category",
      ownerId: string,
      id: string,
      name: string,
      subcategoryOrder: Array< string >,
      owner?:  {
        __typename: "Owner",
        level: OwnershipLevel,
        id?: string | null,
      } | null,
      templates?:  {
        __typename: "ModelTemplateCategoryConnectionConnection",
        nextToken?: string | null,
      } | null,
      subcategories?:  {
        __typename: "ModelSubcategoryConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        subcategoryId: string,
        name: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTemplateMutationVariables = {
  input: CreateTemplateInput,
  condition?: ModelTemplateConditionInput | null,
};

export type CreateTemplateMutation = {
  createTemplate?:  {
    __typename: "Template",
    ownerId: string,
    id: string,
    name: string,
    categoryOrder: Array< string >,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    categories?:  {
      __typename: "ModelTemplateCategoryConnectionConnection",
      items:  Array< {
        __typename: "TemplateCategoryConnection",
        templateOwnerId: string,
        templateId: string,
        categoryOwnerId: string,
        categoryId: string,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTemplateMutationVariables = {
  input: UpdateTemplateInput,
  condition?: ModelTemplateConditionInput | null,
};

export type UpdateTemplateMutation = {
  updateTemplate?:  {
    __typename: "Template",
    ownerId: string,
    id: string,
    name: string,
    categoryOrder: Array< string >,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    categories?:  {
      __typename: "ModelTemplateCategoryConnectionConnection",
      items:  Array< {
        __typename: "TemplateCategoryConnection",
        templateOwnerId: string,
        templateId: string,
        categoryOwnerId: string,
        categoryId: string,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTemplateMutationVariables = {
  input: DeleteTemplateInput,
  condition?: ModelTemplateConditionInput | null,
};

export type DeleteTemplateMutation = {
  deleteTemplate?:  {
    __typename: "Template",
    ownerId: string,
    id: string,
    name: string,
    categoryOrder: Array< string >,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    categories?:  {
      __typename: "ModelTemplateCategoryConnectionConnection",
      items:  Array< {
        __typename: "TemplateCategoryConnection",
        templateOwnerId: string,
        templateId: string,
        categoryOwnerId: string,
        categoryId: string,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTemplateCategoryConnectionMutationVariables = {
  input: CreateTemplateCategoryConnectionInput,
  condition?: ModelTemplateCategoryConnectionConditionInput | null,
};

export type CreateTemplateCategoryConnectionMutation = {
  createTemplateCategoryConnection?:  {
    __typename: "TemplateCategoryConnection",
    templateOwnerId: string,
    templateId: string,
    categoryOwnerId: string,
    categoryId: string,
    template:  {
      __typename: "Template",
      ownerId: string,
      id: string,
      name: string,
      categoryOrder: Array< string >,
      owner?:  {
        __typename: "Owner",
        level: OwnershipLevel,
        id?: string | null,
      } | null,
      categories?:  {
        __typename: "ModelTemplateCategoryConnectionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    category:  {
      __typename: "Category",
      ownerId: string,
      id: string,
      name: string,
      subcategoryOrder: Array< string >,
      owner?:  {
        __typename: "Owner",
        level: OwnershipLevel,
        id?: string | null,
      } | null,
      templates?:  {
        __typename: "ModelTemplateCategoryConnectionConnection",
        nextToken?: string | null,
      } | null,
      subcategories?:  {
        __typename: "ModelSubcategoryConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTemplateCategoryConnectionMutationVariables = {
  input: UpdateTemplateCategoryConnectionInput,
  condition?: ModelTemplateCategoryConnectionConditionInput | null,
};

export type UpdateTemplateCategoryConnectionMutation = {
  updateTemplateCategoryConnection?:  {
    __typename: "TemplateCategoryConnection",
    templateOwnerId: string,
    templateId: string,
    categoryOwnerId: string,
    categoryId: string,
    template:  {
      __typename: "Template",
      ownerId: string,
      id: string,
      name: string,
      categoryOrder: Array< string >,
      owner?:  {
        __typename: "Owner",
        level: OwnershipLevel,
        id?: string | null,
      } | null,
      categories?:  {
        __typename: "ModelTemplateCategoryConnectionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    category:  {
      __typename: "Category",
      ownerId: string,
      id: string,
      name: string,
      subcategoryOrder: Array< string >,
      owner?:  {
        __typename: "Owner",
        level: OwnershipLevel,
        id?: string | null,
      } | null,
      templates?:  {
        __typename: "ModelTemplateCategoryConnectionConnection",
        nextToken?: string | null,
      } | null,
      subcategories?:  {
        __typename: "ModelSubcategoryConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTemplateCategoryConnectionMutationVariables = {
  input: DeleteTemplateCategoryConnectionInput,
  condition?: ModelTemplateCategoryConnectionConditionInput | null,
};

export type DeleteTemplateCategoryConnectionMutation = {
  deleteTemplateCategoryConnection?:  {
    __typename: "TemplateCategoryConnection",
    templateOwnerId: string,
    templateId: string,
    categoryOwnerId: string,
    categoryId: string,
    template:  {
      __typename: "Template",
      ownerId: string,
      id: string,
      name: string,
      categoryOrder: Array< string >,
      owner?:  {
        __typename: "Owner",
        level: OwnershipLevel,
        id?: string | null,
      } | null,
      categories?:  {
        __typename: "ModelTemplateCategoryConnectionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    category:  {
      __typename: "Category",
      ownerId: string,
      id: string,
      name: string,
      subcategoryOrder: Array< string >,
      owner?:  {
        __typename: "Owner",
        level: OwnershipLevel,
        id?: string | null,
      } | null,
      templates?:  {
        __typename: "ModelTemplateCategoryConnectionConnection",
        nextToken?: string | null,
      } | null,
      subcategories?:  {
        __typename: "ModelSubcategoryConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateWorkflowMutationVariables = {
  input: CreateWorkflowInput,
  condition?: ModelWorkflowConditionInput | null,
};

export type CreateWorkflowMutation = {
  createWorkflow?:  {
    __typename: "Workflow",
    id: string,
    ownerId: string,
    name: string,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateWorkflowMutationVariables = {
  input: UpdateWorkflowInput,
  condition?: ModelWorkflowConditionInput | null,
};

export type UpdateWorkflowMutation = {
  updateWorkflow?:  {
    __typename: "Workflow",
    id: string,
    ownerId: string,
    name: string,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteWorkflowMutationVariables = {
  input: DeleteWorkflowInput,
  condition?: ModelWorkflowConditionInput | null,
};

export type DeleteWorkflowMutation = {
  deleteWorkflow?:  {
    __typename: "Workflow",
    id: string,
    ownerId: string,
    name: string,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetCategoryQueryVariables = {
  ownerId: string,
  id: string,
};

export type GetCategoryQuery = {
  getCategory?:  {
    __typename: "Category",
    ownerId: string,
    id: string,
    name: string,
    subcategoryOrder: Array< string >,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    templates?:  {
      __typename: "ModelTemplateCategoryConnectionConnection",
      items:  Array< {
        __typename: "TemplateCategoryConnection",
        templateOwnerId: string,
        templateId: string,
        categoryOwnerId: string,
        categoryId: string,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    subcategories?:  {
      __typename: "ModelSubcategoryConnection",
      items:  Array< {
        __typename: "Subcategory",
        id: string,
        categoryId: string,
        categoryOwnerId: string,
        name: string,
        notes?: string | null,
        itemOrder?: Array< string > | null,
        parentId?: string | null,
        subcategoryOrder?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCategoriesQueryVariables = {
  ownerId?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCategoriesQuery = {
  listCategories?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      ownerId: string,
      id: string,
      name: string,
      subcategoryOrder: Array< string >,
      owner?:  {
        __typename: "Owner",
        level: OwnershipLevel,
        id?: string | null,
      } | null,
      templates?:  {
        __typename: "ModelTemplateCategoryConnectionConnection",
        nextToken?: string | null,
      } | null,
      subcategories?:  {
        __typename: "ModelSubcategoryConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CategoriesByIdQueryVariables = {
  id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CategoriesByIdQuery = {
  categoriesById?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      ownerId: string,
      id: string,
      name: string,
      subcategoryOrder: Array< string >,
      owner?:  {
        __typename: "Owner",
        level: OwnershipLevel,
        id?: string | null,
      } | null,
      templates?:  {
        __typename: "ModelTemplateCategoryConnectionConnection",
        nextToken?: string | null,
      } | null,
      subcategories?:  {
        __typename: "ModelSubcategoryConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetDiscountQueryVariables = {
  ownerId: string,
  id: string,
};

export type GetDiscountQuery = {
  getDiscount?:  {
    __typename: "Discount",
    id: string,
    ownerId: string,
    name: string,
    description: string,
    type: DiscountType,
    appliesTo: DiscountAppliesTo,
    option?: DiscountOption | null,
    value?: number | null,
    minPurchase?: boolean | null,
    minPurchaseAmount?: number | null,
    limit?: boolean | null,
    onePerCustomer?: boolean | null,
    fixedLimit?: boolean | null,
    fixedLimitAmount?: number | null,
    startDate?: string | null,
    endDate?: string | null,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListDiscountsQueryVariables = {
  ownerId?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelDiscountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListDiscountsQuery = {
  listDiscounts?:  {
    __typename: "ModelDiscountConnection",
    items:  Array< {
      __typename: "Discount",
      id: string,
      ownerId: string,
      name: string,
      description: string,
      type: DiscountType,
      appliesTo: DiscountAppliesTo,
      option?: DiscountOption | null,
      value?: number | null,
      minPurchase?: boolean | null,
      minPurchaseAmount?: number | null,
      limit?: boolean | null,
      onePerCustomer?: boolean | null,
      fixedLimit?: boolean | null,
      fixedLimitAmount?: number | null,
      startDate?: string | null,
      endDate?: string | null,
      owner?:  {
        __typename: "Owner",
        level: OwnershipLevel,
        id?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type DiscountByIdQueryVariables = {
  id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelDiscountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type DiscountByIdQuery = {
  discountById?:  {
    __typename: "ModelDiscountConnection",
    items:  Array< {
      __typename: "Discount",
      id: string,
      ownerId: string,
      name: string,
      description: string,
      type: DiscountType,
      appliesTo: DiscountAppliesTo,
      option?: DiscountOption | null,
      value?: number | null,
      minPurchase?: boolean | null,
      minPurchaseAmount?: number | null,
      limit?: boolean | null,
      onePerCustomer?: boolean | null,
      fixedLimit?: boolean | null,
      fixedLimitAmount?: number | null,
      startDate?: string | null,
      endDate?: string | null,
      owner?:  {
        __typename: "Owner",
        level: OwnershipLevel,
        id?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetInspectionQueryVariables = {
  ownerId: string,
  id: string,
};

export type GetInspectionQuery = {
  getInspection?:  {
    __typename: "Inspection",
    ownerId: string,
    id: string,
    workOrder: string,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListInspectionsQueryVariables = {
  ownerId?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelInspectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListInspectionsQuery = {
  listInspections?:  {
    __typename: "ModelInspectionConnection",
    items:  Array< {
      __typename: "Inspection",
      ownerId: string,
      id: string,
      workOrder: string,
      owner?:  {
        __typename: "Owner",
        level: OwnershipLevel,
        id?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetItemQueryVariables = {
  id: string,
};

export type GetItemQuery = {
  getItem?:  {
    __typename: "Item",
    id: string,
    subcategoryId: string,
    name: string,
    subcategory:  {
      __typename: "Subcategory",
      id: string,
      categoryId: string,
      categoryOwnerId: string,
      name: string,
      notes?: string | null,
      itemOrder?: Array< string > | null,
      parentId?: string | null,
      subcategoryOrder?: Array< string > | null,
      parent?:  {
        __typename: "Subcategory",
        id: string,
        categoryId: string,
        categoryOwnerId: string,
        name: string,
        notes?: string | null,
        itemOrder?: Array< string > | null,
        parentId?: string | null,
        subcategoryOrder?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      children?:  {
        __typename: "ModelSubcategoryConnection",
        nextToken?: string | null,
      } | null,
      category?:  {
        __typename: "Category",
        ownerId: string,
        id: string,
        name: string,
        subcategoryOrder: Array< string >,
        createdAt: string,
        updatedAt: string,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListItemsQueryVariables = {
  filter?: ModelItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListItemsQuery = {
  listItems?:  {
    __typename: "ModelItemConnection",
    items:  Array< {
      __typename: "Item",
      id: string,
      subcategoryId: string,
      name: string,
      subcategory:  {
        __typename: "Subcategory",
        id: string,
        categoryId: string,
        categoryOwnerId: string,
        name: string,
        notes?: string | null,
        itemOrder?: Array< string > | null,
        parentId?: string | null,
        subcategoryOrder?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListItemsBySubcategoryIdQueryVariables = {
  subcategoryId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListItemsBySubcategoryIdQuery = {
  listItemsBySubcategoryId?:  {
    __typename: "ModelItemConnection",
    items:  Array< {
      __typename: "Item",
      id: string,
      subcategoryId: string,
      name: string,
      subcategory:  {
        __typename: "Subcategory",
        id: string,
        categoryId: string,
        categoryOwnerId: string,
        name: string,
        notes?: string | null,
        itemOrder?: Array< string > | null,
        parentId?: string | null,
        subcategoryOrder?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProductQueryVariables = {
  ownerId: string,
  id: string,
};

export type GetProductQuery = {
  getProduct?:  {
    __typename: "Product",
    id: string,
    ownerId: string,
    name: string,
    description: string,
    category: ProductCategory,
    size?: string | null,
    duration?: string | null,
    price: number,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    taxExempt?: boolean | null,
    visibility?: BookingVisibility | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListProductsQueryVariables = {
  ownerId?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListProductsQuery = {
  listProducts?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      ownerId: string,
      name: string,
      description: string,
      category: ProductCategory,
      size?: string | null,
      duration?: string | null,
      price: number,
      owner?:  {
        __typename: "Owner",
        level: OwnershipLevel,
        id?: string | null,
      } | null,
      taxExempt?: boolean | null,
      visibility?: BookingVisibility | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductByIdQueryVariables = {
  id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductByIdQuery = {
  productById?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      ownerId: string,
      name: string,
      description: string,
      category: ProductCategory,
      size?: string | null,
      duration?: string | null,
      price: number,
      owner?:  {
        __typename: "Owner",
        level: OwnershipLevel,
        id?: string | null,
      } | null,
      taxExempt?: boolean | null,
      visibility?: BookingVisibility | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetReportQueryVariables = {
  ownerId: string,
  id: string,
};

export type GetReportQuery = {
  getReport?:  {
    __typename: "Report",
    id: string,
    ownerId: string,
    workOrderId: string,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListReportsQueryVariables = {
  ownerId?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelReportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListReportsQuery = {
  listReports?:  {
    __typename: "ModelReportConnection",
    items:  Array< {
      __typename: "Report",
      id: string,
      ownerId: string,
      workOrderId: string,
      owner?:  {
        __typename: "Owner",
        level: OwnershipLevel,
        id?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ReportByIdQueryVariables = {
  id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReportFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ReportByIdQuery = {
  reportById?:  {
    __typename: "ModelReportConnection",
    items:  Array< {
      __typename: "Report",
      id: string,
      ownerId: string,
      workOrderId: string,
      owner?:  {
        __typename: "Owner",
        level: OwnershipLevel,
        id?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSubcategoryQueryVariables = {
  id: string,
};

export type GetSubcategoryQuery = {
  getSubcategory?:  {
    __typename: "Subcategory",
    id: string,
    categoryId: string,
    categoryOwnerId: string,
    name: string,
    notes?: string | null,
    itemOrder?: Array< string > | null,
    parentId?: string | null,
    subcategoryOrder?: Array< string > | null,
    parent?:  {
      __typename: "Subcategory",
      id: string,
      categoryId: string,
      categoryOwnerId: string,
      name: string,
      notes?: string | null,
      itemOrder?: Array< string > | null,
      parentId?: string | null,
      subcategoryOrder?: Array< string > | null,
      parent?:  {
        __typename: "Subcategory",
        id: string,
        categoryId: string,
        categoryOwnerId: string,
        name: string,
        notes?: string | null,
        itemOrder?: Array< string > | null,
        parentId?: string | null,
        subcategoryOrder?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      children?:  {
        __typename: "ModelSubcategoryConnection",
        nextToken?: string | null,
      } | null,
      category?:  {
        __typename: "Category",
        ownerId: string,
        id: string,
        name: string,
        subcategoryOrder: Array< string >,
        createdAt: string,
        updatedAt: string,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    children?:  {
      __typename: "ModelSubcategoryConnection",
      items:  Array< {
        __typename: "Subcategory",
        id: string,
        categoryId: string,
        categoryOwnerId: string,
        name: string,
        notes?: string | null,
        itemOrder?: Array< string > | null,
        parentId?: string | null,
        subcategoryOrder?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    category?:  {
      __typename: "Category",
      ownerId: string,
      id: string,
      name: string,
      subcategoryOrder: Array< string >,
      owner?:  {
        __typename: "Owner",
        level: OwnershipLevel,
        id?: string | null,
      } | null,
      templates?:  {
        __typename: "ModelTemplateCategoryConnectionConnection",
        nextToken?: string | null,
      } | null,
      subcategories?:  {
        __typename: "ModelSubcategoryConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        subcategoryId: string,
        name: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSubcategoriesQueryVariables = {
  filter?: ModelSubcategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSubcategoriesQuery = {
  listSubcategories?:  {
    __typename: "ModelSubcategoryConnection",
    items:  Array< {
      __typename: "Subcategory",
      id: string,
      categoryId: string,
      categoryOwnerId: string,
      name: string,
      notes?: string | null,
      itemOrder?: Array< string > | null,
      parentId?: string | null,
      subcategoryOrder?: Array< string > | null,
      parent?:  {
        __typename: "Subcategory",
        id: string,
        categoryId: string,
        categoryOwnerId: string,
        name: string,
        notes?: string | null,
        itemOrder?: Array< string > | null,
        parentId?: string | null,
        subcategoryOrder?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      children?:  {
        __typename: "ModelSubcategoryConnection",
        nextToken?: string | null,
      } | null,
      category?:  {
        __typename: "Category",
        ownerId: string,
        id: string,
        name: string,
        subcategoryOrder: Array< string >,
        createdAt: string,
        updatedAt: string,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListSubcategoriesByCategoryIdQueryVariables = {
  categoryId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSubcategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSubcategoriesByCategoryIdQuery = {
  listSubcategoriesByCategoryId?:  {
    __typename: "ModelSubcategoryConnection",
    items:  Array< {
      __typename: "Subcategory",
      id: string,
      categoryId: string,
      categoryOwnerId: string,
      name: string,
      notes?: string | null,
      itemOrder?: Array< string > | null,
      parentId?: string | null,
      subcategoryOrder?: Array< string > | null,
      parent?:  {
        __typename: "Subcategory",
        id: string,
        categoryId: string,
        categoryOwnerId: string,
        name: string,
        notes?: string | null,
        itemOrder?: Array< string > | null,
        parentId?: string | null,
        subcategoryOrder?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      children?:  {
        __typename: "ModelSubcategoryConnection",
        nextToken?: string | null,
      } | null,
      category?:  {
        __typename: "Category",
        ownerId: string,
        id: string,
        name: string,
        subcategoryOrder: Array< string >,
        createdAt: string,
        updatedAt: string,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListSubcategoriesByParentIdQueryVariables = {
  parentId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSubcategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSubcategoriesByParentIdQuery = {
  listSubcategoriesByParentId?:  {
    __typename: "ModelSubcategoryConnection",
    items:  Array< {
      __typename: "Subcategory",
      id: string,
      categoryId: string,
      categoryOwnerId: string,
      name: string,
      notes?: string | null,
      itemOrder?: Array< string > | null,
      parentId?: string | null,
      subcategoryOrder?: Array< string > | null,
      parent?:  {
        __typename: "Subcategory",
        id: string,
        categoryId: string,
        categoryOwnerId: string,
        name: string,
        notes?: string | null,
        itemOrder?: Array< string > | null,
        parentId?: string | null,
        subcategoryOrder?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      children?:  {
        __typename: "ModelSubcategoryConnection",
        nextToken?: string | null,
      } | null,
      category?:  {
        __typename: "Category",
        ownerId: string,
        id: string,
        name: string,
        subcategoryOrder: Array< string >,
        createdAt: string,
        updatedAt: string,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTemplateQueryVariables = {
  ownerId: string,
  id: string,
};

export type GetTemplateQuery = {
  getTemplate?:  {
    __typename: "Template",
    ownerId: string,
    id: string,
    name: string,
    categoryOrder: Array< string >,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    categories?:  {
      __typename: "ModelTemplateCategoryConnectionConnection",
      items:  Array< {
        __typename: "TemplateCategoryConnection",
        templateOwnerId: string,
        templateId: string,
        categoryOwnerId: string,
        categoryId: string,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTemplatesQueryVariables = {
  ownerId?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTemplatesQuery = {
  listTemplates?:  {
    __typename: "ModelTemplateConnection",
    items:  Array< {
      __typename: "Template",
      ownerId: string,
      id: string,
      name: string,
      categoryOrder: Array< string >,
      owner?:  {
        __typename: "Owner",
        level: OwnershipLevel,
        id?: string | null,
      } | null,
      categories?:  {
        __typename: "ModelTemplateCategoryConnectionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type TemplatesByIdQueryVariables = {
  id: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TemplatesByIdQuery = {
  templatesById?:  {
    __typename: "ModelTemplateConnection",
    items:  Array< {
      __typename: "Template",
      ownerId: string,
      id: string,
      name: string,
      categoryOrder: Array< string >,
      owner?:  {
        __typename: "Owner",
        level: OwnershipLevel,
        id?: string | null,
      } | null,
      categories?:  {
        __typename: "ModelTemplateCategoryConnectionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTemplateCategoryConnectionQueryVariables = {
  id: string,
};

export type GetTemplateCategoryConnectionQuery = {
  getTemplateCategoryConnection?:  {
    __typename: "TemplateCategoryConnection",
    templateOwnerId: string,
    templateId: string,
    categoryOwnerId: string,
    categoryId: string,
    template:  {
      __typename: "Template",
      ownerId: string,
      id: string,
      name: string,
      categoryOrder: Array< string >,
      owner?:  {
        __typename: "Owner",
        level: OwnershipLevel,
        id?: string | null,
      } | null,
      categories?:  {
        __typename: "ModelTemplateCategoryConnectionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    category:  {
      __typename: "Category",
      ownerId: string,
      id: string,
      name: string,
      subcategoryOrder: Array< string >,
      owner?:  {
        __typename: "Owner",
        level: OwnershipLevel,
        id?: string | null,
      } | null,
      templates?:  {
        __typename: "ModelTemplateCategoryConnectionConnection",
        nextToken?: string | null,
      } | null,
      subcategories?:  {
        __typename: "ModelSubcategoryConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTemplateCategoryConnectionsQueryVariables = {
  filter?: ModelTemplateCategoryConnectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTemplateCategoryConnectionsQuery = {
  listTemplateCategoryConnections?:  {
    __typename: "ModelTemplateCategoryConnectionConnection",
    items:  Array< {
      __typename: "TemplateCategoryConnection",
      templateOwnerId: string,
      templateId: string,
      categoryOwnerId: string,
      categoryId: string,
      template:  {
        __typename: "Template",
        ownerId: string,
        id: string,
        name: string,
        categoryOrder: Array< string >,
        createdAt: string,
        updatedAt: string,
      },
      category:  {
        __typename: "Category",
        ownerId: string,
        id: string,
        name: string,
        subcategoryOrder: Array< string >,
        createdAt: string,
        updatedAt: string,
      },
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCategoriesByTemplateIdQueryVariables = {
  templateId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTemplateCategoryConnectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCategoriesByTemplateIdQuery = {
  listCategoriesByTemplateId?:  {
    __typename: "ModelTemplateCategoryConnectionConnection",
    items:  Array< {
      __typename: "TemplateCategoryConnection",
      templateOwnerId: string,
      templateId: string,
      categoryOwnerId: string,
      categoryId: string,
      template:  {
        __typename: "Template",
        ownerId: string,
        id: string,
        name: string,
        categoryOrder: Array< string >,
        createdAt: string,
        updatedAt: string,
      },
      category:  {
        __typename: "Category",
        ownerId: string,
        id: string,
        name: string,
        subcategoryOrder: Array< string >,
        createdAt: string,
        updatedAt: string,
      },
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListTemplatesByCategoryIdQueryVariables = {
  categoryId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTemplateCategoryConnectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTemplatesByCategoryIdQuery = {
  listTemplatesByCategoryId?:  {
    __typename: "ModelTemplateCategoryConnectionConnection",
    items:  Array< {
      __typename: "TemplateCategoryConnection",
      templateOwnerId: string,
      templateId: string,
      categoryOwnerId: string,
      categoryId: string,
      template:  {
        __typename: "Template",
        ownerId: string,
        id: string,
        name: string,
        categoryOrder: Array< string >,
        createdAt: string,
        updatedAt: string,
      },
      category:  {
        __typename: "Category",
        ownerId: string,
        id: string,
        name: string,
        subcategoryOrder: Array< string >,
        createdAt: string,
        updatedAt: string,
      },
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetWorkflowQueryVariables = {
  ownerId: string,
  id: string,
};

export type GetWorkflowQuery = {
  getWorkflow?:  {
    __typename: "Workflow",
    id: string,
    ownerId: string,
    name: string,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListWorkflowsQueryVariables = {
  ownerId?: string | null,
  id?: ModelIDKeyConditionInput | null,
  filter?: ModelWorkflowFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListWorkflowsQuery = {
  listWorkflows?:  {
    __typename: "ModelWorkflowConnection",
    items:  Array< {
      __typename: "Workflow",
      id: string,
      ownerId: string,
      name: string,
      owner?:  {
        __typename: "Owner",
        level: OwnershipLevel,
        id?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateCategorySubscription = {
  onCreateCategory?:  {
    __typename: "Category",
    ownerId: string,
    id: string,
    name: string,
    subcategoryOrder: Array< string >,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    templates?:  {
      __typename: "ModelTemplateCategoryConnectionConnection",
      items:  Array< {
        __typename: "TemplateCategoryConnection",
        templateOwnerId: string,
        templateId: string,
        categoryOwnerId: string,
        categoryId: string,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    subcategories?:  {
      __typename: "ModelSubcategoryConnection",
      items:  Array< {
        __typename: "Subcategory",
        id: string,
        categoryId: string,
        categoryOwnerId: string,
        name: string,
        notes?: string | null,
        itemOrder?: Array< string > | null,
        parentId?: string | null,
        subcategoryOrder?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCategorySubscription = {
  onUpdateCategory?:  {
    __typename: "Category",
    ownerId: string,
    id: string,
    name: string,
    subcategoryOrder: Array< string >,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    templates?:  {
      __typename: "ModelTemplateCategoryConnectionConnection",
      items:  Array< {
        __typename: "TemplateCategoryConnection",
        templateOwnerId: string,
        templateId: string,
        categoryOwnerId: string,
        categoryId: string,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    subcategories?:  {
      __typename: "ModelSubcategoryConnection",
      items:  Array< {
        __typename: "Subcategory",
        id: string,
        categoryId: string,
        categoryOwnerId: string,
        name: string,
        notes?: string | null,
        itemOrder?: Array< string > | null,
        parentId?: string | null,
        subcategoryOrder?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCategorySubscription = {
  onDeleteCategory?:  {
    __typename: "Category",
    ownerId: string,
    id: string,
    name: string,
    subcategoryOrder: Array< string >,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    templates?:  {
      __typename: "ModelTemplateCategoryConnectionConnection",
      items:  Array< {
        __typename: "TemplateCategoryConnection",
        templateOwnerId: string,
        templateId: string,
        categoryOwnerId: string,
        categoryId: string,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    subcategories?:  {
      __typename: "ModelSubcategoryConnection",
      items:  Array< {
        __typename: "Subcategory",
        id: string,
        categoryId: string,
        categoryOwnerId: string,
        name: string,
        notes?: string | null,
        itemOrder?: Array< string > | null,
        parentId?: string | null,
        subcategoryOrder?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateDiscountSubscription = {
  onCreateDiscount?:  {
    __typename: "Discount",
    id: string,
    ownerId: string,
    name: string,
    description: string,
    type: DiscountType,
    appliesTo: DiscountAppliesTo,
    option?: DiscountOption | null,
    value?: number | null,
    minPurchase?: boolean | null,
    minPurchaseAmount?: number | null,
    limit?: boolean | null,
    onePerCustomer?: boolean | null,
    fixedLimit?: boolean | null,
    fixedLimitAmount?: number | null,
    startDate?: string | null,
    endDate?: string | null,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateDiscountSubscription = {
  onUpdateDiscount?:  {
    __typename: "Discount",
    id: string,
    ownerId: string,
    name: string,
    description: string,
    type: DiscountType,
    appliesTo: DiscountAppliesTo,
    option?: DiscountOption | null,
    value?: number | null,
    minPurchase?: boolean | null,
    minPurchaseAmount?: number | null,
    limit?: boolean | null,
    onePerCustomer?: boolean | null,
    fixedLimit?: boolean | null,
    fixedLimitAmount?: number | null,
    startDate?: string | null,
    endDate?: string | null,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteDiscountSubscription = {
  onDeleteDiscount?:  {
    __typename: "Discount",
    id: string,
    ownerId: string,
    name: string,
    description: string,
    type: DiscountType,
    appliesTo: DiscountAppliesTo,
    option?: DiscountOption | null,
    value?: number | null,
    minPurchase?: boolean | null,
    minPurchaseAmount?: number | null,
    limit?: boolean | null,
    onePerCustomer?: boolean | null,
    fixedLimit?: boolean | null,
    fixedLimitAmount?: number | null,
    startDate?: string | null,
    endDate?: string | null,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateInspectionSubscription = {
  onCreateInspection?:  {
    __typename: "Inspection",
    ownerId: string,
    id: string,
    workOrder: string,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateInspectionSubscription = {
  onUpdateInspection?:  {
    __typename: "Inspection",
    ownerId: string,
    id: string,
    workOrder: string,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteInspectionSubscription = {
  onDeleteInspection?:  {
    __typename: "Inspection",
    ownerId: string,
    id: string,
    workOrder: string,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateItemSubscription = {
  onCreateItem?:  {
    __typename: "Item",
    id: string,
    subcategoryId: string,
    name: string,
    subcategory:  {
      __typename: "Subcategory",
      id: string,
      categoryId: string,
      categoryOwnerId: string,
      name: string,
      notes?: string | null,
      itemOrder?: Array< string > | null,
      parentId?: string | null,
      subcategoryOrder?: Array< string > | null,
      parent?:  {
        __typename: "Subcategory",
        id: string,
        categoryId: string,
        categoryOwnerId: string,
        name: string,
        notes?: string | null,
        itemOrder?: Array< string > | null,
        parentId?: string | null,
        subcategoryOrder?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      children?:  {
        __typename: "ModelSubcategoryConnection",
        nextToken?: string | null,
      } | null,
      category?:  {
        __typename: "Category",
        ownerId: string,
        id: string,
        name: string,
        subcategoryOrder: Array< string >,
        createdAt: string,
        updatedAt: string,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateItemSubscription = {
  onUpdateItem?:  {
    __typename: "Item",
    id: string,
    subcategoryId: string,
    name: string,
    subcategory:  {
      __typename: "Subcategory",
      id: string,
      categoryId: string,
      categoryOwnerId: string,
      name: string,
      notes?: string | null,
      itemOrder?: Array< string > | null,
      parentId?: string | null,
      subcategoryOrder?: Array< string > | null,
      parent?:  {
        __typename: "Subcategory",
        id: string,
        categoryId: string,
        categoryOwnerId: string,
        name: string,
        notes?: string | null,
        itemOrder?: Array< string > | null,
        parentId?: string | null,
        subcategoryOrder?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      children?:  {
        __typename: "ModelSubcategoryConnection",
        nextToken?: string | null,
      } | null,
      category?:  {
        __typename: "Category",
        ownerId: string,
        id: string,
        name: string,
        subcategoryOrder: Array< string >,
        createdAt: string,
        updatedAt: string,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteItemSubscription = {
  onDeleteItem?:  {
    __typename: "Item",
    id: string,
    subcategoryId: string,
    name: string,
    subcategory:  {
      __typename: "Subcategory",
      id: string,
      categoryId: string,
      categoryOwnerId: string,
      name: string,
      notes?: string | null,
      itemOrder?: Array< string > | null,
      parentId?: string | null,
      subcategoryOrder?: Array< string > | null,
      parent?:  {
        __typename: "Subcategory",
        id: string,
        categoryId: string,
        categoryOwnerId: string,
        name: string,
        notes?: string | null,
        itemOrder?: Array< string > | null,
        parentId?: string | null,
        subcategoryOrder?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      children?:  {
        __typename: "ModelSubcategoryConnection",
        nextToken?: string | null,
      } | null,
      category?:  {
        __typename: "Category",
        ownerId: string,
        id: string,
        name: string,
        subcategoryOrder: Array< string >,
        createdAt: string,
        updatedAt: string,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateProductSubscription = {
  onCreateProduct?:  {
    __typename: "Product",
    id: string,
    ownerId: string,
    name: string,
    description: string,
    category: ProductCategory,
    size?: string | null,
    duration?: string | null,
    price: number,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    taxExempt?: boolean | null,
    visibility?: BookingVisibility | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateProductSubscription = {
  onUpdateProduct?:  {
    __typename: "Product",
    id: string,
    ownerId: string,
    name: string,
    description: string,
    category: ProductCategory,
    size?: string | null,
    duration?: string | null,
    price: number,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    taxExempt?: boolean | null,
    visibility?: BookingVisibility | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteProductSubscription = {
  onDeleteProduct?:  {
    __typename: "Product",
    id: string,
    ownerId: string,
    name: string,
    description: string,
    category: ProductCategory,
    size?: string | null,
    duration?: string | null,
    price: number,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    taxExempt?: boolean | null,
    visibility?: BookingVisibility | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateReportSubscription = {
  onCreateReport?:  {
    __typename: "Report",
    id: string,
    ownerId: string,
    workOrderId: string,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateReportSubscription = {
  onUpdateReport?:  {
    __typename: "Report",
    id: string,
    ownerId: string,
    workOrderId: string,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteReportSubscription = {
  onDeleteReport?:  {
    __typename: "Report",
    id: string,
    ownerId: string,
    workOrderId: string,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSubcategorySubscription = {
  onCreateSubcategory?:  {
    __typename: "Subcategory",
    id: string,
    categoryId: string,
    categoryOwnerId: string,
    name: string,
    notes?: string | null,
    itemOrder?: Array< string > | null,
    parentId?: string | null,
    subcategoryOrder?: Array< string > | null,
    parent?:  {
      __typename: "Subcategory",
      id: string,
      categoryId: string,
      categoryOwnerId: string,
      name: string,
      notes?: string | null,
      itemOrder?: Array< string > | null,
      parentId?: string | null,
      subcategoryOrder?: Array< string > | null,
      parent?:  {
        __typename: "Subcategory",
        id: string,
        categoryId: string,
        categoryOwnerId: string,
        name: string,
        notes?: string | null,
        itemOrder?: Array< string > | null,
        parentId?: string | null,
        subcategoryOrder?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      children?:  {
        __typename: "ModelSubcategoryConnection",
        nextToken?: string | null,
      } | null,
      category?:  {
        __typename: "Category",
        ownerId: string,
        id: string,
        name: string,
        subcategoryOrder: Array< string >,
        createdAt: string,
        updatedAt: string,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    children?:  {
      __typename: "ModelSubcategoryConnection",
      items:  Array< {
        __typename: "Subcategory",
        id: string,
        categoryId: string,
        categoryOwnerId: string,
        name: string,
        notes?: string | null,
        itemOrder?: Array< string > | null,
        parentId?: string | null,
        subcategoryOrder?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    category?:  {
      __typename: "Category",
      ownerId: string,
      id: string,
      name: string,
      subcategoryOrder: Array< string >,
      owner?:  {
        __typename: "Owner",
        level: OwnershipLevel,
        id?: string | null,
      } | null,
      templates?:  {
        __typename: "ModelTemplateCategoryConnectionConnection",
        nextToken?: string | null,
      } | null,
      subcategories?:  {
        __typename: "ModelSubcategoryConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        subcategoryId: string,
        name: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSubcategorySubscription = {
  onUpdateSubcategory?:  {
    __typename: "Subcategory",
    id: string,
    categoryId: string,
    categoryOwnerId: string,
    name: string,
    notes?: string | null,
    itemOrder?: Array< string > | null,
    parentId?: string | null,
    subcategoryOrder?: Array< string > | null,
    parent?:  {
      __typename: "Subcategory",
      id: string,
      categoryId: string,
      categoryOwnerId: string,
      name: string,
      notes?: string | null,
      itemOrder?: Array< string > | null,
      parentId?: string | null,
      subcategoryOrder?: Array< string > | null,
      parent?:  {
        __typename: "Subcategory",
        id: string,
        categoryId: string,
        categoryOwnerId: string,
        name: string,
        notes?: string | null,
        itemOrder?: Array< string > | null,
        parentId?: string | null,
        subcategoryOrder?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      children?:  {
        __typename: "ModelSubcategoryConnection",
        nextToken?: string | null,
      } | null,
      category?:  {
        __typename: "Category",
        ownerId: string,
        id: string,
        name: string,
        subcategoryOrder: Array< string >,
        createdAt: string,
        updatedAt: string,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    children?:  {
      __typename: "ModelSubcategoryConnection",
      items:  Array< {
        __typename: "Subcategory",
        id: string,
        categoryId: string,
        categoryOwnerId: string,
        name: string,
        notes?: string | null,
        itemOrder?: Array< string > | null,
        parentId?: string | null,
        subcategoryOrder?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    category?:  {
      __typename: "Category",
      ownerId: string,
      id: string,
      name: string,
      subcategoryOrder: Array< string >,
      owner?:  {
        __typename: "Owner",
        level: OwnershipLevel,
        id?: string | null,
      } | null,
      templates?:  {
        __typename: "ModelTemplateCategoryConnectionConnection",
        nextToken?: string | null,
      } | null,
      subcategories?:  {
        __typename: "ModelSubcategoryConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        subcategoryId: string,
        name: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSubcategorySubscription = {
  onDeleteSubcategory?:  {
    __typename: "Subcategory",
    id: string,
    categoryId: string,
    categoryOwnerId: string,
    name: string,
    notes?: string | null,
    itemOrder?: Array< string > | null,
    parentId?: string | null,
    subcategoryOrder?: Array< string > | null,
    parent?:  {
      __typename: "Subcategory",
      id: string,
      categoryId: string,
      categoryOwnerId: string,
      name: string,
      notes?: string | null,
      itemOrder?: Array< string > | null,
      parentId?: string | null,
      subcategoryOrder?: Array< string > | null,
      parent?:  {
        __typename: "Subcategory",
        id: string,
        categoryId: string,
        categoryOwnerId: string,
        name: string,
        notes?: string | null,
        itemOrder?: Array< string > | null,
        parentId?: string | null,
        subcategoryOrder?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      children?:  {
        __typename: "ModelSubcategoryConnection",
        nextToken?: string | null,
      } | null,
      category?:  {
        __typename: "Category",
        ownerId: string,
        id: string,
        name: string,
        subcategoryOrder: Array< string >,
        createdAt: string,
        updatedAt: string,
      } | null,
      items?:  {
        __typename: "ModelItemConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    children?:  {
      __typename: "ModelSubcategoryConnection",
      items:  Array< {
        __typename: "Subcategory",
        id: string,
        categoryId: string,
        categoryOwnerId: string,
        name: string,
        notes?: string | null,
        itemOrder?: Array< string > | null,
        parentId?: string | null,
        subcategoryOrder?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    category?:  {
      __typename: "Category",
      ownerId: string,
      id: string,
      name: string,
      subcategoryOrder: Array< string >,
      owner?:  {
        __typename: "Owner",
        level: OwnershipLevel,
        id?: string | null,
      } | null,
      templates?:  {
        __typename: "ModelTemplateCategoryConnectionConnection",
        nextToken?: string | null,
      } | null,
      subcategories?:  {
        __typename: "ModelSubcategoryConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    items?:  {
      __typename: "ModelItemConnection",
      items:  Array< {
        __typename: "Item",
        id: string,
        subcategoryId: string,
        name: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTemplateSubscription = {
  onCreateTemplate?:  {
    __typename: "Template",
    ownerId: string,
    id: string,
    name: string,
    categoryOrder: Array< string >,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    categories?:  {
      __typename: "ModelTemplateCategoryConnectionConnection",
      items:  Array< {
        __typename: "TemplateCategoryConnection",
        templateOwnerId: string,
        templateId: string,
        categoryOwnerId: string,
        categoryId: string,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTemplateSubscription = {
  onUpdateTemplate?:  {
    __typename: "Template",
    ownerId: string,
    id: string,
    name: string,
    categoryOrder: Array< string >,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    categories?:  {
      __typename: "ModelTemplateCategoryConnectionConnection",
      items:  Array< {
        __typename: "TemplateCategoryConnection",
        templateOwnerId: string,
        templateId: string,
        categoryOwnerId: string,
        categoryId: string,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTemplateSubscription = {
  onDeleteTemplate?:  {
    __typename: "Template",
    ownerId: string,
    id: string,
    name: string,
    categoryOrder: Array< string >,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    categories?:  {
      __typename: "ModelTemplateCategoryConnectionConnection",
      items:  Array< {
        __typename: "TemplateCategoryConnection",
        templateOwnerId: string,
        templateId: string,
        categoryOwnerId: string,
        categoryId: string,
        id: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTemplateCategoryConnectionSubscription = {
  onCreateTemplateCategoryConnection?:  {
    __typename: "TemplateCategoryConnection",
    templateOwnerId: string,
    templateId: string,
    categoryOwnerId: string,
    categoryId: string,
    template:  {
      __typename: "Template",
      ownerId: string,
      id: string,
      name: string,
      categoryOrder: Array< string >,
      owner?:  {
        __typename: "Owner",
        level: OwnershipLevel,
        id?: string | null,
      } | null,
      categories?:  {
        __typename: "ModelTemplateCategoryConnectionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    category:  {
      __typename: "Category",
      ownerId: string,
      id: string,
      name: string,
      subcategoryOrder: Array< string >,
      owner?:  {
        __typename: "Owner",
        level: OwnershipLevel,
        id?: string | null,
      } | null,
      templates?:  {
        __typename: "ModelTemplateCategoryConnectionConnection",
        nextToken?: string | null,
      } | null,
      subcategories?:  {
        __typename: "ModelSubcategoryConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTemplateCategoryConnectionSubscription = {
  onUpdateTemplateCategoryConnection?:  {
    __typename: "TemplateCategoryConnection",
    templateOwnerId: string,
    templateId: string,
    categoryOwnerId: string,
    categoryId: string,
    template:  {
      __typename: "Template",
      ownerId: string,
      id: string,
      name: string,
      categoryOrder: Array< string >,
      owner?:  {
        __typename: "Owner",
        level: OwnershipLevel,
        id?: string | null,
      } | null,
      categories?:  {
        __typename: "ModelTemplateCategoryConnectionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    category:  {
      __typename: "Category",
      ownerId: string,
      id: string,
      name: string,
      subcategoryOrder: Array< string >,
      owner?:  {
        __typename: "Owner",
        level: OwnershipLevel,
        id?: string | null,
      } | null,
      templates?:  {
        __typename: "ModelTemplateCategoryConnectionConnection",
        nextToken?: string | null,
      } | null,
      subcategories?:  {
        __typename: "ModelSubcategoryConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTemplateCategoryConnectionSubscription = {
  onDeleteTemplateCategoryConnection?:  {
    __typename: "TemplateCategoryConnection",
    templateOwnerId: string,
    templateId: string,
    categoryOwnerId: string,
    categoryId: string,
    template:  {
      __typename: "Template",
      ownerId: string,
      id: string,
      name: string,
      categoryOrder: Array< string >,
      owner?:  {
        __typename: "Owner",
        level: OwnershipLevel,
        id?: string | null,
      } | null,
      categories?:  {
        __typename: "ModelTemplateCategoryConnectionConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    category:  {
      __typename: "Category",
      ownerId: string,
      id: string,
      name: string,
      subcategoryOrder: Array< string >,
      owner?:  {
        __typename: "Owner",
        level: OwnershipLevel,
        id?: string | null,
      } | null,
      templates?:  {
        __typename: "ModelTemplateCategoryConnectionConnection",
        nextToken?: string | null,
      } | null,
      subcategories?:  {
        __typename: "ModelSubcategoryConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateWorkflowSubscription = {
  onCreateWorkflow?:  {
    __typename: "Workflow",
    id: string,
    ownerId: string,
    name: string,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateWorkflowSubscription = {
  onUpdateWorkflow?:  {
    __typename: "Workflow",
    id: string,
    ownerId: string,
    name: string,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteWorkflowSubscription = {
  onDeleteWorkflow?:  {
    __typename: "Workflow",
    id: string,
    ownerId: string,
    name: string,
    owner?:  {
      __typename: "Owner",
      level: OwnershipLevel,
      id?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};
