import React, { useEffect, useState } from "react";
import { Alert, Card, CardBody, Col, Container, Label, Row } from "reactstrap";
import MetaTags from "react-meta-tags";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

// action
import { userRecoverPassword } from "../../store/actions";

// import images
import homeImage from "../../assets/images/home-magnifier.png";
import horizonLogo from "../../assets/images/horizon-logo-no-text.svg";
import npiLogo from "assets/images/npi-logo-sm.png";
import { PasswordInput, TextInput } from "components/Fields";

import PrivacyModal from "pages/PrivacyPolicy/components/privacyModal";
import EulaModal from "pages/EULA/components/eulaModal";

export default function ForgetPasswordPage(props) {
  const { forgetError, forgetSuccessMsg } = useSelector(
    state => state.ForgetPassword
  );
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  let logo = horizonLogo;
  let title = "Horizon";
  let copyright =
    "© " + new Date().getFullYear() + " Carson Dunlop & Associates";
  if (window.location.href.indexOf("npi") !== -1) {
    logo = npiLogo;
    title = "NPI";
    copyright =
      "© " + new Date().getFullYear() + " National Property Inspections";
  }
  const defaultState = {
    logo,
    title,
    copyright,
  };
  const [pageState, setPageState] = useState(defaultState);

  // componentDidMount
  useEffect(() => {
    const search = props.location.search;
    const code = new URLSearchParams(search).get("code");
    const confirm = new URLSearchParams(search).get("confirm");
    const email = new URLSearchParams(search).get("email");
    setPageState({
      ...pageState,
      email: email,
      code: code,
      confirm: confirm,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <MetaTags>
        <title>
          {t("forgot_password_page_title", "Forgot Password")} |{" "}
          {pageState.title}
        </title>
      </MetaTags>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-white p-4">
                        <h5 className="text-white">
                          {pageState.confirm
                            ? t("new_user_new_password_title", "Set password")
                            : t("password_reset", "Password Reset")}
                        </h5>
                        <p>
                          {pageState.confirm
                            ? t(
                                "new_user_new_password_details",
                                "Please enter a password to use for logging in"
                              )
                            : t(
                                "password_reset_new",
                                "Enter the details from your email to reset your password"
                              )}
                        </p>
                      </div>
                    </Col>
                    <Col
                      className="position-absolute mr-3 col-6"
                      style={{
                        right: "0",
                        top: "20px",
                      }}
                    >
                      <img src={homeImage} className="img-fluid" alt="" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="auth-logo mt-n4">
                    <Link to="/" className="auth-logo-dark">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-white">
                          <img
                            src={pageState.logo}
                            alt="Horizon Logo"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        email: (pageState && pageState.email) || "",
                        code: (pageState && pageState.code) || "",
                        password: "",
                        confirmPassword: "",
                        confirm: (pageState && pageState.confirm) || false,
                        eula: false,
                        privacy: false,
                      }}
                      validationSchema={Yup.object().shape({
                        email: Yup.string().required(
                          t("email_error_blank", "Please enter your email")
                        ),
                        password: Yup.string().required(
                          t(
                            "error_password_invalid",
                            "Please Enter Valid Password"
                          )
                        ),
                        eula: Yup.boolean().when("confirm", {
                          is: true,
                          then: Yup.boolean().oneOf(
                            [true],
                            t("error_eula", "Please accept the EULA")
                          ),
                        }),
                        privacy_policy: Yup.boolean().when("confirm", {
                          is: true,
                          then: Yup.boolean().oneOf(
                            [true],
                            t(
                              "error_privacy",
                              "Please accept the Privacy Policy"
                            )
                          ),
                        }),
                        confirmPassword: Yup.string()
                          .oneOf(
                            [Yup.ref("password"), null],
                            t(
                              "error_passwords_do_not_match",
                              "Passwords do not match"
                            )
                          )
                          .required(
                            t(
                              "error_confirm_password_blank",
                              "Please confirm your new password"
                            )
                          ),
                      })}
                      onSubmit={values => {
                        values.confirm = pageState.confirm;
                        dispatch(userRecoverPassword(values, history));
                      }}
                    >
                      {({ errors, touched, values }) => (
                        <Form className="form-horizontal">
                          <div className="mb-3">
                            <TextInput
                              name="email"
                              type="text"
                              label={t("email", "Email")}
                              showErrors={true}
                              formik={{ values, errors, touched }}
                            />
                          </div>
                          {!pageState.confirm && (
                            <div className="mb-3">
                              <TextInput
                                name="code"
                                type="text"
                                label={t("email_code", "Recovery code")}
                                showErrors={true}
                                formik={{ values, errors, touched }}
                              />
                            </div>
                          )}
                          <div className="mb-3">
                            <PasswordInput
                              name="password"
                              label={t("new_password", "New password")}
                              showErrors={true}
                              formik={{ values, errors, touched }}
                              allowShowPassword={true}
                            />
                          </div>
                          <div className="mb-3">
                            <PasswordInput
                              name="confirmPassword"
                              label={t(
                                "confirm_new_password",
                                "Confirm new password"
                              )}
                              showErrors={true}
                              formik={{ values, errors, touched }}
                              allowShowPassword={true}
                            />
                          </div>
                          {pageState.confirm && (
                            <>
                              <div className="mt-4">
                                <Field
                                  type="checkbox"
                                  name="privacy_policy"
                                  className={
                                    "mr-2" +
                                    (errors.privacy_policy &&
                                    touched.privacy_policy
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <Label className="mb-0" for="privacy_policy">
                                  I accept the
                                  <PrivacyModal
                                    linkLabel={t(
                                      "privacy_policy",
                                      "Privacy Policy"
                                    )}
                                    modalTitle={t(
                                      "privacy_policy",
                                      "Privacy Policy"
                                    )}
                                    size="xl"
                                  ></PrivacyModal>
                                </Label>
                                <ErrorMessage
                                  name="privacy_policy"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="mt-1 mb-4">
                                <Field
                                  type="checkbox"
                                  name="eula"
                                  className={
                                    "mr-2 " +
                                    (errors.eula && touched.eula
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <Label className="mb-0" for="eula">
                                  I accept the
                                  <EulaModal
                                    linkLabel={t(
                                      "eula",
                                      "End User License Agreement (EULA)"
                                    )}
                                    modalTitle={t(
                                      "eula",
                                      "End User License Agreement (EULA)"
                                    )}
                                    size="xl"
                                  ></EulaModal>
                                </Label>
                                <ErrorMessage
                                  name="eula"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </>
                          )}
                          <div className="text-end">
                            <button
                              className="btn btn-primary w-md"
                              type="submit"
                            >
                              {pageState.confirm
                                ? t("set_new_password", "Set password")
                                : t("reset_password", "Reset my password")}
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  <Link to="/login" className="fw-medium">
                    {t("go_back_to_login", "Go back to login")}
                  </Link>
                </p>
                <p>{pageState.copyright}</p>
                <PrivacyModal
                  linkLabel={t("privacy_policy", "Privacy Policy")}
                  modalTitle={t("privacy_policy", "Privacy Policy")}
                  size="xl"
                ></PrivacyModal>
                |
                <EulaModal
                  linkLabel={t("eula_short", "EULA")}
                  modalTitle={t("eula", "End User License Agreement (EULA)")}
                  size="xl"
                ></EulaModal>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
