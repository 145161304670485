import {
  useQueryClient,
  useInfiniteQuery,
  useMutation,
  UseInfiniteQueryResult,
} from "@tanstack/react-query";
import { useMemo } from "react";
import toast from "toastr";

import {
  deleteCategoryFn,
  createCategoryFn,
  updateCategoryFn,
} from "./effects";
import { useProfile } from "hooks/profile";
import { getCategories } from "./fetchers";
import { OwnershipLevel, Category } from "API";

export function useCategories() {
  const queryClient = useQueryClient();
  const { myId, franchiseeId, createOwnerId } = useProfile();
  const company = useInfiniteQuery(
    ["categories", createOwnerId(OwnershipLevel.COMPANY)],
    getCategories,
    { enabled: Boolean(myId), getNextPageParam: last => last.nextToken }
  );
  const franchisee = useInfiniteQuery(
    ["categories", createOwnerId(OwnershipLevel.FRANCHISEE)],
    getCategories,
    { enabled: Boolean(myId), getNextPageParam: last => last.nextToken }
  );
  const user = useInfiniteQuery(
    ["categories", createOwnerId(OwnershipLevel.USER)],
    getCategories,
    { enabled: Boolean(myId), getNextPageParam: last => last.nextToken }
  );

  const createCategory = useMutation(createCategoryFn, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        "categories",
        createOwnerId(OwnershipLevel.USER),
      ]);
      toast.success("Category created");
    },
    onError(error) {
      console.error(error);
      toast.error("Failed to create new category");
    },
  });

  const renameCategory = useMutation(updateCategoryFn, {
    onSuccess: (_, input) => {
      queryClient.invalidateQueries(["categories", input.ownerId]);
      toast.success("Category renamed");
    },
    onError(error) {
      console.error(error);
      toast.error("Failed to rename category");
    },
  });

  const deleteCategory = useMutation(deleteCategoryFn, {
    onSuccess: (_, input) => {
      console.log("Category", input);

      console.log(queryClient.getQueryCache());
      queryClient.invalidateQueries(["categories", input.ownerId]);
      toast.success("Category deleted");
    },
    onError(error) {
      console.error(error);
      toast.error("Failed to delete category");
    },
  });

  const byOwner = useMemo(() => {
    const map = new Map<
      OwnershipLevel,
      UseInfiniteQueryResult<{ nextToken?: string; categories: Category[] }>
    >();

    map.set(OwnershipLevel.COMPANY, company);
    map.set(OwnershipLevel.USER, user);

    if (franchiseeId) map.set(OwnershipLevel.FRANCHISEE, franchisee);

    return map;
  }, [company, franchiseeId, franchisee, user]);

  const all = useMemo(() => {
    const categories: Category[] = [];

    for (const query of byOwner.values()) {
      for (const page of query.data?.pages ?? []) {
        categories.push(...page.categories);
      }
    }

    return categories;
  }, [byOwner]);

  return {
    all,
    byOwner,
    createCategory,
    renameCategory,
    deleteCategory,
  };
}
