import { useState } from "react";
import { useTranslation } from "react-i18next";
import ServiceAreaForm from "pages/Company/Team/components/forms/_serviceAreaForm";
import { Collapse } from "reactstrap";

export default function SectionServiceArea() {
  const [isOpen, setIsOpen] = useState(true);
  const { t } = useTranslation();
  function toggle() {
    setIsOpen(isOpen => !isOpen);
  }

  return (
    <div className="accordion card mb-0 pb-0">
      <div className={"accordion-item " + (isOpen ? "active" : "")}>
        <h3
          className="accordion-header"
          id="service-area"
          onClick={() => toggle()}
        >
          {t("invite_member_service_area_accordion_title", "Service Area")}
        </h3>

        <Collapse isOpen={isOpen} className="accordion-collapse">
          <div className="accordion-body" style={{ backgroundColor: "white" }}>
            <ServiceAreaForm />
          </div>
        </Collapse>
      </div>
    </div>
  );
}
