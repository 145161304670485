// Hooks
import { Form, Formik } from "formik";
import { useProfile } from "hooks/profile";
import { useTranslation } from "react-i18next";
import { Label, Input, Row, TabPane, Button } from "reactstrap";
import "reactstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css";
import toastr from "toastr";

import { API, Auth, Storage } from "aws-amplify";
import noAvatar from "assets/images/no-avatar.png";

import { useHistory } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import ProfileForm from "./forms/_formProfile";
import StickyOnChange from "components/Common/StickyOnChange";
import LeavingForm from "components/Common/LeavingForm";

const TabProfile = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const profile = useProfile();
  const queryClient = useQueryClient();

  const [photo, setPhoto] = useState(noAvatar);
  const [uploadPhoto, setUploadPhoto] = useState();

  const saveProfile = useMutation(
    values => {
      return profile.save(values);
    },
    {
      onSuccess: () => {
        toastr.success(
          t("profile_saved_success", "Profile saved successfully")
        );
        queryClient.invalidateQueries(["profile"]);
      },
    }
  );

  const { isLoading, data: profileData } = useQuery(["profile"], profile.fetch);

  useEffect(() => {
    async function updateImage() {
      if (!isLoading) {
        let image = noAvatar;
        if (props.editing) {
          if (props.editing.photo) {
            image = props.editing.photo;
          } else {
            image = noAvatar;
          }
        } else if (profileData.photo && profileData.photo !== "") {
          image = profileData.photo;
        }
        if (image.indexOf("http") === -1 && image !== noAvatar) {
          image = await Storage.get(image, {
            level: "public",
          });
        }
        setPhoto(image);
      }
    }
    updateImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  function handlePhotoChange(e, formik) {
    formik.setFieldValue("photo", URL.createObjectURL(e.target.files[0]));
    setUploadPhoto(e.target.files[0]);
    setPhoto(URL.createObjectURL(e.target.files[0]));
  }

  async function handleFormSubmit(values) {
    let response = false;
    if (uploadPhoto) {
      response = await uploadFile(uploadPhoto);
      values.photo = response.key;
      setUploadPhoto(null);
    } else if (window.location.pathname === "/company/team/edit") {
      const targetUser = await API.get(
        "UserMeta",
        "/userMeta/" + props.editing.id
      );
      values.photo = targetUser[0].photo;
    } else {
      values.photo = profileData.photo;
    }
    saveProfile.mutate({ ...values });
  }

  async function uploadFile(file) {
    const splitName = file.name.split(".");
    const ext = splitName[splitName.length - 1];
    let userId;
    if (window.location.pathname === "/company/team/edit") {
      userId = props.editing.id;
    } else {
      const user = await Auth.currentUserInfo();
      userId = user["username"];
    }
    const key = userId + "-avatar." + ext;
    await Storage.put(key, file, {
      level: "public",
      contentType: file.type,
    });
    const newPhoto = await Storage.get(key, {
      level: "public",
    });
    return { key, newPhoto };
  }

  let page = "settings";
  if (history.location.state) {
    page = "edit_team";
  } else if (history.location.pathname.indexOf("invite") !== -1) {
    page = "invite_team";
  }

  return !isLoading ? (
    <TabPane tabId="profile">
      <Formik
        initialValues={props.editing || profileData}
        enableReinitialize={true}
        onSubmit={handleFormSubmit}
        validationSchema={profile.validation}
      >
        {formik => (
          <Form>
            <h2>
              {window.location.pathname === "/company/team/edit" &&
              profileData.id !== props.editing.id
                ? `Edit ${props.editing.firstName} ${props.editing.lastName} profile`
                : t("personal_settings_profile_tab_title", "Edit my profile")}
              {page === "invite_team" ? (
                <p className="mt-2">
                  {t(
                    "invite_team_member_description",
                    "Enter in the team member's name and contact information. At a minimum a valid and unique email is required for the user to be able to log in to Horizon. Once you've saved your team member, an invitation will be sent via email."
                  )}
                </p>
              ) : (
                ""
              )}
            </h2>
            <p className="text-muted mt-2 mb-2">
              {t(
                "profile_picture_description",
                "This photo is only used as your avatar within the software."
              )}
            </p>
            <div className="row mx-0">
              <div className="col-md-3 p-3 border">
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    className="avatar-lg p-2 w-100 h-100"
                    style={{ maxWidth: "150px" }}
                    src={photo}
                    alt={t("user_photo_alt", "User photo")}
                  />
                </div>
              </div>
              <div className="col-md-9 d-flex flex-column justify-content-center border p-3">
                <Label for="avatar_upload">
                  {t("upload_new_photo", "Upload a new photo")}
                </Label>
                <div className="mb-2 text-muted">
                  {t(
                    "upload_new_photo_details",
                    "Picture should be no larger than 512px x 512px"
                  )}
                </div>
                <div>
                  <Input
                    type="file"
                    id="avatar_upload"
                    name="avatar_upload"
                    onChange={e => {
                      handlePhotoChange(e, formik);
                    }}
                  />
                </div>
              </div>
            </div>
            <Row>
              <ProfileForm loginUser={profileData} />
            </Row>
            <div className="mt-5">
              <Button color="primary" type="submit">
                {t("save_changes", "Save Changes")}
              </Button>
              <LeavingForm formikProps={formik} />
            </div>
            <StickyOnChange formikProps={formik} />
          </Form>
        )}
      </Formik>
    </TabPane>
  ) : (
    ""
  );
};

export default TabProfile;
