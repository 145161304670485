import PropTypes from "prop-types";
import React, { Component } from "react";
import { Container } from "reactstrap";
import MetaTags from "react-meta-tags";
import Breadcrumb from "components/Common/Breadcrumb";
import { withTranslation } from "react-i18next";

import InspectionsTable from "./components/_inspectionsTable";

class Inspectionlisting extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>
              {this.props.t("inspectionlisting_page_title", "Inspections")} |
              Horizon Core
            </title>
          </MetaTags>
          <Container fluid={true}>
            {/* Render Breadcrumb */}
            <Breadcrumb
              title={this.props.t(
                "inspectionlisting_page_title",
                "My Inspections"
              )}
              icon="bx"
              breadcrumbItems={[
                {
                  uri: "/inspections",
                  text: this.props.t("inspections", "Inspections"),
                  active: true,
                },
                {
                  uri: "/inspections/listing",
                  text: this.props.t("listing", "Listing"),
                  active: true,
                },
              ]}
            />
            <InspectionsTable />
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Inspectionlisting.propTypes = {
  error: PropTypes.any,
  success: PropTypes.any,
  t: PropTypes.any,
};

export default withTranslation()(Inspectionlisting);
