import { GraphQLQuery, graphqlOperation } from "@aws-amplify/api";
import { QueryFunction, QueryFunctionContext } from "@tanstack/react-query";
import {
  CreateDiscountInput,
  CreateDiscountMutation,
  DeleteDiscountMutation,
  Discount,
  DiscountByIdQuery,
  ListDiscountsQuery,
  UpdateDiscountInput,
  UpdateDiscountMutation,
} from "API";
import { API } from "aws-amplify";
import {
  createDiscount,
  deleteDiscount,
  updateDiscount,
} from "graphql/mutations";
import { discountById, listDiscounts } from "graphql/queries";
import { getAccessToken } from "helpers/graphql";

export async function getDiscounts({
  queryKey,
}: QueryFunctionContext<["discounts", string]>) {
  const res = await API.graphql<GraphQLQuery<ListDiscountsQuery>>(
    graphqlOperation(
      listDiscounts,
      { ownerId: queryKey[1] },
      await getAccessToken()
    )
  );

  const discounts: Discount[] = [];
  for (const discount of res.data?.listDiscounts?.items ?? []) {
    if (discount) discounts.push({ ...discount });
  }

  return {
    discounts,
    nextToken: res.data?.listDiscounts?.nextToken ?? undefined,
  };
}

export async function addDiscountFn(input: CreateDiscountInput) {
  const res = await API.graphql<GraphQLQuery<CreateDiscountMutation>>(
    graphqlOperation(
      createDiscount,
      {
        input,
      },
      await getAccessToken()
    )
  );

  return res.data?.createDiscount;
}

export async function deleteDiscountFn(discount: {
  id: string;
  ownerId: string;
}) {
  await API.graphql<GraphQLQuery<DeleteDiscountMutation>>(
    graphqlOperation(
      deleteDiscount,
      {
        input: {
          id: discount.id,
          ownerId: discount.ownerId,
        },
      },
      await getAccessToken()
    )
  );
}

export async function updateDiscountFn(input: UpdateDiscountInput) {
  await API.graphql<GraphQLQuery<UpdateDiscountMutation>>(
    graphqlOperation(
      updateDiscount,
      {
        input,
      },
      await getAccessToken()
    )
  );
}

export const getDiscountById: QueryFunction<
  Discount,
  ["discounts", string]
> = async ({ queryKey }) => {
  const res = await API.graphql<GraphQLQuery<DiscountByIdQuery>>(
    graphqlOperation(discountById, { id: queryKey[1] }, await getAccessToken())
  );

  if (res.data?.discountById?.items[0]) {
    return {
      ...res.data?.discountById.items[0],
    };
  } else {
    throw new Error("Discount not found");
  }
};
