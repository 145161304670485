import { useState } from "react";
import { Collapse } from "reactstrap";
import Availability from "components/Custom/Availabilty/Availability";
import { useTranslation } from "react-i18next";
import { FormikValues, useFormikContext } from "formik";

export default function SectionAvailability() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { setFieldValue } = useFormikContext();

  function toggle() {
    setIsOpen(isOpen => !isOpen);
  }

  function handleOnChange(values: FormikValues) {
    setFieldValue("availability", values);
  }

  return (
    <div className="accordion card mb-0 pb-0">
      <div className={"accordion-item " + (isOpen ? "active" : "")}>
        <h3
          className="accordion-header"
          id="availability"
          onClick={() => toggle()}
        >
          {t("availability", "Availability")}
        </h3>
        <Collapse isOpen={isOpen} className="accordion-collapse">
          <div
            className="accordion-body pb-5"
            style={{ backgroundColor: "white" }}
          >
            <Availability show={isOpen} handleChange={handleOnChange} />
          </div>
        </Collapse>
      </div>
    </div>
  );
}
