import { BaseHTMLAttributes, PropsWithChildren } from "react";

export function ErrorPane(props: PropsWithChildren<BaseHTMLAttributes<{}>>) {
  return (
    <div
      className="w-100 d-flex justify-content-center text-white bg-danger p-4 rounded"
      style={props.style}
    >
      <p className="m-0">{props.children}</p>
    </div>
  );
}
