import { useTranslation } from "react-i18next";
const days = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

export function useDefaultBusinessHours() {
  const { t } = useTranslation();
  return [
    {
      name: t("sunday", "Sunday"),
      times: [],
    },
    {
      name: t("monday", "Monday"),
      times: [
        {
          start: "08:30",
          end: "17:00",
        },
      ],
    },
    {
      name: t("tuesday", "Tuesday"),
      times: [
        {
          start: "08:30",
          end: "17:00",
        },
      ],
    },
    {
      name: t("wednesday", "Wednesday"),
      times: [
        {
          start: "08:30",
          end: "17:00",
        },
      ],
    },
    {
      name: t("thursday", "Thursday"),
      times: [
        {
          start: "08:30",
          end: "17:00",
        },
      ],
    },
    {
      name: t("friday", "Friday"),
      times: [
        {
          start: "08:30",
          end: "17:00",
        },
      ],
    },
    {
      name: t("saturday", "Saturday"),
      times: [],
    },
  ];
}

export { days };
