import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { StandardDropdown } from "./Standard";

const CountryDropdown = props => {
  const { t } = useTranslation();
  const formik = useFormikContext();

  return (
    <StandardDropdown
      name={props.name}
      label={t("country", "Country") + "*"}
      showErrors={props.showErrors}
      formik={formik}
      options={[
        {
          label: t("country_select_default", "Select country"),
          value: "",
        },
        {
          label: t("country_canada", "Canada"),
          value: "Canada",
        },
        {
          label: t("country_usa", "United States"),
          value: "United States",
        },
        {
          label: t(
            "country_usa_islands",
            "United States minor outlying islands"
          ),
          value: "United States Minor Outlying Islands",
        },
      ]}
      onChange={e => {
        formik.handleChange(e);
        if (typeof props.onChange === "function") {
          props.onChange(e);
        }
      }}
      onBlur={formik.handleBlur}
      disabled={props.disabled}
    />
  );
};

CountryDropdown.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  showErrors: PropTypes.bool,
  disabled: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
};

export { CountryDropdown };
