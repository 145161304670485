import { useQuery } from "@tanstack/react-query";
import { API } from "aws-amplify";
import Icon from "components/Common/Icons";
import { StandardMap } from "components/Custom/Maps/Standard";
import { DisplayAddress } from "components/Fields/Address";
import { formatDate } from "devextreme/localization";
import { Distance } from "hooks/distance";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Badge, Button, Card, CardBody, Col, Row } from "reactstrap";

export default function JobDetails(props) {
  const { t } = useTranslation();
  const history = useHistory();
  let [workOrder, setWorkOrder] = useState(undefined);
  let [companyDetails, setCompanyDetails] = useState(undefined);

  useQuery(["company_details"], async () => API.get("Company", "/company"), {
    onSettled: data => {
      setCompanyDetails(data[[0]]);
    },
  });

  useQuery(
    ["work_order"],
    async () => {
      return API.get("WorkOrder", "/work/order/" + props.id);
    },
    {
      onSettled: data => {
        setWorkOrder(data);
      },
    }
  );

  function editWorkOrder(e) {
    history.push("/work/order/" + encodeURI(props.workOrder.id));
  }

  function editReport(e) {
    alert("Not yet implemented");
  }

  function getContractStatus() {
    switch (workOrder.contractOptions) {
      case undefined:
      case "":
      case "awaitingAcceptance":
        return (
          <Badge color="warning" className="transparent text-sm">
            {t("awaiting_acceptance", "Not accepted")}
          </Badge>
        );
      case "confirmedInspection":
        return (
          <Badge color="success" className="transparent text-sm">
            {t("confirmed_inspection", "Confirmed inspection")}
          </Badge>
        );
      case "rejected":
        return (
          <Badge color="errors" className="transparent text-sm">
            {t("contract_rejected", "Contract rejected")}
          </Badge>
        );
      default:
        break;
    }
  }

  if (workOrder === undefined || companyDetails === undefined)
    return <React.Fragment>{t("loading", "Loading...")}</React.Fragment>;

  return (
    <React.Fragment>
      <Row>
        <Col className="pr-1">
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <h2>
                    {t("scheduled_on", "Scheduled on")}
                    {": "}
                    {formatDate(
                      new Date(workOrder.inspection.inspectionDate),
                      "shortDate"
                    )}
                    {" - "}
                    {formatDate(
                      new Date(workOrder.inspection.inspectionDate),
                      "shortTime"
                    )}
                    {" ("}
                    {Intl.NumberFormat().format(
                      workOrder.inspection.duration
                    )}{" "}
                    {t("hour", {
                      count: workOrder.inspection.duration,
                    })}
                    {")"}
                  </h2>
                </Col>
              </Row>
              <h3 className="mt-3">{t("clients", "Clients")}</h3>
              {workOrder.clientInformation.map((client, index) => {
                return (
                  <Row key={index}>
                    <Col>
                      {client.firstname} {client.lastname}
                    </Col>
                    <Col>
                      {client.primaryPhone}{" "}
                      <a href={"tel:" + client.primaryPhone}>
                        <Icon name="phone"></Icon>
                      </a>
                    </Col>
                  </Row>
                );
              })}
              <Row className="mt-3">
                <Col>
                  {t("job_type", "Job type")}: {t("inspection", "Inspection")}
                </Col>
              </Row>
              <Row>
                <Col>{t("job_description", "Job description")}</Col>
                <Col>
                  {t("lock_box_code", "Lock box code")}:{" "}
                  {workOrder.propertyDescription?.lockboxCode || ""}
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  {t("contract_status", "Contract status")}:{" "}
                  {getContractStatus()}{" "}
                </Col>
                <Col>
                  {t("payment_status", "Payment status")}:{" "}
                  <Badge color="danger" className="transparent text-sm">
                    {t("payment_not_made", "Not paid")}
                  </Badge>
                </Col>
              </Row>
              <Row>
                <Col className="mt-3">
                  <h3>{t("address", "Address")}</h3>
                </Col>
                <Col className="mt-3">
                  <h3>{t("distance", "Distance")}</h3>
                </Col>
              </Row>
              <Row>
                <Col>{DisplayAddress(workOrder.inspection.address)}</Col>
                <Col>
                  <Distance
                    startAddress={DisplayAddress(companyDetails)}
                    endAddress={DisplayAddress(workOrder.inspection.address)}
                    system="metric"
                  />
                </Col>
              </Row>
              <Row>
                <Col className="mt-3">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={
                      "https://www.google.com/maps/dir/?api=1&origin=" +
                      encodeURI(DisplayAddress(companyDetails)) +
                      "&destination=" +
                      encodeURI(DisplayAddress(workOrder.inspection.address))
                    }
                  >
                    <Icon name="map" /> {t("get_directions", "Get directions")}{" "}
                    ({t("opens_new_window", "opens new window")})
                  </a>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Button onClick={editWorkOrder} color="light">
                    {t("review_work_order", "Review work order")}{" "}
                    <Icon
                      name="reviewWorkOrder"
                      hint={t("review_work_order", "Review work order")}
                    />
                  </Button>
                </Col>
                <Col>
                  <Button onClick={editReport} color="light">
                    {t("write_report", "Write report")}{" "}
                    <Icon
                      name="reviewReport"
                      hint={t("write_report", "Write report")}
                    />
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col className="pl-1">
          <Card className="h-100">
            <CardBody>
              <StandardMap
                address={DisplayAddress(workOrder.inspection.address)}
                settings={{
                  zoom: 14,
                }}
              ></StandardMap>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
