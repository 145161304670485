import {
  DeleteSubcategoryInput,
  GetSubcategoryQuery,
  DeleteItemMutation,
  DeleteItemMutationVariables,
  DeleteSubcategoryMutation,
  DeleteSubcategoryMutationVariables,
} from "API";
import API, { graphqlOperation, GraphQLQuery } from "@aws-amplify/api";
import { deleteItem, deleteSubcategory } from "graphql/mutations";
import { getAccessToken } from "helpers/graphql";

const affectedItemsQuery = /* GraphQL */ `
  query GetAffectedItems($subcategoryId: ID!) {
    getSubcategory(id: $subcategoryId) {
      id
      items {
        items {
          id
        }
      }
    }
  }
`;

export async function deleteSubcategoryFn(input: DeleteSubcategoryInput) {
  const { data } = await API.graphql<GraphQLQuery<GetSubcategoryQuery>>(
    graphqlOperation(
      affectedItemsQuery,
      {
        subcategoryId: input.id,
      },
      await getAccessToken()
    )
  );

  if (!data?.getSubcategory?.items?.items?.length) {
    throw new Error("Subcategory not found");
  }

  const affectedItems = data.getSubcategory.items.items;

  for (const item of affectedItems) {
    if (!item) continue;
    await API.graphql<GraphQLQuery<DeleteItemMutation>>(
      graphqlOperation(
        deleteItem,
        {
          input: {
            id: item.id,
          },
        } as DeleteItemMutationVariables,
        await getAccessToken()
      )
    );
  }

  await API.graphql<GraphQLQuery<DeleteSubcategoryMutation>>(
    graphqlOperation(
      deleteSubcategory,
      {
        input,
      } as DeleteSubcategoryMutationVariables,
      await getAccessToken()
    )
  );

  return input.id;
}
