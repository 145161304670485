import { takeEvery, put, call } from "redux-saga/effects";

//Account Redux states
import { REGISTER_USER } from "./actionTypes";
import { registerUserFailed, messageHubChange } from "../../actions";

//Include Both Helper File with needed methods
import { getCognitoBackend } from "helpers/cognito_helper";

// initialize relavant method of both Auth
const cognitoBackend = getCognitoBackend();

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "cognito") {
      yield call(cognitoBackend.registerUser, user);
      yield put(
        messageHubChange(
          "To complete registration, please check your email to confirm your account.",
          "success"
        )
      );
      history.push("/");
    }
  } catch (error) {
    yield put(registerUserFailed(error));
  }
}

function* accountSaga() {
  yield takeEvery(REGISTER_USER, registerUser);
}

export default accountSaga;
