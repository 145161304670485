import React, { useRef, useState } from "react";
import { Label } from "reactstrap";
import { Field, ErrorMessage } from "formik";

import classnames from "classnames";
import PasswordChecklist from "react-password-checklist";

function PasswordInput(props) {
  const [showPassword, setShowPassword] = useState(false);
  const passwordRef = useRef(null);
  function toggleShowPassword() {
    setShowPassword(prev => !prev);
  }

  function passwordCheckListShow() {
    const showPages = ["/register", "/recover-password", "/my/settings"];
    return (
      showPages.includes(window.location.pathname) &&
      document.activeElement === passwordRef.current &&
      props.name !== "currentPassword" && (
        <div className="password-checklist-wrapper">
          <PasswordChecklist
            className="password-checklist"
            rules={["minLength", "specialChar", "number", "capital", "match"]}
            minLength={8}
            value={
              window.location.pathname !== "/my/settings"
                ? props.formik.values?.password
                : props.formik.values?.newPassword
            }
            valueAgain={props.formik.values?.confirmPassword}
          />
        </div>
      )
    );
  }

  return (
    <React.Fragment>
      {props.label ? (
        <Label for={props.name} className="col-form-label">
          {props.label}
        </Label>
      ) : (
        ""
      )}
      {passwordCheckListShow()}
      <div className="password-block">
        <Field
          name={props.name}
          id={props.name}
          innerRef={passwordRef}
          type={showPassword ? "text" : "password"}
          disabled={props.disabled ? true : false}
          className={
            "form-control" +
            (props.formik.errors[props.name] && props.formik.touched[props.name]
              ? " is-invalid"
              : "")
          }
        />
        {props.allowShowPassword ? (
          <button
            tabIndex="-1"
            className={
              "btn btn-input" +
              (props.formik.errors[props.name] &&
              props.formik.touched[props.name]
                ? " border-danger"
                : "")
            }
            type="button"
            id="password-addon"
            onClick={toggleShowPassword}
          >
            <i
              className={classnames({
                mdi: true,
                "mdi-eye-off-outline": !showPassword,
                "mdi-eye-outline": showPassword,
              })}
            ></i>
          </button>
        ) : (
          ""
        )}
        {props.showErrors ? (
          <ErrorMessage
            name={props.name}
            component="div"
            className="invalid-feedback"
          />
        ) : (
          ""
        )}
      </div>
    </React.Fragment>
  );
}

export { PasswordInput };
