import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Tooltip } from "reactstrap";

// Redux
import { connect } from "react-redux";

import { withTranslation } from "react-i18next";

class NewInspection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hydrated: false,
      inspections: [],
      inspectors: [],
    };
  }

  chooseInspectors = async () => {
    await this.setState({
      ...this.state,
      showInspectionList: true,
    });
  };

  onTeamSelectChanged = async selectedRows => {
    await this.setState({
      ...this.state,
      inspectors: selectedRows,
    });
  };

  closeTeamSelector = async () => {
    await this.setState({
      ...this.state,
      showInspectionList: false,
    });
  };

  render() {
    return (
      <React.Fragment>
        <Tooltip
          placement="right"
          isOpen={this.state.tt_new_inspection}
          target="new-inspection-btn"
          toggle={() =>
            this.setState({ tt_new_inspection: !this.state.tt_new_inspection })
          }
        >
          {this.props.t("to_be_implemented", "Soon to be implented")}
        </Tooltip>

        <Button color="primary" className="btn btn-sm" id="new-inspection-btn">
          <i className="mdi mdi-email-send-outline me-1"></i>Book New Inspection
        </Button>
        {/* <ModalForm
          text="Book New Inspection"
          icon="mdi mdi-email-send-outline me-1"
          className=""
          buttonLabel="Book New Inspection">
            {this.state.showInspectionList ? (
              <React.Fragment>
              <Button
                onClick={this.closeTeamSelector}
              >
                Submit
              </Button>
              <TeamTable 
                onSelectChange={this.onTeamSelectChanged}
              />
              </React.Fragment>
            ) : (
              <Card>
                <CardBody>
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      inspectionStart: this.state.inspectionStart || ''
                    }}
                    validationSchema={Yup.object().shape({
                      inspectionStart: Yup.string().required("Inspection start is blank"),
                    })}
                    onSubmit={this.handleFormSubmit}>
                    {({ values, errors, touched, handleChange, setStatus, handleBlur, setFieldValue }) => (<Form>
                      <Row>
                        <Col sm={6}>
                          <h2>Dates</h2>
                        <label
                          htmlFor="inspectionStart"
                          className="col-form-label"
                        >
                          Inspection Start
                        </label>
                        <input
                          className="form-control"
                          type="datetime-local"
                          defaultValue="2019-08-19T13:45:00"
                          id="inspectionStart"
                        />
                        <br />
                        <label
                          htmlFor="inspectionStart"
                          className="col-form-label"
                        >
                          Inspection End
                        </label>
                        <input
                          className="form-control"
                          type="datetime-local"
                          defaultValue="2019-08-19T13:45:00"
                          id="inspectionEnd"
                        />
                        <h2>Address</h2>
                        <TextInput
                          name="unit"
                          label="Unit"
                          showErrors={true}
                          errors={errors}
                          touched={touched} 
                          placeholder="Unit"
                        />
                        <TextInput
                          name="streetAddress"
                          label="Street Address"
                          showErrors={true}
                          errors={errors}
                          touched={touched} 
                          placeholder="Street Address"
                        />
                        <TextInput
                          name="city"
                          label="City"
                          showErrors={true}
                          errors={errors}
                          touched={touched} 
                          placeholder="City"
                        />
                        <TextInput
                          name="region"
                          label="Region"
                          showErrors={true}
                          errors={errors}
                          touched={touched} 
                          placeholder="Region"
                        />
                        <TextInput
                          name="postCode"
                          label="Post Code"
                          showErrors={true}
                          errors={errors}
                          touched={touched} 
                          placeholder="Post Code"
                        />
                        <TextInput
                          name="country"
                          label="Country"
                          showErrors={true}
                          errors={errors}
                          touched={touched} 
                          placeholder="Country"
                        />
                        </Col>
                        <Col sm={6}>
                          <h2>Inspectors</h2>
                          <Button onClick={this.chooseInspectors}>
                            Add Inspectors
                          </Button>
                          {this.state.inspectors.map((inspector) => (
                            <div key={inspector.id}>{inspector.name}</div>
                          ))}
                          <h2>Clients</h2>
                          <Button>Existing Client</Button><br />
                          <TextInput
                            name="name"
                            label="Name"
                            showErrors={true}
                            errors={errors}
                            touched={touched} 
                            placeholder="Name"
                          />
                          <TextInput
                            name="mobile"
                            label="Mobile Phone"
                            showErrors={true}
                            errors={errors}
                            touched={touched} 
                            placeholder="999-999-9999"
                          />
                          <TextInput
                            name="email"
                            label="Email Address"
                            showErrors={true}
                            errors={errors}
                            touched={touched} 
                            placeholder="email@domain.com"
                          />
                        </Col>
                      </Row>

                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          )}
        </ModalForm> */}
      </React.Fragment>
    );
  }
}

NewInspection.propTypes = {
  error: PropTypes.any,
  success: PropTypes.any,
  t: PropTypes.any,
  hydrateNewInspection: PropTypes.func,
  inspections: PropTypes.array,
  onSelectChange: PropTypes.func,
};

const mapStateToProps = state => {
  return { inspectionList: state.InspectionList };
};

export default withTranslation()(connect(mapStateToProps)(NewInspection));
