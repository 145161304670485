import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";

import { locizePlugin } from "locize";

const language = localStorage.getItem("I18N_LANGUAGE");
if (!language) {
  localStorage.setItem("I18N_LANGUAGE", "en-US");
}

i18n
  .use(detector)
  .use(HttpApi)
  .use(locizePlugin)
  .use(initReactI18next)
  .init({
    // debug: true,
    lng: localStorage.getItem("I18N_LANGUAGE") || "en-US",
    fallbackLng: "en-US",
    defaultNS: "translation",
    loadPath: "/locales/{{lng}}/{{ns}}.json",

    keySeparator: false,

    interpolation: {
      escapeValue: false,
    },
    supportedLngs: ["en-US", "fr-CA", "es-MX"],
  });
i18n.loadNamespaces("snippets");

export default i18n;
