import * as types from "./actionTypes";

export const editCompanySettings = companySettings => {
  return {
    type: types.EDIT_COMPANY_SETTINGS,
    payload: { companySettings },
  };
};

export const editCompanySettingsSuccess = status => {
  return {
    type: types.EDIT_COMPANY_SETTINGS_SUCCESS,
    payload: status,
  };
};

export const hydrateCompanySettings = () => {
  return {
    type: types.HYDRATE_COMPANY_SETTINGS,
  };
};

export const hydrateCompanySettingsSuccess = settings => {
  return {
    type: types.HYDRATE_COMPANY_SETTINGS_SUCCESS,
    payload: settings,
  };
};

export const companySettingsError = error => {
  return {
    type: types.COMPANY_SETTINGS_ERROR,
    payload: error,
  };
};

export const fetchCompanyDiscounts = () => {
  return {
    type: types.FETCH_COMPANY_DISCOUNTS,
  };
};

export const fetchCompanyDiscountsSuccess = discounts => {
  return {
    type: types.FETCH_COMPANY_DISCOUNTS_SUCCESS,
    payload: discounts,
  };
};

export const fetchCompanyDiscountsError = error => {
  return {
    type: types.FETCH_COMPANY_DISCOUNTS_ERROR,
    payload: error,
  };
};

export function createDiscount(discount, history) {
  return {
    type: types.CREATE_DISCOUNT,
    payload: discount,
    history: history,
  };
}

export function resetDiscount() {
  return {
    type: types.RESET_DISCOUNT,
  };
}

export function updateDiscount(discount, history) {
  return {
    type: types.UPDATE_DISCOUNT,
    payload: discount,
    history: history,
  };
}

export function createDiscountSuccess(id, discount) {
  return {
    type: types.CREATE_DISCOUNT_SUCCESS,
    payload: {
      id,
      discount,
    },
  };
}

export function updateDiscountSuccess(id) {
  return {
    type: types.UPDATE_DISCOUNT_SUCCESS,
    payload: {
      id,
      discount: null,
    },
  };
}

export function createDiscountError(error) {
  return {
    type: types.CREATE_DISCOUNT_ERROR,
    payload: error.message,
  };
}

export function fetchDiscount(id, history) {
  return {
    type: types.FETCH_DISCOUNT,
    payload: id,
    history: history,
  };
}

export function fetchDiscountSuccess(discount) {
  return {
    type: types.FETCH_DISCOUNT_SUCCESS,
    payload: discount,
  };
}

export function deleteDiscount(id) {
  return {
    type: types.DELETE_DISCOUNT,
    payload: id,
  };
}

export function deleteDiscountSuccess() {
  return {
    type: types.FETCH_DISCOUNT_SUCCESS,
  };
}
