import { takeEvery, fork, put, call, all } from "redux-saga/effects";

// Login Redux States
import {
  HYDRATE_SNIPPETS,
  HYDRATE_SNIPPET,
  EDIT_SNIPPET,
  ADD_SNIPPET,
} from "./actionTypes";
import {
  hydrateSnippetsSuccess,
  hydrateSnippetSuccess,
  SnippetsError,
  SnippetError,
  messageHubChange,
  messageHubReset,
} from "../actions";

//Include back-end helper component
import { getBackendHelper } from "../../helpers/backend_helper";

const backend = getBackendHelper();

function* hydrateSnippets() {
  try {
    const response = yield call(backend.hydrateSnippets);
    yield put(hydrateSnippetsSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(SnippetsError(error));
  }
}

function* hydrateSnippet(params) {
  try {
    const response = yield call(backend.hydrateSnippet, params.id);
    yield put(hydrateSnippetSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(SnippetError(error));
  }
}

function* editSnippet({ payload: { Snippet } }) {
  try {
    yield call(backend.editSnippet, Snippet);
    hydrateSnippets();
    yield put(messageHubChange("Snippet saved!", "success", true));
    yield put(messageHubReset());
  } catch (error) {
    console.log(error);
    yield put(SnippetsError(error));
  }
}

function* addSnippet({ payload: { Snippet } }, hydrate = true) {
  try {
    yield call(backend.addSnippet, Snippet);
    if (hydrate) hydrateSnippets();
    yield put(messageHubChange("Snippet added!", "success", true));
    yield put(messageHubReset());
  } catch (error) {
    console.log(error);
    yield put(SnippetsError(error));
  }
}

export function* watchSnippets() {
  yield takeEvery(HYDRATE_SNIPPETS, hydrateSnippets);
  yield takeEvery(HYDRATE_SNIPPET, hydrateSnippet);
  yield takeEvery(EDIT_SNIPPET, editSnippet);
  yield takeEvery(ADD_SNIPPET, addSnippet);
}

function* SnippetsSaga() {
  yield all([fork(watchSnippets)]);
}

export default SnippetsSaga;
