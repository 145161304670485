import { useFormikContext } from "formik";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button, Card, CardBody, Col, Row } from "reactstrap";

export default function ButtonSection(props: any) {
  const { t } = useTranslation();
  const history = useHistory();
  const { isValid } = useFormikContext();

  return (
    <Card className="mt-4" noprops="true">
      <CardBody className="">
        <Row>
          <Col sm={12}>
            <div className={!isValid ? "is-invalid" : ""}>
              <Button color="primary" type="submit">
                {props.editing
                  ? t("save_discount", "Save discount")
                  : t("create_discount", "Create discount")}
              </Button>
              <Button
                type="reset"
                color="outline-blue"
                onClick={history.goBack}
                className="ml-2"
              >
                {t("cancel", "Cancel")}
              </Button>
            </div>
            {!isValid ? (
              <div className="invalid-feedback mt-3">
                {t(
                  "form_has_validation_errors",
                  "Could not submit, there are errors within the form."
                )}
              </div>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
