import { useTranslation } from "react-i18next";
import { StandardDropdown } from "components/Fields";
import { Row, Col, Card, CardBody } from "reactstrap";
import StandardSwitches from "components/Fields/Switches/StandardSwitches";
import { useFormikContext } from "formik";

export default function ContactAcceptance(_props) {
  const { t } = useTranslation();
  const formik = useFormikContext();

  const onChangeCommunication = values => {
    formik.setFieldValue("communicationOptions", values);
  };

  return (
    <Row>
      <Col lg={12}>
        <Card className="mb-0 border-bottom">
          <CardBody>
            <Row>
              <Col lg={6}>
                <h2 className="mb-2">
                  {t("contact_acceptance", "Contract acceptance")}
                </h2>
                <div className="note mb-2">
                  {t(
                    "contact_acceptance_note",
                    "This 'placeholder' process allows you to tentatively book the inspection without locking the inspection details or a specific inspection date. Automated email notifications will not be sent to the client until the inspection is confirmed."
                  )}
                </div>
                <StandardDropdown
                  name="contractOptions"
                  options={[
                    {
                      label: t("confirmed_inspection", "Confirmed inspection"),
                      value: "confirmedInspection",
                    },
                    {
                      label: t("awaiting_acceptance", "Awaiting acceptance"),
                      value: "awaitingAcceptance",
                    },
                    {
                      label: t("rejected", "Rejected"),
                      value: "rejected",
                    },
                  ]}
                  value={formik.values.contractOptions}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <Col>
        <Card>
          <CardBody>
            <Row>
              <Col lg={6}>
                <h2 className="mb-2">
                  {t("communication_preferences", "Communication preferences")}
                </h2>
                <StandardSwitches
                  name="communicationOptions"
                  options={[
                    {
                      label: t(
                        "disable_automatic_notifications",
                        "Disable automatic notifications from sending to clients"
                      ),
                      value: "disableAutomaticNotifications",
                      checked:
                        formik?.values?.communicationOptions !== undefined
                          ? formik.values.communicationOptions.includes(
                              "disableAutomaticNotifications"
                            )
                          : false,
                    },
                  ]}
                  onChange={onChangeCommunication}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}
