import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Badge, Button } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import Statuses from "helpers/StaticLists/Statuses";
import noAvatar from "assets/images/no-avatar.png";
import DataGrid from "components/Tables/DataGrid";
import {
  Column,
  Item,
  Pager,
  Paging,
  Scrolling,
  Selection,
  Toolbar,
} from "devextreme-react/data-grid";
import { useTeam } from "hooks/team/team";

export default function TeamTable(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { data: team } = useTeam();
  const defaultState = {
    hydrated: false,
    dataGrid: null,
    rowEvents: {
      onClick: (_e, row) => {
        history.push({
          pathname: "/company/team/edit",
          state: row,
        });
      },
    },
  };
  const [teamData, setTeamData] = useState(defaultState);

  function renderPhoto(data) {
    let photo = data.text;
    if (photo === "") {
      photo = noAvatar;
    }
    return (
      <img className="avatar-xs rounded-circle border" alt="" src={photo}></img>
    );
  }

  function renderName(data) {
    return data.firstName + " " + data.lastName;
  }

  function renderStatus(data) {
    if (data.data?.inactive) {
      return <Badge color="dark">Inactive</Badge>;
    } else {
      const cell = data.text;
      if (cell === undefined || cell === "") return <></>;
      return (
        <Badge
          color={Statuses[cell].color}
          className={"bg-" + Statuses[cell].color}
        >
          {t("status_" + cell)}
        </Badge>
      );
    }
  }

  function editTeamMember(data) {
    history.push({
      pathname: "/company/team/edit",
      state: data.selectedRowsData[0],
    });
  }

  function initializeDataGrid(e) {
    setTeamData(data => ({
      ...data,
      dataGrid: e.component,
    }));
  }

  function searchGrid(e) {
    teamData.dataGrid.searchByText(e.value);
  }

  return (
    <React.Fragment>
      <DataGrid
        dataSource={team}
        search={true}
        rowEvents={teamData.rowEvents}
        allowColumnResizing={true}
        allowResizing={false}
        columnResizingMode="nextColumn"
        onSelectionChanged={editTeamMember}
        onInitialized={initializeDataGrid}
        onRowPrepared={row => {
          if (row?.data?.inactive) {
            row.rowElement.style.backgroundColor = "#f5f5f5";
          }
        }}
      >
        <Toolbar>
          <Item location="before">
            <Link to={"/company/team/invite"}>
              <Button className="btn btn-sm" color="primary" type="button">
                {t("invite_new_member", "Invite new member")}
              </Button>
            </Link>
          </Item>
          <Item
            widget="dxTextBox"
            options={{
              type: "search",
              placeholder: t("search", "Search") + "...",
              onValueChanged: searchGrid,
            }}
            location="after"
          />
        </Toolbar>
        <Scrolling rowRenderingMode="virtual"></Scrolling>
        <Paging defaultPageSize={10} />
        <Pager
          visible={true}
          allowedPageSizes={[5, 10, 20, 50, "all"]}
          displayMode="full"
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
        <Selection mode="single" />
        <Column
          dataField="photo"
          cellRender={renderPhoto}
          headerCellRender={() => {
            /**/
          }}
          allowResizing={false}
          allowSorting={false}
          allowSearch={false}
          width="50"
          alignment="center"
        />
        <Column
          dataField="firstName"
          headerCellRender={() => t("name", "Name")}
          minWidth="150"
          calculateCellValue={renderName}
        />
        <Column
          dataField="region"
          minWidth="150"
          headerCellRender={() => t("region", "Region")}
        />
        <Column
          dataField="role"
          minWidth="150"
          headerCellRender={() => t("role", "Role")}
        />
        <Column
          dataField="createdAt"
          minWidth="100"
          allowSearch={false}
          dataType="date"
          headerCellRender={() => t("date_joined", "Date joined")}
        />
        <Column
          dataField="status"
          minWidth="150"
          allowSearch={false}
          headerCellRender={() => t("status", "Status")}
          cellRender={renderStatus}
        />
      </DataGrid>
    </React.Fragment>
  );
}
