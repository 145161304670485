import { Formik, FormikErrors, Form, Field, ErrorMessage } from "formik";
import { Button, Spinner } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { Template } from "API";
import * as actions from "store/templates/actions";
import { useTemplates, useTemplate } from "hooks/templates";

export function ManageTab({ template }: { template: Template }) {
  const { t } = useTranslation("templates");
  const dispatch = useDispatch();
  const { replace } = useHistory();
  const { deleteTemplate } = useTemplates();
  const { publishTemplate } = useTemplate(template.id);

  return (
    <>
      <h2 className="mb-2">{t("Manage Template")}</h2>
      <p className="mb-4">
        {t(
          "Update information about this template, or modify its availability."
        )}
      </p>
      <section className="mb-4">
        <h3>{t("Rename Template")}</h3>
        <Formik
          enableReinitialize
          validate={values => {
            const errors: FormikErrors<{ name: string }> = {};

            if (!values.name) {
              errors.name = t("A template name is required");
            }

            return errors;
          }}
          initialValues={{ name: template?.name ?? "" }}
          onSubmit={({ name }) => {
            dispatch(
              actions.renameTemplate({
                ownerId: template.ownerId,
                id: template.id,
                name,
              })
            );
          }}
        >
          {({ dirty, errors }) => (
            <Form>
              <div className="d-flex">
                <Field
                  name="name"
                  className={`form-control ${
                    dirty ? (errors.name ? "is-invalid" : "is-valid") : ""
                  }`}
                />
                <Button
                  type="submit"
                  className="ml-2"
                  color="warning"
                  disabled={!dirty || !!errors.name}
                >
                  Rename
                </Button>
              </div>
              <ErrorMessage name="name">
                {msg => <div className="text-danger">{msg}</div>}
              </ErrorMessage>
            </Form>
          )}
        </Formik>
      </section>
      <section className="mb-4">
        <h3>{t("Edit Description")}</h3>
        <p>Not Implemented</p>
      </section>
      {template.owner?.level !== "COMPANY" && (
        <section className="mb-4">
          <h3>{t("Publish Template")}</h3>
          <p>
            {t(
              "Publishing this template will make it available for others within your company to use."
            )}
          </p>
          <p>
            {t(
              "Additionally, all categories under this template will be published as well."
            )}
          </p>
          <Button
            color="warning"
            onClick={() => {
              publishTemplate.mutate();
            }}
          >
            {publishTemplate.isLoading ? (
              <Spinner size="sm" />
            ) : (
              t("Publish Template")
            )}
          </Button>
        </section>
      )}
      <section>
        <h3>{t("Archive/Delete Template")}</h3>
        <Button
          color="danger"
          onClick={async () => {
            await deleteTemplate.mutateAsync({
              id: template.id,
              ownerId: template.ownerId,
            });

            // TODO: Reroute to templates list
            replace(`/report/settings`);
          }}
        >
          {t("Delete Template")}
        </Button>
      </section>
    </>
  );
}
