import React, { useState } from "react";
import { Row, Col, TabPane, Button } from "reactstrap";
import "reactstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css";
import Availabilty from "components/Custom/Availabilty/Availability";
import { useTranslation } from "react-i18next";
import { Formik, Form, FormikValues } from "formik";
import { useProfile } from "hooks/profile";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import toastr from "toastr";
import { hydrateTeam } from "store/actions";
import { useDispatch } from "react-redux";
import {
  AvailabilityObject,
  TimeObject,
} from "components/Custom/Availabilty/_typesAvailability";
import StickyOnChange from "components/Common/StickyOnChange";
import LeavingForm from "components/Common/LeavingForm";

const TabAvailability = (props: {
  editing: {
    availability: AvailabilityObject;
  };
  show: boolean;
}) => {
  const { t } = useTranslation();
  const profile = useProfile();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [invalidDays, setInvalidDays] = useState<string[]>([]);

  const { isLoading, data } = useQuery(["profile"], profile.fetch);

  const saveProfile = useMutation(
    (values: FormikValues) => {
      return profile.save(values);
    },
    {
      onSuccess: () => {
        toastr.success(
          t("profile_saved_success", "Profile saved successfully")
        );
        queryClient.invalidateQueries(["profile"]);
      },
      onError: error => {
        console.log(error);
      },
    }
  );
  const overlapping = (a: TimeObject, b: TimeObject) => {
    const getMinutes = (s: string) => {
      const p = s.split(":").map(Number);
      return p[0] * 60 + p[1];
    };
    return (
      getMinutes(a.end) > getMinutes(b.start) &&
      getMinutes(b.end) > getMinutes(a.start)
    );
  };
  const isOverlapping = (arr: TimeObject[]) => {
    for (let i = 0; i < arr.length - 1; i++) {
      for (let j = i + 1; j < arr.length; j++) {
        if (overlapping(arr[i], arr[j])) {
          return true;
        }
      }
    }
    return false;
  };
  function handleSubmit(values: any) {
    let isValidTime = true;
    let timeBlocks = [...values.availability.blocks];
    for (let timeBlock of timeBlocks) {
      if (isOverlapping(timeBlock.times)) {
        setInvalidDays(d => [...new Set([...d, timeBlock.name])]);
        isValidTime = false;
        break;
      }
    }
    if (isValidTime) {
      saveProfile.mutate(values);
      dispatch(hydrateTeam());
    } else {
      toastr.error("Some time slots overlap.");
    }
  }

  return (
    <TabPane tabId="availability">
      <h2>{t("personal_settings_availibility_tab_title", "Availability")}</h2>
      <Row>
        {!isLoading ? (
          <Formik
            enableReinitialize={true}
            initialValues={data || {}}
            onSubmit={handleSubmit}
          >
            {formik => (
              <Form>
                <Col className="mt-2">
                  <Availabilty
                    show={props.show}
                    values={
                      props.editing
                        ? props.editing.availability || false
                        : data.availability || false
                    }
                    invalidDays={invalidDays}
                    formikProps={formik}
                    handleChange={(values: AvailabilityObject) => {
                      setInvalidDays([]);
                      formik.setFieldValue("availability", values);
                    }}
                  />
                </Col>
                <div className="mt-5">
                  <Button color="primary" type="submit">
                    {t("save_changes", "Save Changes")}
                  </Button>
                  <LeavingForm formikProps={formik} />
                </div>
                <StickyOnChange formikProps={formik} />
              </Form>
            )}
          </Formik>
        ) : (
          ""
        )}
      </Row>
    </TabPane>
  );
};

export default TabAvailability;
