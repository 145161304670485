import PropTypes from "prop-types";

// http://skote-v-light.react.themesbrand.com/icons-boxicons

const mapping = {
  mobile: "bx bx-phone-incoming",
  company: "bx bx-buildings",
  envelope: "bx bx-envelope",
  settings: "bx bx-cog",
  people: "bx bx-group",
  schedule: "bx bx-calendar",
  googleMaps: "bx bx-map-alt",
  work: "bx bx-spreadsheet",
  insights: "bx bx-bar-chart-alt-2",
  edit: "bx bx-edit",
  delete: "mdi mdi-delete-outline",
  save: "mdi mdi-content-save-outline",
  rightArrow: "bx bx-cookie",
  reviewWorkOrder: "mdi mdi-briefcase-check-outline",
  writeReport: "mdi mdi-file-document-edit-outline",
  reviewReport: "mdi mdi-file-check-outline",
  car: "bx bx-car",
  map: "mdi mdi-map-search",
};

const Icon = props => {
  let name = props.name;

  if (name in mapping) {
    name = mapping[name];
  } else {
    name = "bx bx-" + name;
  }
  return (
    <i
      // id={props.id}
      // style={props.style !== undefined ? props.style : null}
      // onClick={props.onClick !== undefined ? props.onClick : null}
      {...props}
      className={
        name + (props.className !== undefined ? " " + props.className : "")
      }
      title={props.hint ? props.hint : ""}
    ></i>
  );
};

const getCSSClass = name => {
  if (name in mapping) {
    return mapping[name];
  } else {
    return name;
  }
};

Icon.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
  hint: PropTypes.string,
};

export default Icon;
export { getCSSClass };
