import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Formik, FormikValues, Form, Field } from "formik";
import { useProfile } from "hooks/profile";
import toastr from "toastr";
import { useTranslation } from "react-i18next";
import { TabPane, Row, Col, Button, Container, Label } from "reactstrap";
import LeavingForm from "components/Common/LeavingForm";
import StickyOnChange from "components/Common/StickyOnChange";

export default function TabNotifications(props: any) {
  const { t } = useTranslation();
  const profile = useProfile();
  const queryClient = useQueryClient();
  const [profileData, setProfileData] = useState<Object>({});
  useQuery(["profile"], profile.fetch, {
    onSuccess: data => {
      setProfileData(data);
    },
  });
  const saveProfile = useMutation(
    values => {
      return profile.save(values);
    },
    {
      onSuccess: () => {
        toastr.success(
          t(
            "notification_setting_saved_success",
            "Notification Setting saved successfully"
          )
        );
        queryClient.invalidateQueries(["profile"]);
      },
    }
  );
  function handleFormSubmit(values: any) {
    saveProfile.mutate(values);
  }
  return (
    <TabPane tabId="notifications">
      <h2>
        {t("personal_settings_notification_tab_title", "Manage my alerts")}
        <p className="card-title-desc">
          <div>
            Alerts are notifications that Horizon sends to keep you informed of
            transactions and to monitor your Horizon accounts.
          </div>
          <div>
            Most alerts are sent automatically whenever something new has
            happened. Look for the bell icon in the top menu to see your site
            alerts.
          </div>
          <div>
            To find out what's going on when you're not on Horizon you can set
            up email notifications below. You can turn them off anytime.
          </div>
        </p>
      </h2>

      <Formik
        enableReinitialize={true}
        onSubmit={handleFormSubmit}
        validationSchema={profile.validation}
        initialValues={profileData}
      >
        {(formik: FormikValues) => (
          <>
            <Form>
              <Row>
                <Container className="ml-2">
                  <h5 className="mt-1">
                    Transactions (a bill is paid or contract signed)
                  </h5>
                  <Row className="mb-1">
                    <Col md xs lg="2" className="text-center">
                      <div className="mb-1">Email</div>
                    </Col>
                    <Col md xs lg="2" className="text-center">
                      <div className="mb-1">Text</div>
                    </Col>
                    <Col></Col>
                  </Row>
                  <Row className="mb-1">
                    <Col md xs lg="2" className="text-center">
                      <div
                        id="newInspectionEmail"
                        className="btn-group"
                        role="group"
                      >
                        <Field
                          id="newInspectionEmailYes"
                          type="radio"
                          className="btn-check"
                          name="notification.transactions.newInspection.email"
                          value="1"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary success"
                          htmlFor="newInspectionEmailYes"
                        >
                          Yes
                        </label>
                        <Field
                          id="newInspectionEmailNo"
                          type="radio"
                          className="btn-check"
                          name="notification.transactions.newInspection.email"
                          value="0"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary danger"
                          htmlFor="newInspectionEmailNo"
                        >
                          No
                        </label>
                      </div>
                    </Col>
                    <Col md xs lg="2" className="border-start text-center">
                      <div
                        id="newInspectionText"
                        className="btn-group"
                        role="group"
                      >
                        <Field
                          id="newInspectionTextYes"
                          type="radio"
                          className="btn-check"
                          name="notification.transactions.newInspection.text"
                          value="1"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary success"
                          htmlFor="newInspectionTextYes"
                        >
                          Yes
                        </label>
                        <Field
                          id="newInspectionTextNo"
                          type="radio"
                          className="btn-check"
                          name="notification.transactions.newInspection.text"
                          value="0"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary danger"
                          htmlFor="newInspectionTextNo"
                        >
                          No
                        </label>
                      </div>
                    </Col>
                    <Col className="border-start d-flex align-items-center">
                      <Label className="mb-0">
                        a New inspection has been booked
                      </Label>
                    </Col>
                  </Row>
                  <Row className="mb-1">
                    <Col md xs lg="2" className="text-center">
                      <div
                        id="inspectionRescheduledEmail"
                        className="btn-group"
                        role="group"
                      >
                        <Field
                          id="inspectionRescheduledEmailYes"
                          type="radio"
                          className="btn-check"
                          name="notification.transactions.inspectionRescheduled.email"
                          value="1"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary success"
                          htmlFor="inspectionRescheduledEmailYes"
                        >
                          Yes
                        </label>
                        <Field
                          id="inspectionRescheduledEmailNo"
                          type="radio"
                          className="btn-check"
                          name="notification.transactions.inspectionRescheduled.email"
                          value="0"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary danger"
                          htmlFor="inspectionRescheduledEmailNo"
                        >
                          No
                        </label>
                      </div>
                    </Col>
                    <Col md xs lg="2" className="border-start text-center">
                      <div
                        id="inspectionRescheduledText"
                        className="btn-group"
                        role="group"
                      >
                        <Field
                          id="inspectionRescheduledTextYes"
                          type="radio"
                          className="btn-check"
                          name="notification.transactions.inspectionRescheduled.text"
                          value="1"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary success"
                          htmlFor="inspectionRescheduledTextYes"
                        >
                          Yes
                        </label>
                        <Field
                          id="inspectionRescheduledTextNo"
                          type="radio"
                          className="btn-check"
                          name="notification.transactions.inspectionRescheduled.text"
                          value="0"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary danger"
                          htmlFor="inspectionRescheduledTextNo"
                        >
                          No
                        </label>
                      </div>
                    </Col>
                    <Col className="border-start d-flex align-items-center">
                      <Label className="mb-0">
                        An inspection has been rescheduled
                      </Label>
                    </Col>
                  </Row>
                  <Row className="mb-1">
                    <Col md xs lg="2" className="text-center">
                      <div
                        id="inspectionCanceledEmail"
                        className="btn-group"
                        role="group"
                      >
                        <Field
                          className="btn-check"
                          type="radio"
                          id="inspectionCanceledEmailYes"
                          name="notification.transactions.inspectionCanceled.email"
                          value="1"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary success"
                          htmlFor="inspectionCanceledEmailYes"
                        >
                          Yes
                        </label>
                        <Field
                          className="btn-check"
                          type="radio"
                          id="inspectionCanceledEmailNo"
                          name="notification.transactions.inspectionCanceled.email"
                          value="0"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary danger"
                          htmlFor="inspectionCanceledEmailNo"
                        >
                          No
                        </label>
                      </div>
                    </Col>
                    <Col md xs lg="2" className="border-start text-center">
                      <div
                        id="inspectionCanceledText"
                        className="btn-group"
                        role="group"
                      >
                        <Field
                          className="btn-check"
                          type="radio"
                          id="inspectionCanceledTextYes"
                          name="notification.transactions.inspectionCanceled.text"
                          value="1"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary success"
                          htmlFor="inspectionCanceledTextYes"
                        >
                          Yes
                        </label>
                        <Field
                          className="btn-check"
                          type="radio"
                          id="inspectionCanceledTextNo"
                          name="notification.transactions.inspectionCanceled.text"
                          value="0"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary danger"
                          htmlFor="inspectionCanceledTextNo"
                        >
                          No
                        </label>
                      </div>
                    </Col>

                    <Col className="border-start d-flex align-items-center">
                      <Label className="mb-0">
                        An Inspection has been canceled
                      </Label>
                    </Col>
                  </Row>
                  <Row className="mb-1">
                    <Col md xs lg="2" className="text-center">
                      <div
                        id="invoicePayFailEmail"
                        className="btn-group"
                        role="group"
                      >
                        <Field
                          id="invoicePayFailEmailYes"
                          type="radio"
                          className="btn-check"
                          name="notification.transactions.invoicePayFail.email"
                          value="1"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary success"
                          htmlFor="invoicePayFailEmailYes"
                        >
                          Yes
                        </label>
                        <Field
                          id="invoicePayFailEmailNo"
                          type="radio"
                          className="btn-check"
                          name="notification.transactions.invoicePayFail.email"
                          value="0"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary danger"
                          htmlFor="invoicePayFailEmailNo"
                        >
                          No
                        </label>
                      </div>
                    </Col>
                    <Col md xs lg="2" className="border-start text-center">
                      <div
                        id="invoicePayFailText"
                        className="btn-group"
                        role="group"
                      >
                        <Field
                          id="invoicePayFailTextYes"
                          type="radio"
                          className="btn-check"
                          name="notification.transactions.invoicePayFail.text"
                          value="1"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary success"
                          htmlFor="invoicePayFailTextYes"
                        >
                          Yes
                        </label>
                        <Field
                          id="invoicePayFailTextNo"
                          type="radio"
                          className="btn-check"
                          name="notification.transactions.invoicePayFail.text"
                          value="0"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary danger"
                          htmlFor="invoicePayFailTextNo"
                        >
                          No
                        </label>
                      </div>
                    </Col>
                    <Col className="border-start d-flex align-items-center">
                      <Label className="mb-0">
                        A invoice payment has failed
                      </Label>
                    </Col>
                  </Row>
                  <Row className="mb-1">
                    <Col md xs lg="2" className="text-center">
                      <div
                        id="invoicePaySuccessEmail"
                        className="btn-group"
                        role="group"
                      >
                        <Field
                          className="btn-check"
                          type="radio"
                          id="invoicePaySuccessEmailYes"
                          name="notification.transactions.invoicePaySuccess.email"
                          value="1"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary success"
                          htmlFor="invoicePaySuccessEmailYes"
                        >
                          Yes
                        </label>

                        <Field
                          className="btn-check"
                          type="radio"
                          id="invoicePaySuccessEmailNo"
                          name="notification.transactions.invoicePaySuccess.email"
                          value="0"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary danger"
                          htmlFor="invoicePaySuccessEmailNo"
                        >
                          No
                        </label>
                      </div>
                    </Col>
                    <Col md xs lg="2" className="border-start text-center">
                      <div
                        id="invoicePaySuccessText"
                        className="btn-group"
                        role="group"
                      >
                        <Field
                          className="btn-check"
                          type="radio"
                          id="invoicePaySuccessTextYes"
                          name="notification.transactions.invoicePaySuccess.text"
                          value="1"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary success"
                          htmlFor="invoicePaySuccessTextYes"
                        >
                          Yes
                        </label>

                        <Field
                          className="btn-check"
                          type="radio"
                          id="invoicePaySuccessTextNo"
                          name="notification.transactions.invoicePaySuccess.text"
                          value="0"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary danger"
                          htmlFor="invoicePaySuccessTextNo"
                        >
                          No
                        </label>
                      </div>
                    </Col>
                    <Col className="border-start d-flex align-items-center">
                      <Label className="mb-0">
                        A invoice payment was successful
                      </Label>
                    </Col>
                  </Row>
                  <Row className="mb-1">
                    <Col md xs lg="2" className="text-center">
                      <div
                        id="invoiceSentEmail"
                        className="btn-group"
                        role="group"
                      >
                        <Field
                          className="btn-check"
                          type="radio"
                          id="invoiceSentEmailYes"
                          name="notification.transactions.invoiceSent.email"
                          value="1"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary success"
                          htmlFor="invoiceSentEmailYes"
                        >
                          Yes
                        </label>
                        <Field
                          className="btn-check"
                          type="radio"
                          id="invoiceSentEmailNo"
                          name="notification.transactions.invoiceSent.email"
                          value="0"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary danger"
                          htmlFor="invoiceSentEmailNo"
                        >
                          No
                        </label>
                      </div>
                    </Col>
                    <Col md xs lg="2" className="border-start text-center">
                      <div
                        id="invoiceSentText"
                        className="btn-group"
                        role="group"
                      >
                        <Field
                          className="btn-check"
                          type="radio"
                          id="invoiceSentTextYes"
                          name="notification.transactions.invoiceSent.text"
                          value="1"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary success"
                          htmlFor="invoiceSentTextYes"
                        >
                          Yes
                        </label>
                        <Field
                          className="btn-check"
                          type="radio"
                          id="invoiceSentTextNo"
                          name="notification.transactions.invoiceSent.text"
                          value="0"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary danger"
                          htmlFor="invoiceSentTextNo"
                        >
                          No
                        </label>
                      </div>
                    </Col>
                    <Col className="border-start d-flex align-items-center">
                      <Label className="mb-0">
                        An Invoice or contract was sent
                      </Label>
                    </Col>
                  </Row>
                  <Row className="mb-1">
                    <Col md xs lg="2" className="text-center">
                      <div
                        id="syncErrorEmail"
                        className="btn-group"
                        role="group"
                      >
                        <Field
                          className="btn-check"
                          type="radio"
                          id="syncErrorEmailYes"
                          name="notification.transactions.syncError.email"
                          value="1"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary success"
                          htmlFor="syncErrorEmailYes"
                        >
                          Yes
                        </label>
                        <Field
                          className="btn-check"
                          type="radio"
                          id="syncErrorEmailNo"
                          name="notification.transactions.syncError.email"
                          value="0"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary danger"
                          htmlFor="syncErrorEmailNo"
                        >
                          No
                        </label>
                      </div>
                    </Col>
                    <Col md xs lg="2" className="border-start text-center">
                      <div
                        id="syncErrorText"
                        className="btn-group"
                        role="group"
                      >
                        <Field
                          className="btn-check"
                          type="radio"
                          id="syncErrorTextYes"
                          name="notification.transactions.syncError.text"
                          value="1"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary success"
                          htmlFor="syncErrorTextYes"
                        >
                          Yes
                        </label>
                        <Field
                          className="btn-check"
                          type="radio"
                          id="syncErrorTextNo"
                          name="notification.transactions.syncError.text"
                          value="0"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary danger"
                          htmlFor="syncErrorTextNo"
                        >
                          No
                        </label>
                      </div>
                    </Col>
                    <Col className="border-start d-flex align-items-center">
                      <Label className="mb-0">
                        Sync errors with integrations
                      </Label>
                    </Col>
                  </Row>
                  <Row className="mb-1">
                    <Col md xs lg="2" className="text-center">
                      <div
                        id="reportPreventEmail"
                        className="btn-group"
                        role="group"
                      >
                        <Field
                          className="btn-check"
                          type="radio"
                          id="reportPreventEmailYes"
                          name="notification.transactions.reportPrevent.email"
                          value="1"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary success"
                          htmlFor="reportPreventEmailYes"
                        >
                          Yes
                        </label>
                        <Field
                          className="btn-check"
                          type="radio"
                          id="reportPreventEmailNo"
                          name="notification.transactions.reportPrevent.email"
                          value="0"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary danger"
                          htmlFor="reportPreventEmailNo"
                        >
                          No
                        </label>
                      </div>
                    </Col>
                    <Col md xs lg="2" className="border-start text-center">
                      <div
                        id="reportPreventText"
                        className="btn-group"
                        role="group"
                      >
                        <Field
                          className="btn-check"
                          type="radio"
                          id="reportPreventTextYes"
                          name="notification.transactions.reportPrevent.text"
                          value="1"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary success"
                          htmlFor="reportPreventTextYes"
                        >
                          Yes
                        </label>
                        <Field
                          className="btn-check"
                          type="radio"
                          id="reportPreventTextNo"
                          name="notification.transactions.reportPrevent.text"
                          value="0"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary danger"
                          htmlFor="reportPreventTextNo"
                        >
                          No
                        </label>
                      </div>
                    </Col>
                    <Col className="border-start d-flex align-items-center">
                      <Label className="mb-0">
                        Horizon report download prevented
                      </Label>
                    </Col>
                  </Row>
                  <Row className="mb-1">
                    <Col md xs lg="2" className="text-center">
                      <div
                        id="emailNotDeliverEmail"
                        className="btn-group"
                        role="group"
                      >
                        <Field
                          className="btn-check"
                          type="radio"
                          id="emailNotDeliverEmailYes"
                          name="notification.transactions.emailNotDeliver.email"
                          value="1"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary success"
                          htmlFor="emailNotDeliverEmailYes"
                        >
                          Yes
                        </label>
                        <Field
                          className="btn-check"
                          type="radio"
                          id="emailNotDeliverEmailNo"
                          name="notification.transactions.emailNotDeliver.email"
                          value="0"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary danger"
                          htmlFor="emailNotDeliverEmailNo"
                        >
                          No
                        </label>
                      </div>
                    </Col>
                    <Col md xs lg="2" className="border-start text-center">
                      <div
                        id="emailNotDeliverText"
                        className="btn-group"
                        role="group"
                      >
                        <Field
                          className="btn-check"
                          type="radio"
                          id="emailNotDeliverTextYes"
                          name="notification.transactions.emailNotDeliver.text"
                          value="1"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary success"
                          htmlFor="emailNotDeliverTextYes"
                        >
                          Yes
                        </label>
                        <Field
                          className="btn-check"
                          type="radio"
                          id="emailNotDeliverTextNo"
                          name="notification.transactions.emailNotDeliver.text"
                          value="0"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary danger"
                          htmlFor="emailNotDeliverTextNo"
                        >
                          No
                        </label>
                      </div>
                    </Col>
                    <Col className="border-start d-flex align-items-center">
                      <Label className="mb-0">
                        An email could not be delivered to an email address.
                      </Label>
                    </Col>
                  </Row>
                  <h5 className="mt-4">News</h5>
                  <Row className="mb-1">
                    <Col md xs lg="2" className="text-center">
                      <div className="mb-1">Email</div>
                    </Col>
                    <Col md xs lg="2" className="text-center">
                      <div className="mb-1">Text</div>
                    </Col>
                    <Col></Col>
                  </Row>
                  <Row className="mb-1">
                    <Col md xs lg="2" className="text-center">
                      <div
                        id="newsUpdateEmail"
                        className="btn-group"
                        role="group"
                      >
                        <Field
                          className="btn-check"
                          type="radio"
                          id="newsUpdateEmailYes"
                          name="notification.news.newsUpdate.email"
                          value="1"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary success"
                          htmlFor="newsUpdateEmailYes"
                        >
                          Yes
                        </label>
                        <Field
                          className="btn-check"
                          type="radio"
                          id="newsUpdateEmailNo"
                          name="notification.news.newsUpdate.email"
                          value="0"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary danger"
                          htmlFor="newsUpdateEmailNo"
                        >
                          No
                        </label>
                      </div>
                    </Col>
                    <Col md xs lg="2" className="border-start text-center">
                      <div
                        id="newsUpdateText"
                        className="btn-group"
                        role="group"
                      >
                        <Field
                          className="btn-check"
                          type="radio"
                          id="newsUpdateTextYes"
                          name="notification.news.newsUpdate.text"
                          value="1"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary success"
                          htmlFor="newsUpdateTextYes"
                        >
                          Yes
                        </label>
                        <Field
                          className="btn-check"
                          type="radio"
                          id="newsUpdateTextNo"
                          name="notification.news.newsUpdate.text"
                          value="0"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary danger"
                          htmlFor="newsUpdateTextNo"
                        >
                          No
                        </label>
                      </div>
                    </Col>
                    <Col className="border-start d-flex align-items-center">
                      <Label className="mb-0">
                        News about the Horizon product and feature updates
                      </Label>
                    </Col>
                  </Row>
                  <Row className="mb-1">
                    <Col md xs lg="2" className="text-center">
                      <div
                        id="newsTipsEmail"
                        className="btn-group"
                        role="group"
                      >
                        <Field
                          className="btn-check"
                          type="radio"
                          id="newsTipsEmailYes"
                          name="notification.news.tips.email"
                          value="1"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary success"
                          htmlFor="newsTipsEmailYes"
                        >
                          Yes
                        </label>
                        <Field
                          className="btn-check"
                          type="radio"
                          id="newsTipsEmailNo"
                          name="notification.news.tips.email"
                          value="0"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary danger"
                          htmlFor="newsTipsEmailNo"
                        >
                          No
                        </label>
                      </div>
                    </Col>
                    <Col md xs lg="2" className="border-start text-center">
                      <div id="newsTipsText" className="btn-group" role="group">
                        <Field
                          className="btn-check"
                          type="radio"
                          id="newsTipsTextYes"
                          name="notification.news.tips.text"
                          value="1"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary success"
                          htmlFor="newsTipsTextYes"
                        >
                          Yes
                        </label>
                        <Field
                          className="btn-check"
                          type="radio"
                          id="newsTipsTextNo"
                          name="notification.news.tips.text"
                          value="0"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary danger"
                          htmlFor="newsTipsTextNo"
                        >
                          No
                        </label>
                      </div>
                    </Col>
                    <Col className="border-start d-flex align-items-center">
                      <Label className="mb-0">
                        Tips on getting more out of Horizon
                      </Label>
                    </Col>
                  </Row>
                  <Row className="mb-1">
                    <Col md xs lg="2" className="text-center">
                      <div
                        id="newsParticipationEmail"
                        className="btn-group"
                        role="group"
                      >
                        <Field
                          className="btn-check"
                          type="radio"
                          id="newsParticipationEmailYes"
                          name="notification.news.participation.email"
                          value="1"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary success"
                          htmlFor="newsParticipationEmailYes"
                        >
                          Yes
                        </label>
                        <Field
                          className="btn-check"
                          type="radio"
                          id="newsParticipationEmailNo"
                          name="notification.news.participation.email"
                          value="0"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary danger"
                          htmlFor="newsParticipationEmailNo"
                        >
                          No
                        </label>
                      </div>
                    </Col>
                    <Col md xs lg="2" className="border-start text-center">
                      <div
                        id="newsParticipationText"
                        className="btn-group"
                        role="group"
                      >
                        <Field
                          className="btn-check"
                          type="radio"
                          id="newsParticipationTextYes"
                          name="notification.news.participation.text"
                          value="1"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary success"
                          htmlFor="newsParticipationTextYes"
                        >
                          Yes
                        </label>
                        <Field
                          className="btn-check"
                          type="radio"
                          id="newsParticipationTextNo"
                          name="notification.news.participation.text"
                          value="0"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary danger"
                          htmlFor="newsParticipationTextNo"
                        >
                          No
                        </label>
                      </div>
                    </Col>
                    <Col className="border-start d-flex align-items-center">
                      <Label className="mb-0">
                        Participation in Horizon surveys and events
                      </Label>
                    </Col>
                  </Row>
                  <h5 className="mt-4">Reminders</h5>
                  <Row className="mb-1">
                    <Col md xs lg="2" className="text-center">
                      <div className="mb-1">Email</div>
                    </Col>
                    <Col md xs lg="2" className="text-center">
                      <div className="mb-1">Text</div>
                    </Col>
                    <Col></Col>
                  </Row>
                  <Row className="mb-1">
                    <Col md xs lg="2" className="text-center">
                      <div
                        id="inspectionReminderEmail"
                        className="btn-group"
                        role="group"
                      >
                        <Field
                          className="btn-check"
                          type="radio"
                          id="inspectionReminderEmailYes"
                          name="notification.reminder.inspectionReminder.email"
                          value="1"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary success"
                          htmlFor="inspectionReminderEmailYes"
                        >
                          Yes
                        </label>
                        <Field
                          className="btn-check"
                          type="radio"
                          id="inspectionReminderEmailNo"
                          name="notification.reminder.inspectionReminder.email"
                          value="0"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary danger"
                          htmlFor="inspectionReminderEmailNo"
                        >
                          No
                        </label>
                      </div>
                    </Col>
                    <Col md xs lg="2" className="border-start text-center">
                      <div
                        id="inspectionReminderText"
                        className="btn-group"
                        role="group"
                      >
                        <Field
                          className="btn-check"
                          type="radio"
                          id="inspectionReminderTextYes"
                          name="notification.reminder.inspectionReminder.text"
                          value="1"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary success"
                          htmlFor="inspectionReminderTextYes"
                        >
                          Yes
                        </label>
                        <Field
                          className="btn-check"
                          type="radio"
                          id="inspectionReminderTextNo"
                          name="notification.reminder.inspectionReminder.text"
                          value="0"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary danger"
                          htmlFor="inspectionReminderTextNo"
                        >
                          No
                        </label>
                      </div>
                    </Col>
                    <Col className="border-start d-flex align-items-center">
                      <Label className="mb-0">
                        24-hour inspection reminder
                      </Label>
                    </Col>
                  </Row>
                  <h5 className="mt-4">Fraud prevention</h5>
                  <Row className="mb-1">
                    <Col md xs lg="2" className="text-center">
                      <div className="mb-1">Email</div>
                    </Col>
                    <Col md xs lg="2" className="text-center">
                      <div className="mb-1">Text</div>
                    </Col>
                    <Col></Col>
                  </Row>
                  <Row className="mb-1">
                    <Col md xs lg="2" className="text-center">
                      <div
                        id="fraudPreventUnusualEmail"
                        className="btn-group"
                        role="group"
                      >
                        <Field
                          className="btn-check"
                          type="radio"
                          id="fraudPreventUnusualEmailYes"
                          name="notification.fraudPrevent.unusualActivity.email"
                          value="1"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary success"
                          htmlFor="fraudPreventUnusualEmailYes"
                        >
                          Yes
                        </label>
                        <Field
                          className="btn-check"
                          type="radio"
                          id="fraudPreventUnusualEmailNo"
                          name="notification.fraudPrevent.unusualActivity.email"
                          value="0"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary danger"
                          htmlFor="fraudPreventUnusualEmailNo"
                        >
                          No
                        </label>
                      </div>
                    </Col>
                    <Col md xs lg="2" className="border-start text-center">
                      <div
                        id="fraudPreventUnusualText"
                        className="btn-group"
                        role="group"
                      >
                        <Field
                          className="btn-check"
                          type="radio"
                          id="fraudPreventUnusualTextYes"
                          name="notification.fraudPrevent.unusualActivity.text"
                          value="1"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary success"
                          htmlFor="fraudPreventUnusualTextYes"
                        >
                          Yes
                        </label>
                        <Field
                          className="btn-check"
                          type="radio"
                          id="fraudPreventUnusualTextNo"
                          name="notification.fraudPrevent.unusualActivity.text"
                          value="0"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary danger"
                          htmlFor="fraudPreventUnusualTextNo"
                        >
                          No
                        </label>
                      </div>
                    </Col>
                    <Col className="border-start d-flex align-items-center">
                      <Label className="mb-0">
                        There was unusual activity on my account (anomaly
                        detection such as impossible travel event or login
                        failures)
                      </Label>
                    </Col>
                  </Row>
                  <Row className="mb-1">
                    <Col md xs lg="2" className="text-center">
                      <div
                        id="changePasswordEmail"
                        className="btn-group"
                        role="group"
                      >
                        <Field
                          className="btn-check"
                          type="radio"
                          id="changePasswordEmailYes"
                          name="notification.fraudPrevent.changePassword.email"
                          value="1"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary success"
                          htmlFor="changePasswordEmailYes"
                        >
                          Yes
                        </label>
                        <Field
                          className="btn-check"
                          type="radio"
                          id="changePasswordEmailNo"
                          name="notification.fraudPrevent.changePassword.email"
                          value="0"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary danger"
                          htmlFor="changePasswordEmailNo"
                        >
                          No
                        </label>
                      </div>
                    </Col>
                    <Col md xs lg="2" className="border-start text-center">
                      <div
                        id="changePasswordText"
                        className="btn-group"
                        role="group"
                      >
                        <Field
                          className="btn-check"
                          type="radio"
                          id="changePasswordTextYes"
                          name="notification.fraudPrevent.changePassword.text"
                          value="1"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary success"
                          htmlFor="changePasswordTextYes"
                        >
                          Yes
                        </label>
                        <Field
                          className="btn-check"
                          type="radio"
                          id="changePasswordTextNo"
                          name="notification.fraudPrevent.changePassword.text"
                          value="0"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary danger"
                          htmlFor="changePasswordTextNo"
                        >
                          No
                        </label>
                      </div>
                    </Col>
                    <Col className="border-start d-flex align-items-center">
                      <Label className="mb-0">
                        Changes have been made to my online and mobile passwords
                      </Label>
                    </Col>
                  </Row>
                  <Row className="mb-1">
                    <Col md xs lg="2" className="text-center">
                      <div
                        id="profileUpdateEmail"
                        className="btn-group"
                        role="group"
                      >
                        <Field
                          className="btn-check"
                          type="radio"
                          id="profileUpdateEmailYes"
                          name="notification.fraudPrevent.profileUpdate.email"
                          value="1"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary success"
                          htmlFor="profileUpdateEmailYes"
                        >
                          Yes
                        </label>
                        <Field
                          className="btn-check"
                          type="radio"
                          id="profileUpdateEmailNo"
                          name="notification.fraudPrevent.profileUpdate.email"
                          value="0"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary success"
                          htmlFor="profileUpdateEmailNo"
                        >
                          No
                        </label>
                      </div>
                    </Col>
                    <Col md xs lg="2" className="text-center">
                      <div
                        id="profileUpdateText"
                        className="btn-group"
                        role="group"
                      >
                        <Field
                          className="btn-check"
                          type="radio"
                          id="profileUpdateTextYes"
                          name="notification.fraudPrevent.profileUpdate.text"
                          value="1"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary success"
                          htmlFor="profileUpdateTextYes"
                        >
                          Yes
                        </label>
                        <Field
                          className="btn-check"
                          type="radio"
                          id="profileUpdateTextNo"
                          name="notification.fraudPrevent.profileUpdate.text"
                          value="0"
                        />
                        <label
                          className="btn btn-sm btn-outline-secondary success"
                          htmlFor="profileUpdateTextNo"
                        >
                          No
                        </label>
                      </div>
                    </Col>
                    <Col className="border-start d-flex align-items-center">
                      <Label className="mb-0">
                        My personal information has been updated on my profile
                      </Label>
                    </Col>
                  </Row>
                </Container>
                <div className="mt-5">
                  <Button color="primary" type="submit">
                    {t("save_changes", "Save Changes")}
                  </Button>
                  <LeavingForm formikProps={formik} />
                </div>
                <StickyOnChange formikProps={formik} />
              </Row>
            </Form>
          </>
        )}
      </Formik>
    </TabPane>
  );
}
