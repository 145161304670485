import React, { useEffect, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import DataGrid from "components/Tables/DataGrid";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";

import {
  Column,
  Editing,
  Button,
  Toolbar,
  Item as DataGridItem,
} from "devextreme-react/data-grid";
import { getCSSClass } from "components/Common/Icons";

import { useDiscounts } from "hooks/discounts";
import { useProfile } from "hooks/profile";
import { TabPane } from "reactstrap";

export default function Discounts() {
  const history = useHistory();
  const { myId } = useProfile();
  const { byOwner, deleteDiscount } = useDiscounts();
  const [discounts, setDiscounts] = useState<any>([]);
  const { t } = useTranslation();

  useEffect(() => {
    setDiscounts(byOwner.data?.discounts || []);
  }, [byOwner.data]);

  // Set up reference to datagrid component
  const dataGrid = useRef<any>();
  const inializeDataGrid = (e: any) => {
    dataGrid.current = e.component;
  };

  // Search grid using search in toobar
  const searchGrid = (e: any) => {
    dataGrid.current?.searchByText(e.value);
  };

  const addNewItem = () => {
    history.push("/company/settings/discounts/new");
  };

  const discountTypeRender = (gridData: any) => {
    return t("discount_type_" + gridData.value.toLowerCase());
  };

  const appliesToRender = (gridData: any) => {
    return t("discount_application_" + gridData.value.toLowerCase());
  };

  const editItem = (e: any) => {
    history.replace("/company/settings/discounts");
    history.push("/company/settings/discounts/edit", {
      id: e.key,
    });
  };

  const deleteItem = (e: any) => {
    deleteDiscount.mutate({
      id: e.key,
      ownerId: myId,
    });
  };

  return (
    <TabPane tabId="discounts">
      <h2>
        {t("discounts_tab_title", "Discounts")}
        <p className="card-title-desc">
          {t(
            "discounts_tab_subtitle",
            "You can create automatic discounts and discount codes to offer to your customers. Discount codes are codes you can create and share individually with customers as a way to promote sales or reward customer loyalty, and automatic discounts are applied automatically to an invoice."
          )}
        </p>
      </h2>

      <DataGrid
        dataSource={discounts}
        keyExpr="id"
        repaintChangesOnly={true}
        onInitialized={(e: any) => inializeDataGrid(e)}
        onEditingStart={editItem}
        onRowRemoved={deleteItem}
      >
        <Editing allowUpdating={true} allowDeleting={true} mode="popup" />
        <Toolbar>
          <DataGridItem
            widget="dxButton"
            cssClass="btn btn-sm btn-primary"
            location="before"
            options={{
              text: t("create_new_discount", "Create new discount"),
              onClick: addNewItem,
            }}
          />
          <DataGridItem
            widget="dxTextBox"
            options={{
              type: "search",
              placeholder: t("search", "Search") + "...",
              onValueChanged: searchGrid,
            }}
            location="after"
          />
        </Toolbar>
        <Column dataField="name" caption={t("discount_name_label", "Name")} />
        <Column
          dataField="description"
          caption={t("discount_description_label", "Description")}
        />
        <Column
          dataField="type"
          caption={t("discount_type_label", "Type")}
          cellRender={discountTypeRender}
        />
        <Column
          dataField="appliesTo"
          caption={t("discount_applies_to_label", "Applies to")}
          cellRender={appliesToRender}
        />
        <Column
          dataField="startDate"
          caption={t("discount_start_date_label", "Start date")}
          dataType="date"
        />
        <Column
          dataField="endDate"
          caption={t("discount_end_date_label", "End date")}
          dataType="date"
        />
        <Column type="buttons" caption={t("discount_actions_label", "Actions")}>
          <Button
            name="edit"
            icon={getCSSClass("edit")}
            hint={t("icon_edit_hint", "Edit")}
          />
          <Button
            name="delete"
            icon={getCSSClass("delete")}
            hint={t("icon_delete_hint", "Delete")}
          />
        </Column>
      </DataGrid>
    </TabPane>
  );
}
