import { API } from "aws-amplify";

export function useSnippets(snippetTypes) {
  function defaults() {
    let emptySnippets = {};
    snippetTypes.forEach(snippet => {
      emptySnippets[snippet] = { values: [] };
    });
    return emptySnippets;
  }

  async function fetch() {
    let results = await API.get(
      "Snippet",
      "/snippet/snippetType/" + snippetTypes.join(",")
    );
    let newResults = {};
    results.forEach(snippet => {
      newResults[snippet.snippetType] = {
        ...snippet,
      };
    });

    return newResults;
  }

  function generateCategorySnippets() {
    let snippet = this.createSnippet("property_type", "work_order", [
      "Single Family",
      "Duplex",
      "Triplex",
      "Fourplex",
      "Multi-unit",
      "Condo/Strata",
      "Single + Suite",
      "Multi Family",
      "Single + Bsmt Apt.",
    ]);
    this.addSnippet(snippet);
    snippet = this.createSnippet("building_type", "work_order", [
      "Unknown",
      "Attached",
      "Detached",
      "Semi-detached",
      "Backsplit",
      "Sidesplit",
      "Townhome",
      "Condo - high rise",
      "Condo - townhome",
      "Condo - stacked",
      "Bi - level",
      "Tri - level",
      "Apartment",
      "Manufactured",
    ]);
    this.addSnippet(snippet);
    snippet = this.createSnippet("floors", "work_order", [
      "Bungalow (1)",
      "1+",
      "2",
      "2+",
      "3",
      "3+",
      "4",
      "4+",
      "5",
      "5+",
      "6",
      "6+",
    ]);
    this.addSnippet(snippet);
    snippet = this.createSnippet("rooms", "work_order", [
      "1+",
      "2",
      "2+",
      "3",
      "3+",
      "4",
      "4+",
      "5",
      "5+",
      "6",
      "6+",
    ]);
    this.addSnippet(snippet);
    snippet = this.createSnippet("property_title", "work_order", [
      "Freehold",
      "Co-operative",
      "Condominium/strata",
      "Leasehold",
    ]);
    this.addSnippet(snippet);
    snippet = this.createSnippet("heating_type", "work_order", [
      "Forced Air-1",
      "Forced Air-2",
      "Baseboard",
      "Fan Coil",
      "In Floor Heat Category",
      "Gravity",
      "Hot Water",
      "Heat Pump",
      "Space Heater",
      "Steam",
      "Wood Stove",
    ]);
    this.addSnippet(snippet);
    snippet = this.createSnippet("heating_fuel", "work_order", [
      "Coal",
      "Electric",
      "Geo Thermal",
      "Natural Gas",
      "Oil",
      "Propane",
      "Solar",
      "Wood",
    ]);
    this.addSnippet(snippet);
    snippet = this.createSnippet("cooling_type", "work_order", [
      "Central air conditioning",
      "Portable Air conditioning",
      "Window Air conditioning",
      "Ductless Split Air conditioning",
      "Air-Sourced Central Heat Pump",
      "Ductless Split Heat Pump",
      "Geothermal Heat Pump",
    ]);
    this.addSnippet(snippet);
    snippet = this.createSnippet("features", "work_order", [
      "Swimming Pool",
      "Balconies",
      "Assisted Living",
      "Balconies",
      "Beach Access",
      "Boating",
      "Club House",
      "Fireplaces",
      "Private Fishing",
      "Gazebo",
      "Guest Suite",
      "Laundry In-Suite",
      "Laundry Same-Floor",
      "Party Room",
      "Picnic Area",
      "Playground",
      "Pool Indoor",
      "Pool Outdoor",
      "Private Park Access",
      "Parking Extra",
      "Sauna/Swirlpool/Steam Room",
      "Storage In-Suite",
      "Storage – Locker Room",
      "Social Rooms",
      "Swirlpool Bath (Jacuzzi)",
      "Tennis Courts",
      "Workshop",
      "Car Wash",
      "Daycare Centre",
      "Elevator Freight",
      "Elevator Passenger",
      "Exercise Room",
      "Fenced",
      "Handicap Access",
      "Handicap Interior Accessories",
      "Intercom",
      "Lake Privileges",
      "Laundry Coin-Op",
      "Laundry Common",
      "Parking Plug-Ins",
      "Parking – Visitor",
      "Racquet Courts",
      "Recreation Room/Centre",
      "R.V. Storage",
      "Satellite TV Connection",
      "Sauna",
      "Security Door",
      "Secured Parking",
      "Security Personnel",
      "Skating",
      "Workshop",
    ]);
    this.addSnippet(snippet);
    snippet = this.createSnippet("parking", "work_order", [
      "Detached Garage",
      "Breezeways",
      "Attached Garages",
      "Parkade Parking",
      "Outdoor Stalls",
      "Parking Pad",
      "Carport",
      "On-Street Parking",
      "Off-Street Parking",
      "Indoor Parking",
      "Underground Parking",
    ]);
    this.addSnippet(snippet);
    snippet = this.createSnippet("roof_type", "work_order", [
      "Asphalt Shingles",
      "Clay Tile",
      "Concrete Tiles",
      "EPDM* Membrane",
      "Fiberglass",
      "Metal",
      "Cedar Shakes",
      "Pine Shakes",
      "Tar & Gravel",
      "Vinyl Shingles",
      "Wood Shingles",
      "Roll Roofing",
    ]);
    this.addSnippet(snippet);
    snippet = this.createSnippet("occupancy", "work_order", [
      "Furnished",
      "Occupied",
      "Utilities off",
      "Vacant",
    ]);
    this.addSnippet(snippet);
    snippet = this.createSnippet("duration", "inspection", [
      "0.0",
      "0.5",
      "1.0",
      "1.5",
      "2.0",
      "2.5",
      "3.0",
      "3.5",
      "4.0",
      "4.5",
      "5.0",
    ]);
    this.addSnippet(snippet);
    snippet = this.createSnippet("referral_source", "booking", [
      "None",
      "Buyer's agent",
      "Seller's agent",
      "Friend",
      "Relative",
      "Lender",
      "Relocation Co.",
      "Title Co.",
      "Other (please specify)",
    ]);
    this.addSnippet(snippet);
    snippet = this.createSnippet("booked_by_source", "booking", [
      "None",
      "Buyer's agent",
      "Seller's agent",
      "Homeowner",
      "Home buyer",
      "Relocation Co.",
      "Other (please specify)",
    ]);
    this.addSnippet(snippet);
    snippet = this.createSnippet("house_facing", "work_order", [
      "North",
      "East",
      "South",
      "West",
    ]);
    this.addSnippet(snippet);
  }

  return {
    fetch,
    defaults,
  };
}
