import PropTypes from "prop-types";
import React, { useState } from "react";
import { Label } from "reactstrap";
import DropDownBox from "devextreme-react/drop-down-box";
import TreeView from "devextreme-react/tree-view";
import { useFormikContext } from "formik";

const TreeViewDropDown = props => {
  const [treeView, setTreeView] = useState();
  const formik = useFormikContext(); //NOSONAR - this is used in an eval statement
  const [treeBoxValue, setTreeBoxValue] = useState(props.value);
  const [isTreeBoxOpened, setIsTreeBoxOpened] = useState(false);

  const selectionMode =
    props.selectionMode === undefined ? "single" : props.selectionMode;

  const treeViewRender = () => {
    return (
      <TreeView
        dataSource={props.dataSource}
        ref={ref => {
          setTreeView(ref);
        }}
        dataStructure="plain"
        keyExpr={props.keyExpr}
        parentIdExpr={props.parentIdExpr}
        selectionMode={selectionMode}
        showCheckBoxesMode={selectionMode === "single" ? "none" : "normal"}
        displayExpr={props.displayExpr}
        selectByClick={true}
        onContentReady={
          selectionMode === "single"
            ? treeViewOnContentReady
            : syncTreeViewSelection
        }
        onItemClick={onTreeItemClick}
        onItemSelectionChanged={treeViewItemSelectionChanged}
      />
    );
  };

  const singleSelectionMode = e => {
    setTreeBoxValue(e.value);
    if (!treeView) return;
    if (!e.value) {
      treeView.instance.unselectAll();
    } else {
      treeView.instance.selectItem(e.value);
    }
  };

  const multipleSelectionMode = e => {
    if (treeView) {
      if (e.value === null) {
        treeView.instance.unselectAll();
      } else {
        const values = e.value || treeBoxValue;
        values &&
          values.forEach(value => {
            treeView.instance.selectItem(value);
          });
      }
    }
    if (e.value !== undefined) {
      setTreeBoxValue(e.value);
    }
  };

  const syncTreeViewSelection = e => {
    if (selectionMode === "single") {
      singleSelectionMode(e);
    } else {
      multipleSelectionMode(e);
    }
    props.onChange(e.value);
  };

  const treeViewItemSelectionChanged = async e => {
    if (selectionMode === "single") {
      setTreeBoxValue(e.itemData[props.valueExpr]);
    } else {
      setTreeBoxValue(e.component.getSelectedNodeKeys());
      let selectedValues = [];
      e.component.getSelectedNodes().forEach(item => {
        selectedValues.push(item[props.valueExpr]);
      });
      setTreeBoxValue(selectedValues);
    }
  };

  const treeViewOnContentReady = e => {
    e.component.selectItem(treeBoxValue);
  };

  const onTreeItemClick = () => {
    setIsTreeBoxOpened(false);
  };

  const onTreeBoxOpened = e => {
    if (e.name === "opened") {
      setIsTreeBoxOpened(e.value);
    }
  };

  let isValid = true;
  let error = undefined;

  try {
    error = eval("formik.errors." + props.name);
    if (error !== undefined) isValid = false;
  } catch (ex) {}

  return (
    <React.Fragment>
      {props.label ? (
        <Label for={props.name} className="col-form-label">
          {props.label}
        </Label>
      ) : (
        ""
      )}
      <DropDownBox
        name={props.name}
        value={treeBoxValue}
        opened={isTreeBoxOpened}
        valueExpr={props.valueExpr}
        displayExpr={props.displayExpr}
        placeholder={props.placeholder}
        showClearButton={
          props.showClearButton === null ? true : props.showClearButton
        }
        dataSource={props.dataSource}
        onValueChanged={syncTreeViewSelection}
        onOptionChanged={onTreeBoxOpened}
        contentRender={treeViewRender}
        className={"form-control " + (!isValid ? "is-invalid " : "")}
      />
      {!isValid ? <div className="invalid-feedback">{error}</div> : ""}
    </React.Fragment>
  );
};

TreeViewDropDown.propTypes = {
  dataSource: PropTypes.any,
  valueExpr: PropTypes.string,
  displayExpr: PropTypes.string,
  placeholder: PropTypes.string,
  showClearButton: PropTypes.bool,
  onChange: PropTypes.func,
  parentIdExpr: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  selectionMode: PropTypes.string,
};

export { TreeViewDropDown };
