import HelpModal from "components/Modals/HelpModal";
import { ChangeEvent, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import DayBlock from "./_dayBlock";
import moment from "moment";
import { Scheduler, View } from "devextreme-react/scheduler";
import "devextreme/dist/css/dx.light.css";
import { useDefaultBusinessHours } from "constants/days";
import { AvailabilityObject, TimeObject } from "./_typesAvailability";

interface AvailabilityProps {
  show: boolean;
  handleChange?: Function;
  values?: AvailabilityObject;
  invalidDays?: string[];
  formikProps?: any;
}

export default function Availability(props: AvailabilityProps) {
  const { t } = useTranslation();
  const [availability, setAvailability] = useState<AvailabilityObject>({
    blocks: Object.values(useDefaultBusinessHours()),
    type: "open",
    timeOff: [],
  });
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    if (props.values) {
      setAvailability(props.values);
    }
  }, [props.values]);

  useEffect(() => {
    if (props.handleChange) {
      props.handleChange(availability);
    }
  }, [availability]);

  function changedFormikState() {
    props.formikProps.setFieldValue("availability_dirty", true);
    props.formikProps.setTouched({
      ...props.formikProps.touched,
      availability: true,
    });
  }

  function changeTime(day: number, timeObj: TimeObject) {
    setAvailability(availability => {
      availability.blocks[day].times[timeObj.idx] = {
        start: moment(timeObj.start).format("HH:mm"),
        end: moment(timeObj.end).format("HH:mm"),
      };
      return { ...availability };
    });
    changedFormikState();
  }

  function deleteTime(day: number, timeIdx: number) {
    setAvailability(availability => {
      availability.blocks[day].times = availability.blocks[day].times.filter(
        (_val, index) => index !== timeIdx
      );
      return { ...availability };
    });
    changedFormikState();
  }

  function addTime(day: number) {
    setAvailability(availability => {
      availability.blocks[day].times.push({
        start: "08:00",
        end: "17:00",
      });
      return { ...availability };
    });
    changedFormikState();
  }

  function toggleEditing(check: boolean) {
    if (!check) {
      setEditing(!editing);
    } else {
      return editing;
    }
  }

  function changeAvailabilityType(e: ChangeEvent) {
    const target = e.target as HTMLInputElement;
    const val = target.value;
    setAvailability(availability => {
      availability.type = val;
      return { ...availability };
    });
    changedFormikState();
  }

  return availability ? (
    <div className="availability-container">
      <div className="description">
        <Trans i18nKey="availability_description">
          Setting up availability helps in a couple of ways:
        </Trans>
        <ul>
          <Trans i18nKey="availability_description_list_items">
            <li>
              The online scheduler will only show your available times for
              clients and agents to book, so no one will request an inspection
              for your off hours or double-book you during another scheduled
              inspection.
            </li>
            <li>
              When scheduling your own inspections internally, you will get a
              warning if it is outside your available times.
            </li>
          </Trans>
        </ul>
      </div>
      <div className="availability-type-switcher">
        <label htmlFor="availability-type">Availbility type</label>
        <HelpModal>
          <ul className="p-0 m-0">
            <li>
              <Trans i18nKey="availability_open_schedule_description">
                <span className="font-weight-semibold">Open Schedule</span> let
                {"'"}s you say {'"'}I work from 8am to 5pm{'"'} and allows for
                any scheduling within that block. You can even have multiple
                time blocks to give yourself a break, for example {'"'}8am to
                12pm{'"'} and {'"'}1pm to 5pm{'"'} Open Scheduling is more
                useful when your inspection lengths vary.
              </Trans>
            </li>
            <li>
              <Trans i18nKey="availability_time_slots_description">
                <span className="font-weight-semibold">Time Slots</span> are
                specific start times for inspections. This let{"'"}s your say{" "}
                {'"'}I can start an inspection at 8am, 11:30am, or 3pm{'"'}. You
                can set different time slots for different days of the week,
                too!
              </Trans>
            </li>
          </ul>
        </HelpModal>
        <select
          id="availability-type"
          className="form-select d-inline-block ml-3 w-auto"
          name="availability-type"
          value={availability.type}
          onChange={changeAvailabilityType}
        >
          <option value="open">
            {t("availability_open_schedule", "Open schedule")}
          </option>
          <option value="slots">
            {t("availability_time_slots", "Time slots")}
          </option>
        </select>
      </div>
      <div
        className={
          "d-flex mt-4 availability-type-container" +
          (editing ? " editing" : "")
        }
      >
        {availability.blocks.map((day, idx) => (
          <DayBlock
            key={idx}
            day={day}
            idx={idx}
            changeTime={changeTime}
            deleteTime={deleteTime}
            addTime={addTime}
            invalidDay={
              props?.invalidDays && props?.invalidDays.includes(day.name)
                ? true
                : false
            }
            toggleEditing={toggleEditing}
            type={availability.type}
          />
        ))}
      </div>
      <div className="time-away border-top pt-4">
        <h3 className="">{t("time_away", "Time away")}</h3>
        <div className="description">
          <Trans i18nKey="time_away_description">
            Let the inspection booking and your coworkers know that you will be
            absent by adding your time away to your calendar.
          </Trans>
          <ol>
            <Trans i18nKey="time_away_description_steps">
              <li>Double click on the calendar date to create a new event.</li>
              <li>
                Fill out the details of your time off, including the start and
                end dates.
              </li>
              <li>Click done</li>
              <li>Save your changes</li>
            </Trans>
          </ol>
        </div>
        {props.show ? (
          <Scheduler
            defaultCurrentView="month"
            dataSource={availability.timeOff}
          >
            <View type="month" />
          </Scheduler>
        ) : (
          ""
        )}
      </div>
    </div>
  ) : null;
}
