import { Button, Row, TabPane } from "reactstrap";
import "reactstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css";
import { useTranslation } from "react-i18next";
import UserAccessForm from "./forms/_userAccess";
import { Form, Formik, FormikValues } from "formik";
import { useProfile } from "hooks/profile";
import * as Yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toastr from "toastr";
import { useHistory } from "react-router-dom";

export default function UserAccess(props: { editing: any }) {
  const { t } = useTranslation();
  const profile = useProfile();
  const queryClient = useQueryClient();
  const history = useHistory();

  const saveProfile = useMutation(
    (values: FormikValues) => {
      return profile.save(values);
    },
    {
      onSuccess: (data: any) => {
        toastr.success(
          t("profile_saved_success", "Profile saved successfully")
        );
        queryClient.invalidateQueries(["profile"]);
        history.replace("/company/team/edit", { ...data.data });
      },
      onError: error => {
        console.log(error);
      },
    }
  );

  function handleFormSubmit(values: FormikValues) {
    saveProfile.mutate({ ...props.editing, role: values.role });
  }

  return (
    <TabPane tabId="roles">
      <h2>{t("user_access", "User Access")}</h2>
      <Row>
        <Formik
          initialValues={props.editing}
          enableReinitialize={true}
          onSubmit={handleFormSubmit}
        >
          <Form>
            <UserAccessForm editing={props.editing} />
            <div className="mt-5">
              <Button color="primary" type="submit">
                {t("save_changes", "Save Changes")}
              </Button>
              <Button type="reset" color="white">
                {t("cancel", "Cancel")}
              </Button>
            </div>
          </Form>
        </Formik>
      </Row>
    </TabPane>
  );
}
