import React, { useEffect, useState } from "react";
import { Alert, Col, Container, Row, TabContent } from "reactstrap";
import MetaTags from "react-meta-tags";

// Redux
import { useTranslation } from "react-i18next";

//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb";

// Menu
import SideMenu from "./menu";

// Tab Content
import TabDetails from "./components/_details";
import TabDataPrivacy from "./components/_data-privacy";
import TabProducts from "./components/_products";
import TabDiscounts from "./Discounts";
import TabTravelFees from "./components/_travel-fees";
import TabTaxSettings from "./Tax-Settings";

// Context
import { useCompany } from "hooks/company";

export default function CompanyDetails(props) {
  const [activeTab, setActiveTab] = useState("details");
  const { data: companyData } = useCompany();
  const { t } = useTranslation();

  /* Method to toggle the clicked tab */
  async function toggleTab(tab, navigate = false) {
    if (activeTab !== tab) {
      setActiveTab(tab);

      if (navigate) {
        // reveal submenu if url is a part of one
        const tabEl = document.getElementById(tab);
        const subMenu = tabEl.closest(".side-nav-sub-menu");
        if (subMenu) {
          const parentName = subMenu.id.split("-")[0];
          const parent = document.getElementById(parentName);
          setTimeout(() => {
            parent.click();
          }, 500);
        }
      }
    }
  }

  useEffect(() => {
    if (props.match.params.tab) {
      toggleTab(props.match.params.tab, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="page-content" id="company-settings">
      <MetaTags>
        <title>
          {t("company_details_meta_title", "Company Details")} | Horizon Core
        </title>
      </MetaTags>
      <Container fluid={true}>
        {/* Render Breadcrumb */}
        <Breadcrumb
          title={t("company_settings_page_title", "Company Settings")}
          icon="bx bx-buildings"
          breadcrumbItems={[
            {
              uri: "/company/settings",
              text: t("settings", "Settings"),
            },
            {
              uri: "/company/settings",
              text: companyData?.name || "",
              active: true,
            },
          ]}
        />
        <Row>
          <Col lg="12">
            {props.error ? <Alert color="danger">{props.error}</Alert> : null}
            {props.success ? (
              <Alert color="success">{props.success}</Alert>
            ) : null}
          </Col>
          <Col lg={12}>
            <Row>
              <Col md="3" className="pr-0 side-nav-column">
                <SideMenu activeTab={activeTab} toggleTab={toggleTab} />
              </Col>
              <Col className="px-0">
                <TabContent activeTab={activeTab} className="bg-white">
                  <TabDetails />
                  <TabDataPrivacy />
                  <TabProducts />
                  <TabDiscounts />
                  <TabTravelFees />
                  <TabTaxSettings />
                </TabContent>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
