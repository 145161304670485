export type CustomRole = {
  id: number;
  label: string;
};

const customRoles: CustomRole[] = [
  {
    id: 1,
    label: "Agent management",
  },
  {
    id: 2,
    label: "Payment management",
  },
  {
    id: 3,
    label: "Report management",
  },
  {
    id: 4,
    label: "Create new custom role",
  },
];

export default customRoles;
