import React, { useState } from "react";
import SideMenu from "components/Menus/SideMenu";
import SideMenuNavItem from "components/Menus/SideMenuNavItem";
import { useTranslation } from "react-i18next";
import SideMenuSubMenu from "components/Menus/SideMenuSubMenu";

export default function Menu(props) {
  const { t } = useTranslation();
  const defaultState = {
    product_menu_open: false,
  };
  const [menuState, setMenuState] = useState(defaultState);

  function closeSubMenu(id) {
    const newState = {
      ...menuState,
    };
    newState[id + "_open"] = false;
    setMenuState(newState);
  }

  return (
    <React.Fragment>
      <SideMenu prefix="company_menu">
        <SideMenuNavItem
          id="details"
          label="Company details"
          activeTab={props.activeTab}
          toggleTab={props.toggleTab}
        />
        <SideMenuNavItem
          id="products"
          label={t("products_and_services", "Products and services")}
          hasSubMenu={true}
          activeTab={props.activeTab}
          toggleTab={_e => {
            setMenuState({
              product_menu_open: !menuState.product_menu_open,
            });
          }}
          subMenu={
            <SideMenuSubMenu
              id="products-submenu"
              header={t("products_and_services", "Products and services")}
              open={menuState.product_menu_open}
              onClose={() => {
                closeSubMenu("product_menu");
              }}
            >
              <SideMenuNavItem
                id="products"
                label={t(
                  "manage_products_and_services",
                  "Manage products and services"
                )}
                activeTab={props.activeTab}
                toggleTab={props.toggleTab}
              />
              <SideMenuNavItem
                id="discounts"
                label={t("discounts", "Discounts")}
                activeTab={props.activeTab}
                toggleTab={props.toggleTab}
              />
              <SideMenuNavItem
                id="travel_fees"
                label={t("travel_fees", "Travel fees")}
                activeTab={props.activeTab}
                toggleTab={props.toggleTab}
              />
              <SideMenuNavItem
                id="tax_settings"
                label={t("tax_settings", "Tax settings")}
                activeTab={props.activeTab}
                toggleTab={props.toggleTab}
              />
            </SideMenuSubMenu>
          }
        />
        <SideMenuNavItem
          id="dataPrivacy"
          label={t("data_and_privacy", "Data and privacy")}
          activeTab={props.activeTab}
          toggleTab={props.toggleTab}
        />
        <SideMenuNavItem
          id="connections"
          label={t(
            "connections_and_integrations",
            "Connections and integrations"
          )}
          activeTab={props.activeTab}
          toggleTab={props.toggleTab}
        />
        <SideMenuNavItem
          id="billing"
          label={t("billing", "Billing settings")}
          activeTab={props.activeTab}
          toggleTab={props.toggleTab}
        />
        <SideMenuNavItem
          id="payments"
          label={t("payment_processing", "Payment processing")}
          activeTab={props.activeTab}
          toggleTab={props.toggleTab}
        />
      </SideMenu>
    </React.Fragment>
  );
}
