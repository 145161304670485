/* eslint no-eval: 0 */
import React from "react";
import { Label } from "reactstrap";
import { Field, useFormikContext } from "formik";

const TextInput = props => {
  const formik = useFormikContext();

  const note = props.note ? <div className="note">{props.note}</div> : "";

  let isValid = true;
  let error = undefined;

  try {
    error = eval("formik.errors." + props.name);
    if (error !== undefined) isValid = false;
  } catch (ex) {}

  return (
    <React.Fragment>
      {props.label ? (
        <Label for={props.name} className="col-form-label">
          {props.label}
          {note}
        </Label>
      ) : (
        ""
      )}
      <Field
        name={props.name}
        type={props.type || "text"}
        as={props.type === "textarea" ? "textarea" : null}
        disabled={props.disabled ? true : false}
        placeholder={props.placeholder}
        onChange={props.onChange || formik.handleChange}
        className={
          "form-control " + (!isValid ? "is-invalid " : "") + props.className
        }
      />
      {!isValid ? <div className="invalid-feedback">{error}</div> : ""}
    </React.Fragment>
  );
};

export { TextInput };
