import { BaseHTMLAttributes } from "react";
import { Spinner } from "reactstrap";

export function LoadingPane(props: BaseHTMLAttributes<{}>) {
  return (
    <div
      className="d-flex justify-content-center align-items-center p-4"
      style={props.style}
    >
      <Spinner />
    </div>
  );
}
