import { takeEvery, fork, put, call, all } from "redux-saga/effects";

// Login Redux States
import {
  HYDRATE_WORK_ORDERS,
  HYDRATE_WORK_ORDER,
  EDIT_WORK_ORDER,
  ADD_WORK_ORDER,
} from "./actionTypes";
import {
  hydrateWorkOrdersSuccess,
  WorkOrdersError,
  WorkOrderError,
  messageHubChange,
  messageHubReset,
} from "../../actions";

//Include back-end helper component
import { getBackendHelper } from "../../../helpers/backend_helper";

const backend = getBackendHelper();

function* hydrateWorkOrders() {
  try {
    const response = yield call(backend.hydrateWorkOrders);
    yield put(hydrateWorkOrdersSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(WorkOrdersError(error));
  }
}

function* hydrateWorkOrder({ id }) {
  try {
    const response = yield call(backend.hydrateWorkOrder, id);
    yield put(hydrateWorkOrdersSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(WorkOrderError(error));
  }
}

function* editWorkOrder({ payload: { workOrder } }) {
  try {
    yield call(backend.editWorkOrder, workOrder);
    yield put(messageHubChange("Work order saved!", "success", true));
    yield put(messageHubReset());
  } catch (error) {
    console.log(error);
    yield put(WorkOrdersError(error));
  }
}

function* addWorkOrder({ payload: { workOrder } }) {
  try {
    yield call(backend.addWorkOrder, workOrder);
    yield put(messageHubChange("Work order created!", "success", true));
    yield put(messageHubReset());
  } catch (error) {
    console.log(error);
    yield put(WorkOrdersError(error));
  }
}

export function* watchWorkOrders() {
  yield takeEvery(HYDRATE_WORK_ORDERS, hydrateWorkOrders);
  yield takeEvery(HYDRATE_WORK_ORDER, hydrateWorkOrder);
  yield takeEvery(EDIT_WORK_ORDER, editWorkOrder);
  yield takeEvery(ADD_WORK_ORDER, addWorkOrder);
}

function* WorkOrdersSaga() {
  yield all([fork(watchWorkOrders)]);
}

export default WorkOrdersSaga;
