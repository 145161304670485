import PropTypes from "prop-types";
import React, { Component } from "react";

import { TextInput, RegionDropdown, CountryDropdown } from "components/Fields";
import { withTranslation } from "react-i18next";
import { Col, Label, Row } from "reactstrap";
import { get } from "lodash";

class Address extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.getPrefix = this.getPrefix.bind(this);
    this.getField = this.getField.bind(this);
  }

  getPrefix(addTrailingDot = true) {
    if (this.props.prefix) {
      if (addTrailingDot) {
        return this.props.prefix + ".";
      } else {
        return this.props.prefix;
      }
    } else {
      return "";
    }
  }

  getField(fieldName) {
    let value = "";
    if (this.props.prefix) {
      if (
        get(this.props.formik.values, this.getPrefix(false)) !== undefined &&
        get(this.props.formik.values, this.getPrefix(false))[fieldName] !==
          undefined
      ) {
        value = get(this.props.formik.values, this.getPrefix(false))[fieldName];
      }
    } else {
      if (this.props.formik.values[fieldName] !== undefined) {
        value = this.props.formik.values[fieldName];
      }
    }
    return value;
  }

  render() {
    return (
      <React.Fragment>
        <div>
          {React.Children.map(this.props.children, child => {
            return child;
          })}
        </div>
        <Row>
          <Col lg={6}>
            <Row>
              <Col lg={12}>
                <Label for="address1" className="col-form-label">
                  {this.props.t("street_address", "Street address")}
                </Label>
                <TextInput
                  name={this.getPrefix() + "address1"}
                  disabled={this.props.disabled}
                  onChange={this.props.onChange}
                />
              </Col>
              <Col sm={12}>
                <Label for="address2" className="col-form-label text-muted">
                  {this.props.t(
                    "street_address_line_2",
                    "Address Line 2 (optional)"
                  )}
                </Label>
                <TextInput
                  name={this.getPrefix() + "address2"}
                  disabled={this.props.disabled}
                  onChange={this.props.onChange}
                />
              </Col>
            </Row>
          </Col>
          <Col lg={6}>
            <Row>
              <Col lg={12}>
                <TextInput
                  name={this.getPrefix() + "city"}
                  label={this.props.t("city", "City")}
                  disabled={this.props.disabled}
                  onChange={this.props.onChange}
                />
              </Col>
              <Col lg={12}>
                <CountryDropdown
                  name={this.getPrefix() + "country"}
                  value={this.getField("country")}
                  label={this.props.t("country", "Country")}
                  disabled={this.props.disabled}
                  onChange={this.props.onChange}
                />
              </Col>
            </Row>
          </Col>
          <Col>
            <TextInput
              name={this.getPrefix() + "postCode"}
              label={this.props.t("postal_code", "ZIP / Postal code")}
              disabled={this.props.disabled}
              onChange={this.props.onChange}
            />
          </Col>
          <Col>
            <RegionDropdown
              name={this.getPrefix() + "region"}
              country={this.getField("country")}
              label={this.props.t("region", "Region")}
              value={
                this.getField("country") === "" ? "" : this.getField("region")
              }
              disabled={this.props.disabled}
              onChange={this.props.onChange}
              showErrors={true}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

Address.propTypes = {
  address: PropTypes.any,
  t: PropTypes.any,
  formik: PropTypes.any,
  prefix: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export function DisplayAddress(object) {
  function isEmpty(value) {
    return value !== undefined && value !== "";
  }

  let output = "";
  if (object !== undefined) {
    if (isEmpty(object.address1)) output += object.address1;
    if (isEmpty(object.address2)) output += " " + object.address2;
    if (isEmpty(object.city)) output += ", " + object.city;
    if (isEmpty(object.region)) output += ", " + object.region;
    if (isEmpty(object.postCode)) output += ", " + object.postCode;
    if (isEmpty(object.country)) output += ", " + object.country;
  }
  return output;
}

export default withTranslation()(Address);
