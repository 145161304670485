const currencies = [
  {
    label: "CAD ($9,999.99)",
    options: {
      currency: "CAD",
      currencyDisplay: "narrowSymbol",
      style: "currency",
    },
  },
  {
    label: "USD ($9,999.99)",
    options: {
      currency: "USD",
      currencyDisplay: "narrowSymbol",
      style: "currency",
    },
  },
];

export { currencies };
