import React from "react";
import { useDispatch } from "react-redux";
import { hydrateTeam } from "store/actions";
import { useHistory } from "react-router-dom";
import Breadcrumb from "components/Common/Breadcrumb";
import MetaTags from "react-meta-tags";
import { useTranslation } from "react-i18next";
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap";

import toast from "toastr";

// Tab Panes
import SectionServiceArea from "../components/_sectionServiceArea";
import SectionUserAccess from "../components/_sectionUserAccess";
import SectionProfile from "../components/_sectionProfile";
import SectionSpecialities from "../components/_sectionSpecialties";
import SectionLicense from "../components/_sectionLicense";
import SectionAvailability from "../components/_sectionAvailability";
import InviteMemeberSchema from "../schemas/_inviteMember";
import { Form, Formik, FormikValues } from "formik";
import { useProfile } from "hooks/profile";
import { useQuery } from "@tanstack/react-query";
import { useUsers } from "hooks/users";

export default function InviteMember() {
  const { t } = useTranslation();
  const profile = useProfile();
  const dispatch = useDispatch();
  const history = useHistory();
  const schema = new InviteMemeberSchema({ t: t });
  const users = useUsers();

  const defaultState = {
    accordion: {
      "licenses-certificates": true,
    },
    validationSchema: schema.validation(),
  };

  const { data: profileData } = useQuery(["profile"], profile.fetch);

  async function handleFormSubmit(values: FormikValues) {
    values.companies = [profileData.companies[0]];
    values.status = "invited";
    const result = await users.signUp(values);
    if (!result) {
      toast.error("Failed to create user");
    } else {
      toast.success("User invited successfully");
      dispatch(hydrateTeam());
      setTimeout(() => {
        history.push("/company/team");
      }, 1000);
    }
  }

  function goBack() {
    history.goBack();
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            {t("personal_settings_invite_new_member", "Invite team member")} |
            Horizon Core
          </title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumb
            title={t("invite_member_page_title", "Manage my team")}
            icon="bx bxs-user-rectangle"
            breadcrumbItems={[
              {
                uri: "/company/team",
                text: t("company", "Company"),
                active: false,
              },
              {
                uri: "/company/team",
                text: t("my_team", "My team"),
                active: true,
              },
              {
                uri: "/company/team/invite",
                text: t("new_member", "Invite new member"),
                active: true,
              },
            ]}
          />
          <Formik
            validationSchema={defaultState.validationSchema}
            initialValues={{
              region: "",
            }}
            onSubmit={handleFormSubmit}
          >
            <Form>
              <SectionProfile />
              <SectionUserAccess />

              <Card className="mb-0 pb-0" noprops="true">
                <CardBody className="">
                  <Row>
                    <Col lg="12">
                      <h3 className="">
                        {t(
                          "invite_memeber_additional_information",
                          "Additional information"
                        )}
                      </h3>
                      <p className="pt-1">
                        {t(
                          "invite_member_additional_information_description",
                          "You may fill out any of the additional information below, but it is not required to send the initial invite to the member.  Whatever is not filled in will be required to be filled in by the new member."
                        )}
                      </p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <SectionServiceArea />
              <SectionLicense />

              <SectionAvailability />

              <SectionSpecialities />

              <Card className="" noprops="true">
                <CardBody className="">
                  <Row>
                    <Col sm={12}>
                      <div className="">
                        <Button color="primary" type="submit">
                          {t("send_invitation", "Send Invitation")}
                        </Button>
                        <Button type="reset" color="white" onClick={goBack}>
                          {t("cancel", "Cancel")}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Form>
          </Formik>
        </Container>
      </div>
    </React.Fragment>
  );
}
