import * as types from "./actionTypes";

import { Action, State } from "./types";

const initialState: State = {
  byOwner: new Map(),
  byId: new Map(),
};

export default function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case types.FETCH_TEMPLATES_SUCCESS:
      return {
        ...state,
        byOwner: state.byOwner.set(
          action.payload.ownerId,
          new Set(action.payload.templates)
        ),
      };
    case types.FETCH_TEMPLATE_SUCCESS:
      return {
        ...state,
        byId: state.byId.set(action.payload.id, action.payload.template),
      };
    case types.DELETE_TEMPLATE_SUCCESS:
      state.byId.delete(action.payload.id);

      return { ...state };
    case types.FETCH_TEMPLATE_SYSTEMS_SUCCESS: {
      const template = state.byId.get(action.payload.id);

      if (template?.categories) {
        template.categories.items = action.payload.categories;
        state.byId.set(action.payload.id, template);
      }

      return { ...state };
    }
    default:
      return state;
  }
}
