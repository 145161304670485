import { useMutation, useQuery } from "@tanstack/react-query";
import { API } from "aws-amplify";
import { ICompanyData } from "pages/Company/interfaces/ICompanyData";
import { useTranslation } from "react-i18next";
import toast from "toastr";

const cacheKey = "company";

async function getCompany() {
  const results = await API.get("Company", "/company", {});
  return results[0];
}

async function updateCompany(companySettings: ICompanyData) {
  let dataCompany = await getCompany();
  const oldId = dataCompany["id"];

  for (let key of Object.keys(companySettings)) {
    dataCompany[key] = companySettings[key as keyof ICompanyData];
  }

  if (dataCompany["id"] !== oldId) {
    return false;
  }

  if (dataCompany.photo && dataCompany.photo.indexOf("no-logo") !== -1) {
    dataCompany.photo = "";
  }

  let result = {
    success: false,
    fail: false,
    company: null,
  };

  try {
    result.company = await API.put("Company", "/company", {
      body: dataCompany,
    });
    result.success = true;
  } catch (err) {
    console.log(err);
    result.fail = true;
  }
  return result;
}

export function useCompany() {
  const { t } = useTranslation();
  const { data, isLoading, refetch } = useQuery([cacheKey], getCompany);
  const mutation = useMutation(updateCompany, {
    onSuccess: () => {
      toast.success(t("company_settings_updated", "Company settings updated!"));
      refetch();
    },
  });

  return {
    data,
    isLoading,
    mutation,
  };
}
