import { MESSAGE_HUB_RESET, MESSAGE_HUB_CHANGE } from "./actionTypes";

export const messageHubReset = () => {
  return {
    type: MESSAGE_HUB_RESET,
    payload: {},
  };
};

export const messageHubChange = (message, type, toast) => {
  return {
    type: MESSAGE_HUB_CHANGE,
    payload: { message, type, toast },
  };
};
