import React from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

export default function BusinessHourRow(props) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <tr
        style={{
          height: "50px",
          border: props.invalid ? "solid red 1px" : "none",
        }}
      >
        <th style={{ width: "100px" }} className="pl-3 fw-normal">
          {t(props.index, "")}
        </th>
        <td style={{ width: "150px" }} className="text-center">
          <div className="square-switch m-0">
            <input
              type="checkbox"
              id={props.index + "Open"}
              switch="none"
              longer="true"
              checked={props.day.open}
              onChange={e => {
                props.toggleOpen(props.index);
                props.setFieldValue(props.index + "Open", !props.day.open);
              }}
            />
            <label
              htmlFor={props.index + "Open"}
              style={{
                width: "72px",
              }}
              data-on-label="Open"
              data-off-label="Closed"
              className="m-0"
            />
          </div>
        </td>
        <td style={{ width: "120px" }} className="pl-4 border-start">
          <input
            type="time"
            id={props.index + "Start"}
            value={props.day.start}
            className={classnames({
              "form-control": true,
              "d-none": !props.day.open,
            })}
            onChange={e => {
              props.timeChange(props.index, "start", e.currentTarget.value);
              props.setFieldValue(props.index + "Start", e.currentTarget.value);
            }}
          />
        </td>
        <td style={{ width: "30px" }} className="text-center">
          {props.day.open ? t("to", "to") : ""}
        </td>
        <td style={{ width: "120px" }} className="pr-4">
          <input
            type="time"
            id={props.index + "End"}
            value={props.day.end}
            className={classnames({
              "form-control": true,
              "d-none": !props.day.open,
            })}
            onChange={e => {
              props.timeChange(props.index, "end", e.currentTarget.value);
              props.setFieldValue(props.index + "End", e.currentTarget.value);
            }}
          />
        </td>
      </tr>
    </React.Fragment>
  );
}
